import { IStore } from '../types/IStore';

export const activeDate = (state: IStore) => state.calendar.activeDate;
export const getCalendarEvents = (state: IStore) =>
  state.calendar.calendarEvents;
export const getFilterEventsByType = (state: IStore) =>
  state.calendar.filterEventsByType;
export const getActiveView = (state: IStore) => state.calendar.activeView;
export const getActiveWebView = (state: IStore) => state.calendar.activeWebView;
export const getActiveDashboard = (state: IStore) => state.calendar.activeDashboard;
export const calendarFilter= (state: IStore) => state.calendar.filter;

export const calendarDate = (state:IStore) => state.calendar.calendarDate;
export const mapDate = (state:IStore) => state.calendar.mapDate;

export const isMap = (state:IStore) => state.calendar.isMap;
export const enableMobileAdd = (state:IStore) => state.calendar.enableMobileAdd;
export const enableMultipleSelect = (state:IStore) => state.calendar.enableMultipleAdd;
export const focusDate = (state:IStore) => state.calendar.focusedDate;
export const weekStartDate = (state: IStore) => state.calendar.weekStartDate;
export const multipleAvails = (state: IStore) => state.calendar.multipleAvailabilities;