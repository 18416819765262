import {
  AdminLegendVariant,
  PharmacistLegendVariant,
  adminReports,
  adminReportsFilter,
  getAdminReports,
  userPreferredTimeFormat,
} from '@pharmaplan/common';
import { useLocation } from 'react-router-dom';
import { dropdownMapper } from '@pharmaplan/common/helpers/utils';
import { Constants, customDropdownTypes } from '../../helpers/Constants';
import { useAppSelector } from '../useAppSelector';
import strings from '../../localization';
import {
  availabilitesReportsRow,
  availabilityReportsHeader,
  bookingReportsHeader,
  bookingReportsRow,
  postedWorkshiftsHeader,
  postedWorkshiftsReportRow,
  requestedReportsHeader,
  requestedReportsRow,
} from '../../components/Admin/AdminReports/helper';
import useUserProfileLink from '../Admin/useUserProfileLink';
import useGetItems from '../useGetItems';
import useAdminReportsFn from './useAdminReportsFn';
import useAdminPermissions from '../Admin/useAdminPermissions';

const {
  bookingReport,
  requestedReport,
  availabilityReport,
  postedWorkshiftReport,
} = Constants.paths.admin;
const { All } = customDropdownTypes;

const useAdminReportsHandler = () => {
  const { pathname } = useLocation();
  const { getImage } = useGetItems();
  const { goToUserProfile } = useUserProfileLink();
  const {
    showExpressBooking,
    findMatchingWorkshifts,
    findMatchingAvailabilities,
    showWorkshiftDelete,
    goToApproveBooking,
    showRequestCancellation,
    showDeleteAvailability,
  } = useAdminReportsFn();

  const timeFormat = useAppSelector(userPreferredTimeFormat);
  const filters = useAppSelector(adminReportsFilter);
  const reports = useAppSelector(adminReports);

  const { can } = useAdminPermissions();

  const { replacementTypes, availabilityStatuses } = filters ?? {};
  const { data } = reports ?? {};
  const isAvailabilities = pathname === availabilityReport;

  const api = getAdminReports;

  const reportTypeConfig = {
    [bookingReport]: AdminLegendVariant.booked,
    [requestedReport]: AdminLegendVariant.requested,
    [availabilityReport]: PharmacistLegendVariant.availability,
    [postedWorkshiftReport]: PharmacistLegendVariant.workshift,
  };

  const titleConfig = {
    [bookingReport]: strings.bookingReport,
    [requestedReport]: strings.requestedReport,
    [availabilityReport]: strings.availabilitesReports,
    [postedWorkshiftReport]: strings.postedWorkshiftsReport,
  };

  const headerConfig = {
    [bookingReport]: bookingReportsHeader(),
    [requestedReport]: requestedReportsHeader(),
    [availabilityReport]: availabilityReportsHeader(),
    [postedWorkshiftReport]: postedWorkshiftsHeader(),
  };

  const rowConfig = {
    [bookingReport]: bookingReportsRow({
      data,
      getImage,
      goToUserProfile,
      timeFormat,
      showExpressBooking,
      showRequestCancellation,
      can,
    }),
    [requestedReport]: requestedReportsRow({
      data,
      getImage,
      goToUserProfile,
      timeFormat,
      goToApproveBooking,
    }),
    [availabilityReport]: availabilitesReportsRow({
      data,
      goToUserProfile,
      timeFormat,
      findMatchingWorkshifts,
      showDeleteAvailability,
      can,
    }),
    [postedWorkshiftReport]: postedWorkshiftsReportRow({
      data,
      getImage,
      goToUserProfile,
      timeFormat,
      findMatchingAvailabilities,
      showExpressBooking,
      showWorkshiftDelete,
      can,
    }),
  };

  const handleDropdown = (value: string) =>
    (value === All ? '' : parseInt(value, 10));

  const menuItems = [
    ...[{ key: customDropdownTypes.All, label: strings.all }],
    ...dropdownMapper(
      (isAvailabilities ? availabilityStatuses : replacementTypes) ?? [],
      'key',
      'name',
    ),
  ];

  return {
    api,
    data: reports,
    title: titleConfig[pathname],
    reportType: reportTypeConfig[pathname],
    headers: headerConfig[pathname],
    rows: rowConfig[pathname],
    handleDropdown,
    menuItems,
    isAvailabilities,
  };
};

export default useAdminReportsHandler;
