import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { pipe } from 'rambda';
import classes from './style';
import { reduceWeekToName, selectWeek, weekElements } from './helper';

interface ICustomWeekSelector {
  handleChange: (selectedMonths: string[]) => void;
  initialWeeks: string;
  customClass: Record<string, unknown>
}

const CustomWeekSelector = ({ handleChange, initialWeeks, customClass }: ICustomWeekSelector) => {
  const [weeks, setWeeks] = useState(weekElements());

  useEffect(() => {
    if (initialWeeks.length > 0) {
      initialWeeks.split(',').forEach((week) => {
        setWeeks((prev) =>
          ({
            ...prev,
            [week]: {
              fullName: weeks[week].fullName,
              value: weeks[week]?.value,
              selected: !weeks[week]?.selected,
            },
          }));
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component="div" sx={[classes.monthSelectorContainer, customClass]}>
      {Object.keys(weeks).map((mon) =>
        (
          <Box
            component="div"
            key={mon}
            onClick={() => {
              pipe(selectWeek, reduceWeekToName, handleChange)(mon, setWeeks, weeks);
            }}
            sx={[classes.monthElement, weeks[mon].selected && classes.monthSelected]}
          >
            {weeks[mon].fullName.slice(0, 1)}
          </Box>
        ))}
    </Box>
  );
};

export default CustomWeekSelector;
