import { SxProps } from '@mui/system';
import ICalendarEventType from '../../../../types/ICalendarEventType';

const styles = {
  container: (eventType: ICalendarEventType) =>
    ({
      padding: 0,
      borderRadius: '5px',
      border: '1px solid',
      borderColor: `calendarHover.${eventType}Border`,
      backgroundColor: `calendarHover.${eventType}`,
      color: '#333333',
    }),

  arrow: (eventType: ICalendarEventType) =>
    ({
      color: `calendarHover.${eventType}`,
      '&:before': {
        border: '1px solid',
        borderColor: `calendarHover.${eventType}Border`,
      },
    }),
} satisfies Record<
  string,
  SxProps | ((eventType: ICalendarEventType) => SxProps)
>;

export default styles;
