import React, { FC, useEffect, useRef } from 'react';

import {
  BookingState,
  PharmacistLegendVariant,
  ServiceTypes,
  counterOffer,
  getCounterOffer,
  getEvent,
  pharmacistActions,
  pharmacistEvent,
  pharmacyActions,
  resetStatus,
  successOrSelector,
  successSelector,
} from '@pharmaplan/common';
import ServiceHandler from '@pharmaplan/common/helpers/ServiceHandler';

import CustomButton from '../../common/CustomButton';
import ContactDetails from '../ContactDetails';
import ServiceNote from '../ServiceNotes';
import PharmacyCard from '../PharmacyCard';
import PharmacyOperation from '../PharmacyOperation';

import {
  resetDrawer,
  setButtons,
  setPagination,
} from '../../../actions/drawerActions';
import { selectFooterPaginationCurrent } from '../../../selectors/drawerSelector';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import useServiceWorkshiftDetails from '../../../hooks/serviceWorkshiftDetails/useServiceWorkshiftDetails';

import strings from '../../../localization';
import ICalendarEventType from '../../../types/ICalendarEventType';
import { Constants } from '../../../helpers/Constants';

import ConfirmBox from './ConfirmBox';
import classes from './styles';
import { showConfirmBox } from './helper';

interface IServiceWorkshiftDetails {
  eventIds: Array<string>;
  eventType: ICalendarEventType;
}

const { booked, workshift } = PharmacistLegendVariant;
const { None } = BookingState;
const { self } = ServiceTypes;
const { counterOfferBtn, counterOfferContainer } = classes;

const {
  variant: { text },
} = Constants;

const ServiceWorkshiftDetails: FC<IServiceWorkshiftDetails> = ({
  eventIds,
  eventType,
}) => {
  const dispatch = useAppDispatch();
  const serviceType = ServiceHandler.getService();

  const event = useAppSelector(pharmacistEvent);
  const current = useAppSelector(selectFooterPaginationCurrent);
  const counterOfferEvent = useAppSelector(counterOffer);

  const id = useRef(eventIds[current - 1]);

  const { serviceConfig, goToCounterOffer, buttonConfig } = useServiceWorkshiftDetails(id.current, eventType);

  const { notes, counterOfferId, bookingState } = event ?? {};
  const { notes: counterNotes } = counterOfferEvent ?? {};

  const getEventSuccess = useAppSelector((state) =>
    successSelector([pharmacistActions.getEvent], state));
  const successes = useAppSelector((state) =>
    successOrSelector(
      [
        pharmacistActions.cancelBooking,
        pharmacistActions.cancelRequestedBooking,
        pharmacistActions.requestBooking,
        pharmacyActions.ignoreRequest,
        pharmacyActions.approveRequest,
        pharmacistActions.rejectUpdatedTimings,
        pharmacistActions.acceptUpdatedTimings,
        pharmacistActions.selfCancelBooking,
      ],
      state,
    ));

  const isWorkshift = eventType === workshift;
  const isBooked = eventType === booked;
  const isSelfWorkshift = isWorkshift && serviceType === self;
  const isCounterOffer = counterOfferId && bookingState === None;
  const displayNote = isCounterOffer ? counterNotes : notes;

  const BillDetails = serviceConfig[serviceType].billDetails;
  const { primaryButton, secondaryButton } = buttonConfig[eventType as keyof typeof buttonConfig];

  const handleButtonClick = () => {
    goToCounterOffer(eventIds);
  };

  useEffect(() => {
    id.current = eventIds[current - 1];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  useEffect(() => {
    dispatch(
      getEvent({
        eventId: id.current,
        type: eventType,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventIds, current]);

  useEffect(() => {
    if (getEventSuccess) {
      isCounterOffer && dispatch(getCounterOffer(id.current));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEventSuccess, isCounterOffer]);

  useEffect(() => {
    dispatch(
      setButtons({
        show: true,
        primaryButton: {
          variant: 'contained',
          ...primaryButton,
        },
        secondaryButton: {
          variant: 'text',
          ...secondaryButton,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonConfig, eventType]);

  useEffect(() => {
    dispatch(
      setPagination({
        show: true,
        total: eventIds.length,
        current,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventIds.length]);

  useEffect(() => {
    if (successes) {
      dispatch(resetDrawer());
      dispatch(resetStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successes]);

  return (
    <>
      {showConfirmBox(
        bookingState ?? None,
        eventType,
      ) && <ConfirmBox />}

      <PharmacyCard
        successAction={pharmacistActions.getEvent}
        event={event}
        type={eventType}
      />

      {BillDetails}

      {isSelfWorkshift && (
        <CustomButton
          variant={text}
          label={strings.submitCounterOffer}
          onClick={handleButtonClick}
          customButtonStyle={counterOfferBtn}
          customClass={counterOfferContainer}
        />
      )}

      {isBooked && <ContactDetails />}

      <PharmacyOperation successAction={pharmacistActions.getEvent} event={event} />

      {displayNote && <ServiceNote notes={displayNote} />}
    </>
  );
};

export default ServiceWorkshiftDetails;
