import Routes from '../helpers/Routes';
import { ICreatePharmacyAdmin } from '../models';
import httpClient from './httpClient';

const { pharmacies, pharmacyAdminBasePath, admins } = Routes.pharmacyAdmin;
class PharmacyAdminController {
  basePath: string;
  constructor() {
    this.basePath = pharmacyAdminBasePath;
  }

  pharmacyAdminPharmacies = async () =>
    httpClient.get(`${this.basePath}${pharmacies}`);

  impersonatePharmacy = async (id: string) =>
    httpClient.get(`${this.basePath}${pharmacies}/${id}`);

  getPharmacyAdminProfile = async () =>
    httpClient.get(`${this.basePath}${admins}`);

  updatePharmacyAdminProfile = async (params: ICreatePharmacyAdmin) =>
    httpClient.put(`${this.basePath}${admins}`, { model: params });
}

export default new PharmacyAdminController();
