import { SxProps } from '@mui/system';
import ThemeConstants from '../../../../../theme/ThemeConstants';

import ICalendarEventType from '../../../../../types/ICalendarEventType';

const imageSize = 30;

const styles = {
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    color: 'common.grey850',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px',
  },

  infoBorder: (eventType: ICalendarEventType) =>
    ({
      borderBottom: '1px solid',
      borderColor: `calendarHover.${eventType}Border`,
    }),

  infoFont: {
    paddingLeft: '1px',
    fontSize: 12,
    fontWeight: 400,
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  image: {
    objectFit: 'contain',
  },

  imageContainer: {
    border: '1px solid',
    color: ThemeConstants.common.border,
    height: imageSize,
    width: imageSize,
    marginRight: '2px',
  },
} satisfies Record<
  string,
  SxProps | ((eventType: ICalendarEventType) => SxProps)
>;

export default styles;
