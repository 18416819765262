import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Box, ClickAwayListener, InputLabel } from '@mui/material';
import { FormikProps } from 'formik';
import { SxProps, SystemStyleObject } from '@mui/system';
import { TimeValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useTimeValidation';
import { TimeFormat, userPreferredTimeFormat } from '@pharmaplan/common';
import classes from './style';
import { useAppSelector } from '../../../hooks/useAppSelector';
import genericClasses from '../../../theme/GenericClasses';
import { TextFieldVariants } from '../../../helpers/Constants';
import useTextField from '../../../hooks/useTextField';

const { filled } = TextFieldVariants;
interface ICustomTimePicker {
  label: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  name: string;
  horizontal?: boolean;
  disabled?: boolean;
  variant?: TextFieldVariants;
  onError: (reason: TimeValidationError, value: any) => void;
  error: string;
  minTime?: Date | null;
  maxTime?: Date | null;
  customHandler?: () => void;
  placeholder?: string;
  customClass?: SxProps;
  usePreFilledColors?: boolean;
}
const defaultProps = {
  horizontal: false,
  disabled: false,
  minTime: null,
  maxTime: null,
  customClass: {},
};

const CustomTimePicker = ({
  label,
  horizontal,
  minTime,
  maxTime,
  formik,
  variant,
  name,
  placeholder,
  onError,
  error,
  disabled,
  customClass,
  customHandler,
  usePreFilledColors,
}: ICustomTimePicker) => {
  const [open, setOpen] = useState(false);
  const handleClick = () =>
    setOpen(false);

  const timeFormat = useAppSelector(userPreferredTimeFormat);
  const isTwelveHours = timeFormat === TimeFormat.twelveHours;
  const isFilled = filled === variant;

  const { style } = useTextField({
    value: formik.values[name],
    usePreFilledColors,
  });

  return (
    <ClickAwayListener onClickAway={handleClick}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box
          component="div"
          sx={[
            horizontal ? classes.horizontalTimePicker : {},
            customClass as SystemStyleObject,
          ]}
        >
          <InputLabel>{label}</InputLabel>
          <TimePicker
            minutesStep={15}
            open={open}
            ampm={isTwelveHours}
            minTime={minTime}
            maxTime={maxTime}
            onOpen={() =>
              setOpen(true)}
            onClose={handleClick}
            ampmInClock
            value={formik.values[name]}
            disabled={disabled}
            onError={onError}
            onChange={(newValue) => {
              customHandler?.();
              formik.setFieldValue(name, newValue);
            }}
            renderInput={(params) =>
              (
                <TextField
                  sx={[
                    genericClasses.borderNoneTextField,
                    isFilled ? genericClasses.customFilledInput : {},
                    {
                      '& .MuiInputBase-input': style,
                    },
                  ]}
                  variant={variant}
                  helperText={error}
                // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                    placeholder,
                  }}
                />
              )}
          />
        </Box>
      </LocalizationProvider>
    </ClickAwayListener>
  );
};

CustomTimePicker.defaultProps = defaultProps;

export default CustomTimePicker;
