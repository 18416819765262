import { createReducer } from '@reduxjs/toolkit';
import {
  cancelRequestedBooking,
  createNote,
  deleteAvailability,
  deleteNote,
  getAllPharmacyNames,
  getAvailability,
  getCounterOffer,
  getEvent,
  getNotes,
  getPharmacistCalendar,
  getPharmacistFilterValues,
  getPharmacistHistory,
  getPharmacistMap,
  getPharmacistReplacements,
  getPharmacistReplacementsWeb,
  getPharmacistReports,
  getPharmacistReportsPage,
  getPharmacistResume,
  getPostedShifts,
  getRegions,
  getReplacementPDF,
  getReportsFilterInfo,
  getReportsPDF,
  logout,
  preRequest,
  resetPreRequest,
  resetPostedShifts,
  resetReportStatus,
  updateNote,
  getIncomeReports,
  getIncomeDetails,
  getIncomeReportsPage,
  getIncomeDetailsPage,
  cancelBooking,
  getPharmacistHistoryPagination,
} from '../actions';
import {
  IAllRegions,
  IAvailabilities,
  ICalendarFilterInfo,
  ICounterOffer,
  ICurrentAvailability,
  IErrorAction,
  IHistory,
  IMap,
  INotes,
  IPDF,
  IPharmacistEvent,
  IPharmacyNames,
  IPostedShifts,
  IReplacements,
  IReportFilterInfo,
  IReports,
  IResume,
  ISuccessAction,
  IPreRequestBooking,
  IIncomeReports,
  IIncomeDetails,
  ICancelRequestedBooking,
} from '../models/PharmacistModels';

import { responseType } from '../helpers/Constants';

export interface IPharmacistState {
  availabilities: Array<IAvailabilities>;
  calendar: Array<any>;
  reports: IReports;
  replacements: IReplacements;
  filterValues: ICalendarFilterInfo;
  history: IHistory;
  pharmacyNames: Array<IPharmacyNames>;
  reportActions: ISuccessAction | IErrorAction;
  notesActions: boolean;
  notes: Array<INotes>;
  currentAvailability: ICurrentAvailability;
  map: Array<IMap>;
  regions: IAllRegions;
  pharmacistEvent: IPharmacistEvent;
  pharmacistResume: IResume;
  pdf: IPDF;
  reportsFilterInfo: IReportFilterInfo;
  postedShifts: Array<IPostedShifts>;
  counterOffer: ICounterOffer;
  preRequest: IPreRequestBooking;
  incomeReports: IIncomeReports;
  incomeDetails: IIncomeDetails;
  cancelBookingMessage: ICancelRequestedBooking;
}
const initialState: IPharmacistState = {
  regions: {
    allRegions: [],
    selectedRegions: [],
    pharmacistRegions: [],
  },
  availabilities: [],
  calendar: [],
  filterValues: {
    banners: [],
    cities: [],
    softwares: [],
    weekVolumes: [],
    weekendVolumes: [],
  },
  reports: {
    page: 1,
    count: 0,
    totalCount: 0,
    type: 'booking',
    data: [],
  },
  replacements: {
    page: 1,
    totalCount: 0,
    itemPerPage: 0,
    count: 0,
    pharmacistId: '',
    data: [],
  },
  history: {
    totalCount: 1,
    page: 1,
    itemPerPage: 1,
    pharmacyId: '',
    pharmacyName: '',
    data: [],
  },
  pharmacyNames: [],
  reportActions: {
    key: '',
  },
  notesActions: false,
  notes: [],
  currentAvailability: {
    id: '',
    code: '',
    selectedDate: '',
    startHour: '',
    startMinute: '',
    endHour: '',
    endMinute: '',
    totalHour: '',
    totalMinute: '',
    booked: false,
    notes: '',
    active: false,
    createDate: '',
    endDate: new Date().toISOString(),
    startDate: new Date().toISOString(),
    pharmacistId: '',
    recurrence: {
      createDate: '',
      day: 0,
      endDate: '',
      month: 0,
      notes: '',
      recurrenceId: '',
      recurrenceType: 0,
      startDate: '',
      updateDate: '',
      weekDays: '',
      weekNumber: 0,
    },
    recurrenceId: '',
  },
  map: [],
  pharmacistResume: {
    contentType: '',
    resume: '',
    fileName: '',
  },
  pharmacistEvent: {
    pharmacyName: '',
    pharmacyId: '',
    city: '',
    code: '',
    startDate: '',
    logoImage: '',
    travelAllowance: 0,
    mealAllowance: 0,
    latitude: '0',
    longitude: '0',
    postalCode: '',
    address: '',
    province: '',
    emergencyPhone: '',
    phone: '',
    endDate: '',
    hourlyRate: 0.0,
    region: '',
    notes: '',
    softwareName: '',
    weekVolume: '',
    weekendVolume: '',
    weekDayTechs: '',
    weekNightTechs: '',
    confirmationDate: '',
    weekendDayTechs: '',
    weekendNightTechs: '',
    weekDayPharmacists: '',
    weekNightPharmacists: '',
    weekendDayPharmacists: '',
    weekendNightPharmacists: '',
    favorite: false,
    count: 0,
  },
  pdf: {
    pdfContent: '',
  },
  reportsFilterInfo: {
    pharmacies: [],
    banners: [],
  },
  postedShifts: [],
  counterOffer: {
    accommodationAllowance: 0,
    accommodationAllowanceType: 0,
    bookingState: 0,
    bookingStateExpiration: '',
    counterAccommodationAllowance: 0,
    counterEndDate: new Date().toISOString(),
    counterHourlyRate: 0,
    counterMealAllowance: 0,
    counterStartDate: new Date().toISOString(),
    counterTravelAllowance: 0,
    emergencyFees: 0,
    endDate: new Date().toISOString(),
    hourlyRate: 0,
    mealAllowance: 0,
    mealAllowanceType: 0,
    notes: '',
    pharmaPlanPercentFees: '',
    pharmacyId: '',
    startDate: new Date().toISOString(),
    travelAllowance: 0,
    travelAllowanceType: 0,
    cost: 0,
    counterCost: 0,
  },
  preRequest: {
    key: '',
    isSufficientSoftwareKnowledge: null,
    softwareLevel: 0,
    name: '',
  },
  incomeReports: {
    totalCount: 0,
    itemPerPage: 15,
    page: 1,
    data: [],
  },
  incomeDetails: {
    pharmacyId: '',
    pharmacyName: '',
    logoId: '',
    city: '',
    revenue: 0,
    totalCount: 0,
    page: 1,
    itemPerPage: 15,
    data: [],
  },
  cancelBookingMessage: {
    message: '',
  },
};

const pharmacistReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getPharmacistCalendar.fulfilled, (state, action) => {
      state.calendar = action.payload.length > 0 ? action.payload : [];
    })
    .addCase(getPharmacistFilterValues.fulfilled, (state, action) => {
      state.filterValues = action.payload;
    })
    .addCase(getAvailability.fulfilled, (state, action) => {
      state.currentAvailability = action.payload;
    })
    .addCase(getPharmacistReports.fulfilled, (state, action) => {
      if (action.payload === responseType.noData) {
        state.reports = initialState.reports;
      }
      state.reports = action.payload;
    })
    .addCase(getPharmacistReportsPage.fulfilled, (state, action) => {
      if (action.payload === responseType.noData) return;

      state.reports.page = action.payload?.page;
      state.reports.count = action.payload?.count;
      state.reports.data.push(...action.payload?.data);
    })
    .addCase(getPharmacistHistoryPagination.fulfilled, (state, action) => {
      if (action.payload === responseType.noData) return;

      state.history.page = action.payload?.page;
      state.history.totalCount = action.payload?.totalCount;
      state.history.data.push(...action.payload?.data);
    })
    .addCase(getPharmacistReplacements.fulfilled, (state, action) => {
      if (action.payload === responseType.noData) return;

      if (action.payload.page !== 1) {
        state.replacements.page = action.payload?.page;
        state.replacements.count = action.payload?.count;
        state.replacements.data.push(...action.payload?.data);
        state.replacements.pharmacistId = action.payload?.pharmacistId;
        return;
      }

      state.replacements = action.payload;
    })
    .addCase(getPharmacistReplacementsWeb.fulfilled, (state, action) => {
      if (action.payload === responseType.noData) {
        state.replacements = { ...initialState.replacements };
      } else {
        state.replacements = action.payload;
      }
    })
    .addCase(getPharmacistHistory.fulfilled, (state, action) => {
      state.history = action.payload;
    })
    .addCase(getAllPharmacyNames.fulfilled, (state, action) => {
      state.pharmacyNames = action.payload;
    })
    .addCase(preRequest.fulfilled, (state, action) => {
      state.preRequest = action.payload;
    })
    .addCase(cancelRequestedBooking.fulfilled, (state, action) => {
      state.reportActions = action.payload;
    })
    .addCase(deleteAvailability.fulfilled, (state, action) => {
      state.reportActions = action.payload;
    })
    .addCase(resetReportStatus, (state) => {
      state.reportActions = { key: ' ' };
    })
    .addCase(createNote.fulfilled, (state, action) => {
      state.notesActions = action.payload;
    })
    .addCase(getNotes.fulfilled, (state, action) => {
      state.notes = action.payload;
    })
    .addCase(updateNote.fulfilled, (state, action) => {
      state.notesActions = action.payload;
    })
    .addCase(deleteNote.fulfilled, (state, action) => {
      state.notesActions = action.payload;
    })
    .addCase(getPharmacistMap.fulfilled, (state, action) => {
      state.map = action.payload;
    })
    .addCase(getPharmacistResume.fulfilled, (state, action) => {
      state.pharmacistResume = action.payload;
    })
    .addCase(getRegions.fulfilled, (state, action) => {
      state.regions = action.payload;
    })
    .addCase(getEvent.fulfilled, (state, action) => {
      state.pharmacistEvent = action.payload;
    }) //reset all on logout
    .addCase(getReplacementPDF.fulfilled, (state, action) => {
      state.pdf = action.payload;
    })
    .addCase(getReportsPDF.fulfilled, (state, action) => {
      state.pdf = action.payload;
    })
    .addCase(getReportsFilterInfo.fulfilled, (state, action) => {
      state.reportsFilterInfo = action.payload;
    })
    .addCase(getPostedShifts.fulfilled, (state, action) => {
      state.postedShifts = action.payload;
    })
    .addCase(resetPostedShifts, (state) => {
      state.postedShifts = [...initialState.postedShifts];
    })
    .addCase(getCounterOffer.fulfilled, (state, action) => {
      state.counterOffer = action.payload;
    })
    .addCase(resetPreRequest, (state, action) => {
      state.preRequest = { ...initialState.preRequest };
    })

    .addCase(getIncomeReports.fulfilled, (state, action) => {
      if (action.payload === responseType.noData) {
        state.incomeReports = initialState.incomeReports;
      }
      state.incomeReports = action.payload;
    })

    .addCase(getIncomeReportsPage.fulfilled, (state, action) => {
      if (action.payload === responseType.noData) return;

      state.incomeReports.page = action.payload?.page;
      state.incomeReports.data.push(...action.payload?.data);
    })

    .addCase(getIncomeDetails.fulfilled, (state, action) => {
      if (action.payload === responseType.noData) {
        state.incomeDetails = initialState.incomeDetails;
      }

      state.incomeDetails = action.payload;
    })
    .addCase(getIncomeDetailsPage.fulfilled, (state, action) => {
      if (action.payload === responseType.noData) return;

      state.incomeDetails.page = action.payload?.page;
      state.incomeDetails.data.push(...action.payload?.data);
    })
    .addCase(cancelBooking.fulfilled, (state, action) => {
      state.cancelBookingMessage = action.payload;
    })
    .addCase(logout.fulfilled, () => initialState);
});

export default pharmacistReducer;
