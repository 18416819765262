import { useNavigate } from 'react-router-dom';
import { genericFunction } from '../../AvailableWorkshift/useAvailableWorkshiftActions';
import strings from '../../../localization';
import { useAppDispatch } from '../../useAppDispatch';
import { IFromScreen, renderScreen } from '../../../actions/drawerActions';
import { Constants, ScreenTypes } from '../../../helpers/Constants';
import useDrawer from '../../useDrawer';

type IPharmacyId = { pharmacyId: string };

export const menuActions = (editAction: genericFunction, incompatibleAction: genericFunction) =>
  [
    {
      key: '0',
      label: strings.edit,
      onClick: editAction,
    },
    {
      key: '1',
      label: strings.incompatibleMatch,
      onClick: incompatibleAction,
    },
  ];

const usePharmacyProfileViewDetails = (fromScreen?: IFromScreen, hasOwner?: boolean, back?: () => void) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { closeDrawer } = useDrawer();

  const goToEditPharmacyProfile = ({ pharmacyId }: IPharmacyId) => {
    dispatch(
      renderScreen({
        screenNumber: 3,
        fromScreen,
        screenType: ScreenTypes.profile,
        hasOwner,
        back,
      }),
    );
  };

  const goToIncompatibleMatches = (pharmacyId: string) => {
    closeDrawer();
    navigate(Constants.paths.admin.updateIncompatibleMatch, {
      state: { pharmacyId },
    });
  };

  return {
    actions: (id: string) =>
      menuActions(
        () =>
          goToEditPharmacyProfile({ pharmacyId: id }),
        () =>
          goToIncompatibleMatches(id),
      ),

  };
};

export default usePharmacyProfileViewDetails;
