import { SxProps, Theme } from '@mui/system';

const styles = {
  backContainer: {
    pt: '1px',
  },
  title: {
    fontSize: 18,
    fontWeight: '600',
    color: 'common.grey850',
    paddingTop: '12px',
  },
  container: {
    backgroundColor: 'white',
    px: '32px',
    mx: '25px',
    my: '6px',
    minHeight: '80vh',
  },
  itemContainer: {
    pt: '40px',
  },
  searchContainer: {
    mb: '3px',
  },
  borderRadiusRemove: {
    '& .MuiInputBase-root': {
      borderRadius: 0,
    },
  },
  configTitleContainer: {
    mb: '24px',
    ml: 1,
  },
  configTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: 'grey.500',
  },
  divider: {
    flex: 1,
    ml: '23px',
  },
  buttonContainer: {
    py: '32px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonSize: {
    minWidth: '160px',
  },
  provinceDropdown: {
    maxHeight: 330 },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
