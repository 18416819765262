import { TypeOfUser } from '@pharmaplan/common';
import ICalendarEventType from '../../../types/ICalendarEventType';

const classes = {
  eventContainer: (eventType: ICalendarEventType, typeOfUser: TypeOfUser) =>
    ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderLeftStyle: 'solid',
      borderLeftWidth: '3px',
      borderLeftColor: `${typeOfUser}.${eventType}`,
      backgroundColor: `${typeOfUser}.${eventType}Light`,
      borderRadius: '0px 6px 6px 0px',
      paddingLeft: '10px',
      marginTop: '5px',
      paddingRight: '10px',
      minWidth: '140px',
      minHeight: '42px',
      cursor: 'pointer',
    // style only comes in the week view
    }),
  iconDynamicColor: (
    eventType: ICalendarEventType,
    typeOfUser: TypeOfUser,
  ) =>
    ({
      fill: `${typeOfUser}.${eventType}`,
    }),
  eventDataContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  eventTitle: (eventType: ICalendarEventType, typeOfUser: TypeOfUser) =>
    ({
      color: `${typeOfUser}.${eventType}`,
      fontWeight: 500,
      fontSize: '14px',
      padddingLeft: '5px!important',
      overflow: 'hidden',
      marginRight: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),

  eventTime: (eventType: ICalendarEventType, typeOfUser: TypeOfUser) =>
    ({
      color: `${typeOfUser}.${eventType}`,
      fontWeight: 500,
      fontSize: '12px',
      display: 'flex',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
  rightGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightBlock: {
    display: 'flex',
    backgroundColor: 'white',
    height: 20,
    width: 20,
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textColor: (eventType: ICalendarEventType, typeOfUser: TypeOfUser) =>
    ({
      color: `${typeOfUser}.${eventType}`,
      fontWeight: 500,
      fontSize: 12,
    }),
  eventClockIconContainer: (
    eventType: ICalendarEventType,
    typeOfUser: TypeOfUser,
  ) =>
    ({
      color: `${typeOfUser}.${eventType}`,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      overflow: 'hidden',
      '& svg': {
        maxHeight: '15px',
        maxWidth: '15px',
      },
    }),
  paperPopover: {
    width: 550,
  },
  itemContainer: {
    margin: '5px',
    paddingLeft: '8px',
    paddingRight: '8px',
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    borderLeft: '1px solid black',
  },
  itemNameContainer: {
    maxWidth: '200px',
    justifyContent: 'flex-start',
  },
  textOverflowFixes: {
    display: 'block',
    maxWidth: '140px',
    fontSize: '13px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  hideBorder: {
    borderLeft: '0',
  },
  icon: {
    margin: '5px',
  },
  logoWidth: {
    width: '72px',
  },
  flex: {
    display: 'flex',
  },
  mt: {
    marginTop: '3px',
  },
};

export default classes;
