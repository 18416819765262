import { emailRegex } from '@pharmaplan/common';
import * as Yup from 'yup';
import { postalCodeRegExp } from '../../../../helpers/Constants';
import strings from '../../../../localization';

const PharmacistEditProfileValidator = () =>
  Yup.object().shape({
    name: Yup.string().required(strings.requiredField),
    description: Yup.string(),
    email: Yup.string()
      .matches(emailRegex, strings.emailValid)
      .required(strings.requiredField),
    languageId: Yup.string().required(strings.requiredField),
    province: Yup.string().required(strings.requiredField),
    address: Yup.string(),
    city: Yup.string().required(strings.requiredField),
    postalCode: Yup.string()
      .required(strings.requiredField)
      .matches(postalCodeRegExp, strings.postalCodeValidation),
    phone: Yup.string()
      .required(strings.requiredField)
      .min(
        12,
        strings.formatString(
          strings.phoneValidation,
          strings.homePhone,
        ) as string,
      ),
    mobile: Yup.string().min(
      12,
      strings.formatString(strings.phoneValidation, strings.mobile) as string,
    ),
  });

export default PharmacistEditProfileValidator;
