import React, { FC, useCallback } from 'react';

import { Chip } from '@mui/material';

import { ReactComponent as PostedIcon } from '@pharmaplan/common/assets/icons/postedIcon.svg';
import { ReactComponent as PendingIcon } from '@pharmaplan/common/assets/icons/pendingIcon.svg';
import { PharmacistLegendVariant } from '@pharmaplan/common';
import { ChipType } from '../../../helpers/Constants';
import ICalendarEventType from '../../../types/ICalendarEventType';

import classes from './styles';

interface ICustomChip {
  type: ICalendarEventType | ChipType;
  label?: string | number;
}

const defaultProps = {};

const CustomChip: FC<ICustomChip> = ({ type, label }) => {
  const isHourlyRateChip = type === ChipType.hourlyRate || type === ChipType.pharmacyHourlyRate;

  const iconStyle = useCallback(() => {
    switch (type) {
      case PharmacistLegendVariant.workshift:
        return classes.posted;
      case PharmacistLegendVariant.requested:
        return classes.requested;
      case PharmacistLegendVariant.booked:
        return classes.booked;
      case ChipType.hourlyRate:
        return classes.hourlyRate;
      case ChipType.pharmacyHourlyRate:
        return classes.pharmacyHourlyRate;
      default:
        return classes.posted;
    }
  }, [type]);

  const customPosted = useCallback(
    () =>
      (
        <PostedIcon
          stroke={iconStyle().color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      ),
    [iconStyle],
  );

  const icon = useCallback(() => {
    switch (type) {
      case PharmacistLegendVariant.booked:
      case PharmacistLegendVariant.workshift:
        return customPosted();
      case PharmacistLegendVariant.requested:
        return <PendingIcon />;
      default:
        return customPosted();
    }
  }, [type, customPosted]);

  const chipConfig = isHourlyRateChip
    ? {
      sx: classes.hourlyRateChip,
    }
    : {
      icon: icon(),
      sx: classes.chip,
    };

  return (
    <Chip
      label={label}
      sx={[iconStyle(), chipConfig.sx]}
      icon={chipConfig.icon}
    />
  );
};

CustomChip.defaultProps = defaultProps;

export default CustomChip;
