import { AdminLegendVariant, ICalendarEventType } from '@pharmaplan/common';
import { renderScreen, setOpen } from '../../actions/drawerActions';
import { ScreenTypes } from '../../helpers/Constants';
import { useAppDispatch } from '../useAppDispatch';

const { posted, requested, booked } = AdminLegendVariant;
const { availableWorkshift, requestedList, bookingList } = ScreenTypes;

const useDetailsNavigation = () => {
  const dispatch = useAppDispatch();

  const handleNavigation = (id: string, eventType: ICalendarEventType, workshiftId?: string) =>
    () => {
      switch (eventType) {
        case posted:
          dispatch(
            renderScreen({
              screenNumber: 3,
              screenType: availableWorkshift,
              eventId: id,
              type: posted,
            }),
          );
          break;
        case requested:
          dispatch(
            renderScreen({
              screenNumber: 3,
              screenType: requestedList,
              workshiftId,
              eventId: id,
            }),
          );
          break;
        case booked:
          dispatch(
            renderScreen({
              screenNumber: 2,
              screenType: bookingList,
              eventId: id,
            }),
          );
          break;
        default:
          break;
      }
      dispatch(setOpen(true));
    };
  return { handleNavigation };
};

export default useDetailsNavigation;
