import React, { FC, useEffect } from 'react';

import { Avatar, IconButton } from '@mui/material';
import { Box } from '@mui/system';

import {
  adminActions,
  adminPicture,
  getAdminPicture,
  getPharmacistPicture,
  getTypeOfUser,
  isPharmacyAdminLoginSelector,
  pharmacistActions,
  pictureUpload,
  profileImage,
  resetStatus,
  successSelector,
  TypeOfUser,
  uploadAdminPicture,
} from '@pharmaplan/common';
import { ReactComponent as CameraIcon } from '@pharmaplan/common/assets/icons/cameraIcon.svg';

import { useAppSelector } from '../../../hooks/useAppSelector';
import useAdmin from '../../../hooks/useAdmin';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { InputTypes } from '../../../helpers/Constants';
import genericClasses from '../../../theme/GenericClasses';

import classes from './styles';

interface ICustomAvatar {
  showFullImage: () => void;
}

const CustomAvatar: FC<ICustomAvatar> = ({ showFullImage }) => {
  const dispatch = useAppDispatch();
  const userType = useAppSelector(getTypeOfUser);

  const avatar = useAppSelector(profileImage);
  const adminAvatar = useAppSelector(adminPicture);
  const isPharmacyAdminLogin = useAppSelector(isPharmacyAdminLoginSelector);

  const { isAdmin } = useAdmin();

  const inputFile = React.useRef<HTMLInputElement>(null);
  const isPharmacist = userType === TypeOfUser.pharmacist;
  const isPharmacy = userType === TypeOfUser.pharmacy;

  const uploadSuccess = useAppSelector((state) =>
    successSelector([pharmacistActions.pictureUpload], state));

  const adminUploadSuccess = useAppSelector((state) =>
    successSelector([adminActions.uploadAdminPicture], state));

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    if (file && file?.length > 0) {
      const formData = new FormData();
      formData.append('image', file[0]);
      if (isAdmin) {
        dispatch(uploadAdminPicture(formData));
      } else {
        dispatch(pictureUpload(formData));
      }
    }
  };

  const imgProps = isPharmacy || isPharmacyAdminLogin ? classes.avatarContain : classes.avatarCover;

  useEffect(() => {
    if (adminUploadSuccess) {
      dispatch(getAdminPicture());
      dispatch(resetStatus());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminUploadSuccess]);

  useEffect(() => {
    if (uploadSuccess) {
      dispatch(getPharmacistPicture());
      dispatch(resetStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadSuccess]);

  return (
    <Box sx={classes.avatarBox}>
      <Avatar
        onClick={showFullImage}
        src={isAdmin ? adminAvatar : avatar}
        imgProps={{ style: imgProps }}
        sx={classes.settingsAvatar}
      />
      {(isPharmacist || isAdmin) && (
        <Box
          onClick={() =>
            inputFile.current?.click()}
          sx={classes.cameraContainer}
        >
          <IconButton color="secondary" sx={{ paddingX: 5 }}>
            <CameraIcon />
            <input
              style={genericClasses.hidden}
              accept={'image/*'}
              ref={inputFile}
              onChange={handleUpload}
              type={InputTypes.file}
            />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default CustomAvatar;
