import React, { FC, useEffect } from 'react';

import { useFormik, FormikValues } from 'formik';
import { Box } from '@mui/system';

import {
  AdminAllowanceNames,
  AdminKeyToParse,
  adminWorkshiftDetails,
  editAdminWorkshift,
  getWorkshiftDetails,
} from '@pharmaplan/common';
import genericClasses from '../../../theme/GenericClasses';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import useSelfService from '../../../hooks/useSelfService';
import { toJSDate } from '../../Dashboard/MainCalendar/helpers';
import strings from '../../../localization';
import AddUpdateEvents from '../../common/AddUpdateEvents';
import CustomTextField from '../../common/CustomTextField';
import AllowanceAdminWorkshift from './AllowanceAdminWorkshift';
import {
  IFromScreen,
  renderScreen,
  setBack,
  setButtons,
  setFooterShow,
  setPagination,
} from '../../../actions/drawerActions';
import {
  MomentTimeFormats,
  OtherScreens,
  ScreenTypes,
} from '../../../helpers/Constants';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { appendTimeToDate } from '../../../helpers/workshift/functions';
import {
  decimalConverterProxy,
  handlerForZeroes,
} from '../../../helpers/proxyHandlers';

import DetailsHeader from '../ApproveBooking/DetailsHeader';
import styles from './styles';
import Validator from './validator';
import { rateValidation } from '../../../helpers/Functions';
import useDrawer from '../../../hooks/useDrawer';

interface IEditWorkshift {
  workshiftId: string;
  fromScreen?: IFromScreen;
}

const {
  emergencyFees,
  mealAllowance,
  travelAllowance,
  paidHourlyRate,
  accommodationAllowance,
  pharmacyHourlyRate: allowancePharmacyHourlyRate,
} = AdminAllowanceNames;

export const zeroHandler = (
  values: { [key: string]: number },
  parser: Array<string>,
) =>
  parser.reduce((acc: Record<string, string | number>, curr) => {
    acc[curr] = values[curr] === 0 ? '' : values[curr];
    return acc;
  }, {});

const EditWorkshift: FC<IEditWorkshift> = ({ workshiftId, fromScreen }) => {
  const dispatch = useAppDispatch();
  const currentWorkshift = useAppSelector(adminWorkshiftDetails);

  const isFromListOfWorkshifts = fromScreen === OtherScreens.ListOfWorkshifts;
  const { closeDrawer } = useDrawer();

  const {
    startDate,
    endDate,
    note,
    pharmacyName,
    emergencyFees: workshiftEmergencyFees,
    travelAllowance: workshiftTravelAllowance,
    mealAllowance: workshiftMealAllowance,
    accommodationAllowance: workshiftAccommodationAllowance,
    pharmacyHourlyRate,
    pharmacistHourlyRate: workshiftPharmacistRate,
  } = currentWorkshift ?? {};

  const { workshiftParser } = useSelfService();

  useEffect(() => {
    dispatch(getWorkshiftDetails(workshiftId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workshiftId]);

  const handleSubmit = (values: FormikValues) => {
    const {
      startTime,
      endTime,
      pharmacyHourlyRate: formikPharmacyHourlyRate,
      paidHourlyRate: formikPaidHourlyRate,
    } = values ?? {};

    const start = appendTimeToDate(startDate, startTime).toFormat(
      MomentTimeFormats.fullDate,
    );
    const end = appendTimeToDate(endDate, endTime).toFormat(
      MomentTimeFormats.fullDate,
    );

    const isPharmacyGreaterPharmacist = rateValidation({
      pharmacistRate: formikPaidHourlyRate,
      pharmacyRate: formikPharmacyHourlyRate,
      dispatch,
    });

    if (isPharmacyGreaterPharmacist) {
      dispatch(
        editAdminWorkshift({
          ...workshiftParser(values, AdminKeyToParse),
          workshiftId,
          startDate: start,
          endDate: end,
          note: values.notes,
        }),
      );
    }
  };

  const allowances = {
    [emergencyFees]: workshiftEmergencyFees,
    [travelAllowance]: workshiftTravelAllowance,
    [mealAllowance]: workshiftMealAllowance,
    [accommodationAllowance]: workshiftAccommodationAllowance,
    [allowancePharmacyHourlyRate]: pharmacyHourlyRate,
    [paidHourlyRate]: workshiftPharmacistRate,
  };

  const handledForZero = new Proxy(allowances, handlerForZeroes);
  const handledForDecimals = new Proxy(handledForZero, decimalConverterProxy);

  const formik = useFormik({
    initialValues: {
      ...handledForDecimals,
      startTime: toJSDate(startDate),
      endTime: toJSDate(endDate),
      notes: note,
    },
    enableReinitialize: true,
    validationSchema: Validator(),
    onSubmit: handleSubmit,
  });

  const goToAvailableWorkshift = () => {
    if (isFromListOfWorkshifts) {
      closeDrawer();
    } else {
      dispatch(
        renderScreen({
          screenNumber: 1,
          screenType: ScreenTypes.availableWorkshift,
          date: startDate,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(setFooterShow(true));
    dispatch(setPagination({ show: false }));
    dispatch(setBack(goToAvailableWorkshift));
    dispatch(
      setButtons({
        show: true,
        primaryButton: {
          label: strings.confirm,
          onClick: () => {
            formik.handleSubmit();
          },
        },
        secondaryButton: {
          label: strings.discardChanges,
          onClick: goToAvailableWorkshift,
        },
      }),
    );
    return () => {
      dispatch(setBack(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  return (
    <Box sx={styles.container}>
      <DetailsHeader
        pharmacyName={pharmacyName}
        startDate={startDate}
        endDate={endDate}
      />

      <AddUpdateEvents
        formik={formik}
        showRecurrences={false}
        setUserWorkTimings={() =>
          null}
        isSeries
      />

      <AllowanceAdminWorkshift formik={formik} />

      <Box sx={styles.notes}>
        <CustomTextField
          variant="outlined"
          formik={formik}
          id="notes"
          placeholder=" "
          label={strings.notes}
          name="notes"
          multiline
          maxLength={200}
          customClass={genericClasses.borderNoneTextField}
        />
      </Box>
    </Box>
  );
};

export default EditWorkshift;
