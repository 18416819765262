import React from 'react';
import { ArrowForward } from '@mui/icons-material';

import { ArrayToDD, dropdownMapper } from '@pharmaplan/common/helpers/utils';
import Map from '@pharmaplan/common/assets/icons/map.svg';

import {
  ISoftware,
  IBanners,
  IProvinces,
  IContactPreferences,
  IUtils,
  IAllRegions,
} from '@pharmaplan/common';

import {
  Constants,
  InputTypes,
  daysDropdown,
  telephoneInputMask,
} from '../../helpers/Constants';
import { LanguageLevel } from '../Profile/ProfileForm/Forms';
import strings from '../../localization';

import { IPharmacistSignupForm, IPharmacySignupForm } from '../Profile/types';
import style from './styles';

const year = new Date().getFullYear();

const years = Array.from(new Array(50), (val, index) => {
  const value = year - index;

  return { key: value, label: value };
});

export type ICommonSignupContact = typeof commonContact;

const commonContact = (type: string, requireLabel: boolean) =>
  [
    {
      name: `${type}ContactName`,
      type: Constants.formInputType.text,
      key: 'contactName',
      requireLabel,
      label: strings.name,
      xs: 6,
    },
    {
      name: `${type}ContactPhone`,
      type: Constants.formInputType.masked,
      mask: telephoneInputMask,
      requireLabel,
      key: 'contactPhone',
      maxLength: 12,
      label: strings.phone,
      xs: 6,
    },
    {
      name: `${type}ContactPhoneExt`,
      type: Constants.formInputType.text,
      key: 'contactPhoneExt',
      label: strings.phoneExt,
      maxLength: 6,
      xs: 6,
    },
    {
      name: `${type}ContactMobile`,
      type: Constants.formInputType.masked,
      key: 'contactMobile',
      maxLength: 12,
      label: strings.mobile,
      mask: telephoneInputMask,
      xs: 6,
    },
    {
      name: `${type}ContactEmail`,
      requireLabel,
      type: Constants.formInputType.text,
      key: 'contactEmail',
      label: strings.email,
      xs: 6,
    },
  ];

export const pharmacySignupFields = ({
  softwares,
  provinces,
  banners,
  contactPreferences,
  showTermsAndConditions,
  showPickYourLocation,
  handleChange,
  isSelfService,
}: {
  softwares: ISoftware[];
  provinces: IProvinces[];
  banners: IBanners[];
  contactPreferences: IContactPreferences[];
  showTermsAndConditions: () => void;
  showPickYourLocation: () => void;
  handleChange: (e: boolean) => void;
  isSelfService: boolean;
}): IPharmacySignupForm =>
  ({
    pharmacyInfo: [
      {
        title: strings.general.toUpperCase(),
        hideSave: true,
        form: () =>
          [
            {
              label: strings.ownerCompanyName,
              type: Constants.formInputType.text,
              xs: 6,
              name: 'name',
              requireLabel: true,
            },
            {
              label: strings.banner,
              type: Constants.formInputType.dropdown,
              name: 'pharmacyChainId',
              xs: 6,
              requireLabel: true,
              menuItems: dropdownMapper(banners, 'pharmacyChainId', 'name'),
            },
            {
              label: strings.bannerNumber,
              type: Constants.formInputType.text,
              xs: 6,
              requireLabel: true,
              name: 'number',
            },
            {
              label: strings.preferredLanguage,
              type: Constants.formInputType.text,
              xs: 6,
              name: 'languageId',
              id: 'languageId',
              requireLabel: true,
              disabled: true,
            },
            {
              label: strings.software,
              type: Constants.formInputType.dropdown,
              xs: 6,
              name: 'softwareId',
              id: 'softwareId',
              requireLabel: true,
              menuItems: dropdownMapper(softwares, 'softwareId', 'name'),
            },
            {
              label: strings.selectedContactPreference,
              type: Constants.formInputType.dropdown,
              requireLabel: true,
              xs: 6,
              multiple: true,
              name: 'selectedContactPreferences',
              id: 'selectedContactPreferences',
              menuItems: dropdownMapper(
                contactPreferences,
                'contactPreferenceId',
                'name',
              ),
            },
          ],
      },
      {
        title: strings.weekdays,
        type: 'multiple',
        hideSave: true,
        form: () =>
          [
            {
              label: strings.volume,
              type: Constants.formInputType.text,
              xs: 12,
              requireLabel: true,
              name: 'weekVolume',
              id: 'weekVolume',
            },
            {
              label: strings.pharmacist,
              type: Constants.formInputType.opreation,
              xs: 6,
              suffix: 'week',
              name: 'Volume',
              requireLabel: true,
              opreationType: 'Pharmacists',
              id: 'weekendVolume',
            },
            {
              label: strings.technicians,
              type: Constants.formInputType.opreation,
              xs: 6,
              requireLabel: true,
              opreationType: 'Techs',
              suffix: 'week',
              name: 'Volume',
            },
          ],
      },
      {
        title: strings.weekends,
        type: 'multiple',
        hideSave: true,
        form: () =>
          [
            {
              label: strings.volume,
              type: Constants.formInputType.text,
              xs: 12,
              requireLabel: true,
              name: 'weekendVolume',
              id: 'weekendVolume',
            },
            {
              label: strings.pharmacist,
              type: Constants.formInputType.opreation,
              xs: 6,
              requireLabel: true,
              suffix: 'weekend',
              name: 'Volume',
              opreationType: 'Pharmacists',
            },
            {
              label: strings.technicians,
              type: Constants.formInputType.opreation,
              xs: 6,
              requireLabel: true,
              opreationType: 'Techs',
              suffix: 'weekend',
              name: 'Volume',
            },
          ],
      },
    ],
    coordinates: [
      {
        title: strings.contacts.toUpperCase(),
        type: 'multiple',
        hideSave: true,
        form: () =>
          [
            {
              key: 'phone',
              type: Constants.formInputType.masked,
              mask: telephoneInputMask,
              name: 'phone',
              requireLabel: true,
              label: strings.pharmacyPhone,
              maxLength: 12,
              xs: 6,
            },
            {
              key: 'fax',
              type: Constants.formInputType.masked,
              mask: telephoneInputMask,
              name: 'fax',
              label: strings.pharmacyFax,
              maxLength: 12,
              xs: 6,
            },
            {
              key: 'emergencyPhone',
              type: Constants.formInputType.masked,
              mask: telephoneInputMask,
              name: 'emergencyPhone',
              requireLabel: true,
              label: strings.emergencyPhone,
              maxLength: 12,
              xs: 6,
            },
          ],
      },
      {
        title: strings.coordinates.toUpperCase(),
        hideSave: true,
        form: () =>
          [
            isSelfService && {
              label: strings.mapButtonLabel,
              fieldLabel: strings.pharmacyLocation,
              type: Constants.formInputType.mapButton,
              xs: 6,
              name: 'pharmacyLocation',
              id: 'pharmacyLocation',
              requireLabel: true,
              fullWidth: true,
              startIcon: <img src={Map} alt="mapIcon" width="20" height="20" />,
              endIcon: <ArrowForward />,
              onClick: () => {
                showPickYourLocation();
              },
              style: style.mapButton,
              helperTextStyle: style.mapButtonHelperTextStyle,
            },
            {
              key: 'address',
              type: Constants.formInputType.text,
              name: 'address',
              requireLabel: true,
              label: strings.address,
              xs: 6,
            },
            {
              key: 'city',
              type: Constants.formInputType.text,
              name: 'city',
              requireLabel: true,
              label: strings.city,
              xs: 6,
            },
            {
              key: 'postalCode',
              type: Constants.formInputType.text,
              capitalize: true,
              requireLabel: true,
              name: 'postalCode',
              customType: InputTypes.postalCode,
              label: `${strings.postalCode} (${strings.postalCodeHint})`,
              maxLength: 10,
              placeholder: ' ',
              xs: 6,
            },
            {
              key: 'province',
              type: Constants.formInputType.dropdown,
              requireLabel: true,
              name: 'province',
              menuItems: dropdownMapper(provinces, 'key', 'name'),
              label: strings.province,
              xs: 6,
            },
          ],
      },
    ],

    primary: {
      title: strings.primary,
      type: 'single',
      form: () =>
        commonContact('primary', true),
    },
    secondary: {
      title: strings.secondary,
      type: 'single',
      form: () =>
        commonContact('secondary', false),
    },
    accounting: {
      title: strings.accounting,
      type: 'single',
      form: () =>
        [
          {
            name: 'accountingContactName',
            type: Constants.formInputType.text,
            key: 'contactName',
            requireLabel: true,
            label: strings.name,
            xs: 6,
          },
          {
            name: 'accountingContactPhone',
            type: Constants.formInputType.masked,
            mask: telephoneInputMask,
            requireLabel: true,
            key: 'contactPhone',
            maxLength: 12,
            label: strings.phone,
            xs: 6,
          },
          {
            name: 'accountingContactPhoneExt',
            type: Constants.formInputType.text,
            key: 'contactPhoneExt',
            maxLength: 6,
            label: strings.phoneExt,
            xs: 6,
          },
          {
            name: 'accountingContactMobile',
            type: Constants.formInputType.masked,
            key: 'contactMobile',
            maxLength: 12,
            label: strings.mobile,
            mask: telephoneInputMask,
            xs: 6,
          },
          {
            name: 'accountingContactEmail',
            requireLabel: true,
            type: Constants.formInputType.text,
            key: 'contactEmail',
            label: strings.email,
            xs: 6,
          },
          {
            label: strings.agreeOn,
            linkLabel: strings.termsAndConditions,
            onLinkClick: showTermsAndConditions,
            handleChange,
            type: Constants.formInputType.checkbox,
            xs: 12,
            name: 'termsAndConditions',
            id: 'termsAndConditions',
            requireLabel: true,
            customClass: {
              color: '#4A5056',
            },
          },
        ],
    },
  });

export const pharmacistSignupFields = ({
  proUtils,
  regions,
  showTermsAndConditions,
  showPickYourLocation,
  handleChange,
  isSelfService,
}: {
  proUtils: IUtils;
  regions: IAllRegions;
  showTermsAndConditions: () => void;
  showPickYourLocation: () => void;
  handleChange: (e: boolean) => void;
  isSelfService: boolean;
}): IPharmacistSignupForm =>
  ({
    general: {
      title: strings.general.toUpperCase(),
      type: 'single',
      form: () =>
        [
          {
            name: 'firstName',
            label: strings.firstName,
            requireLabel: true,
            type: Constants.formInputType.text,
            xs: 6,
          },
          {
            name: 'lastName',
            label: strings.lastName,
            requireLabel: true,
            type: Constants.formInputType.text,
            xs: 6,
          },
          {
            label: strings.preferredLanguage,
            type: Constants.formInputType.text,
            xs: 6,
            requireLabel: true,
            name: 'preferdLanguage',
            id: 'preferdLanguage',
            disabled: true,
          },
        ],
    },
    geographicAvailability: {
      title: strings.myGeographicAvailability.toUpperCase(),
      type: 'single',
      form: () =>
        [
          {
            label: strings.firstName,
            requireLabel: true,
            type: Constants.formInputType.accordion,
            xs: 12,
            items: regions,
          },
        ],
    },
    professionalInfo: {
      title: strings.profissonalInfo.toUpperCase(),
      type: 'single',
      form: () =>
        [
          {
            label: strings.licenseNo,
            type: Constants.formInputType.text,
            xs: 6,
            name: 'licenseNumber',
            id: 'licenseNumber',
            requireLabel: true,
          },
          {
            label: strings.pharmacistCapacity,
            type: Constants.formInputType.dropdown,
            xs: 6,
            name: 'pharmacistCapacityId',
            id: 'pharmacistCapacityId',
            menuItems: dropdownMapper(
              proUtils.pharmacistCapacities ?? [],
              'pharmacistCapacityId',
              'description',
            ),
            requireLabel: true,
          },
          {
            label: strings.yearLicensed,
            type: Constants.formInputType.dropdown,
            xs: 6,
            name: 'graduationYear',
            id: 'graduationYear',
            menuItems: years,
            requireLabel: true,
          },
          {
            label: strings.yearsOfExperience,
            xs: 6,
            key: 'yearsOfExperience',
            id: 'yearsOfExperience',
            type: Constants.formInputType.text,
            name: 'yearsOfExperience',
            disabled: true,
          },
          {
            label: strings.englishLevel,
            type: Constants.formInputType.dropdown,
            xs: 6,
            name: 'englishLevel',
            id: 'englishLevel',
            menuItems: LanguageLevel(),
          },
          {
            label: strings.frenchLevel,
            type: Constants.formInputType.dropdown,
            xs: 6,
            name: 'frenchLevel',
            id: 'frenchLevel',
            menuItems: LanguageLevel(),
          },
          {
            label: strings.otherLanguage,
            type: Constants.formInputType.text,
            xs: 6,
            name: 'otherLanguages',
            id: 'otherLanguages',
          },
          {
            label: strings.areYouIncorporated,
            type: Constants.formInputType.radio,
            xs: 6,
            name: 'incorporated',
            id: 'incorporated',
            radioItems: [
              { label: strings.yes, value: true },
              { label: strings.no, value: false },
            ],
          },
        ],
    },
    selectedSoftwares: {
      title: strings.softwareFamiliarity.toUpperCase(),
      type: 'table',
      form: () =>
        ({
          options: [
            { key: '0', label: '0%' },
            { key: '1', label: '25%' },
            { key: '2', label: '50%' },
            { key: '3', label: '75%' },
            { key: '4', label: '100%' },
          ],
          rows: proUtils?.softwares,
        }),
    },
    contacts: [
      {
        title: strings.addressPhoneNo,
        type: 'multiple',
        hideSave: true,
        form: () =>
          [
            isSelfService && {
              label: strings.mapButtonLabel,
              fieldLabel: strings.pharmacistLocation,
              type: Constants.formInputType.mapButton,
              xs: 6,
              name: 'pharmacistLocation',
              id: 'pharmacistLocation',
              requireLabel: true,
              fullWidth: true,
              startIcon: <img src={Map} alt="mapIcon" width="20" height="20" />,
              endIcon: <ArrowForward />,
              onClick: () =>
                showPickYourLocation(),
              style: style.mapButton,
              helperTextStyle: style.mapButtonHelperTextStyle,
            },
            {
              label: strings.address,
              type: Constants.formInputType.text,
              xs: 6,
              name: 'address',
              id: 'address',
              requireLabel: true,
            },
            {
              label: strings.city,
              type: Constants.formInputType.text,
              xs: 6,
              name: 'city',
              id: 'city',
              requireLabel: true,
            },
            {
              label: strings.province,
              type: Constants.formInputType.dropdown,
              xs: 6,
              name: 'province',
              id: 'province',
              requireLabel: true,
              menuItems: dropdownMapper(proUtils.provinces ?? [], 'key', 'name'),
            },
            {
              label: `${strings.postalCode} (${strings.postalCodeHint})`,
              type: Constants.formInputType.text,
              xs: 6,
              customType: InputTypes.postalCode,
              name: 'postalCode',
              capitalize: true,
              id: 'postalCode',
              requireLabel: true,
              placeholder: ' ',
            },
            {
              label: strings.homePhone,
              type: Constants.formInputType.masked,
              mask: telephoneInputMask,
              xs: 6,
              name: 'phone',
              id: 'phone',
              requireLabel: true,
            },
            {
              label: strings.mobile,
              type: Constants.formInputType.masked,
              mask: telephoneInputMask,
              xs: 6,
              name: 'mobile',
              id: 'mobile',
              requireLabel: true,
            },
          ],
      },
      {
        title: strings.preferences,
        hideSave: true,
        form: () =>
          [
            {
              label: strings.preferredWorkingDays,
              type: Constants.formInputType.dropdown,
              xs: 6,
              name: 'preferredWorkingDays',
              id: 'preferredWorkingDays',
              multiple: true,
              menuItems: daysDropdown(),
            },

            {
              label: strings.preferredReplacementDuration,
              type: Constants.formInputType.dropdown,
              xs: 6,
              name: 'preferredReplacementDuration',
              id: 'preferredReplacementDuration',
              menuItems: ArrayToDD(proUtils?.prefferedReplacementDurations ?? []),
            },
            {
              label: strings.availabilityReminder,
              type: Constants.formInputType.checkbox,
              xs: 12,
              name: 'availabilityReminder',
              id: 'avilabilityReminder',
              requireLabel: true,
              customClass: {
                color: '#4A5056',
              },
            },
            {
              label: strings.agreeOn,
              linkLabel: strings.termsAndConditions,
              handleChange,
              onLinkClick: showTermsAndConditions,
              type: Constants.formInputType.checkbox,
              xs: 12,
              name: 'termsAndConditions',
              id: 'termsAndConditions',
              requireLabel: true,
              customClass: {
                color: '#4A5056',
              },
            },
          ],
      },
    ],
    myLocationOnMap: {
      title: strings.pharmacistLocation,
      type: 'map',
      back: 'contacts',
      form: () =>
        [{}],
    },
  });
