import {
  TypeOfUser,
  adminMap,
  calendarFilter,
  getAdminMap,
  getNearbyProps,
  getPharmacistMap,
  getTypeOfUser,
  mapDate,
  pharmacistMap,
  toType,
  AdminLegendVariant,
} from '@pharmaplan/common';
import { useEffect } from 'react';
import { useAppSelector } from '../useAppSelector';
import { useAppDispatch } from '../useAppDispatch';
import { parseMapDate } from '../../helpers/Functions';
import ICalendarEventType from '../../types/ICalendarEventType';
import { renderScreen, setOpen } from '../../actions/drawerActions';
import { ScreenTypes } from '../../helpers/Constants';

const { admin, pharmacist, superAdmin } = TypeOfUser;
const { booked, requested } = AdminLegendVariant;

const useMap = () => {
  const dispatch = useAppDispatch();
  const userType = useAppSelector(getTypeOfUser);

  const mDate = useAppSelector(mapDate);
  const nearbyProps = useAppSelector(getNearbyProps);
  const filters = useAppSelector(calendarFilter);

  const pharmacistMapEvents = useAppSelector(pharmacistMap);
  const adminMapEvents = useAppSelector(adminMap);

  const mapEvents = {
    [admin]: adminMapEvents,
    [superAdmin]: adminMapEvents,
    [pharmacist]: pharmacistMapEvents,
  };

  const getUserBasedMap = () => {
    switch (userType) {
      case pharmacist:
        dispatch(
          getPharmacistMap({
            ...filters,
            weekendVolume:
              typeof filters?.weekendVolume === 'string'
                ? JSON.parse(filters?.weekendVolume)
                : filters?.weekendVolume,
            weekVolume:
              typeof filters?.weekendVolume === 'string'
                ? JSON.parse(filters?.weekVolume)
                : filters?.weekVolume,
            ...parseMapDate(mDate),
            ...nearbyProps,
          }),
        );
        break;
      case admin:
      case superAdmin:
        dispatch(getAdminMap(parseMapDate(mDate)));
        break;

      default:
        break;
    }
  };

  const handleMapNavigation = (type:ICalendarEventType, eventIds:Array<string>) => {
    switch (type) {
      case booked:
        dispatch(renderScreen({
          eventIds,
          screenNumber: 2,
          screenType: ScreenTypes.bookingList,
        }));
        break;
      case requested:
        dispatch(renderScreen({
          eventIds,
          screenNumber: 3,
          screenType: ScreenTypes.requestedList,
        }));
        break;
      default:
        break;
    }
    dispatch(setOpen(true));
  };

  useEffect(() => {
    getUserBasedMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mDate, filters, nearbyProps]);

  return { mapEvents: mapEvents[toType<typeof mapEvents>(userType)], handleMapNavigation };
};

export default useMap;
