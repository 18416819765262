import React, { FC } from 'react';
import { Typography, Avatar, Box, SxProps } from '@mui/material';
import {
  userPreferredTimeFormat,
  PharmacistLegendVariant,
  BookingState,
  logos,
} from '@pharmaplan/common';
import { ReactComponent as FavIcon } from '../../../../assets/svg/favIconWithBorder.svg';
import classes from './style';
import { getHoursDiff } from '../../../Modals/WorkshiftDetails/utils';
import { getDayMonthDateYear } from '../../../Reports/helpers';
import CustomChip from '../../../common/CustomChip/CustomChip';
import { timeString } from '../../../../helpers/Functions';
import { IPharmacyCard } from '../PharamcyCard';
import WithSkeleton from '../../../common/WithSkeleton';
import strings from '../../../../localization';
import { SkeletonVariants } from '../../../../helpers/Constants';
import { useAppSelector } from '../../../../hooks/useAppSelector';

const PharmacyCardContent: FC<IPharmacyCard> = ({
  type,
  event,
  successAction,
  CustomContent,
  showPharmacyName,
  customTitleStyle,
}) => {
  const timeFormat = useAppSelector(userPreferredTimeFormat);
  const allLogos = useAppSelector(logos);

  const {
    startDate,
    endDate,
    pharmacyName,
    city,
    logoImage,
    favorite,
    bookingState,
    counterEndDate,
    counterStartDate,
    logoId,
  } = event ?? {};

  const {
    card,
    cardContent,
    avatarContainer,
    favIconContainer,
    title,
    addressText,
    date,
    timeStrike,
    chipContainer,
    image,
    infoContainer,
  } = classes ?? {};

  const logo = allLogos?.find((log) =>
    log.logoId === logoId)?.image;

  const isBooked = type === PharmacistLegendVariant.booked;
  const isUpdatedTiming = isBooked && bookingState === BookingState.UpdatedTiming;

  const eventChipConfig = {
    [PharmacistLegendVariant.workshift]: {
      label: strings.availableWorkshift,
    },
    [PharmacistLegendVariant.booked]: {
      label: strings.bookedWorkshift,
    },
    [PharmacistLegendVariant.requested]: {
      label: strings.requestedWorkshift,
    },
  };

  const timeConfig = {
    startDate: isUpdatedTiming ? counterStartDate : startDate,
    endDate: isUpdatedTiming ? counterEndDate : endDate,
  };

  const updatedStartDate = timeConfig?.startDate ?? '';
  const updatedEndDate = timeConfig?.endDate ?? '';

  const time = timeString(updatedStartDate, updatedEndDate, timeFormat);
  const hours = `${time} (${getHoursDiff(
    updatedStartDate,
    updatedEndDate,
  )} Hrs)`;

  const eventLabel = eventChipConfig[type as keyof typeof eventChipConfig].label;

  const SkeletonAvatar = WithSkeleton({
    successAction,
    variant: 'circular',
    height: 75,
    width: 75,
  })(Avatar);

  const SkeletonTypography = WithSkeleton({
    successAction,
    variant: SkeletonVariants.text,
  })(Typography);

  const SkeletonChip = WithSkeleton({
    successAction,
    variant: 'rounded',
  })(Box);

  const contentHeader = () => {
    if (isBooked || showPharmacyName) {
      return pharmacyName;
    }

    return city;
  };

  return (
    <Box sx={card}>
      <Box sx={cardContent}>
        <Box sx={avatarContainer}>
          <SkeletonAvatar
            src={logoId ? logo : logoImage}
            sx={image}
            imgProps={{ style: { objectFit: 'contain' } }}
          />
          <SkeletonChip sx={favIconContainer}>
            {favorite && <FavIcon />}
          </SkeletonChip>
        </Box>

        <Box sx={infoContainer}>
          <SkeletonTypography sx={[title, customTitleStyle] as SxProps}>
            {contentHeader()}
          </SkeletonTypography>

          {isBooked && (
            <SkeletonTypography sx={addressText}>{city}</SkeletonTypography>
          )}

          <SkeletonTypography sx={date}>
            {`${getDayMonthDateYear(startDate ?? '')}`}
          </SkeletonTypography>

          <SkeletonTypography sx={date}>{hours}</SkeletonTypography>

          {isUpdatedTiming && (
            <SkeletonTypography sx={timeStrike}>
              {timeString(startDate ?? '', endDate ?? '', timeFormat)}
              (
              {getHoursDiff(startDate ?? '', endDate ?? '')}
              Hrs)
            </SkeletonTypography>
          )}

          {CustomContent ?? (
            <SkeletonChip sx={chipContainer}>
              <CustomChip label={eventLabel} type={type} />
            </SkeletonChip>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PharmacyCardContent;
