import React, { useEffect } from 'react';

import { getPharmacyAdminLists, pharmacyAdminList } from '@pharmaplan/common';

import usePharmacyAdministratorActions from '../../../hooks/Admin/PharmacyAdministrators/usePharmacyAdministratorActions';
import useAdminPermissions from '../../../hooks/Admin/useAdminPermissions';
import useUserProfileLink from '../../../hooks/Admin/useUserProfileLink';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { IDynamicTableObject } from '../../DynamicTable/types';
import DynamicTable from '../../DynamicTable';
import strings from '../../../localization';

import { pharmacyAdminHeaders, pharmacyAdminRows } from './helper';
import PharmacyAdminHeader from './PharmacyAdminHeader';

const PharmacyAdministrators = () => {
  const dispatch = useAppDispatch();

  const list = useAppSelector(pharmacyAdminList);
  const { can } = useAdminPermissions();
  const { editAction, deleteAction, loadSuccess } = usePharmacyAdministratorActions();
  const { goToUserProfile } = useUserProfileLink();

  const table: IDynamicTableObject = {
    title: strings.pharmacyAdministrators,
    headers: pharmacyAdminHeaders(can),
    rows: pharmacyAdminRows({
      data: list,
      deleteAction,
      editAction,
      goToUserProfile,
      can,
    }),
    headerBar: [],
  };

  useEffect(() => {
    dispatch(getPharmacyAdminLists());
  }, []);

  return (
    <DynamicTable
      table={table}
      loadSuccess={loadSuccess}
      hidePagination
      showHeader={false}
      customHeader={<PharmacyAdminHeader />}
    />
  );
};

export default PharmacyAdministrators;
