import {
  cancelledBookingsList,
  getCancelledBookings,
  getPendingCancellations,
  getRejectedCancellations,
  pendingCancellationsList,
  PendingCancellationTabTypes,
  PermissionsOfAdmin,
  rejectedCancellationsList,
} from '@pharmaplan/common';
import { useState } from 'react';
import { renderScreen, setOpen } from '../../../actions/drawerActions';
import {
  cancellationBookingHeader,
  cancellationBookingRowConfig,
  pendingCancellationHeader,
  pendingCancellationRowConfig,
  rejectedCancellationHeader,
  rejectedCancellationsRowConfig,
} from '../../../components/Admin/PendingCancellations/helper';
import { ScreenTypes } from '../../../helpers/Constants';
import { scrollToTop } from '../../../helpers/Functions';

import { useAppDispatch } from '../../useAppDispatch';
import { useAppSelector } from '../../useAppSelector';
import useUserProfileLink from '../useUserProfileLink';
import usePendingCancellationActions from './usePendingCancellationActions';
import usePendingCancellationDelete from './usePendingCancellationDelete';
import useAdminPermissions from '../useAdminPermissions';

const {
  cancelledBookings: cancelledBookingsTab,
  pendingCancellations: pendingCancellationsTab,
  rejectedCancellations: rejectedCancellationsTab,
} = PendingCancellationTabTypes;

const usePendingCancellations = () => {
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState<PendingCancellationTabTypes>(
    pendingCancellationsTab,
  );

  const dispatch = useAppDispatch();

  const pendingCancellations = useAppSelector(pendingCancellationsList);
  const cancelledBookings = useAppSelector(cancelledBookingsList);
  const rejectedCancellations = useAppSelector(rejectedCancellationsList);

  const { goToUserProfile } = useUserProfileLink();
  const { can } = useAdminPermissions();
  const canPermanentlyDelete = can(PermissionsOfAdmin.PermanentDelete);

  const { approveAction, rejectAction } = usePendingCancellationActions();
  const {
    selectAllFunc,
    handleDelete,
    handlePress,
    selectedIds,
    selectAll,
    setSelectedIds,
    handleReset,
  } = usePendingCancellationDelete(setPage, selectedTab, page);

  const apiDataMap = {
    [pendingCancellationsTab]: {
      list: pendingCancellations,
      api: getPendingCancellations,
    },
    [cancelledBookingsTab]: {
      list: cancelledBookings,
      api: getCancelledBookings,
    },
    [rejectedCancellationsTab]: {
      list: rejectedCancellations,
      api: getRejectedCancellations,
    },
  };

  const {
    list: { data },
  } = apiDataMap[selectedTab];

  const goToDetails = (cancellationRequestId: string) =>
    () => {
      dispatch(setOpen(true));
      scrollToTop();
      dispatch(
        renderScreen({
          screenType: ScreenTypes.pendingCancellation,
          screenNumber: 1,
          fromScreen: selectedTab,
          eventId: cancellationRequestId,
        }),
      );
    };

  const tableConfig = {
    [pendingCancellationsTab]: {
      headers: pendingCancellationHeader(),
      rows: pendingCancellationRowConfig(
        data,
        goToDetails,
        goToUserProfile,
        approveAction,
        rejectAction,
      ),
    },
    [cancelledBookingsTab]: {
      headers: cancellationBookingHeader(can),
      rows: cancellationBookingRowConfig(
        data,
        handlePress,
        selectedIds,
        goToDetails,
        goToUserProfile,
        canPermanentlyDelete,
      ),
    },
    [rejectedCancellationsTab]: {
      headers: rejectedCancellationHeader(can),
      rows: rejectedCancellationsRowConfig(
        data,
        handlePress,
        selectedIds,
        goToDetails,
        goToUserProfile,
        canPermanentlyDelete,
      ),
    },
  };

  return {
    table: { ...tableConfig[selectedTab], headerBar: [] },
    apiDataMap: apiDataMap[selectedTab],
    page,
    setPage,
    selectAllFunc,
    handleDelete,
    handleReset,
    selectedTab,
    setSelectedTab,
    selectAll,
    setSelectedIds,
  };
};

export default usePendingCancellations;
