import React from 'react';

import { FormikValues } from 'formik';

import {
  activePharmacistLists,
  adminActions,
  adminActivatePharmacist,
  adminDeactivatedPharmacists,
  adminDeletedPharmacists,
  adminHiddenPharmacists,
  adminPharmacistSearchedDetails,
  adminShowPharmacist,
  getActivePharmacistList,
  getDeactivatedPharmacists,
  getDeletedPharmacist,
  getHiddenPharmacists,
  IAdminPharmacistDetailsSearchData,
  IAdminPharmacistListBaseData,
  successSelector,
} from '@pharmaplan/common';

import {
  activePharmacistListHeaders,
  activePharmacistListRows,
  deactivatedPharmacistListRows,
  deactivePharmacistListHeaders,
  deletedPharmacistListHeaders,
  deletedPharmacistListRows,
  hiddenPharmacistListHeaders,
  hiddenPharmacistListRows,
  pharmacistSearchedDetailHeaders,
  searchedDetailsRow,
} from '../../components/Admin/PharmacistList/helper';
import { setDialog } from '../../reducers/dialogReducer';
import {
  DeactivateModalIcon,
  PharmacistListTabTypes,
} from '../../helpers/Constants';

import useUserProfileLink from '../Admin/useUserProfileLink';
import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';
import usePharmacistList from './usePharmacistList';
import DeactivateDialog from '../../components/Admin/PharmacistList/DeactivateDialog';
import strings from '../../localization';

const {
  activePharmacists,
  deactivatedPharmacists,
  hiddenPharmacists,
  deletedPharmacists,
  detailsSearch,
} = PharmacistListTabTypes;

const {
  getActivePharmacistList: activeAction,
  getDeactivatedPharmacists: deactiveAction,
  getHiddenPharmacists: hiddenAction,
  getDeletedPharmacist: deletedAction,
  getPharmacistDetailsSearchFilters,
} = adminActions;

const usePharmacistListTabs = (selectedTab: PharmacistListTabTypes) => {
  const dispatch = useAppDispatch();

  const { actions } = usePharmacistList();
  const { goToUserProfile } = useUserProfileLink();

  const active = useAppSelector(activePharmacistLists);
  const deactivated = useAppSelector(adminDeactivatedPharmacists);
  const hidden = useAppSelector(adminHiddenPharmacists);
  const deleted = useAppSelector(adminDeletedPharmacists);
  const details = useAppSelector(adminPharmacistSearchedDetails);

  const activeSuccess = useAppSelector((state) =>
    successSelector([activeAction], state));
  const deactiveSuccess = useAppSelector((state) =>
    successSelector([deactiveAction], state));
  const hiddenSuccess = useAppSelector((state) =>
    successSelector([hiddenAction], state));
  const deletedSuccess = useAppSelector((state) =>
    successSelector([deletedAction], state));
  const detailsSuccess = useAppSelector((state) =>
    successSelector([getPharmacistDetailsSearchFilters], state));

  const dataConfig = {
    [activePharmacists]: active,
    [deactivatedPharmacists]: deactivated,
    [hiddenPharmacists]: hidden,
    [deletedPharmacists]: deleted,
    [detailsSearch]: details,
  };

  const successConfig = {
    [activePharmacists]: activeSuccess,
    [deactivatedPharmacists]: deactiveSuccess,
    [hiddenPharmacists]: hiddenSuccess,
    [deletedPharmacists]: deletedSuccess,
    [detailsSearch]: detailsSuccess,
  };

  const { data } = dataConfig[selectedTab as keyof typeof dataConfig];

  const api = {
    [activePharmacists]: getActivePharmacistList,
    [deactivatedPharmacists]: getDeactivatedPharmacists,
    [hiddenPharmacists]: getHiddenPharmacists,
    [deletedPharmacists]: getDeletedPharmacist,
  };

  const activatePharmacist = (values: FormikValues) => {
    const { id, reason } = values;
    dispatch(
      adminActivatePharmacist({
        pharmacistId: id,
        reason,
      }),
    );
  };

  const unhidePharmacist = (values: FormikValues) => {
    const { id, reason } = values ?? {};
    dispatch(adminShowPharmacist({ pharmacistId: id, reason }));
  };

  const activateDialog = (pharmacistId: string) =>
    () => {
      dispatch(
        setDialog({
          Component: (
            <DeactivateDialog
              id={pharmacistId}
              iconType={DeactivateModalIcon.positiveConfirmation}
              confirmationMessage={strings.areYouSureActivatePharmacist}
              heading={
              strings.formatString(
                strings.activateFormat,
                strings.pharmacist,
              ) as string
            }
              onSubmit={activatePharmacist}
            />
          ),
          heading: {
            title: '',
          },
          showCloseButton: true,
        }),
      );
    };

  const showPharmacistDialog = (pharmacistId: string) =>
    () => {
      dispatch(
        setDialog({
          Component: (
            <DeactivateDialog
              id={pharmacistId}
              iconType={DeactivateModalIcon.positiveConfirmation}
              confirmationMessage={strings.areYouSureUnhidePharmacist}
              heading={
              strings.formatString(
                strings.unhideFormat,
                strings.pharmacist,
              ) as string
            }
              onSubmit={unhidePharmacist}
            />
          ),
          heading: {
            title: '',
          },
          showCloseButton: true,
        }),
      );
    };

  const rowConfig = {
    [activePharmacists]: activePharmacistListRows(
      data as Array<IAdminPharmacistListBaseData>,
      goToUserProfile,
      actions,
    ),
    [deactivatedPharmacists]: deactivatedPharmacistListRows(
      data as Array<IAdminPharmacistListBaseData>,
      goToUserProfile,
      activateDialog,
    ),
    [hiddenPharmacists]: hiddenPharmacistListRows(
      data as Array<IAdminPharmacistListBaseData>,
      goToUserProfile,
      showPharmacistDialog,
    ),
    [deletedPharmacists]: deletedPharmacistListRows(
      data as Array<IAdminPharmacistListBaseData>,
      goToUserProfile,
    ),
    [detailsSearch]: searchedDetailsRow(
      data as Array<IAdminPharmacistDetailsSearchData>,
      goToUserProfile,
    ),
  };

  const headersConfig = {
    [activePharmacists]: activePharmacistListHeaders(),
    [deactivatedPharmacists]: deactivePharmacistListHeaders(),
    [hiddenPharmacists]: hiddenPharmacistListHeaders(),
    [deletedPharmacists]: deletedPharmacistListHeaders(),
    [detailsSearch]: pharmacistSearchedDetailHeaders(),
  };

  return {
    rows: rowConfig[selectedTab],
    headers: headersConfig[selectedTab],
    api: api[selectedTab as keyof typeof api],
    data: dataConfig[selectedTab],
    loadSuccess: successConfig[selectedTab],
  };
};

export default usePharmacistListTabs;
