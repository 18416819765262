import React, { FC, useEffect } from 'react';

import { FormikValues, useFormik } from 'formik';
import { Box } from '@mui/material';
import { DateTime } from 'luxon';

import {
  RecurrenceActions,
  calendarDate,
  deleteAvailability,
  getAvailability,
  getCurrentAvailability,
  getPharmacistCalendar,
  getRecurrence,
  pharmacistActions,
  recurrenceType,
  reset,
  resetStatus,
  setRecurrence,
  successSelector,
  updateAvailability,
} from '@pharmaplan/common';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import {
  makeReqParams,
  setHour,
  setRecurrenceProps,
} from '../../../helpers/workshift/functions';
import {
  resetDrawer,
  setButtons,
  setPagination,
} from '../../../actions/drawerActions';
import { InvertedRepeatMap, isIpad } from '../../../helpers/Constants';
import strings from '../../../localization';
import CustomTextField from '../../common/CustomTextField';
import genericClasses from '../../../theme/GenericClasses';
import AddUpdateEvents from '../../common/AddUpdateEvents/AddUpdateEvents';
import { toJSDate } from '../../Dashboard/MainCalendar/helpers';

import Validator from '../Validator';
import classes from '../styles';
import { getDisableAvailability } from '../../../selectors/calendarSelector';

interface IUpdate {
  id: string;
  recurrenceId?: string;
  recType?: RecurrenceActions;
}

const { Never } = recurrenceType;

const Update: FC<IUpdate> = ({ id, recurrenceId, recType }) => {
  const dispatch = useAppDispatch();

  const allDayStart = 8;
  const allDayEnd = 22;

  const currentAvailability = useAppSelector(getCurrentAvailability);
  const disabled = useAppSelector(getDisableAvailability);
  const recurrence = useAppSelector(getRecurrence);
  const cDate = useAppSelector(calendarDate);

  const { repeatEvery } = recurrence ?? {};

  const {
    startDate,
    endDate,
    notes,
    recurrenceId: currentRecurrenceId,
    recurrence: currentRecurrence,
  } = currentAvailability ?? {};

  const { endDate: recurrenceEndDate, recurrenceType: currentRecurrenceType } = currentRecurrence ?? {};

  const updateSuccess = useAppSelector((state) =>
    successSelector([pharmacistActions.updateAvailability], state));
  const deleteSuccess = useAppSelector((state) =>
    successSelector([pharmacistActions.deleteAvailabilities], state));

  const isSeries = recType === RecurrenceActions.series;

  const handleReset = () => {
    dispatch(reset());
    dispatch(resetDrawer());
  };

  const onSubmit = (values: FormikValues) => {
    dispatch(
      updateAvailability({
        data: makeReqParams(values, recurrence),
        recurrenceId: isSeries ? recurrenceId ?? '' : null,
        availabilityId: id,
      }),
    );
  };

  const recurrenceTypeForWorkshift = isSeries
    ? currentRecurrenceType ?? Never
    : repeatEvery;

  const formik = useFormik({
    initialValues: {
      ...currentAvailability,
      selectedDate: DateTime.fromISO(startDate, { setZone: true }),
      allDay: false,
      endDate: recurrenceEndDate || endDate,
      notes,
      startTime: toJSDate(startDate),
      endTime: toJSDate(endDate),
      recurrence: InvertedRepeatMap[recurrenceTypeForWorkshift],
    },
    enableReinitialize: true,
    validationSchema: Validator(),
    onSubmit,
  });

  const { values, handleSubmit, setFieldValue } = formik ?? {};
  const { selectedDate } = values ?? {};

  useEffect(() => {
    dispatch(setPagination({ show: false }));
    dispatch(
      setButtons({
        show: true,
        primaryButton: {
          label: strings.saveChanges,
          onClick: handleSubmit,
          disabled: !disabled,
        },
        secondaryButton: {
          label: strings.deleteAvailability,
          variant: 'text',
          onClick: () => {
            dispatch(
              deleteAvailability({
                id,
                recurrenceId: isSeries ? recurrenceId : '',
              }),
            );
          },
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  useEffect(() => {
    if (currentRecurrenceId && isSeries) {
      setRecurrenceProps(dispatch, recurrence, currentAvailability);
    } else {
      dispatch(setRecurrence({ ...recurrence, endDate }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAvailability]);

  useEffect(() => {
    if (updateSuccess || deleteSuccess) {
      dispatch(getPharmacistCalendar(cDate));
      handleReset();
      dispatch(resetStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess, deleteSuccess]);

  const setUserWorkTimings = () => {
    setFieldValue('startTime', setHour(selectedDate, allDayStart));
    setFieldValue('endTime', setHour(selectedDate, allDayEnd));
  };

  useEffect(() => {
    id && dispatch(getAvailability(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Box sx={classes.container}>
      <AddUpdateEvents
        formik={formik}
        setUserWorkTimings={setUserWorkTimings}
        isSeries={isSeries}
        isAvailability
      />
      <Box sx={isIpad ? classes.notes : {}}>
        <CustomTextField
          variant="outlined"
          formik={formik}
          id="notes"
          placeholder=" "
          label={strings.notes}
          name="notes"
          multiline
          maxLength={200}
          customClass={genericClasses.borderNoneTextField}
        />
      </Box>
    </Box>
  );
};

export default Update;
