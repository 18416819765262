import React from 'react';

import { NavigateFunction } from 'react-router-dom';

import {
  IHistoryData,
  INotes,
  IPharmacyNotes,
  IPharmacyReplacementData,
  IReplacementsData,
  ITEMS_PER_PAGE,
  TimeFormat,
} from '@pharmaplan/common';
import updateIcon from '@pharmaplan/common/assets/icons/updateNote.svg';

import { ReactComponent as StarNotFilled } from '@pharmaplan/common/assets/icons/starNotFilled.svg';
import { Constants, DynamicTableCell, RoleMap } from '../../helpers/Constants';
import { getDayMonthDateYear, getHourMinute } from '../Reports/helpers';
import historyIcon from '../../assets/svg/historyIcon.svg';
import notesIcon from '../../assets/svg/notesIcon.svg';
import deleteIcon from '../../assets/svg/delete.svg';
import strings from '../../localization';

import classes from './style';

export const pharmacistReplacementMapper = (
  data: Array<IReplacementsData>,
  navigate: NavigateFunction,
  currentIndex: number,
  getImage: (logoId: string) => string,
) =>
  data?.map((item, i) =>
    ({
      key: item.pharmacyId,
      data: [
        {
          key: `${item.pharmacyId}-1`,
          value: currentIndex + i + 1,
          type: DynamicTableCell.text,
        },

        {
          key: `${item.pharmacyId}-2`,
          value: getImage(item.logoId),
          type: DynamicTableCell.icon,
        },
        {
          key: `${item.pharmacyId}-3`,
          value: `${item.pharmacyName}\n${item.city}`,
          type: DynamicTableCell.text,
        },
        {
          key: `${item.pharmacyId}-4`,
          value: {
            checked: item.favourite,
          },
          type: DynamicTableCell.favourite,
        },
        {
          key: `${item.pharmacyId}-5`,
          value: [
            {
              key: '1',
              icon: historyIcon,
              tooltip: strings.history,
              onClick: () =>
                navigate(Constants.paths.history, {
                  state: { id: item.pharmacyId, name: item.pharmacyName },
                }),
            },
            {
              key: '2',
              icon: notesIcon,
              tooltip: strings.notes,
              onClick: () =>
                navigate(Constants.paths.notes, {
                  state: { id: item.pharmacyId, name: item.pharmacyName },
                }),
            },
          ],
          type: DynamicTableCell.bar,
        },
      ],
    }));

export const pharmacyReplacementMapper = (
  data: Array<IPharmacyReplacementData>,
  navigate: NavigateFunction,
  currentIndex: number,
) =>
  data?.map((item, i) =>
    ({
      key: item.pharmacistId,
      data: [
        {
          key: `${item.pharmacistId}-1`,
          value: currentIndex + i + 1,
          type: DynamicTableCell.text,
        },
        {
          key: `${item.pharmacistId}-2`,
          value: item.name,
          type: DynamicTableCell.text,
        },
        {
          key: `${item.pharmacistId}-3`,
          value: RoleMap()[item.role as keyof typeof RoleMap],
          type: DynamicTableCell.text,
        },
        {
          key: `${item.pharmacistId}-4`,
          value: item.totalReplacements,
          type: DynamicTableCell.text,
          isCenterAligned: true,
        },
        {
          key: `${item.pharmacistId}-5`,
          value: Math.trunc(item.totalHours),
          type: DynamicTableCell.text,
          isCenterAligned: true,
        },
        {
          key: `${item.pharmacistId}-6`,
          value: {
            checked: item.favourite,
            disabled: item.isIncompatible,
          },
          type: DynamicTableCell.favourite,
        },
        {
          show: true,
          key: `${item.pharmacistId}-6`,
          value: item.isIncompatible,
          type: DynamicTableCell.incompatible,
        },
        {
          key: `${item.pharmacistId}-7`,
          value: [
            {
              key: '1',
              icon: historyIcon,
              tooltip: strings.history,
              onClick: () =>
                navigate(Constants.paths.history, {
                  state: { id: item.pharmacistId, name: item.name },
                }),
            },
            {
              key: '2',
              icon: notesIcon,
              tooltip: strings.notes,
              onClick: () =>
                navigate(Constants.paths.notes, {
                  state: { id: item.pharmacistId, name: item.name },
                }),
            },
          ],
          type: DynamicTableCell.bar,
        },
      ],
    }));

export const pharmacistNotesMapper = (
  data: Array<INotes>,
  OpenModal: (id: string, update: boolean) => void,
  deleteAction: (id: string) => void,
) =>
  data?.map((item) =>
    ({
      key: item.pharmacistPharmacyNoteId,
      data: [
        {
          key: `${item.pharmacyId}-1`,
          value: item.note,
          type: DynamicTableCell.notes,
        },
        {
          key: `${item.pharmacyId}-2`,
          value: getDayMonthDateYear(item.createDate),
          type: DynamicTableCell.date,
        },
        {
          key: `${item.pharmacyId}-3`,
          value: item.updateDate ? getDayMonthDateYear(item.updateDate) : '',
          type: DynamicTableCell.date,
        },
        {
          key: `${item.pharmacyId}-5`,
          value: [
            {
              key: '1',
              icon: updateIcon,
              onClick: () =>
                OpenModal(item.pharmacistPharmacyNoteId, true),
            },
            {
              key: '2',
              icon: deleteIcon,
              onClick: () =>
                deleteAction(item.pharmacistPharmacyNoteId),
            },
          ],
          type: DynamicTableCell.bar,
        },
      ],
    }));

export const pharmacyNotesMapper = (
  data: Array<IPharmacyNotes>,
  OpenModal: (id: string, update: boolean) => void,
  deleteAction: (id: string) => void,
) =>
  data?.map((item) =>
    ({
      key: item.pharmacyPharmacistNoteId,
      data: [
        {
          key: `${item.pharmacistId}-1`,
          value: item.note,
          type: DynamicTableCell.notes,
        },
        {
          key: `${item.pharmacistId}-2`,
          value: getDayMonthDateYear(item.createDate),
          type: DynamicTableCell.date,
        },
        {
          key: `${item.pharmacistId}-3`,
          value: item.updateDate ? getDayMonthDateYear(item.updateDate) : '',
          type: DynamicTableCell.date,
        },
        {
          key: `${item.pharmacistId}-5`,
          value: [
            {
              key: '1',
              icon: updateIcon,
              onClick: () =>
                OpenModal(item.pharmacyPharmacistNoteId, true),
            },
            {
              key: '2',
              icon: deleteIcon,
              onClick: () =>
                deleteAction(item.pharmacyPharmacistNoteId),
            },
          ],
          type: DynamicTableCell.bar,
        },
      ],
    }));

export const pharmacyHistoryMapper = (
  data: Array<IHistoryData>,
  timeFormat: TimeFormat,
  OpenModal: (bookingId: string) => void,
  page: number,
) =>
  data?.map((item, i) => {
    const { code, startDate, endDate, bookingId, canRatePharmacist } = item ?? {};
    return {
      key: item.code,
      data: [
        {
          key: `${code}-1`,
          value: 1 + i + (page - 1) * ITEMS_PER_PAGE,
          type: DynamicTableCell.text,
        },
        {
          key: `${code}-1`,
          value: code,
          type: DynamicTableCell.text,
        },
        {
          key: `${code}-3`,
          value: getDayMonthDateYear(startDate),
          type: DynamicTableCell.text,
        },
        {
          key: `${code}-2`,
          value: `${getHourMinute(startDate, timeFormat)} - ${getHourMinute(
            endDate,
            timeFormat,
          )}`,
          type: DynamicTableCell.text,
        },
        {
          key: `${code}-4`,
          show: canRatePharmacist,
          value: {
            label: 'Rate',
            variant: 'outlined',
            style: classes.rateButton,
            startIcon: <StarNotFilled />,
            handlePress: () =>
              OpenModal(bookingId),
          },
          type: DynamicTableCell.button,
        },
      ],
    };
  });

export const pharmacistHistoryMapper = (
  data: Array<IHistoryData>,
  timeFormat: TimeFormat,
  page: number,
) =>
  data?.map((item, i) =>
    ({
      key: item.code,
      data: [
        {
          key: `${item.code}-1`,
          value: 1 + i + (page - 1) * ITEMS_PER_PAGE,
          type: DynamicTableCell.text,
        },
        {
          key: `${item.code}-1`,
          value: item.code,
          type: DynamicTableCell.text,
        },
        {
          key: `${item.code}-3`,
          value: getDayMonthDateYear(item.startDate),
          type: DynamicTableCell.text,
        },
        {
          key: `${item.code}-2`,
          value: `${getHourMinute(item.startDate, timeFormat)} - ${getHourMinute(
            item.endDate,
            timeFormat,
          )}`,
          type: DynamicTableCell.text,
        },
      ],
    }));
