import React from 'react';

import { FormikProps } from 'formik';

import {
  BookingState,
  PharmacyLegendVariant,
  getPharmacyWorkshift,
  pharmacistBookingDetails,
  pharmacyActions,
  pharmacyWorkshift,
} from '@pharmaplan/common';

import ICalendarEventType from '../../types/ICalendarEventType';
import { useAppSelector } from '../useAppSelector';
import { useAppDispatch } from '../useAppDispatch';
import { renderScreen, setCurrent } from '../../actions/drawerActions';
import { ScreenTypes } from '../../helpers/Constants';
import strings from '../../localization';
import PharmacistCard from '../../components/SelfService/PharmacistCard';
import BookingRequests from '../../components/SelfService/WorkshiftSummary/BookingRequests';
import { selectFooterPaginationCurrent } from '../../selectors/drawerSelector';

import useWrokshiftSummaryModals from './useWorkshiftSummaryModal';
import useCancellationBooking from '../useCancellationBooking';

const { booked, requested, posted } = PharmacyLegendVariant;
const { PendingWithPharmacist } = BookingState;
const { workshiftSummary } = ScreenTypes;

const useWorkshiftSummary = (
  eventIds: Array<string>,
  eventType: ICalendarEventType,
  bookingState: BookingState,
  formik: FormikProps<any>,
) => {
  const dispatch = useAppDispatch();
  const { showCancelConfirmation, handleCancelConfirmation } = useCancellationBooking();

  const { show, getPharmacist } = useWrokshiftSummaryModals(
    eventIds,
    eventType,
    formik,
  );

  const current = useAppSelector(selectFooterPaginationCurrent);
  const pharmacistDets = useAppSelector(pharmacistBookingDetails);
  const currentWorkshift = useAppSelector(pharmacyWorkshift);

  const { accommodationAllowance, mealAllowance, note } = pharmacistDets ?? {};
  const {
    profilePictures = [],
    requestedCount,
    startDate,
  } = currentWorkshift ?? {};

  const isBookingStateConfirm = bookingState === PendingWithPharmacist;
  const date = startDate;

  const eventId = eventIds[current - 1];

  const details = {
    ...pharmacistDets,
    fixedAccommodationRate: accommodationAllowance,
    fixedMealRate: mealAllowance,
    notes: note,
  };

  const savePaginationIndex = () => {
    dispatch(setCurrent(current));
  };

  const dispatchActions = () => {
    switch (eventType) {
      case posted:
        dispatch(getPharmacyWorkshift(eventId));
        break;

      case booked:
        getPharmacist();
        break;

      case requested:
        if (!isBookingStateConfirm) {
          dispatch(getPharmacyWorkshift(eventId));
        } else {
          getPharmacist();
        }
        break;

      default:
        break;
    }
  };

  const gotoRequestList = () => {
    savePaginationIndex();

    dispatch(
      renderScreen({
        eventIds,
        eventId,
        type: eventType,
        screenNumber: 2,
        date,
        screenType: workshiftSummary,
      }),
    );
  };

  const handleCancellation = () =>
    ({
      primaryButton: {
        label: '',
        onClick: null,
      },
      secondaryButton: {
        label: strings.cancelBooking,
        variant: 'text' as const,
        onClick: () => {
          showCancelConfirmation(eventId, handleCancelConfirmation);
        },
      },
    });

  const fetchEvents = () => {
    switch (eventType) {
      case posted:
        return {
          title: strings.singlePostedWorkshift,
          event: currentWorkshift,
          component: (
            <BookingRequests
              profileImage={profilePictures}
              requestedCount={requestedCount}
            />
          ),
        };

      case booked:
        return {
          title: show ? strings.updateTiming : strings.bookedWorkshift,
          event: details,
          component: (
            <PharmacistCard
              successAction={pharmacyActions.getBookedPharmacistDetails}
              eventId={eventId}
              eventIds={eventIds}
              pharmacistDetails={details}
              type={eventType}
              screenType={workshiftSummary}
            />
          ),
        };

      case requested:
        if (!isBookingStateConfirm) {
          return {
            title: strings.requestedWorkshift,
            event: currentWorkshift,
            component: (
              <BookingRequests
                onClick={gotoRequestList}
                profileImage={profilePictures}
                requestedCount={requestedCount}
              />
            ),
          };
        }

        return {
          title: strings.pharmacistDetails,
          event: details,
          component: (
            <PharmacistCard
              successAction={pharmacyActions.getBookedPharmacistDetails}
              eventId={eventId}
              eventIds={eventIds}
              pharmacistDetails={details}
              type={eventType}
              screenType={workshiftSummary}
            />
          ),
        };
      default:
        return {};
    }
  };

  return {
    fetchEvents,
    handleCancellation,
    dispatchActions,
    savePaginationIndex,
  };
};

export default useWorkshiftSummary;
