import React from 'react';

import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Close } from '@mui/icons-material';
import { DateTime } from 'luxon';

import { ReactComponent as CancelBookingIcon } from '@pharmaplan/common/assets/icons/cancelWebBookingIcon.svg';
import { ReactComponent as AvailabilityConfirmIcon } from '@pharmaplan/common/assets/icons/availabilityConfirmation.svg';
import { ReactComponent as SuccessIcon } from '@pharmaplan/common/assets/icons/successIcon.svg';
import {
  ConfirmationTypes,
  getTypeOfUser,
  ToUserTypings,
  TypeOfUser,
} from '@pharmaplan/common';
import { ReactComponent as PositiveWarning } from '../../../assets/svg/positiveWarning.svg';
import { ReactComponent as WarningGreyIcon } from '../../../assets/svg/greyWarningIcon.svg';
import { ReactComponent as WarningIcon } from '../../../assets/svg/warningIcon.svg';
import { ReactComponent as WarningYellowIcon } from '../../../assets/svg/yellowWarning.svg';
import { ReactComponent as TakeOwnershipIcon } from '../../../assets/svg/takeOwnershipIcon.svg';

import CustomButton from '../../common/CustomButton';
import strings from '../../../localization';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { resetDialog } from '../../../reducers/dialogReducer';
import { MomentTimeFormats } from '../../../helpers/Constants';
import { useAppSelector } from '../../../hooks/useAppSelector';

import classes from './styles';

const { changeEmail, sendNotification } = ConfirmationTypes;
interface IConfirmationModal {
  confirmAction: () => void;
  type: ConfirmationTypes;
  // eslint-disable-next-line react/require-default-props
  count?: number;
  customClose?: () => void;
  showCloseButton?: boolean;
  customMessage?: string | React.ReactNode;
}

export const customDateFormat = (date: string) =>
  DateTime.fromISO(date).toFormat(MomentTimeFormats.dayMonthLong);

const ConfirmationModal = ({
  confirmAction,
  type,
  count,
  customClose,
  customMessage,
  showCloseButton = true,
}: IConfirmationModal) => {
  const dispatch = useAppDispatch();

  const userType = useAppSelector(getTypeOfUser);
  const handleClose = () => {
    dispatch(resetDialog());
  };

  const userConfirmation = ToUserTypings({
    [TypeOfUser.pharmacist]: strings.deleteConfirmationPharmacist,
    [TypeOfUser.pharmacy]: strings.deleteConfirmationPharmacy,
  });

  const title = ToUserTypings({
    [TypeOfUser.pharmacist]: {
      main: strings.availability,
      message: strings.availabilities,
    },
    [TypeOfUser.pharmacy]: {
      main: strings.workshift,
      message: strings.workshifts,
    },
    [TypeOfUser.admin]: {
      main: '',
      message: '',
    },
    [TypeOfUser.superAdmin]: {
      main: '',
      message: '',
    },
  });
  const types = {
    [ConfirmationTypes.activateUser]: {
      icon: <WarningGreyIcon />,
      title: strings.activateUser,
      confirmationMessage: strings.areYouSureActivateUser,
      negative: strings.no,
      positive: strings.yes,
    },
    [ConfirmationTypes.deleteEntry]: {
      icon: <WarningIcon />,
      title: strings.delete,
      confirmationMessage: strings.areYouSureDeleteEntry,
      negative: strings.cancel,
      positive: strings.yes,
    },
    [ConfirmationTypes.takeOwnership]: {
      icon: <TakeOwnershipIcon />,
      title: strings.takeOwnership,
      confirmationMessage: strings.takeOwnershipProfile,
      negative: strings.cancel,
      positive: strings.yes,
    },
    [ConfirmationTypes.booking]: {
      icon: <CancelBookingIcon fontSize={30} />,
      title: strings.cancelBookingConfirmation,
      confirmationMessage: strings.confirmation,
      negative: strings.no,
      positive: strings.yesCancel,
    },
    [ConfirmationTypes.availability]: {
      icon: <AvailabilityConfirmIcon />,
      title: strings.formatString(strings.confirmEvent, title[userType]?.main),
      confirmationMessage: strings.formatString(
        strings.confirmAvailabilityMessage,
        count?.toString() ?? '',
        title[userType]?.message,
      ),
      negative: strings.cancel,
      positive: strings.create,
    },
    [ConfirmationTypes.rejectBooking]: {
      icon: <WarningIcon />,
      title: strings.rejectBooking,
      confirmationMessage: strings.rejectionConfirm,
      negative: strings.no,
      positive: strings.yes,
    },
    [ConfirmationTypes.cancelTAndCModal]: {
      icon: <WarningIcon />,
      title: strings.cancelBooking,
      confirmationMessage: strings.confirmation,
      negative: strings.no,
      positive: strings.yes,
    },
    [ConfirmationTypes.deleteWorkshift]: {
      icon: <WarningIcon />,
      title: strings.deleteWorkshift,
      confirmationMessage: strings.confirmDeleteWorkshift,
      negative: strings.no,
      positive: strings.yes,
    },
    [ConfirmationTypes.updateTiming]: {
      icon: <WarningIcon />,
      title: strings.updateTiming,
      confirmationMessage: strings.workshiftHasAConfirmed,
      negative: strings.cancel,
      positive: strings.proceed,
    },
    [ConfirmationTypes.counterOfferConfirm]: {
      icon: <WarningIcon />,
      title: strings.acceptCounterOffer,
      confirmationMessage: strings.areYouSureAcceptCounter,
      negative: strings.no,
      positive: strings.yes,
    },
    [ConfirmationTypes.counterOfferCancel]: {
      icon: <WarningIcon />,
      title: strings.rejectCounterOffer,
      confirmationMessage: strings.areYouSureRejectCounter,
      negative: strings.no,
      positive: strings.yes,
    },
    [ConfirmationTypes.enforceUpdate]: {
      icon: <WarningIcon />,
      title: strings.updateOperation,
      confirmationMessage: strings.updateOperationMessage,
      negative: strings.logout,
      positive: strings.update,
    },
    [ConfirmationTypes.continueCreation]: {
      icon: <SuccessIcon />,
      title: strings.createMore,
      confirmationMessage: strings.formatString(
        strings.continueCreation,
        title[userType]?.message.toLowerCase(),
      ),
      negative: strings.no,
      positive: strings.yes,
    },
    [ConfirmationTypes.adminContinueCreation]: {
      icon: <SuccessIcon />,
      title: strings.createMore,
      confirmationMessage: strings.formatString(
        strings.adminContinueCreation,
        title[userType]?.message.toLowerCase(),
      ),
      negative: strings.no,
      positive: strings.yes,
    },
    [ConfirmationTypes.softwareWarning]: {
      icon: <WarningIcon />,
      title: '',
      confirmationMessage: '',
      negative: strings.cancel,
      positive: strings.proceed,
    },
    [ConfirmationTypes.userDelete]: {
      icon: <WarningIcon />,
      title: strings.deleteMyAccount,
      confirmationMessage: userConfirmation?.[userType],
      negative: strings.no,
      positive: strings.yes,
    },
    [ConfirmationTypes.expressBooking]: {
      icon: <WarningIcon />,
      title: strings.incompatiblePharmacist,
      confirmationMessage: strings.incompatibleWarning,
      negative: strings.cancel,
      positive: strings.yes,
    },
    [ConfirmationTypes.hidePharmacist]: {
      icon: <WarningIcon />,
      title: strings.hidePharmacist,
      confirmationMessage: strings.areYouSureHidePharmacist,
      negative: strings.cancel,
      positive: strings.yes,
    },
    [ConfirmationTypes.publishConfiguration]: {
      icon: <WarningIcon />,
      title: strings.formatString(strings.recordFormat, strings.publish),
      confirmationMessage: strings.formatString(
        strings.areYouSurePublish,
        strings.publish.toLowerCase(),
      ),
      negative: strings.cancel,
      positive: strings.yes,
    },
    [ConfirmationTypes.unPublishConfiguration]: {
      icon: <WarningIcon />,
      title: strings.formatString(strings.recordFormat, strings.unpublish),
      confirmationMessage: strings.formatString(
        strings.areYouSurePublish,
        strings.unpublish.toLowerCase(),
      ),
      negative: strings.cancel,
      positive: strings.yes,
    },
    [ConfirmationTypes.markIncompatible]: {
      icon: <WarningIcon />,
      title: strings.formatString(strings.markFormat, strings.incompatible),
      confirmationMessage: strings.formatString(
        strings.areYouSureMarkFormat,
        strings.incompatible,
      ),
      negative: strings.cancel,
      positive: strings.yes,
    },
    [ConfirmationTypes.markCompatible]: {
      icon: <WarningIcon />,
      title: strings.formatString(strings.markFormat, strings.compatible),
      confirmationMessage: strings.formatString(
        strings.areYouSureMarkFormat,
        strings.compatible,
      ),
      negative: strings.cancel,
      positive: strings.yes,
    },
    [ConfirmationTypes.unhidePharmacist]: {
      icon: <PositiveWarning />,
      title: strings.formatString(
        strings.unhideFormat,
        strings.pharmacist,
      ) as string,
      confirmationMessage: strings.areYouSureUnhidePharmacist,
      negative: strings.cancel,
      positive: strings.yes,
    },
    [ConfirmationTypes.permanentDelete]: {
      icon: <WarningIcon />,
      title: strings.deleteSelected,
      confirmationMessage: strings.formatString(
        strings.thisRecordDeletePermanently,
        count?.toString() ?? '',
      ),
      negative: strings.cancel,
      positive: strings.yes,
    },
    [ConfirmationTypes.editEmail]: {
      icon: <WarningYellowIcon />,
      title: `${strings.edit} ${strings.email}`,
      confirmationMessage: strings.areYouSureEditEmail,
      negative: strings.cancel,
      positive: strings.yes,
    },
    [ConfirmationTypes.createMultipleAvailabilities]: {
      icon: <WarningIcon />,
      title: strings.createMultipleAvailabilities,
      confirmationMessage: strings.areYouSureCreateAvailabilities,
      negative: strings.cancel,
      positive: strings.yes,
    },
    [changeEmail]: {
      icon: <WarningYellowIcon />,
      title: `${strings.edit} ${strings.email}`,
      confirmationMessage: strings.changeEmailConfirmation,
      negative: strings.cancel,
      positive: strings.continue,
    },
    [ConfirmationTypes.resetSelection]: {
      icon: <WarningIcon />,
      title: strings.resetSelection,
      confirmationMessage: strings.areYouSureResetSelect,
      negative: strings.cancel,
      positive: strings.yes,
    },
    [sendNotification]: {
      icon: <SuccessIcon />,
      title: strings.sendNotification,
      confirmationMessage: strings.areYouSureWantSendNotification,
      negative: strings.cancel,
      positive: strings.yes,
    },
  };

  return (
    <>
      <Box sx={classes.container}>
        <Box sx={classes.closeContainer}>
          {showCloseButton && (
            <IconButton
              size="small"
              sx={classes.closeIcon}
              onClick={handleClose}
            >
              <Close />
            </IconButton>
          )}
        </Box>
        <Box sx={classes.subContainer}>{types[type].icon}</Box>
        <Typography sx={classes.title}>{types[type].title}</Typography>
        <Box sx={classes.subContainer} mx={4}>
          {customMessage ?? (
            <Typography sx={classes.message}>
              {types[type].confirmationMessage}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={[
          classes.buttonContainer,
          type === ConfirmationTypes.availability ? classes.mTop : {},
        ]}
      >
        <CustomButton
          label={types[type].negative}
          customButtonStyle={[classes.button, classes.secondaryButton]}
          variant="text"
          onClick={customClose ?? handleClose}
        />
        <CustomButton
          label={types[type].positive}
          customButtonStyle={classes.button}
          onClick={confirmAction}
        />
      </Box>
    </>
  );
};

export default ConfirmationModal;
