import {
  AdminLegendVariant,
  PharmacistLegendVariant,
} from '@pharmaplan/common';
import { renderScreen } from '../../actions/drawerActions';
import { OtherScreens, ScreenTypes } from '../../helpers/Constants';
import { useAppDispatch } from '../useAppDispatch';
import useDrawer from '../useDrawer';
import useWorkshiftDelete from '../Admin/useWorkshiftDelete';

const useAdminReportsFn = () => {
  const dispatch = useAppDispatch();
  const { openDrawer } = useDrawer();
  const { showWorkshiftDelete, showRequestCancellation, showDeleteAvailability } = useWorkshiftDelete();

  const showExpressBooking = (id: string, workshift = false) => {
    openDrawer();

    const typeAndFromScreen = workshift
      ? {
        type: AdminLegendVariant.posted,
        fromScreen: OtherScreens.AdminPostedWorkshiftReports,
      }
      : {
        type: AdminLegendVariant.booked,
        fromScreen: OtherScreens.AdminBookingReports,
      };

    dispatch(
      renderScreen({
        ...typeAndFromScreen,
        screenNumber: 5,
        screenType: ScreenTypes.availableWorkshift,
        eventId: id,
      }),
    );
  };

  const findMatchingWorkshifts = (
    availabilityId: string,
    code: string,
    pharmacistId: string,
  ) => {
    openDrawer();

    dispatch(
      renderScreen({
        screenNumber: 5,
        screenType: ScreenTypes.pharmacistList,
        eventId: availabilityId,
        date: code,
        pharmacistId,
      }),
    );
  };

  const findMatchingAvailabilities = (workshiftId: string) => {
    openDrawer();
    dispatch(
      renderScreen({
        screenNumber: 4,
        screenType: ScreenTypes.availableWorkshift,
        eventId: workshiftId,
        type: PharmacistLegendVariant.workshift,
        fromScreen: OtherScreens.AdminPostedWorkshiftReports,
      }),
    );
  };

  const goToApproveBooking = (
    bookingId: string,
    workshiftId: string,
    fromScreen: OtherScreens,
    eventIds?: Array<string> | null,
  ) =>
    () => {
      openDrawer();
      dispatch(
        renderScreen({
          screenNumber: 4,
          screenType: ScreenTypes.requestedList,
          eventId: bookingId,
          workshiftId,
          eventIds: eventIds ?? [],
          fromScreen,
        }),
      );
    };

  return {
    showExpressBooking,
    findMatchingWorkshifts,
    findMatchingAvailabilities,
    showWorkshiftDelete,
    goToApproveBooking,
    showRequestCancellation,
    showDeleteAvailability,
  };
};
export default useAdminReportsFn;
