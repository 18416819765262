import { Box, Typography } from '@mui/material';
import React from 'react';
import {
  IBookingsRequested,
  bookingsRequested,
  getBookingRequested,
} from '@pharmaplan/common';
import { useFormik } from 'formik';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import {
  renderScreen,
  setBack,
  setDrawerContentPadding,
  setFooterShow,
  setTitle,
} from '../../../actions/drawerActions';
import strings from '../../../localization';
import { getDayMonthDateYear } from '../../Reports/helpers';
import ICalendarEventType from '../../../types/ICalendarEventType';
import { ScreenTypes } from '../../../helpers/Constants';
import classes from './styles';
import sorter, { sortTypes } from './utils';
import BookingRequest from './BookingRequest/BookingRequest';
import CustomDropdown from '../../common/CustomDropdown/CustomDropdown';

export interface IBookingRequestsList {
  eventId: string;
  date: string;
  type: ICalendarEventType;
  eventIds: Array<string>;
}

const BookingRequestsList: React.FC<IBookingRequestsList> = ({
  eventId,
  date,
  type,
  eventIds,
}) => {
  const dispatch = useAppDispatch();

  const events = useAppSelector(bookingsRequested);

  const formik = useFormik({
    initialValues: {
      requestsList: 'requestedDate',
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  const tempEvents = events.slice();
  const { sortByDate, sortByBoolean, sortByNumeric } = sorter(tempEvents);

  const sortBy = [
    { key: 'requestedDate', label: strings.requestedDate },
    { key: 'rating', label: strings.rating },
    { key: 'price', label: strings.totalCost },
    { key: 'distance', label: strings.distance },
    { key: 'isFavorite', label: strings.favourite },
  ];

  const sortFunctions = () =>
    ({
      requestedDate: () =>
        sortByDate('requestedDate'),
      rating: () =>
        sortByNumeric('rating', sortTypes.descending),
      price: () =>
        sortByNumeric('price', sortTypes.ascending),
      distance: () =>
        sortByNumeric('distance', sortTypes.ascending),
      isFavorite: () =>
        sortByBoolean('isFavorite'),
    });

  const sortedEvents = (
    sortFunctions()[
      formik.values.requestsList as keyof typeof sortFunctions
    ] as () => Array<IBookingsRequested>
  )();

  const gotoWorkshiftSummary = () => {
    dispatch(
      renderScreen({
        eventIds,
        type,
        screenNumber: 1,
        screenType: ScreenTypes.workshiftSummary,
      }),
    );
  };

  React.useEffect(() => {
    dispatch(getBookingRequested(eventId));

    dispatch(
      setTitle({
        title: strings.formatString(
          strings.workshiftDate,
          getDayMonthDateYear(date),
        ) as string,
        customTitleStyle: classes.header,
      }),
    );

    dispatch(setFooterShow(false));
    dispatch(setBack(gotoWorkshiftSummary));
    dispatch(setDrawerContentPadding('0px'));

    return () => {
      dispatch(setFooterShow(true));
      dispatch(setBack(null));
      dispatch(setDrawerContentPadding('16px'));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box sx={classes.root}>
        <Box sx={classes.container}>
          <Typography sx={classes.title}>
            {strings.formatString(strings.bookingRequestsNum, events.length)}
          </Typography>
        </Box>

        <CustomDropdown
          customClass={classes.filterDropdown}
          formik={formik}
          radioDropdown
          id="requestsListFilter"
          name="requestsList"
          menuItems={sortBy}
        />
      </Box>

      {sortedEvents.map((item: IBookingsRequested) =>
        (
          <BookingRequest
            key={item.bookingId}
            date={date}
            eventId={eventId}
            eventIds={eventIds}
            type={type}
            event={item}
          />
        ))}
    </>
  );
};

export default BookingRequestsList;
