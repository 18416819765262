import { createTheme } from '@mui/system';
import ThemeConstants from './ThemeConstants';

const { palette } = createTheme();
const { augmentColor } = palette;
export const createColor = (mainColor: string) =>
  augmentColor({ color: { main: mainColor } });

export default {
  primary: {
    main: ThemeConstants.primary.main,
    light: ThemeConstants.primary.light,
    inputLabel: ThemeConstants.primary.inputLabel,
  },

  secondary: {
    main: ThemeConstants.secondary.main,
  },

  common: {
    main: ThemeConstants.common.lightBackgroundColor,
    lightBackgroundColor: ThemeConstants.common.lightBackgroundColor,
    grey150: ThemeConstants.common.grey150,
    grey350: ThemeConstants.common.grey350,
    grey850: ThemeConstants.common.grey850,
    blue400: ThemeConstants.common.blue400,
    blue600: ThemeConstants.common.blue600,
    lightGreen: ThemeConstants.common.lightGreen,
    border: ThemeConstants.common.border,
    counterRed: ThemeConstants.common.counterRed,
    error: ThemeConstants.common.error,
    secondaryButtonHover: ThemeConstants.common.secondaryButtonHover,
    borderInput: ThemeConstants.common.borderInput,
    hover: ThemeConstants.common.hover,
    green300: ThemeConstants.common.green300,
    green600: ThemeConstants.common.green600,
    otpInput: ThemeConstants.common.otpInput,
    gray: ThemeConstants.common.gray,
    hoverGray: ThemeConstants.common.hoverGray,
    freeGray: ThemeConstants.common.freeGray,
    newBlue: ThemeConstants.common.newBlue,
  },

  footer: {
    backgroundColor: ThemeConstants.footer.backgroundColor,
    verticalLine: ThemeConstants.footer.verticalLine,
  },

  grey: {
    400: ThemeConstants.grey[400],
    500: ThemeConstants.grey[500],
    600: ThemeConstants.grey[600],
    800: ThemeConstants.grey[800],
  },

  eventChip: {
    posted: ThemeConstants.eventChip.posted,
    postedText: ThemeConstants.eventChip.postedText,
    requestedText: ThemeConstants.eventChip.requestedText,
    requested: ThemeConstants.eventChip.requested,
    bookedText: ThemeConstants.eventChip.bookedText,
    booked: ThemeConstants.eventChip.booked,
    workshiftText: ThemeConstants.eventChip.workshiftText,
  },

  pharmacist: {
    requested: ThemeConstants.pharmacists.requested,
    requestedLight: ThemeConstants.pharmacists.requestedLight,

    booked: ThemeConstants.pharmacists.booked,
    bookedLight: ThemeConstants.pharmacists.bookedLight,

    availability: ThemeConstants.pharmacists.availability,
    availabilityLight: ThemeConstants.pharmacists.availabilityLight,

    tempAvailability: ThemeConstants.pharmacists.tempAvailability,
    tempAvailabilityLight: ThemeConstants.pharmacists.tempAvailabilityLight,

    workshift: ThemeConstants.pharmacists.workshift,
    workshiftLight: ThemeConstants.pharmacists.workshiftLight,
  },

  pharmacy: {
    posted: ThemeConstants.pharmacy.posted,
    postedLight: ThemeConstants.pharmacy.postedLight,

    booked: ThemeConstants.pharmacy.booked,
    bookedLight: ThemeConstants.pharmacy.bookedLight,

    tempAvailability: ThemeConstants.pharmacists.tempAvailability,
    tempAvailabilityLight: ThemeConstants.pharmacists.tempAvailabilityLight,

    requested: ThemeConstants.pharmacy.requested,
    requestedLight: ThemeConstants.pharmacy.requestedLight,
  },

  admin: {
    posted: ThemeConstants.pharmacy.posted,
    postedLight: ThemeConstants.pharmacy.postedLight,

    booked: ThemeConstants.pharmacy.booked,
    bookedLight: ThemeConstants.pharmacy.bookedLight,

    availability: ThemeConstants.pharmacists.availability,
    availabilityLight: ThemeConstants.pharmacists.availabilityLight,

    tempAvailability: ThemeConstants.pharmacists.tempAvailability,
    tempAvailabilityLight: ThemeConstants.pharmacists.tempAvailabilityLight,

    requested: ThemeConstants.pharmacy.requested,
    requestedLight: ThemeConstants.pharmacy.requestedLight,

    total: ThemeConstants.pharmacists.availability,
    totalLight: ThemeConstants.pharmacists.availabilityLight,

    workshift: ThemeConstants.pharmacists.workshift,
    workshiftLight: ThemeConstants.pharmacists.workshiftLight,
  },

  superadmin: {
    posted: ThemeConstants.pharmacy.posted,
    postedLight: ThemeConstants.pharmacy.postedLight,

    booked: ThemeConstants.pharmacy.booked,
    bookedLight: ThemeConstants.pharmacy.bookedLight,

    tempAvailability: ThemeConstants.pharmacists.tempAvailability,
    tempAvailabilityLight: ThemeConstants.pharmacists.tempAvailabilityLight,

    availability: ThemeConstants.pharmacists.availability,
    availabilityLight: ThemeConstants.pharmacists.availabilityLight,

    requested: ThemeConstants.pharmacy.requested,
    requestedLight: ThemeConstants.pharmacy.requestedLight,

    total: ThemeConstants.pharmacists.availability,
    totalLight: ThemeConstants.pharmacists.availabilityLight,

    workshift: ThemeConstants.pharmacists.workshift,
    workshiftLight: ThemeConstants.pharmacists.workshiftLight,
  },

  calendarHover: {
    requested: ThemeConstants.calendarHover.requested,
    requestedBorder: ThemeConstants.calendarHover.requestedBorder,
    workshift: ThemeConstants.calendarHover.workshift,
    workshiftBorder: ThemeConstants.calendarHover.workshiftBorder,
    posted: ThemeConstants.calendarHover.posted,
    postedBorder: ThemeConstants.calendarHover.postedBorder,
    availabilityBorder: ThemeConstants.calendarHover.availabilityBorder,
    availability: ThemeConstants.calendarHover.availability,
    tempAvailability: ThemeConstants.common.grey150,
    tempAvailabilityBorder: ThemeConstants.common.grey350,
    bookedBorder: ThemeConstants.calendarHover.bookedBorder,
    booked: ThemeConstants.calendarHover.booked,
  },

  notification: {
    notVisited: ThemeConstants.notification.notVisited,
    confirmBg: ThemeConstants.notification.confirmBg,
  },
};
