import React, { FC } from 'react';
import strings from '../../../localization';
import ServiceCard from '../ServiceCard/ServiceCard';
import ContactContent from './ContactContent';

const ContactDetails: FC = () =>
  (
    <ServiceCard title={strings.contactDetails} content={<ContactContent />} />
  );

export default ContactDetails;
