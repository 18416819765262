import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    ml: '8px',
  },
  autocomplete: {
    '& .MuiFormControl-root': {
      backgroundColor: 'secondary.main',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: '24px',
    pt: '8px',
  },
  titleFont: {
    fontSize: 18,
    fontWeight: 600,
  },

} satisfies Record<string, SxProps<Theme>>;

export default styles;
