import React from 'react';
import { DateTime } from 'luxon';
import { AdminLegendVariant } from '@pharmaplan/common';
import {
  PharmacistLegendVariant,
  PharmacyLegendVariant,
} from '../../../helpers/Constants';
import ICalendar from '../../../types/ICalendar';
import { eventsMapper } from '../../Dashboard/MainCalendar/helpers';
import { adminLegendObject } from './LegendContainer';
import AdminLegend from './AdminLegend';
import genericClasses from '../../../theme/GenericClasses';

export const getTotalCount = (
  events: Array<ICalendar>,
  filterBy: `${
    | PharmacistLegendVariant
    | PharmacyLegendVariant
    | AdminLegendVariant}`,
  activeMonth: number,
) =>
  events.reduce((acc, cur) => {
    const eventActiveMonth = new Date(cur.startDate).getMonth();
    if (cur.type === filterBy && eventActiveMonth === activeMonth) {
      // eslint-disable-next-line no-param-reassign
      acc += 1;
    }
    return acc;
  }, 0);

export const getTotalHrs = (
  events: Array<ICalendar>,
  hoursOf: `${
    | PharmacistLegendVariant
    | PharmacyLegendVariant
    | AdminLegendVariant}`,
  activeMonth: number,
) =>
  events.reduce((acc, cur) => {
    const eventActiveMonth = new Date(cur.start).getMonth();
    if (cur.type === hoursOf && eventActiveMonth === activeMonth) {
      // eslint-disable-next-line no-param-reassign
      acc += Math.abs(
        DateTime.fromISO(cur.endDate)
          .diff(DateTime.fromISO(cur.startDate))
          .as('hour'),
      );
    }
    return acc;
  }, 0);

export const adminTotal = (events: Array<ICalendar>, activeMonth: number) =>
  adminLegendObject.reduce(
    (acc, curr) => {
      acc.totalHours += getTotalHrs(
        events?.map((e) =>
          eventsMapper(e)) ?? [],
        curr.variant,
        activeMonth,
      );
      acc.totalCount += getTotalCount(events ?? [], curr.variant, activeMonth);
      return acc;
    },
    { totalHours: 0, totalCount: 0 },
  );

export const AdminRenderer = (
  totalCount: number,
  totalHours: number,
  events: ICalendar[],
  currentActiveMonth: number,
  row:boolean,
) =>
  adminLegendObject.map((leg) => {
    const isTotal = leg.variant === AdminLegendVariant.total;

    return (
      <AdminLegend
        totalCount={
          isTotal
            ? totalCount
            : getTotalCount(events ?? [], leg.variant, currentActiveMonth)
        }
        totalHrs={
          isTotal
            ? totalHours
            : Math.trunc(
              getTotalHrs(
                events?.map((e) =>
                  eventsMapper(e)) ?? [],
                leg.variant,
                currentActiveMonth,
              ),
            )
        }
        variant={leg.variant}
        customClass={
          row ? genericClasses.legendsRowMargin : genericClasses.legendsMargin
        }
        key={leg.variant}
      />
    );
  });
