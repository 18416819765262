import React, { FC, useEffect } from 'react';

import {
  adminActions,
  adminDeletedUserProfile,
  getPharmacistDetails,
  HelpTypes,
  PermissionsOfAdmin,
  pharmacistDetails,
  selectMatchingAvailabilities,
} from '@pharmaplan/common';
import { Box } from '@mui/material';

import CollapsibleCard from '../../../../common/CollapsibleCard';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import useGetItems from '../../../../../hooks/useGetItems';

import ProfileContent from '../../ProfileContent/ProfileContent';
import ICalendarEventType from '../../../../../types/ICalendarEventType';
import {
  IFromScreen,
  renderScreen,
  setBack,
  setFooterShow,
} from '../../../../../actions/drawerActions';
import {
  OtherScreens,
  ScreenTypes,
  SecondaryAdminNavigation,
} from '../../../../../helpers/Constants';

import { pharmacistProfileConfig } from './helper';
import AdminPharmacistCard from './AdminPharmacistCard';
import usePharmacistProfileActions from '../../../../../hooks/PharmacistProfile/usePharmacistProfileActions';
import useAdminPermissions from '../../../../../hooks/Admin/useAdminPermissions';

export interface IPharmacistViewProfile {
  pharmacistId: string;
  type: ICalendarEventType;
  fromScreen?: IFromScreen;
  hasOwner?: boolean;
  showMenu?: boolean;
  enableActions?: boolean;
  back?: () => void;
}

const PharmacistViewProfile: FC<IPharmacistViewProfile> = ({
  pharmacistId,
  type,
  fromScreen,
  hasOwner,
  showMenu,
  enableActions,
  back,
}) => {
  const dispatch = useAppDispatch();

  const details = useAppSelector(pharmacistDetails);
  const matchingAvailabilities = useAppSelector(selectMatchingAvailabilities);

  const { can } = useAdminPermissions();
  const canActivate = can(PermissionsOfAdmin.Activation);

  const { workShiftId } = matchingAvailabilities ?? {};
  const { pharmacist, pharmacistCapacities } = details ?? {};

  const fromMatchingAvailabilities = fromScreen === OtherScreens.MatchingAvailabilities;
  const fromPendingActivations = fromScreen === SecondaryAdminNavigation.pendingActivation;
  const isDeletedPendingUser = fromScreen === OtherScreens.DeletedPendingUsers;
  const isDeletedPharmacistList = fromScreen === OtherScreens.DeletedPharmacistList;

  const isDeletedList = isDeletedPendingUser || isDeletedPharmacistList;

  const successAction = isDeletedList
    ? adminActions.deletedUserProfile
    : adminActions.getPharmacistDetails;

  const { preferdLanguage, pharmacistCapacityId } = pharmacist ?? {};

  const { getLanguageName, getPharmacistCapacities } = useGetItems();

  const language = getLanguageName(preferdLanguage);
  const { handleButton } = usePharmacistProfileActions(HelpTypes.pharmacist);
  const pharmacistCapacity = getPharmacistCapacities(
    pharmacistCapacities,
    pharmacistCapacityId,
  );

  const gotoMatchingAvailabilities = () => {
    dispatch(
      renderScreen({
        screenNumber: 4,
        screenType: ScreenTypes.availableWorkshift,
        eventId: workShiftId,
        type,
      }),
    );
  };

  const handleBack = () => {
    if (fromMatchingAvailabilities) {
      dispatch(setBack(gotoMatchingAvailabilities));
    }
  };

  const initCall = () => {
    if (isDeletedList) {
      dispatch(
        adminDeletedUserProfile({
          userId: pharmacistId,
          approved: !!isDeletedPharmacistList,
        }),
      );
    } else {
      dispatch(getPharmacistDetails(pharmacistId));
    }
  };

  useEffect(() => {
    handleBack();
    initCall();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workShiftId]);

  useEffect(() => {
    if (fromPendingActivations && enableActions && canActivate) {
      handleButton(pharmacistId);
    } else {
      dispatch(setFooterShow(false));
    }

    dispatch(setBack(back ?? null));

    return () => {
      dispatch(setBack(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <AdminPharmacistCard
        fromScreen={fromScreen}
        pharmacistId={pharmacistId}
        type={type}
        hasOwner={hasOwner}
        showMenu={showMenu}
        showActions
      />

      {pharmacistProfileConfig(
        details,
        language,
        pharmacistCapacity,
        successAction,
      ).map((item) => {
        const { key, title, keepExpanded, cardContent, customComponent } = item ?? {};

        return (
          <CollapsibleCard
            key={key}
            title={title}
            keepExpanded={keepExpanded}
            content={(
              <ProfileContent
                successAction={successAction}
                content={cardContent}
                customComponent={customComponent}
              />
            )}
          />
        );
      })}
    </Box>
  );
};

export default PharmacistViewProfile;
