import React from 'react';
import { FormikValues } from 'formik';
import {
  ConfirmationTypes,
  adminDeleteAvailability,
  adminRequestCancellation,
  deleteAdminWorkshift,
  deletePharmacyWorkshift,
} from '@pharmaplan/common';
import { useAppDispatch } from '../useAppDispatch';
import { setDialog } from '../../reducers/dialogReducer';
import DeactivateDialog from '../../components/Admin/PharmacistList/DeactivateDialog';
import strings from '../../localization';
import { DeactivateModalIcon } from '../../helpers/Constants';
import ValidatorCancellation from '../../components/Admin/PharmacistList/DeactivateDialog/validator';
import CancellationDateTime from '../../components/Admin/PharmacyList/ListOfWorkshifts/CancellationDateTime';
import { IWorkshiftId } from '../AvailableWorkshift/useAvailableWorkshiftActions';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';

const useWorkshiftDelete = () => {
  const dispatch = useAppDispatch();

  const requestCancellation = (values: FormikValues) => {
    const { bookingId, reason } = values ?? {};
    dispatch(adminRequestCancellation({ reason, bookingId }));
  };

  const showRequestCancellation = (bookingId: string, dateTime: string) => {
    dispatch(
      setDialog({
        Component: (
          <DeactivateDialog
            onSubmit={requestCancellation}
            confirmationMessage={strings.confirmWorkshiftCancel}
            id={bookingId}
            initialValues={{
              bookingId,
              reason: '',
            }}
            heading={strings.requestCancellation}
            iconType={DeactivateModalIcon.warning}
            validator={ValidatorCancellation()}
            name="reason"
            customComponent={<CancellationDateTime date={dateTime} />}
          />
        ),
        heading: {
          title: '',
        },
        showCloseButton: true,
      }),
    );
  };

  const handleWorkshiftDelete = (values: FormikValues) => {
    const { notes, workshiftId } = values ?? {};
    dispatch(deletePharmacyWorkshift({ notes, workshiftId }));
  };

  const showDeleteWarning = ({ workshiftId }: IWorkshiftId) => {
    dispatch(
      setDialog({
        heading: {
          title: '',
        },
        Component: (
          <ConfirmationModal
            type={ConfirmationTypes.deleteWorkshift}
            showCloseButton
            confirmAction={() => {
              dispatch(deleteAdminWorkshift(workshiftId));
            }}
          />
        ),
        showCloseButton: false,
      }),
    );
  };

  const showWorkshiftDelete = (workshiftId: string, dateTime: string) => {
    dispatch(
      setDialog({
        Component: (
          <DeactivateDialog
            onSubmit={handleWorkshiftDelete}
            confirmationMessage={strings.confirmDeleteWorkshift}
            id={workshiftId}
            initialValues={{
              workshiftId,
              notes: '',
            }}
            heading={strings.deleteWorkshift}
            iconType={DeactivateModalIcon.warning}
            name="notes"
            validator={null}
            customComponent={<CancellationDateTime date={dateTime} />}
          />
        ),
        heading: {
          title: '',
        },
        showCloseButton: true,
      }),
    );
  };

  const deleteAvailability = (values: FormikValues) => {
    const { notes, availabilityId } = values ?? {};
    dispatch(adminDeleteAvailability({ availabilityIds: [availabilityId], notes }));
  };

  const showDeleteAvailability = (availabilityId: string, dateTime: string) => {
    dispatch(
      setDialog({
        Component: (
          <DeactivateDialog
            onSubmit={deleteAvailability}
            confirmationMessage={strings.confirmDeleteAvailability}
            id={availabilityId}
            initialValues={{
              availabilityId,
              notes: '',
            }}
            heading={strings.deleteAvailability}
            iconType={DeactivateModalIcon.warning}
            validator={null}
            name="notes"
            customComponent={<CancellationDateTime date={dateTime} />}
          />
        ),
        heading: {
          title: '',
        },
        showCloseButton: true,
      }),
    );
  };

  return {
    showWorkshiftDelete,
    showRequestCancellation,
    showDeleteWarning,
    showDeleteAvailability,
  };
};

export default useWorkshiftDelete;
