import React, { useEffect, useMemo } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import {
  adminActions,
  adminBroadcastGroup,
  getBroadcastGroup,
  resetStatus,
  successSelector,
  updateBroadcastGroup,
} from '@pharmaplan/common';

import { FormikValues } from 'formik';
import { Typography } from '@mui/material';
import { showSuccess } from '../../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import { BroadcastGroupActionType } from '../../../helpers/Constants';
import strings from '../../../localization';

import { useAppDispatch } from '../../useAppDispatch';
import { useAppSelector } from '../../useAppSelector';
import styles from '../../../components/Admin/BroadcastList/styles';

interface IUseBroadcastGroupEdit {
  type: BroadcastGroupActionType;
}

const { create, edit } = BroadcastGroupActionType;
const successAction = adminActions.updateBroadcastGroup;

const useBroadcastGroupEdit = ({ type }: IUseBroadcastGroupEdit) => {
  const dispatch = useAppDispatch();
  const broadcastGroup = useAppSelector(adminBroadcastGroup);
  const navigate = useNavigate();

  const { broadcastGroupUsers } = broadcastGroup ?? {};
  const ids = useMemo(
    () =>
      broadcastGroupUsers.map((item) =>
        item.userId),
    [broadcastGroupUsers],
  );

  const success = useAppSelector((state) =>
    successSelector([successAction], state));

  const { state } = useLocation();

  const { id, groupName } = state ?? {};

  const isEdit = type === edit;

  const NumberOfIdComponent = () =>
    (
      <Typography style={styles.title}>
        {strings.editGroup}
        {' '}
        -
        {' '}
        {groupName}
        <Typography component="span" sx={styles.numberOfIds}>
          (
          {ids.length}
          )
        </Typography>
      </Typography>
    );

  const title = {
    [create]: strings.createNewGroup,
    [edit]: <NumberOfIdComponent />,
  };

  const confirmEditAction = (selectedIds: Array<string>) =>
    (values: FormikValues) => {
      const { groupName: formikName } = values ?? {};
      dispatch(
        updateBroadcastGroup({
          groupId: id,
          groupName: formikName,
          userIds: selectedIds,
        }),
      );
    };

  useEffect(() => {
    if (isEdit) {
      dispatch(getBroadcastGroup(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success) {
      showSuccess(dispatch, strings.updatedSuccessfullyFormat);
      navigate(-1);
      dispatch(resetStatus([successAction]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return { title: title[type], confirmEditAction, editIds: ids, isEdit };
};

export default useBroadcastGroupEdit;
