import {
  CalendarStateKey,
  ICalendarEvent,
  TimeFormat,
} from '@pharmaplan/common';
import { DateTime } from 'luxon';
import { groupBy } from 'rambda';
import ICalendar from '../../../types/ICalendar';
import { timeString } from '../../../helpers/Functions';

export const toUTC = (date: string) => {
  const isoDate = DateTime.fromISO(date).toISO();
  const tempDate = new Date(isoDate ?? '');
  return tempDate;
};

export const toJSDate = (date: string) => {
  if (typeof date !== 'string') return date;
  let tempDate = DateTime.fromISO(date, { setZone: true }).toISO({
    includeOffset: false,
  });
  tempDate = `${tempDate?.slice(0, -4)}`;
  return new Date(tempDate);
};
export const eventsMapper = (event: ICalendar) =>
  ({
    ...event,

    end: toJSDate(event.endDate),
    start: toJSDate(event.startDate),
  });

const groupByStartDate = groupBy((e: ICalendar) =>
  e.startDate.split('T')[0]);

const groupByType = groupBy((e: ICalendar) =>
  e.type);

export const transformCalendarEvents = (
  events: ICalendar[],
  activeView: CalendarStateKey,
  timeFormat: TimeFormat,
) => {
  let res: ICalendar[] = [];

  const startGroup = groupByStartDate(events);

  /* [22-03-2022]:{
      [requested]:{...events}
  } */
  const typeStartGroup = Object.keys(startGroup).reduce(
    (result, x) =>
      ({ ...result, [x]: groupByType(startGroup[x]) }),
    {},
  );
  // FLAT MAP OF OBJECTS
  const onlyTypeEvents = Object.values(typeStartGroup).map((item) =>
    Object.values(item as ICalendar).map((inItem) => {
      const durations = inItem.map((event: ICalendarEvent, index: number) => {
        const { startDate, endDate, logoId, id, workShiftId, availableTimeId } = event ?? {};
        return {
          label: timeString(startDate, endDate, timeFormat),
          key: `${index}-${startDate}`,
          logoId,
          id,
          workshiftId: workShiftId,
          availableTimeId,
        };
      });

      return {
        ...inItem[0],
        count: inItem.length,
        durations,
      };
    }));

  if (activeView === CalendarStateKey.month) {
    // TRANSFORM TO TRUE JS DATE OBJECTS
    onlyTypeEvents.forEach((item) =>
      item.forEach((e) =>
        res.push(eventsMapper(e))));
  } else {
    res = events.map((e) =>
      eventsMapper(e));
  }
  return { result: res, typeStartGroup };
};
