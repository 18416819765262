import { useEffect } from 'react';

import { getNearbyProps, setNearbyProps } from '@pharmaplan/common';

import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

const useUsersLocation = () => {
  const dispatch = useAppDispatch();
  const nearbyProps = useAppSelector(getNearbyProps);

  useEffect(() => {
    navigator?.geolocation
      && navigator?.geolocation.getCurrentPosition((position) =>
        dispatch(setNearbyProps({
          ...nearbyProps,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useUsersLocation;
