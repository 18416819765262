import React, { FC } from 'react';

import { SxProps } from '@mui/system';

import {
  IAdminRequestData,
  IAdminWorkshiftDetails,
  IMatchingAvailabilities,
  IPharmacistEvent,
  PharmacistLegendVariant,
} from '@pharmaplan/common';

import { Box } from '@mui/material';
import ServiceCard from '../ServiceCard/ServiceCard';
import PharmacyCardContent from './PharmacyCardContent/PharmacyCardContent';
import PharmacyCardFooter from './PharmacyCardFooter/PharmacyCardFooter';
import ICalendarEventType from '../../../types/ICalendarEventType';
import classes from './PharmacyCardContent/style';

export interface IPharmacyCard {
  type: ICalendarEventType;
  hideBorder?: boolean;
  customContainerStyle?: SxProps;
  event: Partial<IPharmacistEvent> &
    Partial<
      IAdminWorkshiftDetails | IAdminRequestData | IMatchingAvailabilities
    >;
  successAction: string;
  showPharmacyName?: boolean;
  CustomContent?: React.ReactNode;
  CustomFooter?: React.ReactNode;
  contentClick?: () => void;
  customTitleStyle?: SxProps;
}

const PharmacyCard: FC<IPharmacyCard> = ({
  type,
  event,
  successAction,
  customContainerStyle,
  showPharmacyName,
  CustomContent,
  CustomFooter,
  hideBorder = false,
  contentClick = null,
  customTitleStyle,
}) => {
  const handlePharmacyCard = () => {
    if (contentClick) {
      return (
        <Box component="div" sx={classes.cardHover} onClick={contentClick}>
          <PharmacyCardContent
            successAction={successAction}
            CustomContent={CustomContent}
            type={type}
            event={event}
            showPharmacyName={showPharmacyName}
            customTitleStyle={customTitleStyle}
          />
        </Box>
      );
    }

    return (
      <PharmacyCardContent
        successAction={successAction}
        CustomContent={CustomContent}
        type={type}
        event={event}
        showPharmacyName={showPharmacyName}
        customTitleStyle={customTitleStyle}
      />
    );
  };

  return (
    <ServiceCard
      customContainerStyle={customContainerStyle}
      eventType={
        hideBorder
          ? null
          : PharmacistLegendVariant[
              type as keyof typeof PharmacistLegendVariant
          ]
      }
      content={handlePharmacyCard()}
      footer={
        CustomFooter ?? (
          <PharmacyCardFooter successAction={successAction} event={event} />
        )
      }
    />
  );
};

export default PharmacyCard;
