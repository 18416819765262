import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import {
  getPharmacistReports,
  getPharmacyReportFilter,
  getPharmacyReports,
  getPharmacyReportsPdf,
  getReportsFilterInfo,
  getReportsPDF,
  IPharmacyBookingReports,
  IPharmacyReportParams,
  IReportsData,
  IReportsPDFParams,
  pharmacistActions,
  pharmacyActions,
  pharmacyFilterData,
  PharmacyLegendVariant,
  reportFilterInfo,
  ReportRequestTypes,
  resetStatus,
  ServiceTypes,
  successOrSelector,
  SupportedTimezones,
  ToUserTypings,
  TypeOfUser,
  userPreferredTimeFormat,
} from '@pharmaplan/common';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';
import FilledFilter from '@pharmaplan/common/assets/icons/searchIconGreen.svg';
import UnfilledFilter from '@pharmaplan/common/assets/icons/searchIcon.svg';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import ServiceHandler from '@pharmaplan/common/helpers/ServiceHandler';
import CloseFilter from '../../assets/svg/close.svg';

import { useAppSelector } from '../../hooks/useAppSelector';
import AdsContainer from '../common/AdsContainer';
import DynamicTable from '../DynamicTable';
import newReplacmentIcon from '../../assets/svg/newReplacmentIcon.svg';

import { useAppDispatch } from '../../hooks/useAppDispatch';
import reportsMapper, {
  pharmacistHeaders,
  pharmacyHeaders,
  pharmacyReportsMapper,
} from './helpers';
import IDropdownObject from '../../types/ICustomDropdown';
import useRefresh from '../../hooks/useRefresh';
import {
  availableReportFilter,
  bookingReportFilter,
  pharmacyBookingReportFilter,
  pharmacyWorkshiftReportFilter,
  workshiftAndRequestedReportFilter,
} from '../../helpers/Filters';
import { deserializeISODate, downloadPDF, setPageTitle } from '../../helpers/Functions';
import strings from '../../localization';
import useUser from '../../hooks/useUser';
import ResponsiveClasses from '../../theme/ResponsiveClasses';
import { MomentTimeFormats, OutcomeModalTypes } from '../../helpers/Constants';
import CustomButton from '../common/CustomButton/CustomButton';
import useDrawerNavigation from '../../hooks/useDrawerNavigation';
import Drawer from '../Drawer/Drawer';
import usePreRequest from '../../hooks/usePreRequest';
import { setDialog } from '../../reducers/dialogReducer';
import OutcomeModal from '../Modals/OutcomeModal';
import useCancellationBooking from '../../hooks/useCancellationBooking';

export interface IStataData {
  banners: Array<IDropdownObject>;
  pharmacies: Array<IDropdownObject>;
}

export interface IInitialValues {
  startDate: string;
  endDate: string;
  banners: Array<string>;
  pharmacyId: null | string;
}

export interface IPharmacyInitialValues {
  startDate: string;
  endDate: string;
  pharmacistId: string;
  workshiftTypes: string;
}

const initialValues: IPharmacyInitialValues & IInitialValues = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  startDate: null,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  endDate: null,
};

export interface IPopoverState {
  open: boolean;
  date: string;
  endDate: string;
  id: string;
  recId: string;
}

const pharmacistInitialValues = {
  banners: [],
  pharmacyId: null,
};

const pharmacyInitialValues = {
  pharmacistId: '',
  workshiftTypes: '',
};
const reportsFileName = 'reports.pdf';

const Reports = () => {
  const { state } = useLocation();
  const { type, title: headerTitle, start, end } = state ?? {};
  const dispatch = useAppDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [page, setPage] = useState(1);
  const filterValues = useAppSelector(reportFilterInfo);
  const { pdf, reports, userType } = useUser();
  const pharmacyFilter = useAppSelector(pharmacyFilterData);
  const timeFormat = useAppSelector(userPreferredTimeFormat);
  const loadSuccess = useAppSelector((st) =>
    successOrSelector([pharmacistActions.reports, pharmacyActions.reports], st));
  const requestSuccess = useAppSelector((st) =>
    successOrSelector([pharmacistActions.requestBooking], st));

  const isPharmacist = userType === TypeOfUser.pharmacist;

  const isMounted = useRef(false);

  const { preRequestBooking } = usePreRequest();
  const { openDrawer } = useDrawerNavigation();
  const {
    handleSelfCancellation,
    showCancelConfirmation,
    handleCancelConfirmation,
  } = useCancellationBooking();
  const serviceType = ServiceHandler.getService();

  const handleCancellation = ({
    id = '',
    workshiftDate = '',
    confirmationDate = '',
    gracePeriod = 0,
  }) => {
    const isFullService = serviceType === ServiceTypes.full;
    const isSelfService = serviceType === ServiceTypes.self;

    if (isSelfService) {
      handleSelfCancellation(workshiftDate, confirmationDate, id, gracePeriod);
    }

    if (isFullService) {
      showCancelConfirmation(id, handleCancelConfirmation);
    }
  };

  const userBasedRequest = ToUserTypings({
    [TypeOfUser.pharmacist]: {
      action: preRequestBooking,
    },
    [TypeOfUser.pharmacy]: {
      action: openDrawer,
    },
  });

  const { action } = userBasedRequest[userType];

  const userConfig = ToUserTypings({
    [TypeOfUser.pharmacist]: {
      filterRequest: getReportsFilterInfo(type),
      getReport: getPharmacistReports,
      values: pharmacistInitialValues,
      mapper: reportsMapper,
      headers: pharmacistHeaders()[type as keyof typeof pharmacistHeaders],
    },
    [TypeOfUser.pharmacy]: {
      filterRequest: getPharmacyReportFilter(),
      getReport: getPharmacyReports,
      values: pharmacyInitialValues,
      mapper: pharmacyReportsMapper,
      headers: pharmacyHeaders()[type as keyof typeof pharmacyHeaders],
    },
  });

  useEffect(() => {
    setPageTitle(strings.formatString(`${headerTitle}`) as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const pdfSuccess = useAppSelector((st) =>
    successOrSelector(
      [pharmacistActions.getReportsPDF, pharmacyActions.reportsPdf],
      st,
    ));

  const [isFiltered, setIsFiltered] = useState(false);

  const formik = useFormik({
    initialValues: { ...initialValues, ...userConfig[userType].values },
    onSubmit: (values) => {
      const { startDate, endDate } = values ?? {};
      const isEmpty = Object.values(values).every(
        (x) =>
          (x as Array<unknown>)?.length === 0 || x === '' || x === null,
      );
      setIsFiltered(!isEmpty);
      dispatch(
        userConfig[userType].getReport({
          type,
          page: 1,
          ...values,
          startDate: startDate
            ? DateTime.fromISO(startDate).toISO({ includeOffset: false })
            : null,
          endDate: endDate
            ? DateTime.fromISO(endDate).toISO({ includeOffset: false })
            : null,
        }) as AsyncThunkAction<any, IPharmacyReportParams, any>,
      );
    },
  });

  // eslint-disable-next-line consistent-return
  const filterStates = () => {
    switch (true) {
      case !isFiltered && !showFilter:
        return {
          icon: UnfilledFilter,
          onClick: () => {
            setShowFilter((prev) =>
              !prev);
          },
        };
      case !isFiltered && showFilter:
        return {
          icon: CloseFilter,
          onClick: () => {
            setShowFilter((prev) =>
              !prev);
            formik.resetForm();
          },
        };
      case isFiltered:
        return {
          icon: FilledFilter,
          onClick: () =>
            setShowFilter((prev) =>
              !prev),
        };
      default:
        break;
    }
  };

  useRefresh({ type, page, initialValues: formik.values });

  const handleClear = () => {
    formik.resetForm();
    setPage(1);
    setIsFiltered(false);
    dispatch(
      userConfig[userType].filterRequest as AsyncThunkAction<any, void, any>,
    );
    dispatch(
      userConfig[userType].getReport({
        type,
        page: 1,
        ...{ ...initialValues, ...userConfig[userType].values },
      }) as AsyncThunkAction<any, IPharmacyReportParams, any>,
    );
  };

  const handlePagination = (_: unknown, selectedPage: number) => {
    setPage(selectedPage + 1);
    dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      userConfig[userType].getReport({
        type,
        page: selectedPage + 1,
        ...formik.values,
      }),
    );
  };

  const reportConfig = () => {
    switch (type) {
      case ReportRequestTypes.booked:
        return isPharmacist
          ? bookingReportFilter(filterValues, handleClear)
          : pharmacyBookingReportFilter(pharmacyFilter, handleClear);
      case ReportRequestTypes.workshift:
        return isPharmacist
          ? workshiftAndRequestedReportFilter(filterValues, handleClear)
          : pharmacyWorkshiftReportFilter(handleClear);
      case ReportRequestTypes.requested:
        return workshiftAndRequestedReportFilter(filterValues, handleClear);
      case ReportRequestTypes.availability:
        return availableReportFilter(filterValues, handleClear);
      default:
        return null;
    }
  };
  const config = reportConfig();

  const handleRender = (id: string, customType?: PharmacyLegendVariant) => {
    if (customType) {
      openDrawer({ type: customType, eventIds: [id] });
      return;
    }
    openDrawer({ type, eventIds: [id] });
  };

  const title = () => {
    const { CANADA } = SupportedTimezones;
    const { month } = MomentTimeFormats;

    const currentMonth = DateTime.now().setZone(CANADA).toFormat(month);

    // ONLY SHOW FOR PHARMACY AND WHEN NOT FILTERED
    if (!isFiltered && !isPharmacist) {
      return `[${currentMonth}]`;
    }
    return '';
  };

  const Table = {
    title: `${headerTitle} ${title()}`,
    headers: userConfig[userType].headers,
    headerBar: [
      {
        Component: (
          <CustomButton
            label={strings.clear}
            variant="outlined"
            onClick={() => {
              handleClear();
            }}
          />
        ),
        key: '3',
        name: 'clear',
        display: !isFiltered,
      },
      {
        key: '1',
        icon: newReplacmentIcon,
        name: 'new',
        onClick: () => {
          dispatch(
            (isPharmacist
              ? getReportsPDF({ reportFilters: { ...formik.values, type } })
              : getPharmacyReportsPdf({
                type,
                ...formik.values,
              })) as AsyncThunkAction<any, IReportsPDFParams, any>,
          );
        },
      },
      {
        icon: filterStates()?.icon,
        key: '2',
        name: 'filter',
        onClick: filterStates()?.onClick,
      },
    ],
    rows:
      userConfig[userType].mapper(
        reports?.data as IReportsData[] & IPharmacyBookingReports[],
        type,
        timeFormat,
        dispatch,
        handleRender,
        handleCancellation,
        action,
      ) ?? [],
  };

  const showRequestSuccess = () => {
    dispatch(
      setDialog({
        showCloseButton: false,
        heading: {
          title: '',
        },
        Component: (
          <OutcomeModal
            type={OutcomeModalTypes.requestSuccess}
            message={strings.requestSuccess}
            showCloseButton
          />
        ),
      }),
    );
  };

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    handleClear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (!(start || end)) {
      handleClear();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pdfSuccess) {
      downloadPDF(pdf.pdfContent, reportsFileName);
      dispatch(
        resetStatus([
          pharmacistActions.getReportsPDF,
          pharmacyActions.reportsPdf,
        ]),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdf.pdfContent, pdfSuccess]);

  useEffect(() => {
    if (requestSuccess) {
      showRequestSuccess();
      dispatch(resetStatus([pharmacistActions.requestBooking]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestSuccess]);

  useEffect(() => {
    if (start || end) {
      setShowFilter(true);
      formik.setFieldValue('startDate', deserializeISODate(start));
      formik.setFieldValue('endDate', deserializeISODate(end));
      formik.handleSubmit();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={10}>
        <Drawer />
        <DynamicTable
          formik={formik}
          showFilter={showFilter}
          filter={config}
          loadSuccess={loadSuccess}
          page={page}
          totalCount={reports.totalCount}
          count={reports.count}
          handlePagination={handlePagination}
          table={Table}
        />
      </Grid>
      <Grid sx={ResponsiveClasses.hideOnLg} item xs={2}>
        <AdsContainer />
      </Grid>
    </Grid>
  );
};

export default Reports;
