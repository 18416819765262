import { createReducer } from '@reduxjs/toolkit';
import { getPharmacyAdminPharmacies, getPharmacyAdminProfile,  } from '../actions';
import { Language, TimeFormat } from '../helpers/Constants';
import { IPharmacyAdminDetails, IPharmacyAdminPharmaciesData } from '../models';

export interface IPharmacyAdminInitialState {
  pharmacies: Array<IPharmacyAdminPharmaciesData>;
  pharmacyAdminProfile: IPharmacyAdminDetails;
}

export const pharmacyAdminInitialState: IPharmacyAdminInitialState = {
  pharmacies: [],
  pharmacyAdminProfile: {
    pharmacyCompany: {
      user: {
        language: {
          default: false,
          code: Language.en,
          languageId: '',
          name: '',
        },
        userId: '',
        email: '',
        userName: '',
        personnelId: '',
        languageId: '',
        culture: '',
        createDate: '',
        timeFormat: TimeFormat.twelveHours,
      },
      pharmacyCompanyId: '',
      userId: '',
      name: '',
      description: '',
      appointedPharmacyId: null,
      phone: '',
      mobile: '',
      province: '',
      createDate: '',
      active: false,
    },
    pharmacies: [],
  },
};

const pharmacyAdminReducer = createReducer(
  pharmacyAdminInitialState,
  (builder) => {
    builder
      .addCase(getPharmacyAdminPharmacies.fulfilled, (state, action) => {
        state.pharmacies = action.payload;
      })
      .addCase(getPharmacyAdminProfile.fulfilled, (state, action) => {
        state.pharmacyAdminProfile = action.payload;
      });
  }
);

export default pharmacyAdminReducer;
