import React, { FC, ReactNode } from 'react';

import { Box, SxProps, Typography } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { FormikProps } from 'formik';

import Map from '@pharmaplan/common/assets/icons/map.svg';
import usePickLocationModal from '../../../hooks/usePickLocationModal';
import CustomButton from '../CustomButton';

import styles from './styles';

interface IMapButton {
  fieldLabel: string;
  label: string;
  onClick: () => void;
  fullWidth?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  requireLabel: boolean;
  formik: FormikProps<any>;
  helperTextStyle: Record<string, unknown> | Array<Record<string, unknown>>;
  customStyle: Record<string, unknown> | Array<Record<string, unknown>>;
}

const MapButton: FC<IMapButton> = ({
  fieldLabel,
  label,
  onClick,
  fullWidth = true,
  startIcon = <img src={Map} alt="mapIcon" width="20" height="20" />,
  endIcon = <ArrowForward />,
  requireLabel,
  formik,
  helperTextStyle,
  customStyle,
}) => {
  const { showPickYourLocation } = usePickLocationModal();
  return (
    <>
      <CustomButton
        fieldLabel={fieldLabel}
        label={label}
        customButtonStyle={customStyle ?? styles.mapButton}
        onClick={onClick ?? showPickYourLocation}
        variant="outlined"
        startIcon={startIcon}
        fullWidth={fullWidth}
        endIcon={endIcon}
        requireLabel={requireLabel}
      />
      {formik.errors.latitude && formik.touched.latitude && (
        <Box>
          <Typography sx={helperTextStyle ?? styles.mapButtonHelperTextStyle}>
            {formik.errors?.latitude as ReactNode}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default MapButton;
