import React, { FC, useEffect } from 'react';
import { FormikValues, useFormik } from 'formik';

import { Box } from '@mui/material';
import {
  AdminKeyToParse,
  adminActions,
  convertToDecimals,
  createWorkshiftAdmin,
  getPharmacyDetails,
  pharmacyDetails,
  successSelector,
  resetStatus,
  getRecurrence,
} from '@pharmaplan/common';
import AllowanceAdminWorkshift from '../../EditWorkshift/AllowanceAdminWorkshift';

import CustomTextField from '../../../common/CustomTextField';
import genericClasses from '../../../../theme/GenericClasses';
import CustomDatePicker from '../../../common/CustomDatePicker';
import strings from '../../../../localization';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { resetDrawer, setFooter } from '../../../../actions/drawerActions';
import { getZeroHourDate, rateValidation } from '../../../../helpers/Functions';
import useSelfService from '../../../../hooks/useSelfService';
import { makeReqParams } from '../../../../helpers/workshift/functions';
import { DurationTypes, serverDateKey } from '../../../../helpers/Constants';
import { IPharmacyListActionsParams } from '../../../../hooks/Admin/PharmacyList/usePharmacyListAction';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { showSuccess } from '../../Profile/Pharmacist/PharmacistViewProfile/helper';
import useAdminDashboard from '../../../../hooks/Admin/useAdminDashboard';
import AddUpdateEvents from '../../../common/AddUpdateEvents/AddUpdateEvents';
import useRecurrence from '../../../../hooks/useRecurrence';

import Validator from './Validator';
import styles from './style';

const successAction = adminActions.createWorkshift;
const { notRepeat } = DurationTypes;

const AdminCreateWorkshift: FC<IPharmacyListActionsParams> = ({
  pharmacyId,
  name,
}) => {
  const dispatch = useAppDispatch();
  const recurrence = useAppSelector(getRecurrence);

  const { workshiftParser } = useSelfService();
  const { dashBasedRequest } = useAdminDashboard();

  const success = useAppSelector((state) =>
    successSelector([successAction], state));
  const details = useAppSelector(pharmacyDetails);

  const { pharmacy } = details ?? {};
  const { hourlyRate, pharmacyHourlyRate } = pharmacy ?? {};

  const onSubmit = (values: FormikValues) => {
    const {
      notes,
      pharmacyHourlyRate: formikPharmacyHourlyRate,
      paidHourlyRate,
    } = values ?? {};

    const isPharmacyGreaterPharmacist = rateValidation({
      pharmacistRate: paidHourlyRate,
      pharmacyRate: formikPharmacyHourlyRate,
      dispatch,
    });
    if (isPharmacyGreaterPharmacist) {
      dispatch(
        createWorkshiftAdmin({
          pharmacyId,
          model: {
            ...workshiftParser(values, AdminKeyToParse),
            ...makeReqParams(values, recurrence),
            note: notes,
          },
        }),
      );
    }
  };

  const serverToday = getZeroHourDate(
    sessionStorage.getItem(serverDateKey) ?? '',
  );

  const initialValues = {
    name,
    selectedDate: serverToday,
    startTime: null,
    endTime: null,
    recurrence: notRepeat,
    notes: '',
    emergencyFees: '',
    mealAllowance: '',
    travelAllowance: '',
    accommodationAllowance: '',
    paidHourlyRate: convertToDecimals(hourlyRate),
    pharmacyHourlyRate: convertToDecimals(pharmacyHourlyRate),
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Validator(),
    onSubmit,
  });

  const { handleSubmit } = formik ?? {};
  useRecurrence(formik);

  useEffect(() => {
    dispatch(getPharmacyDetails(pharmacyId));
    dispatch(
      setFooter({
        show: true,
        pagination: {
          show: false,
        },
        buttons: {
          show: true,
          primaryButton: {
            label: strings.submit,
            onClick: handleSubmit,
          },
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success) {
      dashBasedRequest();
      showSuccess(dispatch, strings.workshiftCreatedSuccessfully);
      dispatch(resetStatus([successAction]));
      dispatch(resetDrawer());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <Box sx={[styles.container, genericClasses.borderNoneTextField]}>
      <CustomTextField
        label={strings.pharmacyName}
        formik={formik}
        name="name"
        id="name"
        disabled
      />

      <CustomDatePicker
        formik={formik}
        label={strings.selectedDat}
        name="selectedDate"
        error=""
        customClass={genericClasses.dateContainer}
        minDate={serverToday}
      />

      <AddUpdateEvents
        formik={formik}
        isSeries
        setUserWorkTimings={() =>
          null}
      />

      <AllowanceAdminWorkshift formik={formik} />

      <CustomTextField
        variant="outlined"
        formik={formik}
        id="notes"
        placeholder=" "
        label={strings.notes}
        name="notes"
        multiline
        maxLength={200}
        customClass={genericClasses.borderNoneTextField}
      />
    </Box>
  );
};

export default AdminCreateWorkshift;
