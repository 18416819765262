import React from 'react';
import {
  IAvailableWorkshiftData,
  ILogos,
  TimeFormat,
  convertToDecimals,
} from '@pharmaplan/common';
import { timeString } from '../../../helpers/Functions';
import { getHoursDiff } from '../../Modals/WorkshiftDetails/utils';
import strings from '../../../localization';
import { LocationText } from './AvailableWorkshifts';
import { getDayMonthDateYear } from '../../Reports/helpers';

interface IConfigBuilder {
  startDate: string;
  endDate: string;
  timeFormat: TimeFormat;
  city: string;
  software: string;
}

export const configBuilder = ({
  startDate,
  endDate,
  timeFormat,
  city,
  software,
}: IConfigBuilder) => {
  const date = getDayMonthDateYear(startDate);
  const time = timeString(startDate, endDate, timeFormat);
  const hours = `${time} (${getHoursDiff(startDate, endDate)} Hrs)`;
  return [
    { key: '0', value: date },
    { key: '1', value: strings.formatString(strings.timeColon, hours) },
    { key: '2', value: <LocationText label={city} /> },
    { key: '3', value: software },
  ];
};

export const workshiftListBuilder = (
  event: Array<IAvailableWorkshiftData>,
  allLogos: Array<ILogos>,
  timeFormat: TimeFormat,
) =>
  event.map((item) => {
    const {
      name,
      hourlyRate,
      logoId,
      startDate,
      endDate,
      city,
      workshiftId,
      pharmacistHourlyRate,
      pharmacyHourlyRate,
      pharmacyId,
      softwareName,
    } = item;

    const avatar = allLogos.find((logo) =>
      logo.logoId === logoId)?.image;

    return {
      config: configBuilder({
        startDate,
        endDate,
        timeFormat,
        city,
        software: softwareName,
      }),
      title: name,
      hourlyRate: convertToDecimals(hourlyRate),
      avatar,
      workshiftId,
      pharmacyId,
      pharmacistHourlyRate: convertToDecimals(pharmacistHourlyRate),
      pharmacyHourlyRate: convertToDecimals(pharmacyHourlyRate),
      startDate,
      endDate,
      softwareName,
    };
  });

type genericParams = Array<string | number | React.ReactNode>;

export const genericConfigBuilder = (...args: genericParams) =>
  args.map((item, index) =>
    ({
      key: index.toString(),
      value: item,
    }));
