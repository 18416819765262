import React, { useEffect } from 'react';
import {
  getRating,
  getRatingItems,
  pharmacyActions,
  rating,
  ratingItems,
} from '@pharmaplan/common';
import { ScreenTypes } from '../../../../helpers/Constants';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { renderScreen, setBack } from '../../../../actions/drawerActions';
import ServiceCard from '../../../SelfService/ServiceCard/ServiceCard';
import ViewPharmacistRatingContent from './ViewPharmacistContent/ViewPharmacistRatingContent';
import ViewPharmacistRatingHeader from './ViewPharmacistContent/ViewPharmacistRatingHeader/ViewPharmacistRatingHeader';
import ICalendarEventType from '../../../../types/ICalendarEventType';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import styles from './style';

interface IViewPharmacistRatingProps {
  pharmacistId: string;
  type?: ICalendarEventType;
  id?: string;
  date: string;
  eventIds: Array<string>;
  workshiftId: string;
}

const ViewPharmacistRating = ({
  pharmacistId,
  id,
  type,
  date,
  eventIds,
  workshiftId,
}: IViewPharmacistRatingProps) => {
  const dispatch = useAppDispatch();

  const ratingDetails = useAppSelector(rating);
  const ratingData = useAppSelector(ratingItems);

  const gotoPharmacistProfile = () => {
    dispatch(
      renderScreen({
        eventId: id,
        screenNumber: 3,
        type,
        date,
        eventIds,
        workshiftId,
        screenType: ScreenTypes.workshiftSummary,
      }),
    );
  };

  useEffect(() => {
    dispatch(getRatingItems(pharmacistId));
    dispatch(getRating(pharmacistId));
    dispatch(setBack(gotoPharmacistProfile));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ServiceCard
        customContainerStyle={styles.container}
        content={(
          <ViewPharmacistRatingHeader
            successAction={pharmacyActions.rating}
            ratingDetails={ratingDetails}
          />
        )}
      />
      <ServiceCard
        content={(
          <ViewPharmacistRatingContent
            ratingData={ratingData}
            ratingDetails={ratingDetails}
          />
        )}
      />
    </>
  );
};

export default ViewPharmacistRating;
