import React, { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { useLocation } from 'react-router-dom';
import { adminLogsFilters } from '@pharmaplan/common';
import { dropdownMapper } from '@pharmaplan/common/helpers/utils';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import strings from '../../../../localization';
import CustomDateRange from '../../../common/CustomDateRange';
import CustomDropdown from '../../../common/CustomDropdown';
import FilterSubmitButtons from '../../../common/FilterSubmitButtons';
import useLogsHandler from '../../../../hooks/Admin/Logs/useLogsHandler';
import MultilevelDropdown from '../../../common/MultillevelDropdown';
import CustomTextField from '../../../common/CustomTextField';
import { Constants } from '../../../../helpers/Constants';

import styles from './style';

interface IActivityLogsHeader {
  formik: FormikProps<any>;
  handleReset: () => void;
}

const { workshiftLogs, availabilityLogs, bookingLogs, activityLogs } = Constants.paths.admin;

const LogsHeader: FC<IActivityLogsHeader> = ({ formik, handleReset }) => {
  const { title } = useLogsHandler();
  const { pathname } = useLocation();
  const filterValues = useAppSelector(adminLogsFilters);

  const {
    workshiftActions,
    availabilityActions,
    bookingActions,
    activityActions,
  } = filterValues ?? {};
  const { handleSubmit } = formik ?? {};

  const isActivityLog = pathname === activityLogs;
  const isBookingLog = pathname === bookingLogs;

  const config = {
    [workshiftLogs]: {
      label: strings.pharmacy,
      placeholder: strings.formatString(strings.enterCred, strings.pharmacy),
      filter: workshiftActions,
    },
    [activityLogs]: {
      label: strings.user,
      placeholder: strings.enterNameEmail,
      filter: workshiftActions,
    },
    [availabilityLogs]: {
      label: strings.pharmacist,
      placeholder: strings.formatString(strings.enterCred, strings.pharmacist),
      filter: availabilityActions,
    },
    [bookingLogs]: {
      label: strings.pharmacy,
      placeholder: strings.formatString(strings.enterCred, strings.pharmacy),
      filter: bookingActions,
    },
  };

  const { label, placeholder, filter } = config[pathname];

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Box sx={styles.titleContainer}>
        <Typography sx={styles.title}>{title}</Typography>
      </Box>

      <Grid container spacing={0.5} alignItems="center" wrap="nowrap">
        <CustomDateRange
          startName="startDate"
          endName="endDate"
          formik={formik}
          preventPast={false}
          preventFuture
        />

        <Grid item xs={6} sx={styles.dropdownContainer}>
          {isActivityLog ? (
            <MultilevelDropdown
              label={strings.action}
              name="action"
              formik={formik}
              menuItems={activityActions}
            />
          ) : (
            <CustomDropdown
              label={strings.action}
              menuItems={dropdownMapper(filter, 'key', 'value')}
              formik={formik}
              name="action"
            />
          )}
        </Grid>

        <Grid item xs={6} sx={styles.container}>
          <CustomTextField
            label={strings.performer}
            placeholder={strings.enterNameEmail}
            name="performerText"
            id="performerText"
            formik={formik}
          />
        </Grid>

        <Grid item xs={6} sx={styles.container}>
          <CustomTextField
            label={label}
            placeholder={placeholder}
            name="userText"
            id="userText"
            formik={formik}
          />
        </Grid>

        {isBookingLog && (
        <Grid item xs={6} sx={styles.container}>
          <CustomTextField
            label={strings.pharmacist}
            placeholder={strings.formatString(
              strings.enterCred,
              strings.pharmacist,
            )}
            name="pharmacistText"
            id="pharmacistText"
            formik={formik}
          />
        </Grid>
        )}
        <FilterSubmitButtons handleReset={handleReset} />
      </Grid>
    </Box>
  );
};

export default LogsHeader;
