import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import UtilController from '../controllers/UtilController';
import { statusDispatch } from './globalActions';
import { INearbyLocation, ISmartRegisterParams } from '../models';
import { AppIDType } from '../models/IAppID';
import { ISystemDetailParams } from '../models/UtilModel';

export const utilActions = {
  contacts: 'util/CONTACTS',
  languages: 'util/LANGUAGES',
  setLoading: 'util/set/loading',
  getPublicKey: 'util/get/publicKey',
  registerSmartLogin: 'util/register/smartLogin',
  setBiometricEnabled: 'util/set/isBiometricEnabled',
  deRegisterSmartLogin: 'util/deregister/smartLogin',
  setTourCompleted: 'util/set/isTourCompleted',
  setShowBiometric: 'util/set/showBiometric',
  setNearbyProps: 'util/set/nearbyProps',
  checkSystemDetails: 'util/check/systemDetails',
};

export const getContactInfo = createAsyncThunk(
  utilActions.contacts,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UtilController.getContactInfo();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getLanguagesInfo = createAsyncThunk(
  utilActions.languages,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UtilController.getLanguagesInfo();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const setLoading = createAction<Array<string>>(utilActions.setLoading);
export const getPublicKey = createAsyncThunk(
  utilActions.getPublicKey,
  async (deviceId: string, { rejectWithValue }) => {
    try {
      const resp = await UtilController.getPublicKey(deviceId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const registerSmartLogin = createAsyncThunk(
  utilActions.registerSmartLogin,
  async (params: ISmartRegisterParams, { rejectWithValue }) => {
    try {
      const resp = await UtilController.registerSmartLogin(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const setBiometricEnabled = createAction<boolean | string | null>(
  utilActions.setBiometricEnabled
);

export const setTourCompleted = createAction<boolean | null>(
  utilActions.setTourCompleted
);

export const setShowBiometric = createAction<boolean>(
  utilActions.setShowBiometric
);

export const deregisterSmartLogin = createAsyncThunk(
  utilActions.deRegisterSmartLogin,
  async (params: { totp: string; userHash: string }, { rejectWithValue }) => {
    try {
      const resp = await UtilController.deregisterSmartLogin(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const checkSystemDetails = createAsyncThunk(
  utilActions.checkSystemDetails,
  async (params: ISystemDetailParams, { rejectWithValue }) => {
    try {
      const resp = await UtilController.checkSystemDetails(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const setNearbyProps = createAction<INearbyLocation>(
  utilActions.setNearbyProps
);
