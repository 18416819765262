import React from 'react';

import { DateTime } from 'luxon';

import {
  cancelRequestedBooking,
  deleteAvailability,
  deleteWorkshift,
  IAvailabilitiesReports,
  IPharmacyBookingReports,
  IPharmacyWorkshiftReports,
  IReportsData,
  ReportRequestTypes,
  PharmacyWorkshiftStatus,
  TimeFormat,
  PharmacyBookingStatus,
  PharmacyLegendVariant,
  ServiceTypes,
  currency,
  perHour,
} from '@pharmaplan/common';

import ServiceHandler from '@pharmaplan/common/helpers/ServiceHandler';
import UpdateTiming from '../../assets/svg/UpdateTiming.svg';
import CancelBookingIcon from '../../assets/svg/newCancelBooking.svg';
import ReportAnIssueIcon from '../../assets/svg/reportAnIssue.svg';
import {
  DateFormats,
  DynamicTableCell,
  MomentTimeFormats,
  ReplacementTypes,
  statusLabelMap,
} from '../../helpers/Constants';
import requestBookingIcon from '../../assets/svg/requestBooking.svg';
import deleteAvailabilityIcon from '../../assets/svg/delete.svg';
import editAvailability from '../../assets/svg/edit.svg';
import showPassword from '../../assets/svg/showPassword.svg';
import strings from '../../localization';
import { store } from '../../store';
import { setDialog } from '../../reducers/dialogReducer';

import ReportAnIssue from './ReportAnIssue/ReportAnIssue';
import { serverToday } from '../../helpers/Functions';

type HandleRender = (
  id: string,
  customType?: PharmacyLegendVariant
) => React.JSX.Element | void;

type OpenDrawer = ({
  eventIds,
  type,
}: {
  eventIds: Array<string>;
  type: ReportRequestTypes;
}) => void;

type preRequestFunc = (workshiftId: string) => void;
type genericAction = OpenDrawer | preRequestFunc;

type HandleCancellation = ({
  id,
  workshiftDate,
  confirmationDate,
  gracePeriod,
}: {
  id: string;
  workshiftDate?: string;
  confirmationDate?: string;
  gracePeriod?: number;
}) => void;

export const getHourMinute = (date: string, timeFormat: TimeFormat) =>
  (timeFormat === TimeFormat.twelveHours
    ? DateTime.fromISO(date, { zone: 'utc' }).toFormat(DateFormats.twelveHours)
    : DateTime.fromISO(date, { zone: 'utc' }).toFormat(DateFormats.hourMinutes));

export const formatDate = (
  dateString: string,
  prefix?: string,
  abbreviate = false,
) => {
  const currentDate = DateTime.now().toUTC();
  const inputDate = DateTime.fromISO(dateString, {
    setZone: true,
    zone: 'utc',
  });
  const diff = currentDate
    .diff(inputDate, ['days', 'hours', 'minutes'])
    .shiftTo('days', 'hours', 'minutes');
  const { days, hours, minutes } = diff.toObject();
  const roundHours = Math.round(hours ?? 0);
  const roundDays = Math.round(days ?? 0);
  const roundMinutes = Math.round(minutes ?? 0);

  const abbreviations = abbreviate
    ? {
      minutes: strings.minutes.slice(0, 1),
      hours: strings.smallHours.slice(0, 1),
      dayFeedbackMessage: strings.dayFeedbackMessage.slice(0, 1),
      smallDays: strings.smallDays.slice(0, 1),
    }
    : {
      minutes: strings.minutes,
      hours: strings.smallHours,
      dayFeedbackMessage: strings.dayFeedbackMessage,
      smallDays: strings.smallDays,
    };

  const formatRequestedAgo = (value: number, unit: string) =>
    (prefix
      ? strings.formatString(prefix, `${value}`, unit)
      : `${value} ${unit}`);

  switch (true) {
    case roundDays === 0 && (roundHours ?? 0) === 0:
      return formatRequestedAgo(roundMinutes, abbreviations.minutes);
    case roundDays === 0 && (roundHours ?? 0) < 24:
      return formatRequestedAgo(roundHours, abbreviations.hours);
    case roundDays === 1:
      return formatRequestedAgo(roundDays, abbreviations.dayFeedbackMessage);
    case roundDays > 1:
      return formatRequestedAgo(roundDays, abbreviations.smallDays);
    default:
      return '';
  }
};

export const approvalTime = (date: string) =>
  DateTime.fromISO(date).toFormat(`${MomentTimeFormats.dateMonthYear} HH:mm`);

export const getDayMonthDateYear = (date: string) =>
  DateTime.fromISO(date, { setZone: true }).toFormat(
    MomentTimeFormats.weekdayDate,
  );

export const getTodayWithoutOffset = () =>
  serverToday().toISO({ includeOffset: false }) ?? '';

export const hasAction = (date: string) =>
  date >= getTodayWithoutOffset();

export const pharmacistHeaders = () =>
  ({
    [ReportRequestTypes.booked]: [
      { key: '0', label: strings.code },
      { key: '1', label: strings.banner },
      { key: '2', label: strings.pharmacy },
      { key: '3', label: strings.date },
      { key: '4', label: strings.time },
      { key: '5', label: strings.rate },
      { key: '6', label: strings.status },
      { key: '7', label: strings.action },
    ],
    [ReportRequestTypes.workshift]: [
      { key: '0', label: strings.code },
      { key: '1', label: strings.banner },
      { key: '2', label: strings.city },
      { key: '3', label: strings.date },
      { key: '4', label: strings.time },
      { key: '5', label: strings.rate },
      { key: '6', label: strings.action },
    ],
    [ReportRequestTypes.requested]: [
      { key: '0', label: strings.code },
      { key: '1', label: strings.banner },
      { key: '2', label: strings.city },
      { key: '3', label: strings.date },
      { key: '4', label: strings.time },
      { key: '5', label: strings.rate },
      { key: '6', label: strings.status },
      { key: '7', label: strings.action },
    ],
    [ReportRequestTypes.availability]: [
      { key: '-1', label: strings.code },
      { key: '-2', label: strings.date },
      { key: '-3', label: strings.time },
      { key: '-4', label: strings.action },
    ],
  });

export const pharmacyHeaders = () =>
  ({
    [ReportRequestTypes.booked]: [
      { key: '0', label: strings.code },
      { key: '1', label: strings.date },
      { key: '2', label: strings.time },
      { key: '3', label: strings.pharmacistName },
      { key: '4', label: strings.status },
      { key: '5', label: strings.action },
    ],
    [ReportRequestTypes.workshift]: [
      { key: '0', label: strings.code },
      { key: '1', label: strings.date },
      { key: '2', label: strings.time },
      { key: '3', label: strings.replacementType },
      { key: '4', label: strings.status },
      { key: '5', label: strings.action },
    ],
  });

const handleRequestMapper = (
  data: Array<IReportsData>,
  type: ReportRequestTypes,
  timeFormat: TimeFormat,
  dispatch: typeof store.dispatch,
  handleRender: HandleRender,
  handleCancellation: HandleCancellation,
) => {
  const booked = type === ReportRequestTypes.booked;

  return data?.map((item) => {
    const {
      id,
      code,
      pharmacyName,
      city,
      startDate,
      endDate,
      status,
      selectedDate,
      logo,
      rate,
      confirmationDate,
      gracePeriodInHours,
    } = item ?? {};

    return {
      key: id,
      data: [
        {
          key: `${id}-1`,
          value: code,
          type: DynamicTableCell.text,
        },
        {
          key: `${id}-2`,
          value: logo,
          type: DynamicTableCell.icon,
        },
        {
          key: `${id}-3`,
          value:
            type === ReportRequestTypes.booked
              ? `${pharmacyName}\n${city}`
              : city,
          type: DynamicTableCell.text,
        },
        {
          key: `${id}-4`,
          value: getDayMonthDateYear(startDate),
          type: DynamicTableCell.text,
        },
        {
          key: `${id}-5`,
          value: `${getHourMinute(startDate, timeFormat)} - ${getHourMinute(
            endDate,
            timeFormat,
          )}`,
          type: DynamicTableCell.text,
        },
        {
          key: `${id}-8`,
          value: `${currency}${rate} ${perHour}`,
          type: DynamicTableCell.text,
        },
        {
          key: `${id}-6`,
          value: statusLabelMap()[status],
          type: DynamicTableCell.status,
        },
        {
          key: `${id}-7`,
          value: hasAction(selectedDate)
            ? [
              {
                key: '1',
                tooltip: booked
                  ? strings.cancelBooking
                  : strings.cancelRequest,
                icon: CancelBookingIcon,
                onClick: () => {
                  if (booked) {
                    handleCancellation({
                      workshiftDate: startDate,
                      confirmationDate,
                      id,
                      gracePeriod: gracePeriodInHours,
                    });
                  } else {
                    dispatch(cancelRequestedBooking(id));
                  }
                },
              },
              {
                key: '2',
                tooltip: strings.tooltipShowDetails,
                icon: showPassword,
                onClick: () => {
                  handleRender(id);
                },
              },
            ]
            : [],
          type: DynamicTableCell.bar,
        },
      ],
    };
  });
};

const handleAvailability = (
  data: IAvailabilitiesReports[],
  timeFormat: TimeFormat,
  dispatch: typeof store.dispatch,
  handleRender: HandleRender,
) =>
  data?.map((item) => {
    const { id, code, startDate, endDate, selectedDate } = item ?? {};

    return {
      key: id,
      data: [
        {
          key: `${id}-1`,
          value: code,
          type: DynamicTableCell.text,
        },
        {
          key: `${id}-2`,
          value: getDayMonthDateYear(selectedDate),
          type: DynamicTableCell.text,
        },
        {
          key: `${id}-3`,
          value: `${getHourMinute(startDate, timeFormat)} - ${getHourMinute(
            endDate,
            timeFormat,
          )}`,
          type: DynamicTableCell.text,
        },
        {
          key: `${id}-5`,
          value: [
            {
              key: '1',
              tooltip: strings.updateAvailability,
              icon: editAvailability,
              onClick: () => {
                handleRender(id);
              },
            },
            {
              key: '2',
              tooltip: strings.deleteAvailability,
              icon: deleteAvailabilityIcon,
              onClick: () => {
                dispatch(deleteAvailability({ recurrenceId: '', id }));
              },
            },
          ],
          type: DynamicTableCell.bar,
        },
      ],
    };
  });

const handleWorkshift = (
  data: Array<IReportsData>,
  timeFormat: TimeFormat,
  handleRender: HandleRender,
  preRequest: genericAction,
) => {
  const onClick = (id: string) =>
    () => {
      handleRender(id);
    };

  return data?.map((item) => {
    const { id, code, city, selectedDate, logo, endDate, startDate, rate } = item ?? {};

    return {
      key: id,
      data: [
        {
          key: `${id}-1`,
          value: code,
          type: DynamicTableCell.text,
        },
        {
          key: `${id}-2`,
          value: logo,
          type: DynamicTableCell.icon,
        },
        {
          key: `${id}-3`,
          value: city,
          type: DynamicTableCell.text,
        },
        {
          key: `${id}-4`,
          value: getDayMonthDateYear(selectedDate),
          type: DynamicTableCell.text,
        },
        {
          key: `${id}-5`,
          value: `${getHourMinute(startDate, timeFormat)} - ${getHourMinute(
            endDate,
            timeFormat,
          )}`,
          type: DynamicTableCell.text,
        },
        {
          key: `${id}-7`,
          value: `${currency}${rate} ${perHour}`,
          type: DynamicTableCell.text,
        },
        {
          key: `${id}-6`,
          value: hasAction(selectedDate)
            ? [
              {
                key: '1',
                tooltip: strings.requestBooking,
                icon: requestBookingIcon,
                onClick: () => {
                  (preRequest as preRequestFunc)(id);
                },
              },
              {
                key: '2',
                tooltip: strings.tooltipShowDetails,
                icon: showPassword,
                onClick: onClick(id),
              },
            ]
            : [],
          type: DynamicTableCell.bar,
        },
      ],
    };
  });
};

export const reportsMapper = (
  data: Array<IReportsData>,
  type: ReportRequestTypes,
  timeFormat: TimeFormat,
  dispatch: typeof store.dispatch,
  handleRender: HandleRender,
  handleCancellation: HandleCancellation,
  preRequest: genericAction,
) => {
  switch (type) {
    case ReportRequestTypes.booked:
    case ReportRequestTypes.requested:
      return handleRequestMapper(
        data,
        type,
        timeFormat,
        dispatch,
        handleRender,
        handleCancellation,
      );

    case ReportRequestTypes.availability:
      return handleAvailability(data, timeFormat, dispatch, handleRender);

    case ReportRequestTypes.workshift:
      return handleWorkshift(data, timeFormat, handleRender, preRequest);

    default:
      return [];
  }
};

export const pharmacyReportsMapper = (
  data: Array<IPharmacyBookingReports | IPharmacyWorkshiftReports>,
  type: ReportRequestTypes,
  timeFormat: TimeFormat,
  dispatch: typeof store.dispatch,
  handleRender: HandleRender,
  handleCancellation: HandleCancellation,
  openDrawer: genericAction,
) => {
  const isSelfService = ServiceHandler.getService() === ServiceTypes.self;

  switch (type) {
    case ReportRequestTypes.booked:
      return (data as IPharmacyBookingReports[])?.map((item) => {
        const {
          code,
          bookingId,
          startDate,
          endDate,
          bookingStatus,
          pharmacistName,
          canReportIssue,
          confirmationDate,
          gracePeriodInHours,
        } = item ?? {};

        const isBooked = bookingStatus === PharmacyBookingStatus.booked;

        return {
          key: code,
          data: [
            {
              key: `${bookingId}-1`,
              value: code,
              type: DynamicTableCell.text,
            },
            {
              key: `${bookingId}-2`,
              value: getDayMonthDateYear(startDate),
              type: DynamicTableCell.text,
            },
            {
              key: `${bookingId}-3`,
              value: `${getHourMinute(startDate, timeFormat)} - ${getHourMinute(
                endDate,
                timeFormat,
              )}`,
              type: DynamicTableCell.text,
            },
            {
              key: `${bookingId}-4`,
              value: pharmacistName,
              type: DynamicTableCell.text,
            },
            {
              key: `${bookingId}-5`,
              value: isBooked ? statusLabelMap().booked : null,
              type: DynamicTableCell.status,
            },
            {
              key: `${bookingId}-6`,
              value: hasAction(startDate)
                ? [
                  {
                    key: '1',
                    tooltip: strings.cancelBooking,
                    icon: CancelBookingIcon,
                    onClick: () => {
                      handleCancellation({
                        workshiftDate: startDate,
                        confirmationDate,
                        id: bookingId,
                        gracePeriod: gracePeriodInHours,
                      });
                    },
                  },
                  canReportIssue && {
                    key: '2',
                    tooltip: strings.reportAnIssue,
                    icon: ReportAnIssueIcon,
                    onClick: () => {
                      dispatch(
                        setDialog({
                          showCloseButton: true,
                          Component: <ReportAnIssue bookingId={bookingId} />,
                          heading: {
                            title: strings.reportAnIssue,
                          },
                          maxWidth: 'sm',
                        }),
                      );
                    },
                  },

                  isSelfService && {
                    key: '3',
                    tooltip: strings.updateTiming,
                    icon: UpdateTiming,
                    onClick: () => {
                      (openDrawer as OpenDrawer)({
                        type,
                        eventIds: [bookingId],
                      });
                    },
                  },
                ]
                : [],
              type: DynamicTableCell.bar,
            },
          ],
        };
      });

    case ReportRequestTypes.workshift:
      return (data as IPharmacyWorkshiftReports[])?.map((item) => {
        const {
          id,
          code,
          startDate,
          endDate,
          replacementType,
          type: mappedType,
        } = item ?? {};

        const shouldShow = hasAction(startDate)
          && PharmacyWorkshiftStatus?.booked !== mappedType;

        return {
          key: id,
          data: [
            {
              key: `${id}-1`,
              value: code,
              type: DynamicTableCell.text,
            },
            {
              key: `${id}-2`,
              value: getDayMonthDateYear(startDate),
              type: DynamicTableCell.text,
            },
            {
              key: `${id}-3`,
              value: `${getHourMinute(startDate, timeFormat)} - ${getHourMinute(
                endDate,
                timeFormat,
              )}`,
              type: DynamicTableCell.text,
            },
            {
              key: `${id}-4`,
              value: ReplacementTypes()[replacementType],
              type: DynamicTableCell.text,
            },
            {
              key: `${id}-5`,
              value: statusLabelMap()[mappedType],
              type: DynamicTableCell.status,
            },
            {
              key: `${id}-6`,
              value: shouldShow
                ? [
                  {
                    key: '1',
                    tooltip: strings.updateWorkshift,
                    icon: editAvailability,
                    onClick: () => {
                      handleRender(id, PharmacyLegendVariant.posted);
                    },
                  },
                  {
                    key: '2',
                    tooltip: strings.deleteWorkshift,
                    icon: deleteAvailabilityIcon,
                    onClick: () => {
                      dispatch(deleteWorkshift({ id, recurrenceId: '' }));
                    },
                  },
                ]
                : [],
              type: DynamicTableCell.bar,
            },
          ],
        };
      });
    default:
      return [];
  }
};

export const bannersExtractor = (data: Array<IReportsData>) =>
  data?.map((item) =>
    ({ key: item.code, value: item.logo }));

export const pharmaciesExtractor = (data: Array<IReportsData>) => {
  const pharmacies: Array<string> = [];
  data?.map((item) => {
    // eslint-disable-next-line no-unused-expressions
    !pharmacies.includes(item.pharmacyName)
      && pharmacies.push(item.pharmacyName);
    return null;
  });
  return pharmacies?.map((value, key) =>
    ({ key: key.toString(), value }));
};

export default reportsMapper;
