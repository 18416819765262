import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { PermissionsOfAdmin } from '@pharmaplan/common';

import { ReactComponent as PlusIcon } from '@pharmaplan/common/assets/icons/plusIcon.svg';
import { ReactComponent as UserIcon } from '../../../../assets/svg/userIcon.svg';
import useAdminPermissions from '../../../../hooks/Admin/useAdminPermissions';

import genericClasses from '../../../../theme/GenericClasses';
import { Constants } from '../../../../helpers/Constants';
import CustomButton from '../../../common/CustomButton';
import strings from '../../../../localization';

import styles from './styles';

const { outlined } = Constants.variant;
const {
  admin: { createPharmacyAdministrators, appointPharmacyList },
} = Constants.paths;

const PharmacyAdminHeader = () => {
  const navigate = useNavigate();

  const { can } = useAdminPermissions();
  const canViewPharmacyAdmin = can(PermissionsOfAdmin.PharmacyAdmin);

  const goToCreatePharmacyAdmin = () => {
    navigate(createPharmacyAdministrators);
  };

  const goToAppointPharmacy = () => {
    navigate(appointPharmacyList);
  };

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>
        {strings.pharmacyAdministrators}
      </Typography>
      <Box sx={genericClasses.flex}>
        {canViewPharmacyAdmin && (
          <>
            <CustomButton
              onClick={goToAppointPharmacy}
              customClass={styles.button}
              variant={outlined}
              label={strings.appointExistingPharmacy}
              startIcon={<UserIcon />}
            />
            <CustomButton
              onClick={goToCreatePharmacyAdmin}
              variant={outlined}
              label={strings.createNewPharmacyAdmin}
              startIcon={<PlusIcon />}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default PharmacyAdminHeader;
