import React, { useCallback, useEffect, useState } from 'react';

import { debounce } from '@mui/material';
import { FormikValues } from 'formik';

import {
  AdminLegendVariant,
  searchUsers,
  adminValuesToBeParsed,
  AdminAllowanceNames,
  ConfirmationTypes,
  expressBooking,
  adminResetValue,
  HelpTypes,
  adminActions,
  successSelector,
  resetStatus,
} from '@pharmaplan/common';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal';
import { setDialog } from '../../../reducers/dialogReducer';

import { useAppDispatch } from '../../useAppDispatch';
import { useAppSelector } from '../../useAppSelector';
import useSelfService from '../../useSelfService';
import { MomentTimeFormats } from '../../../helpers/Constants';
import { appendTimeToDate } from '../../../helpers/workshift/functions';
import { showSuccess } from '../../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import strings from '../../../localization';
import { resetDrawer } from '../../../actions/drawerActions';
import useAdminDashboard from '../useAdminDashboard';
import useExpressBookingTypes from './useExpressBookingTypes';
import useExpressBookingFormik from './useExpressBookingFormik';
import { resetSecondaryRoute } from '../../../actions/navbarActions';

const { booked, posted } = AdminLegendVariant;

const useExpressBooking = (
  type: Exclude<AdminLegendVariant, AdminLegendVariant.total>,
  id?: string,
) => {
  const dispatch = useAppDispatch();
  const [expressValues, setExpressValues] = useState([...adminValuesToBeParsed]);

  const { dashBasedRequest } = useAdminDashboard();
  const { detailsApiMap } = useExpressBookingTypes();
  const { getFormikInitialValues } = useExpressBookingFormik(type, expressValues, id);
  const { workshiftParser } = useSelfService();

  const isPosted = type === posted;
  const isBooked = type === booked;

  const success = useAppSelector((state) =>
    successSelector([adminActions.expressBooking], state));

  const confirmAction = (successAction: () => void) => {
    dispatch(
      setDialog({
        heading: {
          title: '',
        },
        Component: (
          <ConfirmationModal
            type={ConfirmationTypes.expressBooking}
            showCloseButton
            confirmAction={successAction}
          />
        ),
        showCloseButton: false,
      }),
    );
  };

  const getInput = (input: string) => {
    if (input) {
      dispatch(
        searchUsers({
          userTypes: [HelpTypes.pharmacist],
          query: input,
        }),
      );
    }
  };

  const getPharmacyInput = (input: string) => {
    if (input) {
      dispatch(
        searchUsers({
          userTypes: [HelpTypes.pharmacy],
          query: input,
        }),
      );
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(detailsApiMap[type].api(id));
    }
    if (isBooked) {
      setExpressValues((prev) =>
        ([...prev, AdminAllowanceNames.pharmacyHourlyRate]));
    }

    return () => {
      dispatch(adminResetValue(['searchedPharmacists', 'searchedPharmacies']));
      dispatch(adminResetValue([detailsApiMap[type].state]));
      dispatch(resetSecondaryRoute());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bookWorkshift = (values: FormikValues) => {
    const startD = appendTimeToDate(
      values.selectedDate,
      values.startTime,
    ).toFormat(MomentTimeFormats.fullDate);
    const endD = appendTimeToDate(values.selectedDate, values.endTime).toFormat(
      MomentTimeFormats.fullDate,
    );

    const rest = isPosted
      ? {
        workshiftId: id,
      }
      : {
        bookingId: id,
      };

    dispatch(
      expressBooking({
        ...workshiftParser(values, expressValues),
        startDate: startD,
        endDate: endD,
        pharmacyId: values.pharmacyId,
        pharmacistId: values.pharmacistId,
        notes: values.notes,
        notesForSalesOrder: values.notesForSalesOrder,
        pharmacistHourlyRate: parseFloat(values.pharmacistHourlyRate ?? '0'),
        pharmacyHourlyRate: parseFloat(values.pharmacyHourlyRate ?? '0'),
        sendEmailToPharmacist: values.sendEmailToPharmacist,
        sendEmailToPharmacy: values.sendEmailToPharmacy,
        ...rest,
      }),
    );
  };

  const successMsg = id && isBooked
    ? strings.bookingEditedSuccessfully
    : strings.bookingCreatedSuccessfully;

  useEffect(() => {
    if (success) {
      dashBasedRequest();
      showSuccess(dispatch, successMsg);
      dispatch(resetStatus([adminActions.expressBooking]));
      dispatch(resetDrawer());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedInput = useCallback(debounce(getInput, 300), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedPharmacyInput = useCallback(
    debounce(getPharmacyInput, 300),
    [],
  );

  return {
    confirmAction,
    detailsApiMap,
    debouncedInput,
    getFormikInitialValues,
    debouncedPharmacyInput,
    bookWorkshift,
  };
};

export default useExpressBooking;
