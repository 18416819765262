import React, { FC } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { FormikValues, useFormik } from 'formik';

import { ReactComponent as CancelBookingIcon } from '@pharmaplan/common/assets/icons/cancelWebBookingIcon.svg';
import { ReactComponent as SuccessIcon } from '@pharmaplan/common/assets/icons/successIcon.svg';

import { Schema } from 'yup';
import { ReactComponent as PositiveWarning } from '../../../../assets/svg/positiveWarning.svg';
import { ReactComponent as WarningIcon } from '../../../../assets/svg/warningIcon.svg';
import {
  Constants,
  DeactivateModalIcon,
  TextFieldVariants,
} from '../../../../helpers/Constants';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import strings from '../../../../localization';
import { resetDialog } from '../../../../reducers/dialogReducer';
import CustomButton from '../../../common/CustomButton';
import CustomTextField from '../../../common/CustomTextField';

import Validator from './validator';
import styles from './style';

interface IDeactivateDialog {
  id: string;
  confirmationMessage: string;
  heading: string;
  iconType: DeactivateModalIcon;
  onSubmit: (values: FormikValues) => void;
  customComponent?: React.ReactNode;
  initialValues?: Record<string, string | boolean>;
  name?: string;
  validator?: null | Schema;
}

const { text } = Constants.variant;

const DeactivateDialog: FC<IDeactivateDialog> = ({
  id,
  confirmationMessage,
  heading,
  iconType,
  onSubmit,
  initialValues,
  customComponent,
  validator,
  name = 'reason',
}) => {
  const dispatch = useAppDispatch();

  const defaultInitialValues = {
    reason: '',
    id,
  };

  const config = {
    [DeactivateModalIcon.warning]: {
      icon: <WarningIcon />,
    },
    [DeactivateModalIcon.cancelBooking]: {
      icon: <CancelBookingIcon />,
    },
    [DeactivateModalIcon.positiveConfirmation]: {
      icon: <PositiveWarning />,
    },
    [DeactivateModalIcon.success]: {
      icon: <SuccessIcon />,
    },
  };

  const { icon } = config[iconType] ?? {};

  const formik = useFormik({
    initialValues: initialValues ?? defaultInitialValues,
    onSubmit,
    validationSchema: validator === null ? null : validator ?? Validator(),
  });

  const handleReset = () => {
    dispatch(resetDialog());
  };

  return (
    <Grid container sx={styles.girdContainer}>
      <Box sx={styles.iconContainer}>{icon}</Box>

      <Box
        sx={[styles.contentContainer, customComponent ? {} : styles.bottomGap]}
      >
        <Typography sx={styles.headingFont}>{heading}</Typography>
        <Typography sx={styles.confirmationFont}>
          {confirmationMessage}
        </Typography>
        {!!customComponent && customComponent}
      </Box>

      <Grid xs={12} item>
        <CustomTextField
          formik={formik}
          name={name}
          id={name}
          placeholder={strings.enterAReason}
          variant={TextFieldVariants.filled}
          multiline
          horizontal
        />
      </Grid>
      <Grid
        container
        sx={[styles.reasonSubmitContainer, styles.buttonContainer]}
      >
        <CustomButton
          label={strings.cancel}
          onClick={handleReset}
          variant={text}
          customButtonStyle={[styles.buttons, styles.cancelButton]}
        />
        <CustomButton
          label={strings.yes}
          onClick={formik.handleSubmit}
          customButtonStyle={styles.buttons}
        />
      </Grid>
    </Grid>
  );
};

export default DeactivateDialog;
