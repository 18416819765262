import React, { FC, useEffect } from 'react';

import { Stack } from '@mui/system';
import { Typography } from '@mui/material';
import { useFormik } from 'formik';

import { dropdownMapper } from '@pharmaplan/common/helpers/utils';
import CustomDropdown from '../../common/CustomDropdown';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import CustomTextField from '../../common/CustomTextField';
import strings from '../../../localization';
import genericClasses from '../../../theme/GenericClasses';
import CustomCheckbox from '../../common/CustomCheckbox';
import { ICustomBack, IFromScreen, setBack, setFooter, setFooterShow } from '../../../actions/drawerActions';
import { EmptyStringForZero } from '../../../helpers/Functions';
import useApproveBooking from '../../../hooks/ApproveBooking/useApproveBooking';
import ICalendarEventType from '../../../types/ICalendarEventType';
import { Constants } from '../../../helpers/Constants';

import styles from './styles';
import { config } from './helper';
import Validator from './validator';
import DetailsHeader from './DetailsHeader';

const { priceSuffix, allowancePlaceholder } = Constants;
export interface IApproveBooking {
  workshiftId: string;
  bookingId: string;
  eventIds?: Array<string>;
  fromScreen?: IFromScreen;
  type?: ICalendarEventType;
  back?:ICustomBack;
}

const ApproveBooking: FC<IApproveBooking> = ({
  bookingId,
  workshiftId,
  eventIds,
  type,
  fromScreen,
  back,
}) => {
  const dispatch = useAppDispatch();
  const { details, onSubmit } = useApproveBooking({
    fromScreen,
    eventIds,
    bookingId,
    workshiftId,
    type,
    back,
  });

  const {
    mealAllowance,
    emergencyFees,
    accommodationAllowance,
    travelAllowance,
    pharmacyName,
    pharmacistName,
    startDate,
    endDate,
    pharmacistHourlyRate,
    pharmacyHourlyRate,
    pharmaId,
    pharmaList,
  } = details ?? {};

  const formik = useFormik({
    initialValues: {
      pharmaId,
      notesForSalesOrder: '',
      pharmacistHourlyRate: EmptyStringForZero(pharmacistHourlyRate),
      pharmacyHourlyRate: EmptyStringForZero(pharmacyHourlyRate),
      travelAllowance: EmptyStringForZero(travelAllowance),
      mealAllowance: EmptyStringForZero(mealAllowance),
      accommodationAllowance: EmptyStringForZero(accommodationAllowance),
      emergencyFees: EmptyStringForZero(emergencyFees),
      sendEmailToPharmacist: false,
      sendEmailToPharmacy: false,
    },
    enableReinitialize: true,
    validationSchema: Validator(),
    onSubmit,
  });

  const { handleSubmit } = formik ?? {};

  useEffect(() => {
    dispatch(
      setFooter({
        show: true,
        pagination: {
          show: false,
        },
        buttons: {
          show: true,
          primaryButton: {
            label: strings.approve,
            onClick: handleSubmit,
          },
        },
      }),
    );

    return () => {
      dispatch(setFooterShow(false));
      dispatch(setBack(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack sx={styles.container}>
      <DetailsHeader
        pharmacistName={pharmacistName}
        pharmacyName={pharmacyName}
        startDate={startDate}
        endDate={endDate}
      />
      <CustomDropdown
        name="pharmaId"
        label={strings.pharma}
        formik={formik}
        menuItems={dropdownMapper(pharmaList, 'id', 'name')}
      />

      <CustomTextField
        variant="outlined"
        formik={formik}
        id="notesForSalesOrder"
        placeholder=" "
        label={strings.notesForSalesOrder}
        name="notesForSalesOrder"
        multiline
        maxLength={200}
        customClass={genericClasses.borderNoneTextField}
      />

      <Typography sx={styles.title}>{strings.rateAndAllowances}</Typography>

      {config.map((item) => {
        const { key, name, label } = item ?? {};
        return (
          <CustomTextField
            label={label}
            suffix={priceSuffix}
            customClass={styles.horizontalField}
            placeholder={allowancePlaceholder}
            horizontal
            id={name}
            formik={formik}
            name={name}
            key={key}
          />
        );
      })}

      <CustomCheckbox
        id="1"
        handleChange={(e) =>
          formik.setFieldValue('sendEmailToPharmacist', e.target.checked)}
        value={formik.values.sendEmailToPharmacist}
        name="sendEmailToPharmacist"
        labelClass={styles.checkboxLabel}
        label={
          strings.formatString(
            strings.sendEmailNotification,
            strings.pharmacists,
          ) as string
        }
      />

      <CustomCheckbox
        value={formik.values.sendEmailToPharmacy}
        labelClass={styles.checkboxLabel}
        customClass={styles.checkbox}
        handleChange={(e) => {
          formik.setFieldValue('sendEmailToPharmacy', e.target.checked);
        }}
        id="2"
        name="sendEmailToPharmacy"
        label={
          strings.formatString(
            strings.sendEmailNotification,
            strings.pharmacies,
          ) as string
        }
      />
    </Stack>
  );
};

export default ApproveBooking;
