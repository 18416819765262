import React, { FC, ReactElement } from 'react';

import { Tooltip } from '@mui/material';

import ICalendarEventType from '../../../../types/ICalendarEventType';

import CalendarTooltipContent from './CalendarTooltipContent';
import styles from './style';

interface ICalendarTooltip {
  children: ReactElement<any, any>;
  eventType: ICalendarEventType;
  durations: Array<{
    label: string;
    key: string;
    logoId?: string;
    id: string;
    workshiftId: string;
  }>;
  onClick: (inId: string) => void;
}

const modifiers = [
  {
    name: 'offset',
    options: {
      offset: [0, -5],
    },
  },
];

const CalendarTooltip: FC<ICalendarTooltip> = ({
  children,
  eventType,
  durations,
  onClick,
}) =>
  (
    <Tooltip
      arrow
      placement="top-end"
      title={(
        <CalendarTooltipContent
          onClick={onClick}
          durations={durations}
          eventType={eventType}
        />
    )}
      componentsProps={{
        tooltip: {
          sx: styles.container(eventType),
        },
        arrow: {
          sx: styles.arrow(eventType),
        },
      }}
      slotProps={{
        popper: {
          modifiers,
        },
      }}
    >
      {children}
    </Tooltip>
  );

export default CalendarTooltip;
