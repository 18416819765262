import * as Yup from 'yup';
import { emailRegex } from '@pharmaplan/common';
import strings from '../../../../../localization';
import { nameRegex, onlyNumbersRegex, phoneRegex, postalCodeRegExp } from '../../../../../helpers/Constants';

const pharmacyEditValidator = () =>
  Yup.object().shape({
    name: Yup.string()
      .required(strings.requiredField),
    number: Yup.string().nullable().matches(
      onlyNumbersRegex,
      strings.formatString(
        strings.numberValidation,
        strings.bannerNumber,
      ) as string,
    ),
    phone: Yup.string()
      .required(strings.requiredField)
      .max(
        12,
        strings.formatString(strings.phoneValidation, strings.phone) as string,
      )
      .min(
        12,
        strings.formatString(strings.phoneValidation, strings.phone) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`),
    emergencyPhone: Yup.string()
      .required(strings.requiredField)
      .max(
        12,
        strings.formatString(strings.phoneValidation, strings.emergencyPhone) as string,
      )
      .min(
        12,
        strings.formatString(strings.phoneValidation, strings.emergencyPhone) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`),
    address: Yup.string().required(strings.requiredField),
    postalCode: Yup.string()
      .required(strings.requiredField)
      .matches(postalCodeRegExp, strings.postalCodeValidation),
    city: Yup.string().required(strings.requiredField),
    fax: Yup.string()
      .max(
        12,
        strings.formatString(strings.phoneValidation, strings.fax) as string,
      )
      .min(
        12,
        strings.formatString(strings.phoneValidation, strings.fax) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`)
      .nullable(),
    primaryContactName: Yup.string()
      .required(strings.requiredField)
      .matches(nameRegex, strings.alphaOnly),
    primaryContactPhone: Yup.string()
      .required(strings.requiredField)
      .min(
        12,
        strings.formatString(
          strings.phoneValidation,
          strings.homePhone,
        ) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`)
      .nullable(),
    primaryContactMobile: Yup.string()
      .max(
        12,
        strings.formatString(strings.phoneValidation, strings.mobile) as string,
      )
      .min(
        12,
        strings.formatString(strings.phoneValidation, strings.mobile) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`)
      .nullable(),
    primaryContactEmail: Yup.string()
      .matches(emailRegex, strings.emailValid)
      .required(strings.requiredField)
      .nullable(),
    secondaryContactName: Yup.string()
      .matches(nameRegex, strings.alphaOnly)
      .nullable(),
    secondaryContactPhone: Yup.string()
      .min(
        12,
        strings.formatString(strings.phoneValidation, strings.phone) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`)
      .nullable(),
    secondaryContactMobile: Yup.string()
      .max(
        12,
        strings.formatString(strings.phoneValidation, strings.mobile) as string,
      )
      .min(
        12,
        strings.formatString(strings.phoneValidation, strings.mobile) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`)
      .nullable(),
    secondaryContactEmail: Yup.string().matches(emailRegex, strings.emailValid).nullable(),
    accountingContactName: Yup.string()
      .required(strings.requiredField)
      .matches(nameRegex, strings.alphaOnly)
      .nullable(),
    accountingContactPhone: Yup.string()
      .required(strings.requiredField)
      .min(
        12,
        strings.formatString(
          strings.phoneValidation,
          strings.homePhone,
        ) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`)
      .nullable(),
    accountingContactMobile: Yup.string()
      .max(
        12,
        strings.formatString(strings.phoneValidation, strings.mobile) as string,
      )
      .min(
        12,
        strings.formatString(strings.phoneValidation, strings.mobile) as string,
      )
      .matches(phoneRegex, `${strings.wrongFormat} : ###-###-####`)
      .nullable(),
    accountingContactEmail: Yup.string()
      .required(strings.requiredField)
      .matches(emailRegex, strings.emailValid)
      .nullable(),
    latitude: Yup.string().nullable().required(strings.requiredField),
  });

export default pharmacyEditValidator;
