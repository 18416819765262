import { RootState } from '../store';

export const activeDate = (state: RootState) =>
  state.utilCalendar.activeDate;

export const getActiveDashboard = (state: RootState) =>
  state.utilCalendar.activeDashboard;

export const getActiveCalendarView = (state: RootState) =>
  state.utilCalendar.calendarView;

export const getCalendarEvents = (state: RootState) =>
  state.utilCalendar.calendarEvents;

export const getEnabledAddEvents = (state: RootState) =>
  state.utilCalendar.enableAddEvents;
export const getTempEvents = (state: RootState) =>
  state.utilCalendar.tempEvents;
export const getMultipleEventAdd = (state: RootState) =>
  state.utilCalendar.multipleAddEvents;
export const getTypeOfUser = (state: RootState) =>
  state.utilCalendar.calendarStakeholder;

export const getPharmacyDisplayEvents = (state:RootState) =>
  state.utilCalendar.displayEvents;

export const getDisableAvailability = (state:RootState) =>
  state.utilCalendar.disableAvailability;

export const getDisabledSubmitFilter = (state:RootState) =>
  state.utilCalendar.disableSubmitFilter;
