import { SxProps, Theme } from '@mui/system';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between;',
    alignItems: 'center',
    paddingBlock: '10px 20px',
    paddingTop: '16px',
    paddingInline: '8px',
  },
  title: {
    color: 'common.grey850',
    fontSize: 18,
    fontWeight: 600,
  },
  button: { mr: '32px' },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
