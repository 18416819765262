import { FormikProps } from 'formik';
import React from 'react';
import { MonthlyYearlyRecurrence } from '@pharmaplan/common';
import { DurationTypes, MonthlyYearlyRecurrenceDropdown } from '../../../helpers/Constants';
import strings from '../../../localization';
import genericClasses from '../../../theme/GenericClasses';
import { weekElements } from '../../common/CustomWeekSelector/helper';
import CustomWeekSelector from '../../common/CustomWeekSelector';
import MonthYearRecurrence from './MonthYearRecurrence';

const onTheMap = [
  '',
  strings.first,
  strings.second,
  strings.third,
  strings.fourth,
];

const onTheDayMap = [
  strings.sun,
  strings.mon,
  strings.tue,
  strings.wed,
  strings.thurs,
  strings.fri,
  strings.sat,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RepeatEveryMonthWeekComponent = (formik: FormikProps<any>) => {
  const { values, setFieldValue } = formik;
  switch (values.repeatEvery) {
    case DurationTypes.week:
      return (
        <CustomWeekSelector
          key={1}
          handleChange={(selectedMonths: string[]) => {
            setFieldValue('weeklyRecurrence', selectedMonths.join(','));

            const selectedMonthsFull: string[] = [];

            selectedMonths.forEach((day) => {
              selectedMonthsFull.push(weekElements()[day].fullName);
            });
            setFieldValue('weeklyRecurrenceFullDay', selectedMonthsFull);
          }}
          initialWeeks={values.weeklyRecurrence}
          customClass={genericClasses.addAvailabilityMargin}
        />
      );

    case DurationTypes.month:
      return (
        <MonthYearRecurrence formik={formik} />
      );
    case DurationTypes.weekday:
      return (
        <CustomWeekSelector
          key={2}
          handleChange={(selectedMonths: string[]) => {
            setFieldValue('weekDayRecurrence', selectedMonths.join(','));

            const selectedMonthsFull: string[] = [];

            selectedMonths.forEach((day) => {
              selectedMonthsFull.push(weekElements()[day].fullName);
            });
            setFieldValue('weekDayRecurrenceFullDay', selectedMonthsFull);
          }}
          initialWeeks={values.weekDayRecurrence}
          customClass={genericClasses.addAvailabilityMargin}
        />
      );
    default:
      return <div />;
  }
};
export const isRecurrenceRepeated = (recurrenceTimes: number) =>
  recurrenceTimes === 1;

export const getDailyRecurrenceMessage = (recurrenceTimes: number) => {
  const message = isRecurrenceRepeated(recurrenceTimes)
    ? strings.dayFeedbackMessage
    : strings.formatString(strings.daysFeedbackMessage, recurrenceTimes);

  return message;
};
export const getWeeklyRecurrenceMessage = (recurrenceTimes: number, daysInTheWeek: Array<string>) => {
  const days = daysInTheWeek.join(', ');
  const message = isRecurrenceRepeated(recurrenceTimes)
    ? strings.formatString(strings.weekFeedbackMessage, days)
    : strings.formatString(strings.weeksFeedbackMessage, recurrenceTimes.toString(), days);

  return message;
};
export const getMonthlyRecurrenceMessage = (recurrenceTimes: number, recurrencDayMonth: MonthlyYearlyRecurrence, onDay: string, onThe: number, onTheDay: number) => {
  const on = recurrencDayMonth === MonthlyYearlyRecurrence.day
    ? `${strings.onDay} ${onDay}`
    : `${strings.onThe} ${onTheMap[onThe]} ${onTheDayMap[onTheDay]}`;

  const message = isRecurrenceRepeated(recurrenceTimes)
    ? strings.formatString(strings.monthFeedbackMessage, on)
    : strings.formatString(strings.monthsFeedbackMessage, recurrenceTimes.toString(), on);

  return message;
};
export const getYearlyRecurrenceMessage = (recurrenceTimes: number, recurrencDayMonth: string, onDay: string, onThe: string, onTheDay: string, onTheMonth: string) => {
  const on = recurrencDayMonth === MonthlyYearlyRecurrenceDropdown.onDayOfTheMonth
    ? `${strings.onDay} ${onDay} of ${onTheMonth}`
    : `${strings.onThe} ${onThe} ${onTheDay} of ${onTheMonth}`;

  const message = isRecurrenceRepeated(recurrenceTimes)
    ? strings.formatString(strings.yearFeedbackMessage, on)
    : strings.formatString(strings.yearsFeedbackMessage, recurrenceTimes.toString(), on);

  return message;
};
