import React, { FC } from 'react';

import {
  AllowanceTypes,
  BookingState,
  IAdminRequestedDetailsData,
  IPharmacistDetails,
  IWorkshift,
  PharmacyLegendVariant,
  TypeOfSummary,
  convertToDecimals,
  userPreferredTimeFormat,
} from '@pharmaplan/common';
import { ReactComponent as CalendarIcon } from '@pharmaplan/common/assets/icons/calendarIconNew.svg';
import { ReactComponent as FeesIcon } from '@pharmaplan/common/assets/icons/feesIcon.svg';

import strings from '../../../localization';
import { getDayMonthDateYear } from '../../Reports/helpers';
import { getTimeWithDiff } from '../../Modals/WorkshiftDetails/utils';
import { useAppSelector } from '../../../hooks/useAppSelector';
import ThemeConstants from '../../../theme/ThemeConstants';
import { repeatEvery } from '../../../helpers/Constants';

import PharmacistSummaryContent from './PharmacistSummaryContent/PharmacistSummaryContent';
import ICalendarEventType from '../../../types/ICalendarEventType';
import useSelfService from '../../../hooks/useSelfService';
import ServiceCard from '../ServiceCard/ServiceCard';

import { displayIcon, handleValues, title } from './helpers';
import DisplayRecurrence from './DisplayRecurrence/DisplayRecurrence';
import classes from './styles';

interface IPharmacistSummary {
  event: Partial<IWorkshift> & Partial<IPharmacistDetails> & Partial<IAdminRequestedDetailsData>;
  travelFees?: number | null;
  DateTime?: React.ReactNode | null;
  summaryType?: TypeOfSummary;
  isCounterOffer?: boolean;
  type: ICalendarEventType;
}

const { pharmacist, workshiftDetails } = TypeOfSummary;
const { booked } = PharmacyLegendVariant;
const { notCovered } = AllowanceTypes;
const { UpdatedTiming } = BookingState;

const {
  dateContainer,
  dateTimeContainer,
  recurrencePadding,
  workshiftContainer,
} = classes;

const { grey850 } = ThemeConstants.common;

const defaultProps = {
  travelFees: null,
  DateTime: null,
  summaryType: pharmacist,
  isCounterOffer: false,
};

const PharmacistSummary: FC<IPharmacistSummary> = ({
  event,
  travelFees,
  DateTime,
  summaryType,
  isCounterOffer,
  type,
}) => {
  const { isSelfService } = useSelfService();

  const timeFormat = useAppSelector(userPreferredTimeFormat);

  const {
    bookingState,
    counterEndDate = '',
    counterStartDate = '',
    startDate = '',
    endDate = '',
    recurrence,
    emergencyFees,
    accommodationAllowanceType = notCovered,
    mealAllowanceType = notCovered,
    travelAllowanceType = notCovered,
    fixedMealRate,
    fixedAccommodationRate,
    paidHourlyRate = 0,
    counterHourlyRate = 0,
    notes,
  } = event ?? {};

  const isBooked = type === booked;
  const isPharmacistSummary = summaryType === pharmacist;
  const isWorkshiftDetails = summaryType === workshiftDetails;
  const isUpdatedTiming = bookingState === UpdatedTiming && isBooked;

  const updatedTimings = isUpdatedTiming
    ? {
      startDate: counterStartDate,
      endDate: counterEndDate,
    }
    : {
      startDate,
      endDate,
    };

  const { startDate: updatedStartDate, endDate: updatedEndDate } = updatedTimings ?? {};

  const hourlyRate = {
    paidHourlyRate,
    counterHourlyRate,
  };

  const recurrenceRepeat = repeatEvery()[
    (recurrence?.recurrenceType as keyof typeof repeatEvery) ?? 0
  ];

  const hours = getTimeWithDiff(updatedStartDate, updatedEndDate, timeFormat);
  const counterHours = getTimeWithDiff(
    counterStartDate,
    counterEndDate,
    timeFormat,
  );
  const dateLabel = `${getDayMonthDateYear(startDate)} | ${hours}`;
  const counterLabel = isUpdatedTiming ? hours : '';
  const counterDateLabel = `${getDayMonthDateYear(
    counterStartDate,
  )} | ${counterHours}`;

  const recurrenceStyle = DateTime ? recurrencePadding : workshiftContainer;

  const travelLabel = `${strings.travel}: ${
    displayIcon(
      travelAllowanceType,
      handleValues(travelFees, strings.calculated),
    ).data
  }`;

  const emergencyFeesLabel = `${
    strings.emergencyServiceFees
  }: $${convertToDecimals(emergencyFees)}`;

  const accomodationLabel = `${strings.accomodation}: ${
    displayIcon(
      accommodationAllowanceType,
      handleValues(fixedAccommodationRate, strings.notCovered),
    ).data
  }`;

  const mealLabel = `${strings.meal}: ${
    displayIcon(
      mealAllowanceType,
      handleValues(fixedMealRate, strings.notCovered),
    ).data
  }`;

  const travelIcon = displayIcon(travelAllowanceType).icon;
  const accommodationIcon = displayIcon(accommodationAllowanceType).icon;
  const mealIcon = displayIcon(mealAllowanceType).icon;

  const config = [
    {
      key: '0',
      component: <CalendarIcon fill={grey850} />,
      data: !!startDate,
      label: dateLabel,
      counterLabel,
      style: dateContainer,
    },
    DateTime
      ? {
        key: '1',
        component: DateTime,
        data: true,
        style: dateTimeContainer,
      }
      : {
        key: '11',
      },
    {
      key: '2',
      data: isPharmacistSummary,
      component: <DisplayRecurrence recurrenceRepeat={recurrenceRepeat} />,
      style: recurrenceStyle,
    },
    {
      key: '3',
      label: emergencyFeesLabel,
      data: isSelfService && emergencyFees,
      component: <FeesIcon fill={grey850} />,
      style: workshiftContainer,
    },
    {
      key: '4',
      label: travelLabel,
      component: travelIcon,
      data: isSelfService,
      style: workshiftContainer,
    },
    {
      key: '5',
      label: accomodationLabel,
      component: accommodationIcon,
      data: isSelfService,
      style: workshiftContainer,
    },
    {
      key: '6',
      label: mealLabel,
      component: mealIcon,
      data: isSelfService,
      style: workshiftContainer,
    },
  ];

  const counterOfferConfig = isCounterOffer && isWorkshiftDetails
    ? [
      {
        key: '0',
        component: <CalendarIcon fill={grey850} />,
        data: startDate,
        label: dateLabel,
        counterData: counterStartDate,
        counterLabel: counterDateLabel,
        style: dateContainer,
      },
    ]
    : [];

  const cardTitle = title(summaryType);
  const summaryContent = isCounterOffer ? counterOfferConfig : config;
  const displayNote = isPharmacistSummary ? notes : null;

  return (
    <ServiceCard
      title={cardTitle}
      isCounterOffer={isCounterOffer}
      hourlyRate={hourlyRate}
      content={(
        <PharmacistSummaryContent
          summaryContent={summaryContent}
          notes={displayNote}
        />
      )}
    />
  );
};

PharmacistSummary.defaultProps = defaultProps;

export default PharmacistSummary;
