import { createReducer } from '@reduxjs/toolkit';
import {
  checkSystemDetails,
  getContactInfo,
  getLanguagesInfo,
  getPublicKey,
  logout,
  registerSmartLogin,
  setBiometricEnabled,
  setLoading,
  setNearbyProps,
  setShowBiometric,
  setTourCompleted,
} from '../actions';
import { IAllLanguages, INearbyLocation } from '../models';
import { defaultCenter } from '../helpers/Constants';

export interface IUtils {
  contacts: any;
  languages: Array<IAllLanguages>;
  loading: Array<string>;
  userHash: string | null;
  publicKey: string;
  isBiometricEnabled: boolean;
  isTourCompleted: boolean;
  showBiometric: boolean;
  nearbyLocation: INearbyLocation;
}
const initialState: IUtils = {
  contacts: null,
  languages: [],
  loading: [],
  userHash: null,
  publicKey: '',
  isBiometricEnabled: false,
  isTourCompleted: true,
  showBiometric: false,
  nearbyLocation: {
    latitude: defaultCenter.lat,
    longitude: defaultCenter.lng,
    showNearBy: false,
  },
};

const utilsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getContactInfo.fulfilled, (state, action) => {
      state.contacts = action.payload;
    })
    .addCase(setLoading, (state, action) => {
      state.loading = action.payload;
    })
    .addCase(getPublicKey.fulfilled, (state, action) => {
      state.publicKey = action.payload?.publicKey;
    })
    .addCase(registerSmartLogin.fulfilled, (state, action) => {
      state.userHash = action.payload?.userHash;
    })
    .addCase(setBiometricEnabled, (state, action) => {
      state.isBiometricEnabled = action.payload as boolean;
    })
    .addCase(setTourCompleted, (state, action) => {
      state.isTourCompleted = action.payload as boolean;
    })
    .addCase(logout.fulfilled, (state) => {
      state.publicKey = '';
      state.userHash = null;
    })
    .addCase(setShowBiometric, (state, action) => {
      state.showBiometric = action.payload;
    })
    .addCase(setNearbyProps, (state, action) => {
      state.nearbyLocation = action.payload;
    })
    .addCase(getLanguagesInfo.fulfilled, (state, action) => {
      state.languages = action.payload;
    });
});

export default utilsReducer;
