import React, { useEffect } from 'react';

import moment from 'moment';
import { SlotInfo, momentLocalizer } from 'react-big-calendar';
import { DateTime } from 'luxon';

import {
  BookingState,
  TypeOfUser,
  getAllBannerLogos,
  getTypeOfUser,
  language,
} from '@pharmaplan/common';

import {
  EventWrapper,
  ITypeGroup,
} from '../../components/Dashboard/MainCalendar/types/IMainCalendar';
import CustomMonthEvent from '../../components/Dashboard/MainCalendar/CustomMonthEvent';
import useSelfService from '../useSelfService';
import { enableAddCalendarEvent } from '../../reducers/mainCalendarReducer';
import ICalendar from '../../types/ICalendar';
import {
  getEnabledAddEvents,
  getMultipleEventAdd,
} from '../../selectors/calendarSelector';

import useDrawerNavigation from '../useDrawerNavigation';
import useMultipleEvents from '../useMultipleEvents';
import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';
import useRefresh from '../useRefresh';

const { pharmacist, pharmacy } = TypeOfUser;
const { PendingWithPharmacist } = BookingState;

const useMainCalendar = () => {
  const dispatch = useAppDispatch();
  const { createNewEvent } = useMultipleEvents();

  const { isSelfService } = useSelfService();
  const { openAddDrawer } = useDrawerNavigation();

  const userType = useAppSelector(getTypeOfUser);
  const portalLang = useAppSelector(language);
  const enabledAddEvent = useAppSelector(getEnabledAddEvents);
  const multipleEventAdd = useAppSelector(getMultipleEventAdd);

  const localizer = momentLocalizer(moment);

  const isPharmacist = userType === pharmacist;
  const isPharmacy = userType === pharmacy;

  const previousDay = DateTime.now().minus({ day: 1 }).toJSDate();

  const CustomEventComponent = (
    eventElement: EventWrapper<ICalendar>,
    typeGroup: ITypeGroup,
  ) => {
    const { event, style, onSelect } = eventElement ?? {};

    const { bookingState, type: eventType, startDate } = event ?? {};

    const isBookingId = isPharmacy && isSelfService && bookingState === PendingWithPharmacist;

    const getType = isBookingId ? 'bookingId' : 'id';

    const eventIds = typeGroup?.[startDate.split('T')?.[0]][
      eventType as string
    ]?.map((item) =>
      item[getType as keyof typeof item]);

    return (
      <CustomMonthEvent
        eventIds={(eventIds ?? []) as string[]}
        event={event}
        style={style}
        onSelect={onSelect}
      />
    );
  };

  const handleOnSelectSlot = (slot: SlotInfo) => {
    const { start } = slot;

    if (!enabledAddEvent || start < previousDay) {
      return;
    }

    if (multipleEventAdd) {
      createNewEvent(slot);
    } else {
      openAddDrawer(slot);
    }
  };

  useEffect(() => {
    if (isPharmacist) {
      dispatch(getAllBannerLogos());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useRefresh();

  useEffect(
    () =>
      () => {
        dispatch(enableAddCalendarEvent(false));
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    moment.locale(portalLang);
  }, [portalLang]);

  return { localizer, CustomEventComponent, handleOnSelectSlot };
};

export default useMainCalendar;
