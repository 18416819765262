import React from 'react';

import { IPharmacistDetailsModel, ServiceTypes } from '@pharmaplan/common';

import ServiceHandler from '@pharmaplan/common/helpers/ServiceHandler';
import strings from '../../../../../localization';

import {
  handleBooleanValue,
  handleValue,
} from '../../Pharmacy/PharmacyViewProfile/helper';
import {
  OutcomeModalTypes,
  TypeOfSoftwareExpertise,
  preferredDaysMap,
} from '../../../../../helpers/Constants';
import SoftwareExpertise from '../../../../SelfService/SoftwareExpertise';
import { setDialog } from '../../../../../reducers/dialogReducer';
import OutcomeModal from '../../../../Modals/OutcomeModal';
import { store } from '../../../../../store';
import { languageLevelMap } from '../../../../Profile/ProfileForm/Forms';

const selfServiceConfigKey = 'pharmaGapConfigKey';

export const showSuccess = (
  dispatch: typeof store.dispatch,
  message: string,
) => {
  dispatch(
    setDialog({
      heading: {
        title: '',
      },
      Component: (
        <OutcomeModal type={OutcomeModalTypes.justSuccess} message={message} />
      ),
      showCloseButton: true,
    }),
  );
};

export const showError = (
  dispatch: typeof store.dispatch,
  message: string,
) => {
  dispatch(
    setDialog({
      heading: {
        title: '',
      },
      Component: (
        <OutcomeModal type={OutcomeModalTypes.error} message={message} />
      ),
      showCloseButton: true,
    }),
  );
};

export const handlePreferredDays = (preferredWorkingDays: string) =>
  preferredWorkingDays
    ?.split(',')
    .map((item) =>
      preferredDaysMap()[parseInt(item, 10)])
    .join('; ');

export const pharmacistProfileConfig = (
  details: IPharmacistDetailsModel,
  language: string,
  pharmacistCapacity: string,
  successAction: string,
) => {
  const isSelfService = ServiceHandler.getService() === ServiceTypes.self;
  const { pharmacist } = details ?? {};
  const {
    phone,
    firstName,
    lastName,
    licenseNumber,
    graduationYear,
    yearsOfExperience,
    otherLanguages,
    city,
    province,
    address,
    postalCode,
    mobile,
    availabilityReminder,
    preferredWorkingDays,
    preferredReplacementDuration,
    selectedSoftwares,
    frenchLevel,
    englishLevel,
    incorporated,
    noCancellationPeriod,
    bookingStateExpiration,
    cancellationPercentFees,
  } = pharmacist ?? {};

  const selfServiceConfig = {
    key: selfServiceConfigKey,
    title: strings.configurations,
    cardContent: [
      {
        key: 1,
        label: strings.noCancellationPeriod,
        value: handleValue(noCancellationPeriod),
      },
      {
        key: 2,
        label: strings.requestPendingPeriod,
        value: handleValue(bookingStateExpiration),
      },
      {
        key: 3,
        label: strings.cancellationFees,
        value: handleValue(cancellationPercentFees),
      },
    ],
  };

  const arr = [
    {
      key: 0,
      title: strings.general,
      keepExpanded: true,
      cardContent: [
        {
          key: 1,
          label: strings.firstName,
          value: handleValue(firstName),
        },
        {
          key: 2,
          label: strings.lastName,
          value: handleValue(lastName),
        },
        {
          key: 3,
          label: strings.preferredLanguage,
          value: handleValue(language),
        },
      ],
    },
    {
      key: 1,
      title: strings.professionalInformation,
      cardContent: [
        {
          key: 0,
          label: strings.licenseNumber,
          value: handleValue(licenseNumber),
        },
        {
          key: 1,
          label: strings.pharmacistCapacity,
          value: handleValue(pharmacistCapacity),
        },
        {
          key: 2,
          label: strings.graduationYear,
          value: handleValue(graduationYear),
        },
        {
          key: 3,
          label: strings.yearsOfExperience,
          value: handleValue(yearsOfExperience),
        },
        {
          key: 4,
          label: strings.englishLevel,
          value: languageLevelMap()[parseInt(englishLevel as string, 10)],
        },
        {
          key: 5,
          label: strings.frenchLevel,
          value: languageLevelMap()[parseInt(frenchLevel as string, 10)],
        },
        {
          key: 6,
          label: strings.otherLanguage,
          value: handleValue(otherLanguages),
        },
        {
          key: 7,
          label: strings.areYouIncorporated,
          value: handleBooleanValue(incorporated),
        },
      ],
    },
    {
      key: 2,
      title: strings.softwareFamiliarity,
      customComponent: (
        <SoftwareExpertise
          successAction={successAction}
          softwareDetails={selectedSoftwares}
          layout={TypeOfSoftwareExpertise.softwareFamilarity}
        />
      ),
    },
    {
      key: 3,
      title: strings.contactDetails,
      cardContent: [
        {
          key: 0,
          label: strings.address,
          value: handleValue(address),
        },
        {
          key: 1,
          label: strings.city,
          value: handleValue(city),
        },
        {
          key: 2,
          label: strings.province,
          value: handleValue(province),
        },
        {
          key: 3,
          label: strings.postalCode,
          value: handleValue(postalCode),
        },
        {
          key: 4,
          label: strings.homePhone,
          value: handleValue(phone),
        },
        {
          key: 5,
          label: strings.mobile,
          value: handleValue(mobile),
        },
      ],
    },
    {
      key: 4,
      title: strings.preferences,
      cardContent: [
        {
          key: 0,
          label: strings.preferredWorkingDays,
          value: handleValue(handlePreferredDays(preferredWorkingDays as string)),
        },
        {
          key: 1,
          label: strings.preferredReplacementDuration,
          value: handleValue(preferredReplacementDuration),
        },
        {
          key: 2,
          label: strings.availabilityReminder,
          value: handleBooleanValue(availabilityReminder),
        },
      ],
    },
    {
      key: selfServiceConfigKey,
      title: strings.configurations,
      cardContent: [
        {
          key: 1,
          label: strings.noCancellationPeriod,
          value: handleValue(`${noCancellationPeriod} ${strings.days}`),
        },
        {
          key: 2,
          label: strings.requestPendingPeriod,
          value: handleValue(`${bookingStateExpiration} ${strings.hrs}`),
        },
        {
          key: 3,
          label: strings.cancellationFees,
          value: handleValue(`${cancellationPercentFees}%`),
        },
      ],
    },
  ];

  if (!isSelfService) {
    return arr.filter((item) =>
      item.key !== selfServiceConfigKey);
  }

  return arr;
};
