import { useEffect, useState } from 'react';
import {
  adminActions,
  AdminLegendVariant,
  getPharmacyDetails,
  getPharmacyWorkshiftsList,
  ICalendarEventType,
  MiscType,
  PermissionsOfAdmin,
  PharmacyFilterWorkshifts,
  pharmacyWorkshiftsList,
  resetStatus,
  successSelector,
  userPreferredTimeFormat,
} from '@pharmaplan/common';
import { FormikValues, useFormik } from 'formik';
import { useAppDispatch } from '../../useAppDispatch';
import { useAppSelector } from '../../useAppSelector';
import { IDynamicTableObject } from '../../../components/DynamicTable/types';
import {
  pastWorkshiftsListHeaders,
  pharmacyWorkshiftsListHeaders,
  pharmacyWorkshiftsListRow,
} from '../../../components/Admin/PharmacyList/ListOfWorkshifts/helper';
import { showSuccess } from '../../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import strings from '../../../localization';
import useWorkshiftDelete from '../useWorkshiftDelete';
import useUserProfileLink from '../useUserProfileLink';
import useAdminPermissions from '../useAdminPermissions';
import { ListOfWorkshiftsTabTypes, OtherScreens, ScreenTypes } from '../../../helpers/Constants';
import { renderScreen, resetDrawer } from '../../../actions/drawerActions';
import useDrawer from '../../useDrawer';
import useAdminReportsFn from '../../AdminReports/useAdminReportsFn';

const { bookAvailablity: bookAvailablityAction } = adminActions;
const { booked, requested, posted } = AdminLegendVariant;

const handleAll = (value: PharmacyFilterWorkshifts | MiscType) =>
  (value === MiscType.All ? '' : value);

const useListOfWorkshifts = (pharmacyId: string) => {
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState<ListOfWorkshiftsTabTypes>(
    ListOfWorkshiftsTabTypes.upcomingWorkshifts,
  );

  const dispatch = useAppDispatch();
  const { goToUserProfile } = useUserProfileLink();
  const { can } = useAdminPermissions();
  const { showWorkshiftDelete, showRequestCancellation } = useWorkshiftDelete();
  const { openDrawer } = useDrawer();
  const { findMatchingAvailabilities } = useAdminReportsFn();

  const workshifts = useAppSelector(pharmacyWorkshiftsList);
  const timeFormat = useAppSelector(userPreferredTimeFormat);
  const deleteSuccess = useAppSelector((state) =>
    successSelector([adminActions.deletePharmacyWorkshift], state));
  const editSuccess = useAppSelector((state) =>
    successSelector([adminActions.editAdminWorkshift], state));
  const bookingAvailabilitySuccess = useAppSelector((state) =>
    successSelector([bookAvailablityAction], state));

  const canDelete = can(PermissionsOfAdmin.Delete);
  const isPast = selectedTab === ListOfWorkshiftsTabTypes.pastWorkshifts;

  const { data, totalCount } = workshifts ?? {};

  const getWorkshiftList = (selectedPage = 1, status = '', past = isPast) => {
    dispatch(
      getPharmacyWorkshiftsList({
        pharmacyId,
        past,
        status,
        pagingModel: {
          page: selectedPage,
        },
      }),
    );
  };

  const onSubmit = (values: FormikValues) => {
    const { status } = values ?? {};
    const handledStatus = handleAll(status);
    setPage(1);
    getWorkshiftList(1, handledStatus);
  };

  const formik = useFormik({
    initialValues: {
      status: MiscType.All,
    },
    onSubmit,
  });

  const { values, resetForm, handleSubmit } = formik ?? {};
  const { status } = values ?? {};

  const handlePagination = (_: unknown, selectedPage: number) => {
    const gotoPage = selectedPage + 1;
    setPage(gotoPage);
    getWorkshiftList(gotoPage, handleAll(status));
  };

  const handleEdit = (type: ICalendarEventType, bookingId: string | null, workshiftId: string) =>
    () => {
      openDrawer();

      switch (type) {
        case booked:
          dispatch(
            renderScreen({
              screenNumber: 5,
              screenType: ScreenTypes.availableWorkshift,
              eventId: bookingId ?? '',
              workshiftId,
              type,
            }),
          );
          return;
        case requested:
          dispatch(
            renderScreen({
              screenNumber: 5,
              screenType: ScreenTypes.availableWorkshift,
              eventId: workshiftId ?? '',
              workshiftId,
              type: posted,
            }),
          );
          return;
        case posted:
          dispatch(
            renderScreen({
              screenNumber: 2,
              screenType: ScreenTypes.availableWorkshift,
              eventId: workshiftId,
              fromScreen: OtherScreens.ListOfWorkshifts,
            }),
          );
          break;
        default:
          break;
      }
    };

  const table: IDynamicTableObject = {
    headerBar: [],
    title: strings.activePharmacies,
    headers: isPast ? pastWorkshiftsListHeaders() : pharmacyWorkshiftsListHeaders(),
    rows: pharmacyWorkshiftsListRow({
      data,
      timeFormat,
      goToUserProfile,
      showWorkshiftDelete,
      findMatchingAvailabilities,
      canDelete,
      isPast,
      showRequestCancellation,
      handleEdit,
    }),
  };

  useEffect(() => {
    dispatch(getPharmacyDetails(pharmacyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getWorkshiftList();
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(resetStatus([adminActions.deletePharmacyWorkshift]));
      showSuccess(dispatch, strings.workshiftDeletedSuccessfully);
      resetForm();
      getWorkshiftList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess]);

  useEffect(() => {
    if (bookingAvailabilitySuccess) {
      getWorkshiftList();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingAvailabilitySuccess]);

  useEffect(() => {
    if (editSuccess) {
      dispatch(resetStatus([adminActions.editAdminWorkshift]));
      showSuccess(dispatch, strings.updatedSuccessfullyFormat);
      resetForm();
      getWorkshiftList();
      dispatch(resetDrawer());
    }
  });

  return { table, page, handlePagination, totalCount, selectedTab, setSelectedTab, formik };
};

export default useListOfWorkshifts;
