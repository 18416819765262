import React, { FC, useEffect } from 'react';
import { FormikValues, useFormik } from 'formik';
import { Box } from '@mui/material';
import {
  calendarDate,
  calendarFilter,
  createAvailability,
  getPharmacistCalendar,
  getRecurrence,
  pharmacistActions,
  reset,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';
import { DateTime } from 'luxon';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { makeReqParams, setHour } from '../../../helpers/workshift/functions';
import { DurationTypes } from '../../../helpers/Constants';
import {
  resetDrawer,
  setButtons,
  setPagination,
} from '../../../actions/drawerActions';
import AddUpdateEvents from '../../common/AddUpdateEvents/AddUpdateEvents';
import strings from '../../../localization';
import NotesField from '../../common/NotesField/NotesField';
import { getDisableAvailability } from '../../../selectors/calendarSelector';

import classes from '../styles';
import Validator from '../Validator';
import useRecurrence from '../../../hooks/useRecurrence';

interface IAddAvailability {
  date: string;
}

const AddAvailability: FC<IAddAvailability> = ({ date }) => {
  const dispatch = useAppDispatch();
  const recurrence = useAppSelector(getRecurrence);
  const disabled = useAppSelector(getDisableAvailability);
  const cDate = useAppSelector(calendarDate);
  const filters = useAppSelector(calendarFilter);
  const luxonDate = DateTime.fromISO(date);
  const isSuccess = useAppSelector((state) =>
    successSelector([pharmacistActions.createAvailability], state));

  const handleSubmit = (values: FormikValues) => {
    dispatch(createAvailability(makeReqParams(values, recurrence)));
  };

  const formik = useFormik({
    initialValues: {
      selectedDate: luxonDate,
      allDay: false,
      startTime: null,
      endTime: null,
      notes: '',
      recurrence: DurationTypes.notRepeat,
    },
    onSubmit: handleSubmit,
    validationSchema: Validator(),
  });

  useRecurrence(formik);

  const handleReset = () => {
    dispatch(reset());
    dispatch(resetDrawer());
    return true;
  };

  const setUserWorkTimings = () => {
    formik.setFieldValue('startTime', setHour(luxonDate, 8));
    formik.setFieldValue('endTime', setHour(luxonDate, 22));
  };

  useEffect(() => {
    dispatch(setPagination({ show: false }));
    dispatch(
      setButtons({
        show: true,
        primaryButton: {
          label: strings.create,
          disabled: !disabled,
          onClick: formik.handleSubmit,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(getPharmacistCalendar({ ...cDate, ...filters }));
      handleReset();
      dispatch(resetStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Box sx={classes.container}>
      <AddUpdateEvents
        formik={formik}
        setUserWorkTimings={setUserWorkTimings}
        isSeries={false}
      />
      <NotesField formik={formik} />
    </Box>
  );
};

export default AddAvailability;
