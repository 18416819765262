import { IStore } from "../types/IStore";

export const pharmacistReports = (state:IStore) => state.pharmacist.reports;
export const pharmacistReplacements = (state:IStore) => state.pharmacist.replacements;
export const pharmacistHistory = (state:IStore) => state.pharmacist.history;
export const pharmacyNames = (state:IStore) => state.pharmacist.pharmacyNames;
export const pharmacistCalendar = (state:IStore) => state.pharmacist.calendar;
export const pharmacistFilterValues= (state:IStore) => state.pharmacist.filterValues;

export const reportActionStatus = (state:IStore) => state.pharmacist.reportActions;
export const noteActionStatus = (state:IStore) => state.pharmacist.notesActions;
export const allNotes = (state:IStore) => state.pharmacist.notes;
export const getCurrentAvailability= (state:IStore) => state.pharmacist.currentAvailability;

export const pharmacistMap = (state:IStore) => state.pharmacist.map;
export const pharmacistResume = (state:IStore) => state.pharmacist.pharmacistResume;
export const pharmacistRegions = (state:IStore) => state.pharmacist.regions;
export const pharmacistEvent = (state:IStore) => state.pharmacist.pharmacistEvent;
export const reportFilterInfo = (state:IStore) => state.pharmacist.reportsFilterInfo;

export const pharmacistPDF = (state:IStore) => state.pharmacist.pdf;
export const postedShifts = (state:IStore) => state.pharmacist.postedShifts;
export const counterOffer = (state: IStore) => state.pharmacist.counterOffer;

export const pharmacistReplacementsWeb = (state: IStore) => state.pharmacist.replacements;
export const selectPreRequest = (state: IStore) => state.pharmacist.preRequest;
export const selectIncomeReports = (state: IStore) => state.pharmacist.incomeReports;
export const selectIncomeDetails = (state: IStore) => state.pharmacist.incomeDetails;
export const pharmacistCancelMessage = (state: IStore) => state.pharmacist.cancelBookingMessage;