import React, { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

import {
  adminBroadcastRecipientList,
  BroadcastNotificationType,
  IAdminBroadcastUserGroupApiData,
} from '@pharmaplan/common';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';

import styles from './styles';

const { message } = BroadcastNotificationType;

interface IRecipientList {
  label: string;
  type: BroadcastNotificationType;
}

const fieldNames = {
  mobile: 'mobile' as keyof IAdminBroadcastUserGroupApiData,
  email: 'email' as keyof IAdminBroadcastUserGroupApiData,
};

const RecipientList: FC<IRecipientList> = ({ label, type }) => {
  const recipientList = useAppSelector(adminBroadcastRecipientList);

  const isMessage = type === message;
  const secondaryField = isMessage ? fieldNames.mobile : fieldNames.email;

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.label}>{label}</Typography>
      <Grid sx={styles.recipientsContainer} container>
        {recipientList.map((item) => {
          const { userId, userName } = item ?? {};
          const secondary = item[secondaryField];

          const primaryAndSecondary = `${userName} (${secondary})`;

          return (
            <Grid key={userId} item>
              <Typography sx={styles.textChip}>
                {primaryAndSecondary}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default RecipientList;
