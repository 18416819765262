import React, { FC, useEffect } from 'react';

import { FormikValues, useFormik } from 'formik';

import {
  BookingState,
  pharmacistBookingDetails,
  updateTimings,
} from '@pharmaplan/common';

import { pharmacyInitialState } from '@pharmaplan/common/reducers/pharmacyReducer';
import PharmacistSummary from '../PharmacistSummary/PharmacistSummary';
import { appendTimeToDate } from '../../../helpers/workshift/functions';
import useSelfService from '../../../hooks/useSelfService';
import useWrokshiftSummaryModals from '../../../hooks/workshiftSummary/useWorkshiftSummaryModal';
import useWorkshiftSummary from '../../../hooks/workshiftSummary/useWorkshiftSummary';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { selectFooterPaginationCurrent } from '../../../selectors/drawerSelector';
import {
  setBack,
  setButtons,
  setPagination,
  setTitle,
} from '../../../actions/drawerActions';

import { MomentTimeFormats } from '../../../helpers/Constants';
import ICalendarEventType from '../../../types/ICalendarEventType';
import strings from '../../../localization';

import UpdateDateTimeRange from './UpdateDateTimeRange/UpdateDateTimeRange';
import Validator from './Validator';
import InfoBox from './InfoBox/InfoBox';

interface IWorkshiftSummary {
  eventIds: Array<string>;
  type: ICalendarEventType;
  bookingState: BookingState;
}

const { fullDate } = MomentTimeFormats;

const WorkshiftSummary: FC<IWorkshiftSummary> = ({
  type,
  eventIds,
  bookingState,
}) => {
  const dispatch = useAppDispatch();

  const { isSelfService } = useSelfService();

  const current = useAppSelector(selectFooterPaginationCurrent);
  const pharmacistDets = useAppSelector(pharmacistBookingDetails);

  const { startDate, endDate, travelAllowance } = pharmacistDets ?? {};

  const id = eventIds[current - 1];
  const isBookingStateConfirm = bookingState === BookingState.PendingWithPharmacist;
  const totalEvents = eventIds.length;

  const handleSubmit = (values: FormikValues) => {
    const { startTime, endTime } = values ?? {};

    dispatch(
      updateTimings({
        bookingId: id,
        startDate: appendTimeToDate(startDate, startTime).toFormat(fullDate),
        endDate: appendTimeToDate(endDate, endTime).toFormat(fullDate),
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      startTime: startDate,
      endTime: endDate,
    },

    onSubmit: handleSubmit,
    validationSchema: Validator(),
  });

  const { fetchEvents, handleCancellation, dispatchActions } = useWorkshiftSummary(eventIds, type, bookingState, formik);
  const { show: showDateTime, handleActions } = useWrokshiftSummaryModals(
    eventIds,
    type,
    formik,
  );

  const dateTime = showDateTime ? (
    <UpdateDateTimeRange formik={formik} />
  ) : null;
  const { component, event, title = '' } = fetchEvents();

  const setDrawerTitle = () => {
    const drawerTitle = isBookingStateConfirm
      ? strings.workshiftDetails
      : title;

    dispatch(setTitle({ title: drawerTitle }));
  };

  const disableDrawerBackButton = () => {
    dispatch(setBack(null));
  };

  const setDrawerButtons = () => {
    dispatch(
      setButtons({
        show: !isBookingStateConfirm,
        ...(isSelfService ? handleActions() : handleCancellation()),
      }),
    );
  };

  const setDrawerPagination = () => {
    dispatch(
      setPagination({
        show: true,
        total: totalEvents,
        current,
      }),
    );
  };

  useEffect(() => {
    setDrawerTitle();
    disableDrawerBackButton();
    setDrawerButtons();
    setDrawerPagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDateTime, pharmacistDets]);

  useEffect(() => {
    id && dispatchActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      {component}

      <InfoBox bookingState={bookingState} eventType={type} />

      <PharmacistSummary
        DateTime={dateTime}
        travelFees={travelAllowance}
        event={event ?? pharmacyInitialState.workshift}
        type={type}
      />
    </>
  );
};

export default WorkshiftSummary;
