import { Dispatch, SetStateAction } from 'react';

import { IMatchingAvailabilitiesData, TimeFormat } from '@pharmaplan/common';
import { timeString } from '../../../helpers/Functions';
import { getDayMonthDateYear } from '../../Reports/helpers';
import { renderScreen } from '../../../actions/drawerActions';
import { store } from '../../../store';
import ICalendarEventType from '../../../types/ICalendarEventType';
import { DynamicTableCell, OtherScreens, ScreenTypes } from '../../../helpers/Constants';
import strings from '../../../localization';
import addAvailablilityIcon from '../../../assets/svg/addAvailabilityIcon.svg';

export interface ITableRowConfig {
  data: Array<IMatchingAvailabilitiesData>;
  timeFormat: TimeFormat;
  dispatch: typeof store.dispatch;
  workshiftId: string;
  type: ICalendarEventType;
  back: () => void
}

export const tableHeaderConfig = () =>
  [
    { key: '0', label: strings.pharmacist },
    { key: '1', label: strings.date },
    { key: '2', label: strings.time },
    { key: '3', label: strings.action },
  ];

export const tableRowConfig = ({
  data,
  timeFormat,
  dispatch,
  workshiftId,
  type,
  back,
}: ITableRowConfig) =>
  data?.map((item) => {
    const {
      pharmacistName,
      startDate,
      endDate,
      availableTimeId,
      selectedDate,
      pharmacistId,
    } = item ?? {};

    const time = timeString(startDate, endDate, timeFormat);
    const date = getDayMonthDateYear(selectedDate);

    return {
      key: `${availableTimeId}`,
      data: [
        {
          key: `0-${availableTimeId}`,
          value: {
            label: pharmacistName,
            handlePress: () => {
              dispatch(
                renderScreen({
                  screenNumber: 2,
                  screenType: ScreenTypes.profile,
                  pharmacistId,
                  fromScreen: OtherScreens.MatchingAvailabilities,
                  type,
                  back,
                }),
              );
            },
          },
          type: DynamicTableCell.smallerCustomLink,
        },
        {
          key: `1-${availableTimeId}`,
          value: date,
          type: DynamicTableCell.smallerText,
        },
        {
          key: `2-${availableTimeId}`,
          value: time,
          type: DynamicTableCell.smallerText,
        },
        {
          key: `3-${availableTimeId}`,
          show: true,
          value: [
            {
              key: '1',
              icon: addAvailablilityIcon,
              tooltip: strings.book,
              onClick: () => {
                dispatch(
                  renderScreen({
                    screenNumber: 4,
                    screenType: ScreenTypes.requestedList,
                    fromScreen: OtherScreens.MatchingAvailabilities,
                    eventId: availableTimeId,
                    workshiftId,
                    type,
                  }),
                );
              },
            },
          ],
          type: DynamicTableCell.iconButton,
        },
      ],
    };
  });

export const handleClick = (
  setShowOtherAvailabilities: Dispatch<SetStateAction<boolean>>,
  state: boolean,
) =>
  () => {
    setShowOtherAvailabilities(state);
  };
