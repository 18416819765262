import React from 'react';

import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

import {
  TypeOfUser,
  logos,
  PharmacistLegendVariant,
  PharmacyLegendVariant,
  RecurrenceActions,
  getTypeOfUser,
  userPreferredTimeFormat,
  BookingState,
  AdminLegendVariant,
} from '@pharmaplan/common';
import { ReactComponent as HourRate } from '@pharmaplan/common/assets/icons/hourlyRateRow.svg';
import { ReactComponent as Location } from '@pharmaplan/common/assets/icons/location.svg';
import { ReactComponent as Software } from '@pharmaplan/common/assets/icons/softwareBasic.svg';
import { ReactComponent as Time } from '@pharmaplan/common/assets/icons/timeRow.svg';
import { ReactComponent as FavouriteNotActive } from '@pharmaplan/common/assets/icons/favouriteNotActive.svg';
import { ReactComponent as FavouriteActive } from '@pharmaplan/common/assets/icons/favourtieActive.svg';

import useSelfService from '../../../hooks/useSelfService';
import useDrawerNavigation from '../../../hooks/useDrawerNavigation';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import useAdmin from '../../../hooks/useAdmin';
import { getHourMinute } from '../../Reports/helpers';
import ICalendar from '../../../types/ICalendar';
import { updateTitle } from '../MainCalendar/CustomMonthEvent/CustomMonthEvent';
import { setDialog } from '../../../reducers/dialogReducer';
import UpdateModal from '../../Modals/UpdateModal';
import { toUTC } from '../MainCalendar/helpers';
import { getEnabledAddEvents } from '../../../selectors/calendarSelector';
import { isPast } from '../../../helpers/Functions';
import { ReplacementTypes } from '../../../helpers/Constants';
import strings from '../../../localization';

import classes from './style';
import useDetailsNavigation from '../../../hooks/Admin/useDetailsNavigation';

interface IEventChip {
  event: ICalendar;
  typeOfUser: TypeOfUser;
}

const { pharmacist, pharmacy } = TypeOfUser;
const { availability } = PharmacistLegendVariant;
const { requested, booked } = PharmacyLegendVariant;
const { PendingWithPharmacist } = BookingState;

const EventChip = ({ event, typeOfUser }: IEventChip) => {
  const dispatch = useAppDispatch();

  const allLogos = useAppSelector(logos);
  const userType = useAppSelector(getTypeOfUser);

  const {
    startDate,
    type,
    bookingId,
    bookingState,
    id,
    recurrenceId,
    logoId,
    endDate,
    hourlyRate,
    pharmacyName,
    replacementType,
    softwareName,
    pharmacistName,
    favourite,
    workShiftId,
    city,
  } = event ?? {};

  const {
    itemContainer,
    itemNameContainer,
    hideBorder,
    icon,
    textOverflowFixes,
    flex,
    mt,
    eventContainer,
    eventTitle,
    logoWidth,
  } = classes ?? {};

  const { isSelfService } = useSelfService();

  const preventClick = isPast(toUTC(startDate));
  const enabledAdd = useAppSelector(getEnabledAddEvents);

  const timeFormat = useAppSelector(userPreferredTimeFormat);

  // ONLY SHOW WHEN EVENT IS NOT IN PAST AND CREATE EVENTS IS DISABLED
  const showEvent = !preventClick && !enabledAdd;

  const logo = allLogos?.find(
    (item: { logoId: string }) =>
      item.logoId === logoId,
  )?.image;

  const { openDrawer } = useDrawerNavigation();
  const { handleNavigation } = useDetailsNavigation();
  const { isAdmin } = useAdmin();

  const isPendingWithPharmacist = type === requested && bookingState === PendingWithPharmacist;
  const isAvailability = type === availability;
  const isBooked = type === booked;

  const isPharmacy = typeOfUser === pharmacy;
  const isPharmacist = typeOfUser === pharmacist;

  const isPharmacyOrAvailability = isAvailability || isPharmacy;
  const isPharmacyAndBooked = isBooked && isPharmacy;
  const isReplacementTypeValid = replacementType !== null && replacementType !== undefined;
  const isHourlyRateValid = hourlyRate !== null && hourlyRate !== undefined;

  const showFavoriteWhen = isPharmacist && !isAvailability;
  const showFavorite = showFavoriteWhen && favourite;

  const time = ` ${getHourMinute(startDate, timeFormat)} - ${getHourMinute(
    endDate,
    timeFormat,
  )}`;

  const getEventId = () => {
    if (isPendingWithPharmacist) {
      return bookingId;
    }

    return id;
  };

  const eventSelect = () => {
    isAdmin
      ? handleNavigation(id, type as AdminLegendVariant, workShiftId)()
      : openDrawer({
        eventIds: [getEventId() ?? ''],
        type,
        bookingState,
      });
  };

  const handlePress = () => {
    if (!showEvent && !isAdmin) {
      return;
    }

    Object.hasOwn(updateTitle(isSelfService), type) && recurrenceId
      ? dispatch(
        setDialog({
          showCloseButton: false,
          Component: (
            <UpdateModal
              type={userType}
              handleSelect={(selected: RecurrenceActions) =>
                openDrawer({
                  eventIds: [id],
                  recurrenceType: selected,
                  recurrenceId,
                  type,
                })}
            />
          ),
          heading: {
            title: '',
          },
        }),
      )
      : eventSelect();
  };

  const renderEndItem = () => {
    if (isAdmin) {
      return (
        <Box sx={[flex]}>
          <Typography component="span" sx={textOverflowFixes}>{pharmacistName}</Typography>
        </Box>

      );
    }

    return (
      <Box sx={[flex, mt]}>
        {showFavorite ? <FavouriteActive /> : <FavouriteNotActive />}
      </Box>
    );
  };

  return (
    <Box sx={eventContainer(type, typeOfUser.toLowerCase() as TypeOfUser)}>
      <Grid sx={flex}>
        {isPharmacyOrAvailability && (
          <Typography sx={eventTitle(type, typeOfUser)}>
            {strings[type]}
          </Typography>
        )}

        {logo && (
          <Box
            sx={[itemContainer, itemNameContainer, hideBorder]}
            component="span"
          >
            <Box component="img" style={logoWidth} src={logo} alt="logo" />
          </Box>
        )}
      </Grid>

      <Grid container flexDirection="row" onClick={handlePress}>
        <Grid item sx={flex} component="span">
          {!!pharmacyName && (
            <Box sx={itemContainer}>
              <Typography sx={[textOverflowFixes]}>{pharmacyName}</Typography>
            </Box>
          )}

          {isReplacementTypeValid
            && (isPharmacyAndBooked ? (
              <Box sx={itemContainer}>{pharmacistName}</Box>
            ) : (
              <Box sx={itemContainer} component="span">
                {ReplacementTypes()[replacementType]}
              </Box>
            ))}

          {!!city && (
            <Box sx={itemContainer} component="span">
              <Location style={icon} />
              <Box sx={textOverflowFixes} component="span">
                {city}
              </Box>
            </Box>
          )}

          <Box sx={itemContainer} component="span">
            <Time style={icon} />
            <Box sx={textOverflowFixes} component="span">
              {time}
            </Box>
          </Box>
        </Grid>

        <Grid item sx={flex}>
          {isHourlyRateValid && (
            <Box sx={itemContainer} component="span">
              <HourRate style={icon} />

              <Box sx={textOverflowFixes} component="span">
                {`$${hourlyRate}`}
              </Box>
            </Box>
          )}

          {softwareName && (
            <Box sx={itemContainer} component="span">
              <Software style={icon} />
              {softwareName}
            </Box>
          )}
        </Grid>
      </Grid>
      {renderEndItem()}
    </Box>
  );
};

export default EventChip;
