import React, { useEffect } from 'react';

import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { Outlet } from 'react-router-dom';

import { getAllBannerLogos } from '@pharmaplan/common';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import Navbar from '../../Navbar';

import styles from './styles';

const PharmacyAdminHome = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllBannerLogos());
  }, []);

  return (
    <Box sx={styles.container}>
      <Grid sm={11} width="100%">
        <Box>
          <Navbar BottomToolbar={<div />} />
        </Box>

        <Box sx={styles.contentContainer}>
          <Outlet />
        </Box>
      </Grid>
    </Box>
  );
};
export default PharmacyAdminHome;
