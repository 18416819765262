import {
  IAdminPharmacyWorkshiftsListData,
  ICalendarEventType,
  PharmacyLegendVariant,
  TimeFormat,
  TypeOfUser,
  WorkshiftStatus,
} from '@pharmaplan/common';
import strings from '../../../../localization';
import { getDayMonthDateYear } from '../../../Reports/helpers';
import { timeString } from '../../../../helpers/Functions';
import { DynamicTableCell, OtherScreens } from '../../../../helpers/Constants';
import {
  convertDecimal,
  handleValue,
} from '../../Profile/Pharmacy/PharmacyViewProfile/helper';
import { IUserProfileLinkSignature } from '../../../../hooks/Admin/useUserProfileLink';
import deleteRequestIcon from '../../../../assets/svg/delete.svg';
import requestCancellationIcon from '../../../../assets/svg/requestCancellation.svg';
import editWorkshift from '../../../../assets/svg/edit.svg';
import findMatchingIcon from '../../../../assets/svg/findMatching.svg';

const { text, customLink } = DynamicTableCell;

interface IPharmacyWorkshiftListRow {
  data: IAdminPharmacyWorkshiftsListData[];
  timeFormat: TimeFormat;
  goToUserProfile: IUserProfileLinkSignature;
  showWorkshiftDelete: (workshiftId: string, dateTime: string) => void;
  showRequestCancellation: (bookingId: string, dateTime: string) => void;
  findMatchingAvailabilities: (workshiftId: string) => void;
  canDelete: boolean;
  isPast: boolean;
  handleEdit: (
    type: ICalendarEventType,
    bookingId: string | null,
    workshiftId: string
  ) => () => void;
}

export const pharmacyWorkshiftsListHeaders = () =>
  [
    { key: '0', label: `${strings.date} | ${strings.time}` },
    { key: '1', label: strings.status },
    { key: '2', label: strings.totalHours, isCenterAligned: true },
    { key: '3', label: strings.replacementName },
    { key: '4', label: strings.pharmacyHourlyRate, isCenterAligned: true },
    { key: '5', label: strings.pharmacistHourlyRate, isCenterAligned: true },
    { key: '6', label: strings.action },
  ];

export const pastWorkshiftsListHeaders = () =>
  [
    { key: '0', label: `${strings.date} | ${strings.time}` },
    { key: '1', label: strings.status },
    { key: '2', label: strings.totalHours, isCenterAligned: true },
    { key: '5', label: strings.replacementName },
    { key: '3', label: strings.pharmacyHourlyRate, isCenterAligned: true },
    { key: '4', label: strings.pharmacistHourlyRate, isCenterAligned: true },
  ];

export const statusMap = {
  [WorkshiftStatus.Posted]: strings.posted,
  [WorkshiftStatus.Requested]: strings.requested,
  [WorkshiftStatus.Booked]: strings.booked,
  [WorkshiftStatus.Unfulfilled]: strings.unfulfilled,
};

export const pharmacyWorkshiftsListRow = ({
  data,
  timeFormat,
  goToUserProfile,
  showWorkshiftDelete,
  showRequestCancellation,
  findMatchingAvailabilities,
  handleEdit,
  canDelete,
  isPast,
}: IPharmacyWorkshiftListRow) =>
  data.map((item) => {
    const {
      bookingId,
      status,
      endDate,
      startDate,
      pharmacistName,
      type,
      pharmacistId,
      workShiftId,
      totalHours,
      paidHourlyRate,
      pharmacyHourlyRate,
    } = item ?? {};

    const date = getDayMonthDateYear(startDate);
    const time = timeString(startDate, endDate, timeFormat);

    const dateTime = `${date} | ${time}`;

    const isBooked = type === PharmacyLegendVariant.booked;

    const handleOnClick = () => {
      if (isBooked && bookingId) {
        showRequestCancellation(bookingId, dateTime);
      } else {
        showWorkshiftDelete(workShiftId, dateTime);
      }
    };

    return {
      key: bookingId,
      data: [
        {
          key: '0',
          value: `${date} | ${time}`,
          type: text,
          maxWidth: 200,
        },
        {
          key: '1',
          value: statusMap[status],
          type: text,
        },
        {
          key: '2',
          value: totalHours,
          type: text,
          isCenterAligned: true,
        },
        !isBooked
          ? {
            key: '3',
            value: handleValue(pharmacistName),
            type: text,
          }
          : {
            key: '4',
            value: {
              label: pharmacistName,
              handlePress: goToUserProfile({
                personnelId: pharmacistId,
                userType: TypeOfUser.pharmacist,
                fromScreen: OtherScreens.ListOfWorkshifts,
              }),
            },
            type: customLink,
          },
        {
          key: '9',
          value: convertDecimal(pharmacyHourlyRate),
          type: text,
          isCenterAligned: true,
        },
        {
          key: '9',
          value: convertDecimal(paidHourlyRate),
          type: text,
          isCenterAligned: true,
        },

        !isPast && {
          key: '6',
          value: [
            !isBooked && {
              key: '8',
              tooltip: strings.matchingAvailabilities,
              icon: findMatchingIcon,
              onClick: () => {
                findMatchingAvailabilities(workShiftId);
              },
            },
            {
              key: '7',
              tooltip: strings.edit,
              icon: editWorkshift,
              onClick: handleEdit(type, bookingId, workShiftId),
            },
            canDelete && {
              key: '6',
              tooltip: isBooked ? strings.cancel : strings.delete,
              icon: isBooked ? requestCancellationIcon : deleteRequestIcon,
              onClick: handleOnClick,
            },
          ],
          type: DynamicTableCell.bar,
        },
      ],
    };
  });
