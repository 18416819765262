import React, { FC, useEffect, useMemo } from 'react';
import {
  AdminLegendVariant,
  AllowanceTypes,
  TypeOfBill,
  TypeOfSummary,
  adminActions,
  adminRequestedDetails,
  getRequestedDetails,
} from '@pharmaplan/common';
import { Box } from '@mui/system';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import styles from './styles';
import ServiceCard from '../../../SelfService/ServiceCard';
import CustomGridLayout from '../../../common/CustomGridLayout';
import strings from '../../../../localization';
import PharmacistCard from '../../../SelfService/PharmacistCard';
import {
  Constants,
  OtherScreens,
  ScreenTypes,
} from '../../../../helpers/Constants';
import SoftwareExpertise from '../../../SelfService/SoftwareExpertise';
import PharmacistSummary from '../../../SelfService/PharmacistSummary';
import BillDetails from '../../../SelfService/BillDetails';
import {
  renderScreen,
  setBack,
  setFooter,
  setFooterShow,
} from '../../../../actions/drawerActions';
import useRequestedPharmacistActions from '../../../../hooks/AvailableWorkshift/useRequestedPharmacistActions';
import { serverToday } from '../../../../helpers/Functions';
import useDetailsPagination from '../../../../hooks/Admin/useDetailsPagination';
import useGetItems from '../../../../hooks/useGetItems';
import useOptimizedAvatar from '../../../../hooks/useOptimizedAvatar';

interface IViewRequestedDetails {
  bookingId: string;
  workshiftId: string;
  bookingIdList?: Array<string> | null;
}

const {
  adminPharmacistPictures: adminPharmacistPicturesAction,
  getRequestedDetails: getRequestedDetailsAction,
} = adminActions;

const {
  variant: { text },
} = Constants;

const ViewRequestedDetails: FC<IViewRequestedDetails> = ({
  bookingId,
  workshiftId,
  bookingIdList,
}) => {
  const dispatch = useAppDispatch();
  const requestedDetails = useAppSelector(adminRequestedDetails);

  const { id, paginationModel } = useDetailsPagination(bookingIdList);

  const eventId = id ?? bookingId;
  const isNotFromMap = !id;

  const { ignoreRequestAction, goToApproveBooking } = useRequestedPharmacistActions();

  const isPast = (serverToday().toISO({ includeOffset: false }) ?? '')
    > requestedDetails?.startDate;

  const {
    experience,
    email,
    address,
    province,
    city,
    postalCode,
    softwares,
    licenseNumber,
    pharmacistId,
  } = requestedDetails ?? {};

  const goToRequestDetails = () => {
    dispatch(
      renderScreen({
        screenNumber: 2,
        screenType: ScreenTypes.requestedList,
        eventId: workshiftId,
      }),
    );
  };

  const pharmacistDetailsConfig = [
    { key: strings.yearsOfExperience, value: experience?.toString() },
    { key: strings.licenseNo, value: licenseNumber },
    { key: strings.email, value: email },
    {
      key: strings.address,
      value: `${address}, ${city}, ${province}, ${postalCode}`,
    },
  ];

  const fullEvent = {
    ...requestedDetails,
    accommodationAllowanceType: AllowanceTypes.custom,
    travelAllowanceType: AllowanceTypes.custom,
    mealAllowanceType: AllowanceTypes.custom,
    cost: requestedDetails.bookingCost,
  };

  const { getPharmacistImage } = useGetItems();

  const pharmacistDetails = {
    ...requestedDetails,
    profilePicture: getPharmacistImage(pharmacistId),
  };

  useEffect(() => {
    if (isNotFromMap) {
      dispatch(setBack(goToRequestDetails));
    }

    return () => {
      dispatch(setFooterShow(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workshiftId]);

  useEffect(() => {
    if (!isPast) {
      dispatch(
        setFooter({
          show: true,
          pagination: isNotFromMap
            ? {
              show: false,
            }
            : paginationModel,
          buttons: {
            show: true,
            primaryButton: {
              label: strings.approve,
              onClick: goToApproveBooking(
                eventId,
                workshiftId,
                isNotFromMap
                  ? OtherScreens.AdminCalendar
                  : OtherScreens.AdminMap,
                bookingIdList,
              ),
            },
            secondaryButton: {
              variant: text,
              label: strings.ignore,
              onClick: ignoreRequestAction(eventId),
            },
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPast, eventId]);

  useEffect(() => {
    dispatch(getRequestedDetails(eventId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const pIds = useMemo(() =>
    [pharmacistId], [pharmacistId]);

  useOptimizedAvatar(pIds);

  return (
    <Box>
      <PharmacistCard
        eventIds={bookingIdList ?? []}
        workshiftId={workshiftId}
        eventId={eventId}
        successAction={getRequestedDetailsAction}
        pharmacistDetails={pharmacistDetails}
        screenType={ScreenTypes.pharmacistProfile}
        imageSuccessAction={adminPharmacistPicturesAction}
      />
      <ServiceCard
        customContainerStyle={styles.serviceContainer}
        content={(
          <CustomGridLayout
            successAction={getRequestedDetailsAction}
            title={strings.pharmacistDetails}
            config={pharmacistDetailsConfig}
          />
        )}
      />
      <SoftwareExpertise
        successAction={getRequestedDetailsAction}
        softwareDetails={softwares}
      />
      <PharmacistSummary
        travelFees={0}
        event={requestedDetails}
        summaryType={TypeOfSummary.workshiftDetails}
        type={AdminLegendVariant.requested}
      />
      <BillDetails
        successAction={getRequestedDetailsAction}
        billType={TypeOfBill.finalBill}
        event={fullEvent}
      />
    </Box>
  );
};

export default ViewRequestedDetails;
