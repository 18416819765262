import { useEffect } from 'react';

import { FormikValues, useFormik } from 'formik';

import {
  IAdminPharmacistDetails,
  adminActions,
  convertToDecimals,
  getPharmacistDetails,
  pharmacistDetails,
  resetStatus,
  submitPharmacistDetails,
  successSelector,
  toType,
} from '@pharmaplan/common';

import PharmacistEditProfileValidator from '../../../components/Admin/Profile/Pharmacist/PharmacistEditProfile/Validator';
import { showSuccess } from '../../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';
import {
  ICustomBack,
  IFromScreen,
  renderScreen,
  setBack,
  setFooter,
  setFooterShow,
} from '../../../actions/drawerActions';
import strings from '../../../localization';
import { getMinusCurrentYear } from '../../../helpers/Functions';
import ICalendarEventType from '../../../types/ICalendarEventType';
import { OtherScreens, ScreenTypes } from '../../../helpers/Constants';

import { useAppDispatch } from '../../useAppDispatch';
import { useAppSelector } from '../../useAppSelector';
import useSelfService from '../../useSelfService';

interface SelectedSoftware {
  [softwareId: string]: number;
}

export interface IPharmacistEditProfile {
  pharmacistId: string;
  type: ICalendarEventType;
  fromScreen?: IFromScreen;
  hasOwner?: boolean;
  back?: ICustomBack;
}

const usePharmacistEditProfile = ({
  pharmacistId,
  type,
  fromScreen,
  hasOwner,
  back,
}: IPharmacistEditProfile) => {
  const dispatch = useAppDispatch();
  const { isSelfService } = useSelfService();

  const details = useAppSelector(pharmacistDetails);
  const success = useAppSelector((state) =>
    successSelector([adminActions.submitPharmacistDetails], state));

  const { pharmacist } = details ?? {};
  const {
    englishLevel,
    frenchLevel,
    selectedSoftwares,
    preferredWorkingDays,
    hourlyRate,
  } = pharmacist ?? {};

  const setSoftwares = (): SelectedSoftware =>
    selectedSoftwares.reduce((acc, cur) => {
      acc[cur.softwareId] = cur.level;
      return acc;
    }, {} as SelectedSoftware);

  const onSubmit = (values: FormikValues) => {
    const modifiedSoftwares = selectedSoftwares.map((item) =>
      ({
        softwareId: item.softwareId,
        expLevel: values[toType(item.softwareId)],
      }));

    selectedSoftwares.map((item) =>
      delete values[toType(item.softwareId)]);

    const {
      preferredWorkingDays: formikPreferredWorkingDays,
      hourlyRate: formikHourlyRate,
      cancellationPercentFees,
      noCancellationPeriod,
      bookingStateExpiration,
      ...otherValues
    } = values ?? {};

    const cleanedValues = {
      ...(otherValues as IAdminPharmacistDetails),
      preferredWorkingDays: formikPreferredWorkingDays ?? [],
      hourlyRate: parseFloat(formikHourlyRate),
      selectedSoftwares: modifiedSoftwares,
    };

    const selfServiceValues = {
      ...cleanedValues,
      cancellationPercentFees: parseFloat(cancellationPercentFees),
      noCancellationPeriod: parseInt(noCancellationPeriod, 10),
      bookingStateExpiration: parseInt(bookingStateExpiration, 10),
    };

    dispatch(
      submitPharmacistDetails(isSelfService ? selfServiceValues : cleanedValues),
    );
  };

  const formik = useFormik({
    initialValues: {
      ...pharmacist,
      englishLevel: englishLevel.toString(),
      frenchLevel: frenchLevel.toString(),
      preferredWorkingDays: preferredWorkingDays
        ? (preferredWorkingDays as string).split(',')
        : [],
      ...setSoftwares(),
      hourlyRate: convertToDecimals(hourlyRate) as number,
    },
    enableReinitialize: true,
    validationSchema: PharmacistEditProfileValidator,
    onSubmit,
  });

  const { handleSubmit, setFieldValue, values } = formik ?? {};
  const { graduationYear } = values ?? {};

  const gotoPharmacistProfile = () => {
    dispatch(
      renderScreen({
        screenNumber: 2,
        screenType: ScreenTypes.profile,
        pharmacistId,
        hasOwner,
        fromScreen,
        type,
      }),
    );
  };

  useEffect(() => {
    dispatch(
      setFooter({
        show: true,
        pagination: {
          show: false,
        },
        buttons: {
          show: true,
          primaryButton: {
            label: strings.saveChanges,
            onClick: handleSubmit,
          },
        },
      }),
    );
    if (fromScreen !== OtherScreens.PharmacistList) {
      dispatch(setBack(back ?? gotoPharmacistProfile));
    }

    return () => {
      dispatch(setFooterShow(false));
      dispatch(setBack(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFieldValue('yearsOfExperience', getMinusCurrentYear(graduationYear));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graduationYear]);

  useEffect(() => {
    if (success) {
      showSuccess(dispatch, strings.profileDataSaved);
      dispatch(resetStatus());
      dispatch(getPharmacistDetails(pharmacistId));
      gotoPharmacistProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return { formik };
};

export default usePharmacistEditProfile;
