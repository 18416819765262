import { IStore } from '../types/IStore';

export const contacts = (state: IStore) => state.utils?.contacts;
export const getLanguages = (state: IStore) => state.utils?.languages;
export const loadingSelector = (state: IStore) => state.utils.loading;
export const outPublicKey = (state: IStore) => state.utils?.publicKey;
export const userHash = (state: IStore) => state.utils?.userHash;
export const biometricEnabled = (state: IStore) => state.utils?.isBiometricEnabled;
export const tourCompleted = (state: IStore) => state.utils?.isTourCompleted;
export const shouldShowBiometric = (state: IStore) => state.utils?.showBiometric; 
export const getNearbyProps = (state:IStore) => state.utils.nearbyLocation;
export const systemDetailsSelector = (state:IStore) => state.utils.systemDetails;