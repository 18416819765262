import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';

import { TypeOfUser, getTypeOfUser } from '@pharmaplan/common';
import { IPriceLabel } from '@pharmaplan/common/types/IBillDetails';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import WithSkeleton from '../../../common/WithSkeleton';

import classes from './style';

const PriceLabel: FC<IPriceLabel> = ({
  title,
  price,
  counterPrice,
  successAction,
}) => {
  const userType = useAppSelector(getTypeOfUser);

  const isPharmacist = userType === TypeOfUser.pharmacist;

  const styleConfig = counterPrice
    ? {
      price: classes.strike,
      counterPrice: isPharmacist
        ? classes.price
        : classes.pharmacyCounterPrice,
    }
    : {
      price: classes.price,
      counterPrice: {},
    };

  const SkeletonTypography = WithSkeleton({
    successAction,
    variant: 'text',
  })(Typography);

  return (
    <Box sx={classes.root}>
      <Box sx={classes.titleContainer}>
        <SkeletonTypography sx={classes.title}>{title}</SkeletonTypography>
        <Box sx={classes.priceContainer}>
          <SkeletonTypography sx={styleConfig.price}>
            {price}
          </SkeletonTypography>
          <SkeletonTypography sx={styleConfig.counterPrice}>
            {counterPrice}
          </SkeletonTypography>
        </Box>
      </Box>
    </Box>
  );
};

export default PriceLabel;
