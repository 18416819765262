import React, { useEffect, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useFormik } from 'formik';
import moment, { Moment } from 'moment';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import 'react-calendar/dist/Calendar.css';

import {
  activeDate,
  setActiveWebView,
  CalendarStateKey,
  TypeOfUser,
  getActiveWebView,
  createAvailability,
  createWorkshift,
  successOrSelector,
  pharmacistActions,
  pharmacyActions,
  reset,
  AvailabilityTypes,
  getNearbyProps,
  adminCreateAvailabilities,
  ToUserTypings,
} from '@pharmaplan/common';
import { ReactComponent as FilledFilter } from '@pharmaplan/common/assets/icons/searchIconGreen.svg';
import { ReactComponent as UnfilledFilter } from '@pharmaplan/common/assets/icons/searchIcon.svg';
import { ReactComponent as CloseFilter } from '@pharmaplan/common/assets/icons/close.svg';
import { ReactComponent as RefreshIcon } from '@pharmaplan/common/assets/icons/webRefresh.svg';
import { ReactComponent as PlusIcon } from '@pharmaplan/common/assets/icons/webPlus.svg';

import { IAvailabilityModel } from '@pharmaplan/common/models/Models';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/deleteIcon.svg';

import CustomDropdown from '../../common/CustomDropdown';
import CustomButton from '../../common/CustomButton';
import CustomCheckbox from '../../common/CustomCheckbox';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import useAdmin from '../../../hooks/useAdmin';
import useUser from '../../../hooks/useUser';
import useNearbyPharmacies from '../../../hooks/Toolbar/useNearbyPharmacies';
import {
  setMultipleAddEvents,
  setTempEvents,
} from '../../../reducers/mainCalendarReducer';
import { resetDialog } from '../../../reducers/dialogReducer';
import {
  getEnabledAddEvents,
  getMultipleEventAdd,
  getTempEvents,
} from '../../../selectors/calendarSelector';
import ThemeConstants from '../../../theme/ThemeConstants';
import { MonthWeek, createMultipleDate } from '../../../helpers/Functions';
import { Constants, MomentFormatOptions } from '../../../helpers/Constants';
import strings from '../../../localization';

import DashboardFilter from './DashboardFilter';
import DayCalendar from './DayCalendar';
import YearCalendar from './YearCalendar';
import NavigateButtons from './NavigateButtons';
import CalendarMapToggle from './CalendarMapToggle';
import classes from './style';
import useAdminContinueCreation from '../../../hooks/Admin/useAdminContinueCreation';
import useMultipleDelete from '../../../hooks/useMultipleDelete';

export interface IOnNavigate {
  setDate: (date: Moment) => void;
}

interface IToolbarProps {
  title: string;
}

const Toolbar = ({ title }: IToolbarProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { userType } = useUser();
  const { state } = useLocation();
  const { id } = state ?? {};

  const { isAdmin } = useAdmin();
  const { showConfirmModal } = useAdminContinueCreation();
  const {
    deleteSelectedAvailabilities,
    getDeletedAvailabilities,
    handleDeleteReset,
  } = useMultipleDelete();

  const dayCalendarRef = useRef<{ reset:() => void }>();
  const ref = useRef<{ reset:() => void; clear: () => void }>();
  const nearbyProps = useAppSelector(getNearbyProps);

  const [filterState, setFilterState] = useState({
    submitted: false,
    showFilter: false,
  });
  const { checkForLocation } = useNearbyPharmacies();
  const date = useAppSelector(activeDate);
  const tempEvents = useAppSelector(getTempEvents);
  const activeView = useAppSelector(getActiveWebView);
  const multipleEventAdd = useAppSelector(getMultipleEventAdd);
  const enableAdd = useAppSelector(getEnabledAddEvents);

  const success = useAppSelector((st) =>
    successOrSelector(
      [pharmacistActions.createAvailability, pharmacyActions.createWorkshift],
      st,
    ));

  const {
    toolbarContainer,
    toolbarHeadingContainer,
    filterCont,
    toggleViewContainer,
    navigationToggle,
    navigationToggleLabel,
    navigateDateContainer,
    calendarHeading,
    navigateDate,
    searchIcon,
    multipleSelectButtons,
    resetButton,
    dropdownCustomHeight,
    clearButton,
  } = classes;

  const { submitted, showFilter } = filterState;
  const { pathname } = location;
  const {
    paths: { mapDashboard, admin: adminPaths },
  } = Constants;

  const { adminMapDashboard } = adminPaths;

  const { pharmacist, pharmacy, admin, superAdmin } = TypeOfUser;
  const { shortMonthName, fullYear } = MomentFormatOptions;

  const isPharmacist = userType === pharmacist;
  const mapToggle = pathname === mapDashboard;
  const adminMapToggle = pathname === adminMapDashboard;
  const hideMonthWeek = !mapToggle && !adminMapToggle;

  const showMap = isPharmacist || isAdmin;

  const shouldShowSelectButton = multipleEventAdd && enableAdd;

  const formattedDate = moment(date).format(`${shortMonthName}, ${fullYear}`);

  const disableSave = tempEvents.filter(
    (item: { type: string }) =>
      item.type === AvailabilityTypes.tempAvailability,
  ).length === 0;
  const disableDelete = getDeletedAvailabilities().length === 0;
  const disableReset = disableSave && isAdmin && disableDelete;

  const userEventDispatch = ToUserTypings({
    [pharmacist]: createAvailability,
    [pharmacy]: createWorkshift,
    [admin]: (params: IAvailabilityModel): AsyncThunkAction<any, any, any> =>
      adminCreateAvailabilities({ pharmacistId: id ?? '', model: params }),
    [superAdmin]: (
      params: IAvailabilityModel,
    ): AsyncThunkAction<any, any, any> =>
      adminCreateAvailabilities({ pharmacistId: id ?? '', model: params }),
  });

  const formik = useFormik({
    initialValues: {
      calendarState: activeView.toLowerCase(),
    },
    enableReinitialize: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  const handleReset = () => {
    dispatch(setTempEvents([]));
    handleDeleteReset();
  };

  const handleFilterState = () => {
    setFilterState({
      ...filterState,
      showFilter: !showFilter,
    });
  };

  const selectedDateList = tempEvents.map(
    (item: { selectedDate: string | unknown[] }) =>
      `${item.selectedDate.slice(0, -4)}Z`,
  );

  const handleSave = () => {
    const events = [...tempEvents];

    events.sort((a, b) =>
      (a.selectedDate as string).localeCompare(b.selectedDate));

    dispatch(
      userEventDispatch[userType]({
        startDate: createMultipleDate(events[0]?.selectedDate, 8),
        endDate: createMultipleDate(
          events[events.length - 1]?.selectedDate,
          22,
        ),
        recurrenceType: 0,
        selectedDates: selectedDateList,
      }),
    );
  };

  const filterStates = () => {
    switch (true) {
      case !submitted && !showFilter:
        return <UnfilledFilter id="filter" onClick={handleFilterState} />;
      case !submitted && showFilter:
        return (
          <CloseFilter
            fill={ThemeConstants.grey[800]}
            onClick={() => {
              handleFilterState();
              ref.current?.clear();
            }}
          />
        );
      case submitted:
        return <FilledFilter onClick={handleFilterState} />;
      default:
        return null;
    }
  };

  const {
    values: { calendarState },
  } = formik ?? {};

  useEffect(() => {
    if (success && multipleEventAdd) {
      dispatch(setTempEvents([]));
      dispatch(resetDialog());
      dispatch(reset());
    }

    return () => {
      dispatch(setMultipleAddEvents(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    dispatch(setActiveWebView(calendarState as CalendarStateKey));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarState, date]);

  return (
    <Box sx={toolbarContainer}>
      <Box sx={toolbarHeadingContainer}>
        <Typography variant="h5" sx={calendarHeading}>
          {title}
        </Typography>

        {isPharmacist && (
          <Box sx={filterCont}>
            {submitted && (
              <CustomButton
                customClass={clearButton}
                label={strings.clear}
                variant="outlined"
                onClick={() => {
                  ref?.current?.reset();
                  dayCalendarRef.current?.reset();
                }}
              />
            )}
            <Box sx={searchIcon}>{filterStates()}</Box>
          </Box>
        )}
      </Box>

      {isPharmacist && (
        <DashboardFilter
          filterState={filterState}
          setFilterState={setFilterState}
          ref={ref}
        />
      )}

      <Box sx={navigateDateContainer}>
        <Box sx={navigateDate}>

          <Box id="calendarViewDropdown" component="div" sx={navigationToggle}>
            <Typography variant="subtitle1" sx={navigationToggleLabel}>
              {formattedDate}
            </Typography>
            {mapToggle ? (
              <DayCalendar ref={dayCalendarRef} setFiltered={setFilterState} />
            ) : (
              <YearCalendar />
            )}
          </Box>
          <NavigateButtons />

        </Box>

        <Box component="div" sx={toggleViewContainer}>
          {shouldShowSelectButton && (
            <Box sx={multipleSelectButtons}>
              <CustomButton
                startIcon={<RefreshIcon />}
                customClass={resetButton}
                onClick={handleReset}
                disabled={disableReset}
                variant="outlined"
                label={strings.reset}
              />

              <CustomButton
                customClass={resetButton}
                startIcon={<PlusIcon />}
                onClick={isAdmin ? showConfirmModal(handleSave) : handleSave}
                disabled={disableSave}
                label={strings.save}
              />

              {isAdmin && (
                <CustomButton
                  customClass={resetButton}
                  startIcon={<DeleteIcon stroke={ThemeConstants.secondary.main} />}
                  onClick={deleteSelectedAvailabilities}
                  disabled={disableDelete}
                  label={strings.delete}
                  color="error"
                />
              )}
            </Box>
          )}

          {mapToggle && (
            <CustomCheckbox
              label={strings.pharmaciesNearby}
              name="toggle"
              value={nearbyProps.showNearBy}
              labelClass={classes.checkboxLabel}
              handleChange={checkForLocation}
              id="toggle"
            />
          )}

          {showMap && <CalendarMapToggle disabled={enableAdd} />}
          {hideMonthWeek && (
            <CustomDropdown
              id="monthWeek"
              name="calendarState"
              formik={formik}
              disabled={enableAdd}
              customClass={dropdownCustomHeight}
              menuItems={MonthWeek()}
              placeholder={Constants.null}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Toolbar;
