import { SxProps, Theme } from '@mui/system';

const styles = {
  title: {
    fontSize: 22,
    fontWeight: '600',
    color: 'common.grey850',
    verticalAlign: 'middle',
  },
  numberOfIds: {
    ml: '6px',
    fontSize: 16,
    color: 'grey.600',
  },
  notificationTitle: {
    fontWeight: '600',
    fontSize: 16,
    color: 'common.gray',
  },
  filterMargin: {
    ml: '24px',
  },
  dateMargin: {
    ml: '12px',
  },
  groupDropdown: {
    maxWidth: 320,
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
