import { SxProps, Theme } from '@mui/system';

const classes = {
  avatarSection: {
    borderRadius: '10px',
    borderBottom: '5px solid',
    borderColor: 'common.borderInput',
  },

  container: {
    marginBottom: '16px',
  },

  title: {
    color: '#161C2C',
    fontSize: 20,
    fontWeight: 600,
  },

  detailContainer: {
    flex: 1,
    justifyContent: 'center',
  },

  menuContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },

  codeFont: {
    color: '#333',
    fontSize: 14,
  },

} satisfies Record<string, SxProps<Theme>>;

export default classes;
