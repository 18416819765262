import React from 'react';
import {
  IAllLanguages,
  ISoftware,
  IContact,
  IProfessionalInfo,
  TimeFormat,
  IProvinces,
  IAllRegions,
  IUtils,
} from '@pharmaplan/common';
import {
  dropdownMapper,
  radioButtonMapper,
} from '@pharmaplan/common/helpers/utils';
import Map from '@pharmaplan/common/assets/icons/map.svg';
import { ArrowForward } from '@mui/icons-material';
import {
  Constants,
  daysDropdown,
  telephoneInputMask,
} from '../../../helpers/Constants';
import strings from '../../../localization';
import style from './style';
import { IPharmacistForm, IPharmacyForm, RadioItemS } from '../types';

const year = new Date().getFullYear();

const startingYear = 1969;

export const years = Array.from(
  new Array(year - startingYear),
  (val, index) => {
    const value = year - index;

    return { key: value, label: value };
  },
);

export type ICommonContact = typeof commonContact;
export const LanguageLevel = () =>
  [
    {
      key: '0',
      label: strings.weakLevel,
    },
    {
      key: '1',
      label: strings.goodLevel,
    },
    {
      key: '2',
      label: strings.fluentLevel,
    },
  ];

export const languageLevelMap = () =>
  LanguageLevel().map((item) =>
    item.label);

const commonContact = (type: string, requireLabel: boolean) =>
  [
    {
      name: `${type}ContactName`,
      type: Constants.formInputType.text,
      key: 'contactName',
      label: strings.name,
      requireLabel,
      xs: 6,
    },
    {
      name: `${type}ContactPhone`,
      type: Constants.formInputType.masked,
      mask: telephoneInputMask,
      key: 'contactPhone',
      maxLength: 12,
      requireLabel,
      label: strings.phone,
      xs: 6,
    },
    {
      name: `${type}ContactPhoneExt`,
      type: Constants.formInputType.text,
      key: 'contactPhoneExt',
      label: strings.phoneExt,
      xs: 6,
    },
    {
      name: `${type}ContactMobile`,
      type: Constants.formInputType.masked,
      key: 'contactMobile',
      maxLength: 12,
      label: strings.mobile,
      mask: telephoneInputMask,
      xs: 6,
    },
    {
      name: `${type}ContactEmail`,
      type: Constants.formInputType.text,
      key: 'contactEmail',
      requireLabel,
      label: strings.email,
      xs: 6,
    },
  ];

export const radioButtonConfig = [
  {
    id: TimeFormat.twentyFourHours,
    desc: strings.twentyFourHours,
  },
  {
    id: TimeFormat.twelveHours,
    desc: strings.twelveHours,
  },
];

export const pharmacyField = ({
  languages,
  softwares,
  provinces,
  isSelfService,
  handleDelete,
  setSelected,
}: {
  languages: IAllLanguages[];
  softwares: ISoftware[];
  provinces: IProvinces[];
  isSelfService: boolean;
  handleDelete: () => void;
  setSelected?: React.Dispatch<React.SetStateAction<string>>;
}): IPharmacyForm =>
  ({
    pharmacyInfo: [
      {
        hideSave: true,
        form: () =>
          [
            {
              label: strings.ownerCompanyName,
              type: Constants.formInputType.label,
              xs: 6,
              name: 'name',
            },
            {
              label: strings.banner,
              type: Constants.formInputType.label,
              name: 'logo',
              image: true,
              xs: 6,
            },
            {
              label: strings.bannerNumber,
              type: Constants.formInputType.label,
              xs: 6,
              name: 'number',
            },
          ],
      },
      {
        title: strings.general.toUpperCase(),
        hideSave: true,
        form: () =>
          [
            {
              label: strings.loginEmail,
              type: Constants.formInputType.changeEmail,
              xs: 6,
              name: 'email',
            },
            {
              label: strings.software,
              type: Constants.formInputType.dropdown,
              xs: 6,
              name: 'softwareId',
              id: 'softwareId',
              menuItems: dropdownMapper(softwares, 'softwareId', 'name'),
            },
            {
              label: strings.preferredLanguage,
              type: Constants.formInputType.dropdown,
              xs: 6,
              name: 'languageId',
              id: 'languageId',
              menuItems: dropdownMapper(languages, 'languageId', 'name'),
            },
            isSelfService && {
              label: strings.hourlyRate,
              type: Constants.formInputType.text,
              suffix: '$',
              maxLength: 6,
              xs: 6,
              name: 'hourlyRate',
              id: 'hourlyRate',
            },
            {
              label: strings.preferredTime,
              type: Constants.formInputType.radio,
              name: 'timeFormat',
              id: 'timeFormat',
              radioItems: radioButtonMapper(radioButtonConfig, 'id', 'desc'),
            },
          ],
      },
      {
        title: strings.weekdays,
        type: 'multiple',
        hideSave: true,
        form: () =>
          [
            {
              label: strings.volume,
              type: Constants.formInputType.text,
              xs: 2,
              requireLabel: true,
              name: 'weekVolume',
              id: 'weekVolume',
            },
            {
              label: strings.pharmacist,
              type: Constants.formInputType.opreation,
              xs: 5,
              requireLabel: true,
              suffix: 'week',
              name: 'Volume',
              opreationType: 'Pharmacists',
              id: 'weekendVolume',
            },
            {
              label: strings.technicians,
              type: Constants.formInputType.opreation,
              requireLabel: true,
              xs: 5,
              opreationType: 'Techs',
              suffix: 'week',
              name: 'Volume',
            },
          ],
      },
      {
        title: strings.weekends,
        type: 'multiple',
        form: () =>
          [
            {
              label: strings.volume,
              type: Constants.formInputType.text,
              xs: 2,
              name: 'weekendVolume',
              requireLabel: true,
              id: 'weekendVolume',
            },
            {
              label: strings.pharmacist,
              type: Constants.formInputType.opreation,
              xs: 5,
              suffix: 'weekend',
              requireLabel: true,
              name: 'Volume',
              opreationType: 'Pharmacists',
            },
            {
              label: strings.technicians,
              type: Constants.formInputType.opreation,
              requireLabel: true,
              xs: 5,
              opreationType: 'Techs',
              suffix: 'weekend',
              name: 'Volume',
            },
            {
              name: 'deleteUser',
              label: strings.deleteMyAccount,
              type: Constants.formInputType.button,
              style: style.deleteButton,
              onClick: handleDelete,
            },
          ],
      },
    ],
    coordinates: [
      {
        title: strings.contacts.toUpperCase(),
        type: 'multiple',
        hideSave: true,
        form: () =>
          [
            {
              key: 'phone',
              type: Constants.formInputType.masked,
              mask: telephoneInputMask,
              name: 'phone',
              label: strings.pharmacyPhone,
              requireLabel: true,
              maxLength: 12,
              xs: 6,
            },
            {
              key: 'fax',
              type: Constants.formInputType.masked,
              mask: telephoneInputMask,
              name: 'fax',
              label: strings.pharmacyFax,
              maxLength: 12,
              xs: 6,
            },
            {
              key: 'emergencyPhone',
              type: Constants.formInputType.masked,
              mask: telephoneInputMask,
              requireLabel: true,
              name: 'emergencyPhone',
              label: strings.emergencyPhone,
              maxLength: 12,
              xs: 6,
            },
          ],
      },
      {
        title: strings.coordinates.toUpperCase(),
        form: () =>
          [
            isSelfService && {
              label: strings.mapButtonLabel,
              fieldLabel: strings.pharmacyLocation,
              type: Constants.formInputType.mapButton,
              xs: 6,
              name: 'pharmacyLocation',
              id: 'pharmacyLocation',
              requireLabel: true,
              fullWidth: true,
              startIcon: <img src={Map} alt="mapIcon" width="20" height="20" />,
              endIcon: <ArrowForward />,
              onClick: () =>
                setSelected?.('myLocationOnMap'),
              style: style.mapButton,
              helperTextStyle: style.mapButtonHelperTextStyle,
            },
            {
              key: 'address',
              type: Constants.formInputType.text,
              requireLabel: true,
              name: 'address',
              label: strings.address,
              xs: 6,
            },
            {
              key: 'city',
              type: Constants.formInputType.text,
              name: 'city',
              requireLabel: true,
              label: strings.city,
              xs: 6,
            },
            {
              key: 'postalCode',
              type: Constants.formInputType.text,
              requireLabel: true,
              name: 'postalCode',
              label: strings.postalCode,
              maxLength: 10,
              xs: 6,
            },
            {
              key: 'province',
              type: Constants.formInputType.dropdown,
              name: 'province',
              disabled: true,
              menuItems: dropdownMapper(provinces, 'key', 'name'),
              label: strings.province,
              xs: 6,
            },
            {
              key: 'region',
              type: Constants.formInputType.text,
              name: 'region',
              disabled: true,
              label: strings.region,
              maxLength: 10,
              xs: 6,
            },
          ],
      },
    ],
    myLocationOnMap: {
      title: strings.pharmacyLocation,
      type: 'map',
      back: 'coordinates',
      form: () =>
        [{}],
    },
    primary: {
      title: strings.primary,
      type: 'single',
      form: () =>
        commonContact('primary', true),
    },
    secondary: {
      title: strings.secondary,
      type: 'single',
      form: () =>
        commonContact('secondary', false),
    },
    accounting: {
      title: strings.accounting,
      type: 'single',
      form: () =>
        commonContact('accounting', true),
    },
  });

export const pharmacistFields = ({
  languages,
  proInfoData,
  contact,
  proUtils,
  regions,
  isSelfService,
  handleDelete,
  setSelected,
}: {
  languages: IAllLanguages[];
  proInfoData: IProfessionalInfo;
  contact: IContact;
  proUtils: IUtils;
  regions: IAllRegions;
  isSelfService: boolean;
  handleDelete: () => void;
  setSelected?: React.Dispatch<React.SetStateAction<string>>;
}): IPharmacistForm =>
  ({
    general: {
      title: strings.general.toUpperCase(),
      type: 'single',
      form: () =>
        [
          {
            name: 'firstName',
            label: strings.firstName,
            requireLabel: true,
            type: Constants.formInputType.text,
            xs: 6,
          },
          {
            name: 'lastName',
            label: strings.lastName,
            requireLabel: true,
            type: Constants.formInputType.text,
            xs: 6,
          },
          {
            name: 'email',
            label: strings.email,
            type: Constants.formInputType.changeEmail,
            xs: 6,
          },
          {
            name: 'prefferedDistanceRange',
            label: strings.preferredDistanceRadius,
            suffix: 'Km',
            type: Constants.formInputType.text,
            xs: 6,
          },
          {
            label: strings.preferredLanguage,
            type: Constants.formInputType.dropdown,
            xs: 6,
            requireLabel: true,
            name: 'preferdLanguage',
            id: 'preferdLanguage',
            menuItems: dropdownMapper(languages, 'languageId', 'name'),
          },
          {
            label: strings.preferredTime,
            type: Constants.formInputType.radio,
            name: 'timeFormat',
            id: 'timeFormat',
            radioItems: radioButtonMapper(
              radioButtonConfig,
              'id',
              'desc',
            ) as RadioItemS[],
          },
          {
            name: 'uploadResume',
            label: strings.uploadYourResume,
            type: Constants.formInputType.upload,
          },
          {
            name: 'myGeographicAvailability',
            label: strings.myGeographicAvailability,
            xs: 6,
            type: Constants.formInputType.button,
            style: style.alignleft,
            onClick: () =>
              setSelected?.('geographicAvailability'),
          },
          {
            name: 'label',
            type: Constants.formInputType.label,
            style: style.label,
            xs: 6,
          },
          {
            name: 'deleteUser',
            label: strings.deleteMyAccount,
            type: Constants.formInputType.button,
            style: style.deleteButton,
            onClick: handleDelete,
          },
        ],
    },
    geographicAvailability: {
      title: strings.myGeographicAvailability.toUpperCase(),
      type: 'single',
      back: 'general',
      form: () =>
        [
          {
            label: strings.firstName,
            requireLabel: true,
            type: Constants.formInputType.accordion,
            xs: 12,
            items: regions,
          },
        ],
    },
    professionalInfo: {
      title: strings.profissonalInfo.toUpperCase(),
      type: 'single',
      form: () =>
        [
          {
            label: strings.licenseNo,
            type: Constants.formInputType.text,
            xs: 6,
            name: 'licenseNumber',
            id: 'licenseNumber',
            requireLabel: true,
          },
          {
            label: strings.pharmacistCapacity,
            type: Constants.formInputType.dropdown,
            xs: 6,
            name: 'pharmacistCapacityId',
            id: 'pharmacistCapacityId',
            menuItems: dropdownMapper(
              proInfoData?.pharmacistCapacities,
              'pharmacistCapacityId',
              'description',
            ),
            requireLabel: true,
          },
          {
            label: strings.yearLicensed,
            type: Constants.formInputType.dropdown,
            xs: 6,
            name: 'graduationYear',
            id: 'graduationYear',
            menuItems: years,
            requireLabel: true,
          },
          {
            label: strings.yearsOfExperience,
            xs: 6,
            key: 'yearsOfExperience',
            id: 'yearsOfExperience',
            type: Constants.formInputType.text,
            name: 'yearsOfExperience',
            disabled: true,
          },
          {
            label: strings.englishLevel,
            type: Constants.formInputType.dropdown,
            xs: 6,
            name: 'englishLevel',
            id: 'englishLevel',
            menuItems: LanguageLevel(),
          },
          {
            label: strings.frenchLevel,
            type: Constants.formInputType.dropdown,
            xs: 6,
            name: 'frenchLevel',
            id: 'frenchLevel',
            menuItems: LanguageLevel(),
          },
          {
            label: strings.otherLanguage,
            type: Constants.formInputType.text,
            xs: 6,
            name: 'otherLanguages',
            id: 'otherLanguages',
          },
          {
            label: strings.areYouIncorporated,
            type: Constants.formInputType.radio,
            xs: 6,
            name: 'incorporated',
            id: 'incorporated',
            radioItems: [
              { label: strings.yes, value: true },
              { label: strings.no, value: false },
            ],
          },
        ],
    },
    software: {
      title: strings.software.toUpperCase(),
      type: 'table',
      form: () =>
        ({
          options: [
            { key: '0', label: '0%' },
            { key: '1', label: '25%' },
            { key: '2', label: '50%' },
            { key: '3', label: '75%' },
            { key: '4', label: '100%' },
          ],
          rows: proUtils?.softwares,
        }),
    },
    contacts: [
      {
        title: strings.contact.toUpperCase(),
        type: 'multiple',
        hideSave: true,
        form: () =>
          [
            isSelfService && {
              label: strings.mapButtonLabel,
              fieldLabel: strings.pharmacistLocation,
              type: Constants.formInputType.mapButton,
              xs: 6,
              name: 'pharmacistLocation',
              id: 'pharmacistLocation',
              requireLabel: true,
              fullWidth: true,
              startIcon: <img src={Map} alt="mapIcon" width="20" height="20" />,
              endIcon: <ArrowForward />,
              onClick: () =>
                setSelected?.('myLocationOnMap'),
              style: style.mapButton,
              helperTextStyle: style.mapButtonHelperTextStyle,
            },
            {
              label: strings.address,
              type: Constants.formInputType.text,
              xs: 6,
              name: 'address',
              id: 'address',
              requireLabel: true,
            },
            {
              label: strings.city,
              type: Constants.formInputType.text,
              xs: 6,
              name: 'city',
              id: 'city',
              requireLabel: true,
            },
            {
              label: strings.postalCode,
              type: Constants.formInputType.text,
              xs: 6,
              name: 'postalCode',
              id: 'postalCode',
              requireLabel: true,
            },
            {
              label: strings.homePhone,
              type: Constants.formInputType.masked,
              mask: telephoneInputMask,
              xs: 6,
              name: 'homePhone',
              id: 'homePhone',
              requireLabel: true,
            },
            {
              label: strings.mobile,
              type: Constants.formInputType.masked,
              mask: telephoneInputMask,
              xs: 6,
              name: 'mobile',
              id: 'mobile',
              requireLabel: true,
            },
            {
              label: strings.province,
              type: Constants.formInputType.text,
              xs: 6,
              disabled: true,
              name: 'province',
              id: 'province',
            },
          ],
      },
      {
        title: strings.preferences.toUpperCase(),
        form: () =>
          [
            {
              label: strings.preferredWorkingDays,
              type: Constants.formInputType.dropdown,
              xs: 6,
              name: 'preferredWorkingDays',
              id: 'preferredWorkingDays',
              multiple: true,
              menuItems: daysDropdown(),
            },
            {
              label: strings.preferredReplacementDuration,
              type: Constants.formInputType.dropdown,
              xs: 6,
              name: 'preferredReplacementDuration',
              id: 'preferredReplacementDuration',
              menuItems: contact?.prefferedReplacementDurations || [],
            },
            {
              label: strings.availabilityReminder,
              type: Constants.formInputType.checkbox,
              xs: 6,
              name: 'avilabilityReminder',
              id: 'avilabilityReminder',
              requireLabel: true,
            },
          ],
      },
    ],
    myLocationOnMap: {
      title: strings.pharmacistLocation,
      type: 'map',
      back: 'contacts',
      form: () =>
        [{}],
    },
  });
