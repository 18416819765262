import React, { useEffect } from 'react';

import { Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';

import {
  getPharmacyAdminPharmacies,
  pharmacyAdminPharmacies,
} from '@pharmaplan/common';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import useUser from '../../../hooks/useUser';
import strings from '../../../localization';

import PharmacyAdminCard from './PharmacyAdminCard';
import styles from './styles';

const PharmacyAdminListings = () => {
  const dispatch = useAppDispatch();
  const pharmacies = useAppSelector(pharmacyAdminPharmacies);
  const { name } = useUser();

  useEffect(() => {
    dispatch(getPharmacyAdminPharmacies());
  }, []);

  return (
    <Paper sx={styles.container}>
      <Box>
        <Typography sx={styles.title}>
          {strings.formatString(strings.welcomeUser, name)}
        </Typography>
        <Typography sx={styles.description}>
          {strings.pharmacyAdminDescription}
        </Typography>
        <Typography sx={styles.subTitle}>{strings.myPharmacies}</Typography>
      </Box>
      {pharmacies.map((item) =>
        (
          <PharmacyAdminCard item={item} />
        ))}
    </Paper>
  );
};

export default PharmacyAdminListings;
