import { SlotInfo } from 'react-big-calendar';
import { DateTime } from 'luxon';

import {
  BookingState,
  getTypeOfUser,
  PharmacistLegendVariant,
  PharmacyLegendVariant,
  RecurrenceActions,
  TypeOfUser,
} from '@pharmaplan/common';

import { ScreenTypes } from '../helpers/Constants';
import { renderScreen, setOpen } from '../actions/drawerActions';
import { scrollToTop } from '../helpers/Functions';
import ICalendarEventType from '../types/ICalendarEventType';

import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import useSelfService from './useSelfService';

interface IOpenDrawer {
  eventIds?: Array<string>;
  recurrenceType?: RecurrenceActions;
  recurrenceId?: string;
  type?: ICalendarEventType;
  bookingState?: BookingState;
}

const { pharmacy } = TypeOfUser;
const { availability } = PharmacistLegendVariant;
const { posted, booked, requested } = PharmacyLegendVariant;

const {
  workshiftSummary,
  updateWorkshift,
  updateAvailability,
  serviceWorkshiftDetails,
  addWorkshift,
  addAvailability,
} = ScreenTypes;

const useDrawerNavigation = () => {
  const dispatch = useAppDispatch();
  const { isSelfService } = useSelfService();

  const userType = useAppSelector(getTypeOfUser);

  const isPharmacy = userType === pharmacy;

  const handleWorkshiftScreen = {
    screenType: isSelfService ? workshiftSummary : updateWorkshift,
    screenNumber: isSelfService ? 1 : 2,
  };

  const { screenType, screenNumber } = handleWorkshiftScreen;

  const handleScreenTypes = (type: ICalendarEventType) => {
    switch (type) {
      case availability:
        return {
          screenType: updateAvailability,
          screenNumber: 1,
        };
      case posted:
        return {
          screenType,
          screenNumber,
        };
      case requested:
        return isPharmacy
          ? {
            screenType,
            screenNumber,
          }
          : {
            screenType: serviceWorkshiftDetails,
            screenNumber: 1,
          };
      case booked:
        return isPharmacy
          ? {
            screenType: workshiftSummary,
            screenNumber: 1,
          }
          : {
            screenType: serviceWorkshiftDetails,
            screenNumber: 1,
          };
      default:
        return {
          screenType: serviceWorkshiftDetails,
          screenNumber: 1,
        };
    }
  };

  const openAddDrawer = (slot: SlotInfo) => {
    scrollToTop();
    dispatch(setOpen(true));
    dispatch(
      renderScreen({
        date:
          DateTime.fromJSDate(slot.start).toISO({
            includeOffset: false,
          }) ?? '',
        screenType: isPharmacy ? addWorkshift : addAvailability,
        screenNumber: 1,
      }),
    );
  };

  const openDrawer = ({
    eventIds,
    recurrenceType,
    recurrenceId,
    type,
    bookingState,
  }: IOpenDrawer) => {
    scrollToTop();

    dispatch(
      renderScreen({
        recType: recurrenceType,
        recurrenceId,
        type,
        eventIds,
        bookingState,
        ...handleScreenTypes(type ?? PharmacistLegendVariant.workshift),
      }),
    );
    dispatch(setOpen(true));
  };

  return { openDrawer, openAddDrawer };
};

export default useDrawerNavigation;
