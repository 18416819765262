import React, { FC, useEffect } from 'react';

import { Box, Stack } from '@mui/system';
import { FormikValues, useFormik } from 'formik';
import { DateTime } from 'luxon';

import {
  adminActions,
  adminCreateAvailabilities,
  recurrenceType,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';
import {
  MomentTimeFormats,
  serverDateKey,
} from '../../../../helpers/Constants';
import { getZeroHourDate } from '../../../../helpers/Functions';
import {
  appendTimeToDate,
  setHour,
} from '../../../../helpers/workshift/functions';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import strings from '../../../../localization';
import CustomCheckbox from '../../../common/CustomCheckbox';
import CustomDatePicker from '../../../common/CustomDatePicker';
import DateTimeRange from '../../../common/DateTimeRange';

import styles from './styles';
import Validator from './validator';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { showSuccess } from '../../Profile/Pharmacist/PharmacistViewProfile/helper';
import CustomTextField from '../../../common/CustomTextField';
import genericClasses from '../../../../theme/GenericClasses';
import { setFooter } from '../../../../actions/drawerActions';
import { getDisableAvailability } from '../../../../selectors/calendarSelector';

interface IAdminCreateAvailability {
  pharmacistId: string;
  pharmacistName: string;
}

const successAction = adminActions.createAvailabilities;

const AdminCreateAvailability: FC<IAdminCreateAvailability> = ({
  pharmacistId,
  pharmacistName,
}) => {
  const dispatch = useAppDispatch();
  const disabledButton = useAppSelector(getDisableAvailability);
  const luxonDate = DateTime.now();

  const createSuccess = useAppSelector((state) =>
    successSelector([successAction], state));

  const handleSubmit = (values: FormikValues) => {
    const { startDate, endDate, selectedDate } = values ?? {};

    const appendToDate = (time: string) =>
      appendTimeToDate(selectedDate, time).toFormat(MomentTimeFormats.fullDate);

    dispatch(
      adminCreateAvailabilities({
        pharmacistId,
        model: {
          startDate: appendToDate(startDate),
          endDate: appendToDate(endDate),
          recurrenceType: recurrenceType.Never,
          notes: '',
          weekDays: null,
          weekNumber: 0,
          day: 1,
          month: 1,
        },
      }),
    );
  };

  useEffect(() => {
    if (createSuccess) {
      dispatch(resetStatus([successAction]));
      showSuccess(dispatch, strings.availabilityCreatedSuccessfully);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess]);

  const formik = useFormik({
    initialValues: {
      pharmacistName,
      startDate: null,
      endDate: null,
      selectedDate: luxonDate,
      allDay: false,
    },
    validationSchema: Validator(),
    onSubmit: handleSubmit,
  });

  const { allDay } = formik.values ?? {};

  const handleNegativeDates = () => {
    formik.setFieldValue('endTime', null);
  };

  const serverToday = getZeroHourDate(
    sessionStorage.getItem(serverDateKey) ?? '',
  );

  const handleAllDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('allDay', e.target.checked);
    if (e.target.checked) {
      formik.setFieldValue('startDate', setHour(luxonDate, 8));
      formik.setFieldValue('endDate', setHour(luxonDate, 22));
    }
  };

  useEffect(() => {
    dispatch(
      setFooter({
        show: true,
        pagination: {
          show: false,
        },
        buttons: {
          show: true,
          primaryButton: {
            label: strings.submit,
            disabled: !disabledButton,
            onClick: formik.handleSubmit,
          },
        },
      }),
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledButton]);

  return (
    <Stack sx={styles.container}>
      <CustomTextField
        label={strings.pharmacist}
        formik={formik}
        name="pharmacistName"
        id="pharmacistName"
        disabled
      />
      <CustomDatePicker
        readOnly
        minDate={serverToday}
        formik={formik}
        name="selectedDate"
        label={strings.selectedDate}
        error=""
      />
      <Box sx={styles.checkboxContainer}>
        <CustomCheckbox
          id="id"
          value={allDay}
          name="allDay"
          handleChange={handleAllDay}
          label={strings.allDay}
        />
      </Box>
      <Box component="div" sx={genericClasses.timeContainer}>
        <DateTimeRange
          startPlaceholder="00:00"
          customStartHandler={handleNegativeDates}
          disabled={allDay}
          removeMargin
          horizontal={false}
          endPlaceholder="00:00"
          startNameLabel={strings.startTime}
          endNameLabel={strings.endTime}
          formik={formik}
          startName="startDate"
          endName="endDate"
          allowValidOnly
        />
      </Box>
    </Stack>
  );
};

export default AdminCreateAvailability;
