import ThemeConstants from '../../../../theme/ThemeConstants';

const classes = {
  card: {
    display: 'flex',
    paddingTop: '22px',
  },

  cardContent: {
    display: 'flex',
    flexDirection: 'row',
  },

  image: {
    height: '75px',
    width: '75px',
    border: `2px solid ${ThemeConstants.common.border}`,
  },

  avatarContainer: {
    display: 'flex',
    position: 'relative',
  },

  favIconContainer: {
    position: 'absolute',
    top: 50,
    left: 50,
    zIndex: 1,
  },

  infoContainer: {
    flex: 1,
    marginLeft: '16px',
  },

  title: {
    fontWeight: 600,
    lineHeight: '30px',
    fontSize: '20px',
    color: 'common.grey850',
    marginRight: '10px',
  },

  addressText: {
    fontSize: '14px',
    fontWeight: 400,
    color: 'common.grey850',
  },

  date: {
    lineHeight: '21px',
    fontSize: '14px',
    fontWeight: 400,
    color: 'common.grey850',
  },

  timeChip: {
    marginTop: '10px',
  },

  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
  },

  footerPart: {
    flex: 1,
    marginTop: '16px',
    justifyContent: 'center',
  },

  itemTitle: {
    paddingBottom: '4px',
    fontSize: '12px',
  },

  itemValue: {
    fontSize: '14px',
    fontWeight: 500,
  },

  counterValue: {
    fontWeight: 600,
  },

  strike: {
    textDecoration: 'line-through',
    paddingRight: '6px',
  },

  chipContainer: {
    marginBottom: '16px',
  },

  timeStrike: {
    textDecorationLine: 'line-through',
    fontSize: '14px',
    fontWeight: 400,
    color: 'common.grey850',
  },

  cardHover: {
    cursor: 'pointer',
  },
};

export default classes;
