import { SxProps, Theme } from '@mui/system';
import ThemeConstants from '../../../../theme/ThemeConstants';

const classes = {
  container: {
    marginBottom: '5px',
  },

  shadowStyle: {
    boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
  },

  notVisited: {
    backgroundColor: 'notification.notVisited',
  },

  secondaryText: {
    fontSize: '12px',
    fontWeight: 500,
    color: 'common.grey850',
    paddingLeft: '4px',
  },

  dateText: {
    fontSize: '12px',
    fontWeight: 500,
    color: 'grey.500',
    paddingLeft: '4px',
  },

  confirmContainer: {
    display: 'inline-flex',
    backgroundColor: 'notification.confirmBg',
    borderRadius: '4px',
    padding: '5px',
    marginTop: '8px',
  },

  confirmText: {
    fontSize: '12px',
    fontWeight: 400,
    color: 'common.grey850',
    paddingLeft: '4px',
  },

  secondaryContainer: {
    display: 'flex',
    marginTop: '8px',
  },

  secondaryIconContainer: {
    marginTop: '2.5px',
  },

  alignCenter: {
    alignItems: 'center',
  },

  itemContainer: {
    display: 'flex',
  },

  image: {
    objectFit: 'contain',
    border: `1px solid ${ThemeConstants.common.border}`,
  },

  ticker: {
    marginTop: '2px',
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
