import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import NotificationsController from '../controllers/NotificationsController';
import { statusDispatch } from './globalActions';
import {
  INotificationListParams,
  INotificationsData,
  genericNotifType,
} from '../models/NotificationsModel';
import { ITEMS_PER_PAGE } from '../helpers/Constants';

export const notificationActions = {
  getCount: 'notifications/get/count',
  getNotificationsList: 'notifications/get/list',
  getNotificationsListPagination: 'notifications/get/list/page',
  getNotification: 'notifications/get',
  setInitialNotification: 'notifications/set/initial',
  markAsRead: 'notifications/markAsRead',
  deleteNotification: 'notifications/delete',
};

export const getNotificationsCount = createAsyncThunk(
  notificationActions.getCount,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await NotificationsController.getNotificationsCount();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const getNotificationsList = createAsyncThunk(
  notificationActions.getNotificationsList,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await NotificationsController.getNotificationsList({
        page: 1,
        itemPerPage: ITEMS_PER_PAGE,
      });
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const getNotificationsListPagination = createAsyncThunk(
  notificationActions.getNotificationsListPagination,
  async (params: INotificationListParams, { rejectWithValue }) => {
    try {
      const resp = await NotificationsController.getNotificationsList(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const getNotification = createAsyncThunk(
  notificationActions.getNotification,
  async (id: string, { rejectWithValue }) => {
    try {
      const resp = await NotificationsController.getNotification(id);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const setInitialNotification = createAction<
  INotificationsData | genericNotifType | null
>(notificationActions.setInitialNotification);

export const markAsRead = createAsyncThunk(
  notificationActions.markAsRead,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await NotificationsController.markAsRead();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const deleteNotification = createAsyncThunk(
  notificationActions.deleteNotification,
  async (notificationId: string, { rejectWithValue }) => {
    try {
      const resp = await NotificationsController.deleteNotification(
        notificationId
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      rejectWithValue(err);
    }
  }
);
