import React, { FC, useEffect } from 'react';

import { SxProps, Box } from '@mui/material';

import {
  AdminLegendVariant,
  IAvailableWorkshiftData,
  adminActions,
  adminEvent,
  getAdminEvent,
  logos,
  userPreferredTimeFormat,
  resetStatus,
} from '@pharmaplan/common';
import { ReactComponent as Location } from '../../../assets/svg/location.svg';
import CustomAvatarSection from '../../common/CustomAvatarSection';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import {
  setBack,
  setDrawerContentPadding,
  setFooterShow,
  setTitle,
} from '../../../actions/drawerActions';
import ThemeConstants from '../../../theme/ThemeConstants';
import useAvailableWorkshiftActions from '../../../hooks/AvailableWorkshift/useAvailableWorkshiftActions';
import DrawerOutOfDateTitle from '../../common/DrawerOutOfDateTitle';
import { useAppSelector } from '../../../hooks/useAppSelector';
import strings from '../../../localization';

import { workshiftListBuilder } from './helpers';
import styles from './styles';
import AdminHourlyRateFooter from '../../common/AdminHourlyRateFooter';

export const LocationText: FC<{
  label: string | React.ReactNode;
  containerStyle?: SxProps;
}> = ({ label, containerStyle }) =>
  (
    <Box sx={[styles.locationContainer, containerStyle] as SxProps}>
      <Location
        fill={ThemeConstants.grey[600]}
        height={13}
        width={10}
        style={styles.locationIcon}
      />
      {label}
    </Box>
  );

interface IAvailableWorkshift {
  date: string;
}

const AvailableWorkshifts: FC<IAvailableWorkshift> = ({ date }) => {
  const dispatch = useAppDispatch();

  const allLogos = useAppSelector(logos);
  const timeFormat = useAppSelector(userPreferredTimeFormat);
  const availableWorkshifts = useAppSelector(adminEvent);

  const { actions, goToViewDetails } = useAvailableWorkshiftActions();

  useEffect(() => {
    const title = (
      <DrawerOutOfDateTitle
        dateLabel={strings.availableWorkshiftFormatted}
        date={date}
      />
    );
    dispatch(setBack(null));
    dispatch(setTitle({ title }));
    dispatch(setDrawerContentPadding('0px'));
    dispatch(setFooterShow(false));
    dispatch(
      getAdminEvent({
        type: AdminLegendVariant.posted,
        date: date.split('T')[0],
      }),
    );
    return () => {
      dispatch(resetStatus([adminActions.getAdminEvent]));
      dispatch(setDrawerContentPadding('16px'));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const events = workshiftListBuilder(
    availableWorkshifts as IAvailableWorkshiftData[],
    allLogos,
    timeFormat,
  );

  return (
    <>
      {events.map((item) => {
        const {
          config,
          title,
          avatar,
          workshiftId,
          pharmacistHourlyRate,
          pharmacyHourlyRate,
          pharmacyId,
          startDate,
          endDate,
        } = item ?? {};

        return (
          <Box key={workshiftId} sx={styles.buttonContainer}>
            <CustomAvatarSection
              customMenuContainerStyle={styles.menu}
              successAction={adminActions.getAdminEvent}
              config={config}
              title={title}
              avatar={avatar}
              showFullImage={false}
              onClick={() =>
                goToViewDetails({ workshiftId, date, pharmacyId })}
              imageSuccessAction={adminActions.getAdminEvent}
              menuActions={actions({
                id: workshiftId,
                startDate,
                endDate,
                pharmacyId,
              })}
            />
            <Box sx={styles.footerContainer}>
              <AdminHourlyRateFooter
                pharmacyHourlyRate={pharmacyHourlyRate as number}
                pharmacistHourlyRate={pharmacistHourlyRate as number}
                successAction={adminActions.getAdminEvent}
              />
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default AvailableWorkshifts;
