import { Box } from '@mui/system';
import React, { FC, useEffect } from 'react';

import {
  AdminLegendVariant,
  PharmacistLegendVariant,
  getPharmacyDetails,
  pharmacyDetails,
} from '@pharmaplan/common';

import PharmacyCard from '../../../SelfService/PharmacyCard';
import strings from '../../../../localization';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { ScreenTypes } from '../../../../helpers/Constants';
import {
  serverToday,
} from '../../../../helpers/Functions';

import {
  ICustomBack,
  renderScreen,
  setBack,
  setButtons,
  setFooterShow,
  setPagination,
} from '../../../../actions/drawerActions';
import useViewDetailsPharmacy from '../../../../hooks/Admin/useViewDetailsPharmacy';
import AdminHourlyRateFooter from '../../../common/AdminHourlyRateFooter';
import useGetItems from '../../../../hooks/useGetItems';
import { pharmacyProfileConfig } from '../../Profile/Pharmacy/PharmacyViewProfile/helper';
import CollapsibleCard from '../../../common/CollapsibleCard';
import ProfileContent from '../../Profile/ProfileContent';

import styles from './styles';

interface IViewDetails {
  workshiftId: string;
  type?: AdminLegendVariant;
  back?: ICustomBack;
  pharmacyId: string;
}

const ViewDetails: FC<IViewDetails> = ({
  workshiftId,
  pharmacyId,
  type = AdminLegendVariant.posted,
  back,
}) => {
  const dispatch = useAppDispatch();
  const { details: event, api, successAction } = useViewDetailsPharmacy(type);
  const {
    getImage,
    getLanguageName,
    getSoftwareName,
    getSelectedContactPreferences,
  } = useGetItems();

  const details = useAppSelector(pharmacyDetails);

  const isBooked = type === AdminLegendVariant.booked;
  const isRequested = type === AdminLegendVariant.requested;

  const { pharmacy, softwares, contactPreferences } = details ?? {};
  const { softwareId, selectedContactPreferences } = pharmacy ?? {};

  const {
    logoId,
    languageId,
    pharmacyHourlyRate,
    startDate,
    softwareName,
  } = (event as any) ?? {};

  const avatar = getImage(logoId);
  const language = getLanguageName(languageId);
  const software = getSoftwareName(softwares, softwareId);
  const selectedPreferences = getSelectedContactPreferences(
    contactPreferences,
    selectedContactPreferences,
  );

  const isPast = (serverToday().toISO({ includeOffset: false }) ?? '') > startDate;

  const goToEditWorkshift = () => {
    dispatch(
      renderScreen({
        screenNumber: 2,
        screenType: ScreenTypes.availableWorkshift,
        eventId: workshiftId,
      }),
    );
  };

  useEffect(() => {
    dispatch(api(workshiftId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workshiftId]);

  useEffect(() => {
    if (pharmacyId) {
      dispatch(getPharmacyDetails(pharmacyId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacyId]);

  useEffect(() => {
    if (!(isBooked || isRequested)) {
      dispatch(
        setButtons({
          show: true,
          primaryButton: {
            label: strings.editWorkshift,
            onClick: goToEditWorkshift,
          },
        }),
      );
    }

    dispatch(setBack(back ?? null));
    dispatch(setFooterShow(!(isBooked || isRequested) && !isPast));
    dispatch(setPagination({ show: false }));

    return () => {
      dispatch(setBack(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  return (
    <Box sx={styles.container}>
      <PharmacyCard
        hideBorder={isBooked}
        showPharmacyName
        type={PharmacistLegendVariant.workshift}
        CustomContent={isBooked ? <div style={styles.emptyDiv} /> : null}
        event={{
          ...event,
          hourlyRate: pharmacyHourlyRate,
          logoImage: avatar,
        }}
        successAction={successAction}
        CustomFooter={(
          <AdminHourlyRateFooter
            pharmacyHourlyRate={pharmacyHourlyRate}
            software={softwareName}
            successAction={successAction}
          />
        )}
      />
      {pharmacyProfileConfig({
        details,
        language,
        software,
        successAction,
        selectedPreferences,
      }).map((item) => {
        const { key, title, keepExpanded, cardContent, customComponent } = item ?? {};

        return (
          <CollapsibleCard
            key={key}
            title={title}
            keepExpanded={keepExpanded}
            content={(
              <ProfileContent
                content={cardContent}
                customComponent={customComponent}
                successAction={successAction}
              />
            )}
          />
        );
      })}
    </Box>
  );
};

export default ViewDetails;
