import React, { FC } from 'react';

import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';

import {
  adminReports,
  searchedPharmacists,
  TypeOfUser,
} from '@pharmaplan/common';
import { FormikProps } from 'formik';
import CustomDateRange from '../../../common/CustomDateRange';
import CustomDropdown from '../../../common/CustomDropdown';
import FilterSubmitButtons from '../../../common/FilterSubmitButtons';
import CustomAutocomplete from '../../../common/CustomAutocomplete';
import { toOptions } from '../../ExpressBooking/ExpressBooking';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import useAdminReportsHandler from '../../../../hooks/AdminReports/useAdminReportsHandler';
import { ReactComponent as ExportPDFIcon } from '../../../../assets/svg/exportPDFIcon.svg';
import { ReactComponent as DisabledExportPDFIcon } from '../../../../assets/svg/disabledExportIcon.svg';
import strings from '../../../../localization';

import styles from './style';
import useAdminReportsPDF from '../../../../hooks/AdminReports/useAdminReportsPDF';
import useAutoCompleteScroll, {
  AutoCompleteScrollTypes,
} from '../../../../hooks/useAutoCompleteScroll';

interface IAdminReportsHeader {
  formik: FormikProps<any>;
  handleReset: () => void;
}

const AdminReportsHeader: FC<IAdminReportsHeader> = ({
  formik,
  handleReset,
}) => {
  const { menuItems, isAvailabilities, title } = useAdminReportsHandler();
  const { exportPDF } = useAdminReportsPDF(formik);
  const { handleSubmit, values } = formik ?? {};

  const { onEndReached, pharmacistListData } = useAutoCompleteScroll(
    AutoCompleteScrollTypes.pharmacist,
  );
  const { pharmacists: formikPharmacists } = formik.values ?? {};

  const { startDate, endDate } = values ?? {};

  const pharmacists = useAppSelector(searchedPharmacists);
  const reports = useAppSelector(adminReports);
  const { data } = reports ?? {};

  const noData = data.length === 0;

  const pharmacistOptions = toOptions(pharmacists);

  const pharmacistOption = formikPharmacists
    ? pharmacistOptions
    : pharmacistListData;

  const placeholder = strings.formatString(
    strings.enterCred,
    `${strings.name}/${strings.email}`,
  );

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Box sx={styles.header}>
        <Typography sx={styles.titleFont}>{title}</Typography>
        <Tooltip title={strings.exportAsAPdf}>
          <Box component="div">
            <IconButton disabled={noData} onClick={exportPDF}>
              {noData ? <DisabledExportPDFIcon /> : <ExportPDFIcon />}
            </IconButton>
          </Box>
        </Tooltip>
      </Box>

      <Grid container lg={9} alignItems="center" xs={12} wrap="nowrap">
        <CustomDateRange
          startName="startDate"
          endName="endDate"
          formik={formik}
          preventPast={false}
        />

        {isAvailabilities && (
          <>
            <Grid item xs={5}>
              <CustomAutocomplete
                customAutoCompleteStyle={styles.autocomplete}
                label={strings.pharmacist}
                idName="pharmacistId"
                infiniteScroll={!formikPharmacists}
                textName="pharmacists"
                autoCompleteName="pharmacistSelected"
                onEndReached={onEndReached(TypeOfUser.pharmacist)}
                placeholder={placeholder as string}
                formik={formik}
                options={pharmacistOption}
              />
            </Grid>
            <Grid item xs={5} sx={styles.container}>
              <CustomDropdown
                label={strings.status}
                menuItems={menuItems}
                formik={formik}
                name="availabilityStatus"
              />
            </Grid>
          </>
        )}

        {!isAvailabilities && (
          <Grid item xs={5} sx={styles.container}>
            <CustomDropdown
              label={strings.replacementType}
              menuItems={menuItems}
              formik={formik}
              name="replacementType"
            />
          </Grid>
        )}
        <FilterSubmitButtons
          disableSubmit={startDate && endDate}
          handleReset={handleReset}
        />
      </Grid>
    </Box>
  );
};

export default AdminReportsHeader;
