import { useEffect, useState } from 'react';

import {
  PharmacistLegendVariant,
  getMatchingAvailabilities,
  selectMatchingAvailabilities,
  userPreferredTimeFormat,
} from '@pharmaplan/common';

import {
  tableHeaderConfig,
  tableRowConfig,
} from '../../components/Admin/MatchingAvailabilities/helper';
import { IFromScreen, renderScreen, setBack, setFooterShow } from '../../actions/drawerActions';
import { OtherScreens, ScreenTypes } from '../../helpers/Constants';

import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';
import ICalendarEventType from '../../types/ICalendarEventType';

const { requested } = PharmacistLegendVariant;
const { availableWorkshift, requestedList } = ScreenTypes;

export interface IMatchingAvailabilities {
  workshiftId: string;
  type: ICalendarEventType;
  fromScreen?: IFromScreen
}

const noActionsScreens = [
  OtherScreens.AdminPostedWorkshiftReports,
];

const useMatchingAvailabilities = ({
  workshiftId,
  type,
  fromScreen,
}: IMatchingAvailabilities) => {
  const [showOtherAvailabilities, setShowOtherAvailabilities] = useState(true);
  const dispatch = useAppDispatch();

  const matchingAvailabilities = useAppSelector(selectMatchingAvailabilities);
  const timeFormat = useAppSelector(userPreferredTimeFormat);

  const isRequested = type === requested;

  const { matchingAvailabilities: matchingData, startDate } = matchingAvailabilities ?? {};

  const noActionsScreen = noActionsScreens.includes(fromScreen as OtherScreens);

  const back = () => {
    dispatch(
      renderScreen({
        screenNumber: 4,
        screenType: ScreenTypes.availableWorkshift,
        eventId: workshiftId,
        type,
      }),
    );
  };

  const table = {
    title: '',
    headerBar: [],
    headers: tableHeaderConfig(),
    rows: tableRowConfig({
      data: matchingData,
      timeFormat,
      dispatch,
      workshiftId,
      type,
      back,
    }),
  };

  const goToAvailableWorkshift = () => {
    dispatch(
      renderScreen({
        screenType: availableWorkshift,
        screenNumber: 1,
        date: startDate,
      }),
    );
  };

  const goToRequestPharmacist = () => {
    dispatch(
      renderScreen({
        screenType: requestedList,
        screenNumber: 2,
        eventId: workshiftId,
      }),
    );
  };

  useEffect(() => {
    dispatch(getMatchingAvailabilities(workshiftId));

    if (noActionsScreen) {
      dispatch(setBack(null));
      dispatch(setFooterShow(false));
    } else {
      isRequested ? dispatch(setBack(goToRequestPharmacist)) : dispatch(setBack(goToAvailableWorkshift));
    }

    return () => {
      dispatch(setBack(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, workshiftId]);

  return {
    table,
    showOtherAvailabilities,
    setShowOtherAvailabilities,
  };
};

export default useMatchingAvailabilities;
