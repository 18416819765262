import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { FormikProps } from 'formik';

import PendingTabs from '../../../PendingTabs';
import strings from '../../../../../localization';
import { ListOfWorkshiftsTabTypes } from '../../../../../helpers/Constants';

import ListOfWorkshiftsInfo from './ListOfWorkshiftsInfo';
import { tabConfig, workshiftStatuses } from './helper';
import styles from './style';

interface IListOfWorkshiftsHeader {
  formik: FormikProps<any>;
  setSelected: React.Dispatch<React.SetStateAction<ListOfWorkshiftsTabTypes>>;
  selectedTab: ListOfWorkshiftsTabTypes;
}

const ListOfWorkshiftsHeader: FC<IListOfWorkshiftsHeader> = ({ selectedTab, formik, setSelected }) => {
  const handleTabClick = (tab: ListOfWorkshiftsTabTypes) => {
    setSelected(tab);
  };

  const isPast = selectedTab === ListOfWorkshiftsTabTypes.pastWorkshifts;
  const statuses = workshiftStatuses(isPast);

  return (
    <Box>
      <Box sx={styles.root}>
        <Typography sx={styles.title}>{strings.listOfWorkshifts}</Typography>
      </Box>

      <ListOfWorkshiftsInfo formik={formik} workshiftStatuses={statuses} />

      <PendingTabs
        selectedTab={selectedTab}
        tabConfig={tabConfig(handleTabClick)}
      />
    </Box>
  );
};

export default ListOfWorkshiftsHeader;
