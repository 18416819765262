import * as Yup from 'yup';
import { formikRequired } from '../../SoftwareList/validator';

const Validator = () =>
  Yup.object().shape({
    startDate: formikRequired,
    endDate: formikRequired,
    selectedDate: formikRequired,
  });

export default Validator;
