import * as React from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Box, InputLabel } from '@mui/material';
import { FormikProps } from 'formik';
import { SystemStyleObject } from '@mui/system';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { DateValidationError } from '@mui/x-date-pickers/internals';
import 'moment/locale/fr';
import { language } from '@pharmaplan/common';
import classes from './style';
import {
  MomentTimeFormats,
  TextFieldVariants,
} from '../../../helpers/Constants';
import { useAppSelector } from '../../../hooks/useAppSelector';
import genericClasses from '../../../theme/GenericClasses';

interface ICustomDatePicker {
  label: string;
  formik: FormikProps<any>;
  name: string;
  minDate?: DateTime | null;
  maxDate?: DateTime;
  horizontal?: boolean;
  variant?: TextFieldVariants;
  disabled?: boolean;
  onError?: (reason: DateValidationError, value: any) => void;
  error?: string;
  customClass?: { [key: string]: string | number };
  readOnly?: boolean;
  defaultMonth?: DateTime;
}
const defaultProps = {
  horizontal: false,
  disabled: false,
  minDate: null,
  customClass: {},
  readOnly: false,
};

const { filled } = TextFieldVariants;

const CustomDatePicker = ({
  label,
  horizontal,
  formik,
  minDate,
  maxDate,
  error = ' ',
  onError,
  name,
  disabled,
  customClass,
  variant,
  readOnly,
  defaultMonth,
}: ICustomDatePicker) => {
  const handleChange = (value: DateTime | null) => {
    value && !value.isValid && onError?.('invalidDate', value);
    formik.setFieldValue(name, value);
  };

  const isReadOnlyPassed = typeof readOnly === 'boolean';

  const userLanguage = useAppSelector(language);

  const isError = !!onError;
  const isFilled = filled === variant;

  return (
    <LocalizationProvider
      dateAdapter={AdapterLuxon}
      adapterLocale={userLanguage}
    >
      <Box
        component="div"
        sx={[
          horizontal ? classes.horizontalDatePicker : {},
          customClass as SystemStyleObject,
        ]}
      >
        <InputLabel>{label}</InputLabel>
        <DesktopDatePicker
          PopperProps={{ placement: 'bottom-start' }}
          disableMaskedInput
          value={formik?.values?.[name]}
          defaultCalendarMonth={defaultMonth}
          maxDate={maxDate}
          minDate={minDate as any}
          disabled={disabled}
          onError={(reason: DateValidationError, value: any) => {
            isError && onError(reason, value);
            console.log(reason, value);
          }}
          onChange={handleChange}
          inputFormat={MomentTimeFormats.dateMonthYear}
          renderInput={(params) =>
            (
              <TextField
                fullWidth
              // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                FormHelperTextProps={{
                  style: !readOnly
                    ? {
                      minHeight: '35px',
                    }
                    : {},
                }}
                variant={variant}
                sx={[
                  isFilled ? genericClasses.customFilledInput : {},
                  classes.error,
                ]}
                error={isReadOnlyPassed ? !readOnly : error !== ' '}
                helperText={error}
                inputProps={{ ...params.inputProps, readOnly, placeholder: '' }}
              />
            )}
        />
      </Box>
    </LocalizationProvider>
  );
};

CustomDatePicker.defaultProps = defaultProps;

export default CustomDatePicker;
