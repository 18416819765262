import React, { FC } from 'react';

import { IBillFooter } from '@pharmaplan/common/types/IBillDetails';
import { currency } from '@pharmaplan/common';
import strings from '../../../../localization';
import PriceLabel from '../PriceLabel/PriceLabel';

const BillFooter: FC<IBillFooter> = ({
  total,
  counterTotal,
  successAction,
  suffix,
}) =>
  (
    <PriceLabel
      successAction={successAction}
      title={strings.total}
      price={`${currency}${total}${suffix}`}
      counterPrice={counterTotal && `$${counterTotal}`}
    />
  );

export default BillFooter;
