import { createSlice } from '@reduxjs/toolkit';
import moment, { Moment } from 'moment';
import { logout, TypeOfUserCapital } from '@pharmaplan/common';
import {
  CalendarMapKey,
  CalendarStateKey,
  PharmacyLegendVariant,
} from '../helpers/Constants';
import ICalendar from '../types/ICalendar';

interface ICalendarState {
  calendarView: `${CalendarStateKey}`;
  activeDashboard: `${CalendarMapKey}`;
  activeDate: Moment;
  tempEvents: Array<ICalendar>;
  calendarEvents: Array<ICalendar>;
  enableAddEvents: boolean;
  multipleAddEvents: boolean;
  disableAvailability: boolean;
  disableSubmitFilter: boolean;
  calendarStakeholder: `${TypeOfUserCapital}`;
  displayEvents: Array<`${PharmacyLegendVariant}`>;
}

const initialState: ICalendarState = {
  calendarView: CalendarStateKey.month,
  activeDashboard: CalendarMapKey.calendar,
  disableAvailability: false,
  activeDate: moment(),
  calendarEvents: [],
  enableAddEvents: false,
  multipleAddEvents: false,
  disableSubmitFilter: false,
  tempEvents: [],
  calendarStakeholder: TypeOfUserCapital.pharmacist,
  displayEvents: [
    PharmacyLegendVariant.booked,
    PharmacyLegendVariant.posted,
    PharmacyLegendVariant.requested,
  ],
};

const modalSlice = createSlice({
  name: 'mainCalendar',
  initialState,
  reducers: {
    setCalendarView(state, { payload }: { payload: `${CalendarStateKey}` }) {
      state.calendarView = payload;
    },
    setTempEvents(state, { payload }) {
      state.tempEvents = payload;
    },
    setActiveDashboard(state, { payload }: { payload: `${CalendarMapKey}` }) {
      state.activeDashboard = payload;
    },
    setActiveDate(state, { payload }: { payload: Moment }) {
      state.activeDate = payload;
    },
    setActiveUserType(state, { payload }: { payload: TypeOfUserCapital }) {
      state.calendarStakeholder = payload;
    },
    setCalendarEvent(state, { payload }: { payload: Array<ICalendar> }) {
      state.calendarEvents = [...state.calendarEvents, ...payload];
    },
    editCalendarEvent(state, { payload }: { payload: ICalendar }) {
      const elIndex = state.calendarEvents.findIndex(
        (el) =>
          el.id === payload.id,
      );
      state.calendarEvents = [
        ...state.calendarEvents.splice(0, elIndex),
        payload,
        ...state.calendarEvents.splice(
          elIndex + 1,
          state.calendarEvents.length,
        ),
      ];
    },
    setDisableAvailability(state, { payload }: { payload: boolean }) {
      state.disableAvailability = payload;
    },
    setMultipleAddEvents(state, { payload }: { payload: boolean }) {
      state.multipleAddEvents = payload;
    },
    setDisableSubmitFilter(state, { payload }: { payload: boolean }) {
      state.disableSubmitFilter = payload;
    },
    enableAddCalendarEvent(state, { payload }: { payload: boolean }) {
      state.enableAddEvents = payload;
    },

    editDisplayEventsPharmacy(
      state,
      { payload }: { payload: Array<`${PharmacyLegendVariant}`> },
    ) {
      state.displayEvents = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(logout.fulfilled, () =>
      initialState);
  },
});

const { reducer, actions } = modalSlice;

export const {
  setCalendarView,
  setActiveDashboard,
  setActiveDate,
  setCalendarEvent,
  setDisableAvailability,
  setActiveUserType,
  setMultipleAddEvents,
  setTempEvents,
  editCalendarEvent,
  enableAddCalendarEvent,
  setDisableSubmitFilter,
  editDisplayEventsPharmacy,
} = actions;
export default reducer;
