import React, { memo, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import {
  getUsername,
  login,
  resetName,
  setSignedUp,
  username,
} from '@pharmaplan/common';
import { useFormik } from 'formik';
import { ReactComponent as LoginIcon } from '@pharmaplan/common/assets/icons/loginIcon.svg';
import { Constants, InputTypes } from '../../../helpers/Constants';

import strings from '../../../localization';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import genericClasses from '../../../theme/GenericClasses';
import CustomButton from '../../common/CustomButton/CustomButton';
import CustomCheckbox from '../../common/CustomCheckbox/CustomCheckbox';
import CustomLink from '../../common/CustomLink';
import CustomTextField from '../../common/CustomTextField';
import usePushRegistration from '../../../hooks/PushNotifications/usePushRegistration';
import { getBrowser, getPlatformOS } from '../../../helpers/Functions';

import classes from './style';
import Validator, { rememberValidator } from './Validator';

const LoginForm = () => {
  const dispatch = useAppDispatch();
  const { token } = usePushRegistration();

  const name = useAppSelector(username);
  const remember = name;

  const device = {
    osType: getPlatformOS() ?? '',
    type: getBrowser(),
    deviceToken: token,
  };

  useEffect(() => {
    dispatch(getUsername());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    onSubmit: (values) => {
      dispatch(setSignedUp(false));
      dispatch(
        login({
          email: remember ? '' : values.email,
          password: values.password,
          appId: 'windows',
          rememberMe: !!remember || values.rememberMe,
          device,
        }),
      );
    },
    enableReinitialize: true,
    validationSchema: remember ? rememberValidator : Validator,
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      {!remember ? (
        <CustomTextField
          name="email"
          placeholder={strings.formatString(strings.enterCred, strings.email)}
          id="email"
          formik={formik}
          label={strings.email}
          customClass={genericClasses.loginMargins}
        />
      ) : (
        <Typography sx={[classes.welcomeBack]}>
          {strings.formatString(strings.welcomeUser, name)}
        </Typography>
      )}
      <CustomTextField
        name="password"
        type={InputTypes.password}
        customClass={classes.passwordMargin}
        formik={formik}
        placeholder={strings.formatString(strings.enterCred, strings.password)}
        id="password"
        label={strings.password}
      />
      <Box component="div" sx={classes.forgotPassContainer}>
        {!remember && (
          <CustomCheckbox
            label={strings.rememberMe}
            name="rememberMe"
            id="rememberMe"
            value={formik.values.rememberMe}
            labelClass={classes.labelClass}
            handleChange={(e) => {
              formik.setFieldValue('rememberMe', e.target.checked);
            }}
          />
        )}
        <CustomLink
          linkText={strings.forgotPass}
          to={Constants.paths.resetPassword}
          classnames={classes.forgotPass}
        />
      </Box>
      <CustomButton label={strings.login} onClick={formik.handleSubmit} />
      {remember && (
        <CustomButton
          customClass={classes.customLoginButtonContainer}
          variant="outlined"
          label={strings.loginAsAnotherUser}
          startIcon={<LoginIcon />}
          onClick={() =>
            dispatch(resetName())}
        />
      )}
    </Box>
  );
};

export default memo(LoginForm);
