import React, { useCallback, useEffect, useRef } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import {
  AdminLegendVariant,
  BookingState,
  CalendarStateKey,
  getActiveWebView,
  getTypeOfUser,
  PharmacistLegendVariant,
  PharmacyLegendVariant,
  RecurrenceActions,
  TypeOfUser,
} from '@pharmaplan/common';

import UpdateModal from '../../../Modals/UpdateModal';
import useUser from '../../../../hooks/useUser';
import useAdmin from '../../../../hooks/useAdmin';
import useDrawerNavigation from '../../../../hooks/useDrawerNavigation';
import useAdminNavigation from '../../../../hooks/Admin/useAdminNavigation';
import useSelfService from '../../../../hooks/useSelfService';
import useMultipleDelete from '../../../../hooks/useMultipleDelete';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { setDialog } from '../../../../reducers/dialogReducer';
import { getEnabledAddEvents } from '../../../../selectors/calendarSelector';
import { ReactComponent as BellIcon } from '../../../../assets/svg/calendarBellIcon.svg';
import ICalendarEvent from '../../../../types/ICalendar';
import { isPast } from '../../../../helpers/Functions';
import ThemeConstants from '../../../../theme/ThemeConstants';
import strings from '../../../../localization';

import CalendarTooltip from '../CalendarTooltip';
import classes from './style';

interface ICustomEventProps {
  event: ICalendarEvent;
  style: (React.CSSProperties & { xOffset: number }) | undefined;
  onSelect: (event: ICalendarEvent) => void;
  eventIds: Array<string>;
}

export const updateTitle = (isSelfService: boolean) => {
  if (isSelfService) {
    return {
      [PharmacistLegendVariant.availability]: strings.updateAvailability,
    };
  }
  return {
    [PharmacistLegendVariant.availability]: strings.updateAvailability,
    [PharmacyLegendVariant.posted]: strings.updateWorkshift,
    [PharmacyLegendVariant.requested]: strings.updateWorkshift,
  };
};

export const showConfig = [
  PharmacistLegendVariant.availability,
  PharmacyLegendVariant.posted,
];

export interface IPopover {
  open: boolean;
  top: number;
  type: RecurrenceActions;
  left: number;
}

const CustomMonthEvent = ({
  event: {
    start,
    end,
    title,
    type,
    id,
    recurrenceId,
    code,
    count,
    bookingState,
    durations,
    availableTimeId,
    isDeleted,
  },
  style,
  eventIds,
  onSelect,
}: ICustomEventProps) => {
  const ref = useRef<HTMLElement | null>(null);
  const { isSelfService } = useSelfService();
  const typeOfUser = useAppSelector(getTypeOfUser).toLowerCase() as TypeOfUser;
  const dispatch = useAppDispatch();
  const { stopMouseDown, handleMultipleClicks } = useMultipleDelete();

  const preventClick = isPast(start);
  const enabledAdd = useAppSelector(getEnabledAddEvents);

  // ONLY SHOW WHEN EVENT IS NOT IN PAST AND CREATE EVENTS IS DISABLED
  const showEvent = !preventClick && !enabledAdd;

  const isRequestedBooked = type === PharmacistLegendVariant.booked
    || type === PharmacistLegendVariant.requested;
  const isPharmacist = typeOfUser === TypeOfUser.pharmacist.toLowerCase();
  const requiresAction = bookingState === BookingState.PendingWithPharmacist
    || bookingState === BookingState.UpdatedTiming;

  const showBellIcon = isRequestedBooked && isPharmacist && requiresAction;

  const activeWebView = useAppSelector(getActiveWebView);

  const { userType } = useUser();
  const { isAdmin } = useAdmin();

  const { openDrawer } = useDrawerNavigation();
  const { openAdminDrawer } = useAdminNavigation();

  const isRegularOrSuperAdmin = typeOfUser === TypeOfUser.admin.toLowerCase()
    || typeOfUser === TypeOfUser.superAdmin.toLowerCase();

  const eventSelect = () => {
    if (handleMultipleClicks({ type, availableTimeId })) {
      return;
    }
    isRegularOrSuperAdmin
      ? openAdminDrawer({ startDate: start, type: type as AdminLegendVariant })
      : openDrawer({
        eventIds,
        type,
        bookingState,
        recurrenceType: RecurrenceActions.series,
        recurrenceId,
      });
  };

  const {
    gridContainer,
    eventContainer,
    textColor,
    eventTitle,
    rightBlock,
    rightGrid,
  } = classes ?? {};

  useEffect(() => {
    const handlePress = (e: MouseEvent) => {
      if (!showEvent && !isAdmin) {
        return;
      }
      Object.hasOwn(updateTitle(isSelfService), type) && recurrenceId
        ? dispatch(
          setDialog({
            showCloseButton: false,
            Component: (
              <UpdateModal
                type={userType}
                handleSelect={(selected: RecurrenceActions) =>
                  openDrawer({
                    eventIds,
                    recurrenceType: selected,
                    recurrenceId,
                    type,
                  })}
              />
            ),
            heading: {
              title: '',
            },
          }),
        )
        : eventSelect(); // Stop event bubbling
      e.stopPropagation();
    };
    const refCurrent = ref.current;

    if (refCurrent) {
      refCurrent.addEventListener('click', handlePress);
    }

    return () => {
      if (refCurrent) {
        refCurrent.removeEventListener('click', handlePress);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      // What does this do?
      // onSelect({ start, end, title, type, id, code });
      e.stopPropagation();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [end, id, onSelect, start, title, type, code],
  );

  const themeConstantsKey = `${typeOfUser.toLowerCase()}s` as keyof typeof ThemeConstants;
  const fillColor = ThemeConstants[themeConstantsKey]?.[type as never];

  return (
    <Box
      component="div"
      sx={eventContainer(type, style, typeOfUser, isDeleted)}
      ref={ref}
      onMouseDown={stopMouseDown}
      onClick={handleSelect}
    >
      <CalendarTooltip
        onClick={(inId: string) =>
          openDrawer({
            eventIds: [inId],
            type,
            bookingState,
            recurrenceType: RecurrenceActions.series,
            recurrenceId,
          })}
        eventType={type}
        durations={durations ?? []}
      >
        <Grid container wrap="nowrap" sx={gridContainer}>
          <Grid item md={9} sx={[classes.titleContainer, classes.center]}>
            <Typography sx={eventTitle(type, typeOfUser, !!isDeleted)}>
              {strings[type]}
            </Typography>
            {showBellIcon && (
              <Box sx={[classes.bellContainer, classes.center]}>
                <BellIcon
                  height={18}
                  width={18}
                  fill={fillColor}
                  stroke={fillColor}
                />
              </Box>
            )}
          </Grid>
          {activeWebView === CalendarStateKey.month && (
            <Grid md={3} item sx={rightGrid}>
              <Box sx={rightBlock}>
                <Typography sx={textColor(type, typeOfUser, !!isDeleted)}>
                  {isDeleted ? '-' : count}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </CalendarTooltip>
    </Box>
  );
};
export default CustomMonthEvent;
