import React, { useEffect } from 'react';

import {
  adminActions,
  calendarDate,
  ConfirmationTypes,
  getAllPharmacistAvailabilies,
  PharmacistLegendVariant,
  resetStatus,
  successOrSelector,
} from '@pharmaplan/common';
import { useLocation } from 'react-router-dom';
import { resetDialog, setDialog } from '../../reducers/dialogReducer';
import { setTempEvents } from '../../reducers/mainCalendarReducer';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';

import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';
import { getISO, mapFormat } from '../../helpers/Functions';
import { todaysDateISO } from '../../helpers/Constants';

const createAction = adminActions.createAvailabilities;
const useAdminContinueCreation = () => {
  const dispatch = useAppDispatch();
  const cDate = useAppSelector(calendarDate);

  const { state } = useLocation();
  const { id } = state ?? {};

  const isSuccess = useAppSelector((st) =>
    successOrSelector([createAction], st));

  const showConfirmModal = (confirmAction: () => void) =>
    () => {
      dispatch(
        setDialog({
          Component: (
            <ConfirmationModal
              confirmAction={confirmAction}
              type={ConfirmationTypes.createMultipleAvailabilities}
            />
          ),
          showCloseButton: false,
        }),
      );
    };

  const getCalendar = () => {
    const startDate = mapFormat(todaysDateISO() ?? '');
    const endDate = mapFormat(getISO(cDate.endDate));

    dispatch(
      getAllPharmacistAvailabilies({
        pharmacistId: id,
        startDate,
        endDate,
        type: PharmacistLegendVariant.availability,
      }),
    );
  };

  useEffect(() => {
    if (isSuccess) {
      getCalendar();
      dispatch(setTempEvents([]));
      dispatch(resetDialog());
      dispatch(resetStatus([createAction]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return { showConfirmModal };
};

export default useAdminContinueCreation;
