import React, { FC } from 'react';

import Typography from '@mui/material/Typography';
import { Box, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useAppSelector } from '../../../hooks/useAppSelector';
import useDrawer from '../../../hooks/useDrawer';
import { ReactComponent as CloseBtn } from '../../../assets/svg/closeBtn.svg';
import { selectBack, selectTitle } from '../../../selectors/drawerSelector';

import classes from './style';

const { headerContainer, headerTitle, backButton, closeButton, closeButtonBg } = classes;

const DrawerHeader: FC = () => {
  const { closeDrawer } = useDrawer();

  const back = useAppSelector(selectBack);
  const drawerTitle = useAppSelector(selectTitle);

  const { customTitleStyle, title } = drawerTitle ?? {};

  return (
    <Box sx={headerContainer}>
      {back && (
        <IconButton onClick={back} sx={{ paddingRight: '20px' }}>
          <ArrowBackIcon sx={backButton} />
        </IconButton>
      )}

      <Typography sx={[headerTitle, customTitleStyle]}>{title}</Typography>

      <Box sx={closeButton}>
        <IconButton sx={closeButtonBg} onClick={closeDrawer}>
          <CloseBtn />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DrawerHeader;
