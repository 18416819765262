import React from 'react';
import { PermissionsOfAdmin } from '@pharmaplan/common';
import { genericFunction } from '../../AvailableWorkshift/useAvailableWorkshiftActions';
import strings from '../../../localization';
import { useAppDispatch } from '../../useAppDispatch';
import {
  ICustomBack,
  IFromScreen,
  renderScreen,
} from '../../../actions/drawerActions';
import { ScreenTypes } from '../../../helpers/Constants';
import ICalendarEventType from '../../../types/ICalendarEventType';
import { setDialog } from '../../../reducers/dialogReducer';
import UploadResume from '../../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/UploadResume';
import UploadPhoto from '../../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/UploadPhoto/UploadPhoto';
import useAdminPermissions from '../useAdminPermissions';

interface IGeographicAvailability {
  pharmacistId: string;
  type: ICalendarEventType;
  back?: ICustomBack;
}

export const menuActions = (
  canChangeParameters: boolean,
  editAction: genericFunction,
  uploadPhotoAction: genericFunction,
  uploadResumeAction: genericFunction,
  geographicAvailabilityAction: genericFunction,
) => {
  const arr = [
    {
      key: '0',
      label: strings.edit,
      onClick: editAction,
    },
    {
      key: '2',
      label: strings.uploadPicture,
      onClick: uploadPhotoAction,
    },
    {
      key: '3',
      label: strings.uploadResume,
      onClick: uploadResumeAction,
    },
    {
      key: '4',
      label: strings.pharmacistGeographicAvailability,
      onClick: geographicAvailabilityAction,
    },
  ];

  return canChangeParameters ? arr : arr.slice(1);
};

const usePharmacistProfileViewDetails = (
  fromScreen?: IFromScreen,
  hasOwner?: boolean,
) => {
  const dispatch = useAppDispatch();

  const { can } = useAdminPermissions();
  const canChangeParameters = can(PermissionsOfAdmin.ChangeParameters);

  const showPictureUploadModal = (pharmacistId: string) =>
    () => {
      dispatch(
        setDialog({
          maxWidth: 'xs',
          showCloseButton: true,
          Component: <UploadPhoto pharmacistId={pharmacistId} />,
          heading: {
            title: strings.uploadPicture,
          },
        }),
      );
    };

  const showResumeUploadModal = (pharmacistId: string) =>
    () => {
      dispatch(
        setDialog({
          maxWidth: 'xs',
          showCloseButton: true,
          Component: <UploadResume pharmacistId={pharmacistId} />,
          heading: {
            title: strings.uploadResume,
          },
        }),
      );
    };

  const goToEditPharmacistProfile = ({
    pharmacistId,
    type,
    back,
  }: {
    pharmacistId: string;
    type: ICalendarEventType;
    back?: ICustomBack;
  }) => {
    dispatch(
      renderScreen({
        screenNumber: 4,
        screenType: ScreenTypes.profile,
        pharmacistId,
        fromScreen,
        type,
        hasOwner,
        back,
      }),
    );
  };

  const goToGeographicAvailability = ({ pharmacistId, type, back }: IGeographicAvailability) =>
    () => {
      dispatch(
        renderScreen({
          screenNumber: 5,
          screenType: ScreenTypes.profile,
          fromScreen,
          hasOwner,
          pharmacistId,
          type,
          back,
        }),
      );
    };

  return {
    actions: (id: string, type: ICalendarEventType, back?: ICustomBack) =>
      menuActions(
        canChangeParameters,
        () =>
          goToEditPharmacistProfile({ pharmacistId: id, type, back }),
        showPictureUploadModal(id),
        showResumeUploadModal(id),
        goToGeographicAvailability({ pharmacistId: id, type, back }),
      ),
  };
};

export default usePharmacistProfileViewDetails;
