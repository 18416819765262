export default {
  primary: {
    main: '#48A14D',
    inputLabel: '#4A5056',
    light: '#F5F6F7',
  },
  secondary: {
    main: '#ffffff',
  },
  common: {
    lightBackgroundColor: '#F6F7FC',
    grey150: '#F9FAFD',
    grey350: '#969FA8',
    grey850: '#333333',
    blue400: '#ADB6CF',
    blue600: '#56607E',
    newBlue: '#4695F7',
    gray: '#6E757C',
    error: '#d32f2f',
    border: '#E1E4EF',
    lightGreen: '#E7F3DE',
    pharmacyCounterGreen: '#F9FCF8',
    green300: '#E8FAF0',
    green600: '#08A767',
    pharmacistCounterGreen: '#8CC63F',
    counterRed: '#EB4869',
    hint: '#FF7F50',
    secondaryButtonHover: '#CFEDBB',
    borderInput: '#CFD4D9',
    hover: '#FAFBFE',
    otpInput: '#F7F8FB',
    notificationActions: '#ECEDF6',
    hoverGray: '#EDEFF8',
    deletedAvails: '#eb0000',
    freeGray: '#E9EFF4',
  },

  footer: {
    backgroundColor: '#ECEFF6',
    verticalLine: '#000000',
  },

  grey: {
    400: '#828282',
    500: '#96A0BC',
    600: '#8690AA',
    800: '#4A5056',
  },

  eventChip: {
    posted: '#DDF9E9',
    postedText: '#11723B',
    requestedText: '#EC7600',
    requested: '#FFF0E5',
    bookedText: '#B74641',
    booked: '#F8D4D2',
    workshiftText: '#11723B',
  },

  pharmacists: {
    requested: '#EC7600',
    booked: '#B74641',
    availability: '#2C64BE',
    tempAvailability: '#455A64',
    workshift: '#11723B',
    requestedLight: '#FFE9D3',
    bookedLight: '#F8D4D2',
    availabilityLight: '#D2E1FA',
    tempAvailabilityLight: '#CFD8DC',
    workshiftLight: '#DDF9E9',

    // REPORT
    pending: '#EC7600',
    notAssigned: '#B74641',
    cancelled: '#96A0BC',
  },

  pharmacy: {
    posted: '#11723B',
    postedLight: '#DDF9E9',

    booked: '#B74641',
    bookedLight: '#F8D4D2',

    requested: '#EC7600',
    requestedLight: '#FFE9D3',
  },

  admin: {
    posted: '#11723B',
    postedLight: '#DDF9E9',

    booked: '#B74641',
    bookedLight: '#F8D4D2',

    requested: '#EC7600',
    requestedLight: '#FFE9D3',
    availability: '#2C64BE',
    tempAvailability: '#455A64',
    workshift: '#11723B',
    availabilityLight: '#D2E1FA',
    tempAvailabilityLight: '#CFD8DC',
    workshiftLight: '#DDF9E9',

    // REPORT
    pending: '#EC7600',
    notAssigned: '#B74641',
    cancelled: '#96A0BC',
  },

  superadmin: {
    posted: '#11723B',
    postedLight: '#DDF9E9',

    booked: '#B74641',
    bookedLight: '#F8D4D2',

    requested: '#EC7600',
    requestedLight: '#FFE9D3',
    availability: '#2C64BE',
    tempAvailability: '#455A64',
    workshift: '#11723B',
    availabilityLight: '#D2E1FA',
    tempAvailabilityLight: '#CFD8DC',
    workshiftLight: '#DDF9E9',

    // REPORT
    pending: '#EC7600',
    notAssigned: '#B74641',
    cancelled: '#96A0BC',
  },

  calendarHover: {
    requested: '#FFF4E9',
    requestedBorder: '#FFCA94',
    workshift: '#F0FFF6',
    workshiftBorder: '#94DCB3',
    posted: '#F0FFF6',
    postedBorder: '#94DCB3',
    availabilityBorder: '#7AA3E4',
    availability: '#EDF4FF',
    bookedBorder: '#FBB6B2',
    booked: '#FFF1F0',
  },

  notification: {
    notVisited: '#F0F2FC',
    confirmBg: '#DDF9E9',
  },
};
