import React, { FC } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { FormikProps } from 'formik';

import { adminActions, pharmacyDetails } from '@pharmaplan/common';
import OptimizedAvatar from '../../../../../common/OptimizedAvatar/OptimizedAvatar';
import withSkeleton from '../../../../../common/WithSkeleton';
import CustomDropdown from '../../../../../common/CustomDropdown';
import useGetItems from '../../../../../../hooks/useGetItems';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import { ReactComponent as LocationIcon } from '../../../../../../assets/svg/location.svg';
import ThemeConstants from '../../../../../../theme/ThemeConstants';
import strings from '../../../../../../localization';
import IDropdownObject from '../../../../../../types/ICustomDropdown';
import {
  ChipType,
  SkeletonVariants,
} from '../../../../../../helpers/Constants';
import HourlyRateChipLabel from '../../../../../common/AdminHourlyRateFooter/HourlyRateChipLabel';

import styles from './style';

const successAction = adminActions.getPharmacyDetails;

interface IListOfWorkshiftsInfo {
  formik: FormikProps<any>;
  workshiftStatuses: IDropdownObject[];
}

const ListOfWorkshiftsInfo: FC<IListOfWorkshiftsInfo> = ({
  workshiftStatuses,
  formik,
}) => {
  const SkeletonTypography = withSkeleton({
    successAction,
    variant: SkeletonVariants.text,
  })(Typography);

  const details = useAppSelector(pharmacyDetails);
  const { pharmacy } = details ?? {};
  const { logoId, city, name, primaryContactName, pharmacyHourlyRate } = pharmacy ?? {};

  const { getImage } = useGetItems();
  const image = getImage(logoId);

  return (
    <Grid
      container
      lg={12}
      alignItems="center"
      justifyContent="space-between"
      xs={12}
      wrap="nowrap"
    >
      <Grid xs={8} sx={styles.root}>
        <Box sx={styles.container}>
          <OptimizedAvatar
            src={image}
            avatarSize={75}
            successAction={successAction}
          />

          <Box sx={styles.infoContainer}>
            <SkeletonTypography sx={styles.pharmacyNameText}>
              {name}
            </SkeletonTypography>
            <Box sx={styles.locationContainer}>
              <LocationIcon
                style={styles.locationIcon}
                fill={ThemeConstants.common.gray}
              />
              <SkeletonTypography sx={styles.cityText}>
                {city}
              </SkeletonTypography>
            </Box>
          </Box>

          <Box sx={styles.infoContainer}>
            <Box component="div" sx={styles.divider} />
          </Box>

          <Box sx={styles.infoContainer}>
            <Typography sx={styles.cityText}>{strings.contactName}</Typography>
            <SkeletonTypography sx={styles.contactText}>
              {primaryContactName}
            </SkeletonTypography>
          </Box>

          <Box sx={styles.infoContainer}>
            <Box component="div" sx={styles.divider} />
          </Box>

          <Box sx={styles.infoContainer}>
            <Box>
              <HourlyRateChipLabel
                rate={pharmacyHourlyRate}
                type={ChipType.pharmacyHourlyRate}
                successAction={successAction}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid xs={2}>
        <CustomDropdown
          formik={formik}
          label={strings.filterBy}
          name="status"
          menuItems={workshiftStatuses}
        />
      </Grid>
    </Grid>
  );
};

export default ListOfWorkshiftsInfo;
