import React from 'react';

import { Box, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
  getTypeOfUser,
  TypeOfUser,
  ToUserTypings,
  CalendarStateKey,
  DashboardType,
  setActiveDashboard,
  setActiveWebView,
} from '@pharmaplan/common';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomSwitch from '../../common/CustomSwitch';
import { enableAddCalendarEvent } from '../../../reducers/mainCalendarReducer';
import { reportItems, toolbarItems } from '../config';
import { ReactComponent as ReportsIcon } from '../../../assets/svg/reportsIcon.svg';
import CustomButton from '../../common/CustomButton';
import strings from '../../../localization';
import genericClasses from '../../../theme/GenericClasses';
import CustomMenuList from '../../common/CustomMenuList';

import { Constants } from '../../../helpers/Constants';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { getEnabledAddEvents } from '../../../selectors/calendarSelector';

import styles from './styles';

const { pharmacist, pharmacy } = TypeOfUser;
const { reports, calendarDashboard } = Constants.paths;

const NavToolBar = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const userType = useAppSelector(getTypeOfUser);
  const enableAdd = useAppSelector(getEnabledAddEvents);

  const { pathname } = location ?? {};

  const isPharmacist = userType === pharmacist;
  const isReports = pathname === reports;

  const userConfig = ToUserTypings({
    [pharmacist]: {
      buttonLabel: strings.availabilities,
    },
    [pharmacy]: {
      buttonLabel: strings.workshifts,
    },
  });

  const goToAvailability = () => {
    dispatch(setActiveDashboard(DashboardType.calendar));
    dispatch(setActiveWebView(CalendarStateKey.month));
    dispatch(enableAddCalendarEvent(!enableAdd));
    navigate(calendarDashboard);
  };

  const {
    bottomNavbar,
    bottomNavbarItemActive,
    bottomNavbarItemIcon,
    iconLabel,
    keyboardIcon,
    iconButtonGridContainer,
    iconButtonRightBorder,
    availabilitiesButton,
    bottomNavbarItem,
    rightNavbarContainer,
  } = styles;

  const { flex } = genericClasses;

  const { buttonLabel } = userConfig[userType] ?? {};

  return (
    <Toolbar sx={bottomNavbar} disableGutters>
      {/* left toolbar buttons */}
      <Grid xs={6} item sx={flex}>
        {toolbarItems(userType).map((item) => {
          const { key, label, Icon, path, id, subPaths } = item;
          const isActive = pathname === path || subPaths?.includes(pathname);

          return (
            <Grid item key={key} sx={iconButtonGridContainer}>
              <IconButton
                id={id}
                sx={[
                  bottomNavbarItem,
                  isActive && !enableAdd ? bottomNavbarItemActive : {},
                ]}
                color="inherit"
                size="small"
                onClick={() => {
                  dispatch(enableAddCalendarEvent(false));
                  navigate(path);
                }}
              >
                <Icon style={bottomNavbarItemIcon} />
                <Typography sx={iconLabel}>{label}</Typography>
              </IconButton>
              <Box component="div" sx={iconButtonRightBorder} />
            </Grid>
          );
        })}

        <CustomMenuList
          id="reports"
          items={reportItems(navigate, userType)}
          anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
          disableRipple={false}
          customIconButtonStyle={[
            bottomNavbarItem,
            isReports ? bottomNavbarItemActive : {},
          ]}
        >
          <Grid item sx={iconButtonGridContainer}>
            <ReportsIcon style={bottomNavbarItemIcon} />
            <Typography sx={iconLabel}>{strings.reports}</Typography>
            <KeyboardArrowDownIcon sx={keyboardIcon} />
          </Grid>
        </CustomMenuList>
      </Grid>

      <Grid xs={6} item sx={rightNavbarContainer}>
        {enableAdd && isPharmacist && <CustomSwitch />}
        <CustomButton
          id="availabilties"
          label={buttonLabel}
          color="primary"
          customClass={availabilitiesButton}
          variant={enableAdd ? 'contained' : 'outlined'}
          startIcon={<Add />}
          onClick={goToAvailability}
        />
      </Grid>
    </Toolbar>
  );
};

export default NavToolBar;
