import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import ThemeConstants from '../../../theme/ThemeConstants';

const legendHeight = 347;

const styles = {
  container: {
    marginTop: '16px',
    display: 'flex',
    backgroundColor: ThemeConstants.secondary.main,
    flexDirection: 'column',
  },
  list: {
    overflowY: 'auto',
    overFlowX: 'hidden',
  },
  dynamicHeight: (height: number) =>
    ({
      height: (height * 3) / 4 - legendHeight,
    }),
  title: {
    margin: '16px',
    fontWeight: '600',
  },
  segment: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  segmentButtons: {
    width: '100% !important',
    fontSize: 12,
    borderRadius: 0,
  },
  text: {
    position: 'relative',
    minWidth: 110,
    maxWidth: 110,
  },
  active: {
    fontWeight: '500',
    borderBottom: '1px solid',
  },
  inActive: {
    color: ThemeConstants.grey[500],
    fontSize: 12,
  },
  alertRedDot: {
    height: 7,
    width: 7,
    borderRadius: 3.5,
    backgroundColor: 'red',
    position: 'absolute',
    top: 0,
    right: 8,
  },
  activeText: {
    fontWeight: '500',
  },
} satisfies Record<
  string,
  SxProps<Theme> | ((height: number) => SxProps<Theme>)
>;

export default styles;
