import React, { FC } from 'react';
import { Avatar, Box, SxProps } from '@mui/material';

import { adminActions } from '@pharmaplan/common';
import AvatarFallback from '@pharmaplan/common/assets/icons/avatarPlaceholder.png';
import { ReactComponent as FavIcon } from '@pharmaplan/common/assets/icons/favourtieActive.svg';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { resetDialog, setDialog } from '../../../reducers/dialogReducer';
import { SkeletonVariants } from '../../../helpers/Constants';

import withSkeleton from '../WithSkeleton';
import FullScreenImage from '../FullScreenImage';
import styles from './style';

interface IOptimizedAvatar {
  avatarSize?: number;
  src?: string | null;
  successAction?: string;
  favorite?: boolean;
  showFullImage?: boolean;
  noSpacing?: boolean;
}
const { circular } = SkeletonVariants;
const { adminPharmacistPictures } = adminActions;

const OptimizedAvatar: FC<IOptimizedAvatar> = ({
  src,
  successAction,
  avatarSize = 60,
  favorite,
  noSpacing,
  showFullImage = false,
}) => {
  const dispatch = useAppDispatch();

  const style = styles(avatarSize);

  const SkeletonAvatar = withSkeleton({
    successAction: successAction ?? adminPharmacistPictures,
    variant: circular,
    height: avatarSize,
    width: avatarSize,
  })(Avatar);

  const hideImage = () => {
    dispatch(resetDialog());
  };

  const isImage = showFullImage && src;

  const showImage = () => {
    if (isImage) {
      dispatch(
        setDialog({
          Component: (
            <FullScreenImage
              image={src ?? AvatarFallback}
              hideImage={hideImage}
              hideBackdrop
            />
          ),
          heading: {
            title: '',
          },
          showCloseButton: false,
          maxWidth: 'lg',
          hideBackground: true,
        }),
      );
    }
  };

  const avatarSx = isImage ? style.cursor : {};

  return (
    <Box sx={style.container}>
      <SkeletonAvatar
        imgProps={{ style: style.imgStyle }}
        sx={[style.avatar, noSpacing ? {} : style.alignment, avatarSx]}
        src={src ?? AvatarFallback}
        onClick={showImage}
      />
      <Box sx={style.favoritePosition}>
        {favorite && <FavIcon height={18} width={20} />}
      </Box>
    </Box>
  );
};

export default OptimizedAvatar;
