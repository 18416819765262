import {
  BroadcastNotificationType,
  IAdminBroadcastGroupListData,
  IBroadcastNotificationsData,
  PermissionsOfAdmin,
  TimeFormat,
} from '@pharmaplan/common';
import {
  BroadcastNotificationMap,
  DynamicTableCell,
} from '../../../helpers/Constants';
import { getDayMonthDateYear, getHourMinute } from '../../Reports/helpers';
import strings from '../../../localization';

import editIcon from '../../../assets/svg/updateNoteIcon.svg';
import deleteIcon from '../../../assets/svg/delete.svg';

const { text, textBox, bar, labelValue } = DynamicTableCell;
const { BroadcastNotification } = PermissionsOfAdmin;
const actionKey = 'actionKey';

export const broadcastGroupHeaders = (
  can: (param: PermissionsOfAdmin) => boolean,
) => {
  const cannotUseBroadcast = !can(BroadcastNotification);
  let list = [
    { key: '1', label: strings.groupName },
    { key: '2', label: `${strings.created} ${strings.date}` },
    { key: '3', label: `${strings.created} ${strings.by}` },
    { key: '4', label: strings.recepients },
    { key: actionKey, label: strings.action },
  ];
  if (cannotUseBroadcast) {
    list = list.filter((item) =>
      item.key !== actionKey);
  }
  return list;
};

export const broadcastNotificationHeaders = (
  can: (param: PermissionsOfAdmin) => boolean,
) => {
  const cannotUseBroadcast = !can(BroadcastNotification);

  let list = [
    { key: '1', label: strings.notificationType },
    { key: '2', label: `${strings.sent} ${strings.date} | ${strings.time}` },
    { key: '3', label: strings.notifications },
    { key: '4', label: strings.group },
    { key: actionKey, label: strings.action },
  ];
  if (cannotUseBroadcast) {
    list = list.filter((item) =>
      item.key !== actionKey);
  }
  return list;
};

export const broadcastGroupRows = (
  data: Array<IAdminBroadcastGroupListData>,
  handleEdit: (groupId: string, groupName: string) => () => void,
  handleDelete: (groupId: string) => () => void,
  can: (permission: PermissionsOfAdmin) => boolean,
) =>
  data?.map((item) => {
    const {
      name,
      userName,
      createDate,
      broadcastGroupId,
      broadcastGroupUsers,
    } = item ?? {};

    const creationDate = getDayMonthDateYear(createDate);
    const recepients = broadcastGroupUsers
      ?.map((users) =>
        users.userName)
      .join(', ');

    const canUseBroadcast = can(BroadcastNotification);

    return {
      key: broadcastGroupId,
      data: [
        {
          key: '1',
          value: name,
          type: text,
        },
        {
          key: '2',
          value: creationDate,
          type: text,
        },
        {
          key: '3',
          value: userName,
          type: text,
        },
        {
          key: '4',
          value: recepients,
          type: textBox,
          maxWidth: 300,
        },
        {
          key: '5',
          value: [
            canUseBroadcast && {
              key: '1',
              icon: editIcon,
              tooltip: strings.edit,
              onClick: handleEdit(broadcastGroupId, name),
            },
            canUseBroadcast && {
              key: '2',
              icon: deleteIcon,
              tooltip: strings.delete,
              onClick: handleDelete(broadcastGroupId),
            },
          ],
          type: bar,
        },
      ],
    };
  });

export const broadcastNotificationRows = (
  data: Array<IBroadcastNotificationsData>,
  timeFormat: TimeFormat,
  handleEdit: (
    id: string,
    notificationType: BroadcastNotificationType
  ) => () => void,
  handleDelete: (id: string) => () => void,
  can: (permission: PermissionsOfAdmin) => boolean,
) =>
  data?.map((item) => {
    const {
      type,
      sentDate,
      subject,
      message,
      broadcastGroups,
      broadcastMessageId,
    } = item ?? {};
    const date = getDayMonthDateYear(sentDate);
    const time = getHourMinute(sentDate, timeFormat);

    const canUseBroadcast = can(BroadcastNotification);

    const timeString = `${date} | ${time}`;
    const groupName = broadcastGroups?.map((inItem) =>
      inItem.name).join('; ');

    const value = [{ key: '2', label: strings.message, value: message }];

    if (subject) {
      value.unshift({ key: '1', label: strings.subject, value: subject });
    }

    return {
      key: broadcastMessageId,
      data: [
        {
          key: '1',
          value: BroadcastNotificationMap[type],
          type: text,
        },
        {
          key: '2',
          value: timeString,
          type: text,
        },
        {
          key: '3',
          value,
          type: labelValue,
        },
        { key: '4', value: groupName, type: text },
        {
          key: '5',
          value: [
            canUseBroadcast && {
              key: '1',
              icon: editIcon,
              tooltip: strings.edit,
              onClick: handleEdit(broadcastMessageId, type),
            },
            canUseBroadcast && {
              key: '2',
              icon: deleteIcon,
              tooltip: strings.delete,
              onClick: handleDelete(broadcastMessageId),
            },
          ],
          type: bar,
        },
      ],
    };
  });
