import React, { FC } from 'react';

import {
  AllowanceTypes,
  TypeOfBill,
  TypeOfUser,
  areValuesDifferent,
  convertToDecimals,
  getTypeOfUser,
  userPreferredTimeFormat,
} from '@pharmaplan/common';
import { IBillDetails } from '@pharmaplan/common/types/IBillDetails';
import ServiceCard from '../ServiceCard/ServiceCard';
import BillContent from './BillContent/BillContent';
import BillFooter from './BillFooter/BillFooter';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { getTimeWithDiff } from '../../Modals/WorkshiftDetails/utils';
import strings from '../../../localization';
import useAdmin from '../../../hooks/useAdmin';

const { covered, notCovered, custom } = AllowanceTypes;
const { pharmacist, pharmacy } = TypeOfUser;

const BillDetails: FC<IBillDetails> = ({
  billType,
  event,
  percentFees,
  isCounterOffer,
  successAction,
}) => {
  const {
    cost = 0,
    accommodationAllowance,
    travelAllowance,
    mealAllowance,
    counterCost = 0,
    counterStartDate,
    counterEndDate,
    counterAccommodationAllowance = 0,
    counterAccommodationAllowanceType = notCovered,
    accommodationAllowanceType = custom,
    travelAllowanceType = notCovered,
    counterTravelAllowanceType = notCovered,
    mealAllowanceType = notCovered,
    counterMealAllowanceType = notCovered,
    counterTravelAllowance = 0,
    emergencyFees = 0,
    counterMealAllowance = 0,
  } = event ?? {};

  const timeFormat = useAppSelector(userPreferredTimeFormat);
  const userType = useAppSelector(getTypeOfUser);

  const pharmaPlanFees = percentFees ? (percentFees / 100) * cost : null;

  const counterPharmaPlanFees = percentFees
    ? (percentFees / 100) * counterCost
    : null;

  const { isAdmin } = useAdmin();
  const isPharmacist = userType === pharmacist;
  const isPharmacy = userType === pharmacy;

  const suffix = isPharmacy ? ` + ${strings.tax}` : '';

  const counterOfferTime = getTimeWithDiff(
    counterStartDate,
    counterEndDate,
    timeFormat,
  );

  const rateAndTime = isPharmacist
    ? [
      {
        key: '0',
        name: strings.time,
        value: '',
        data: true,
        counterOfferData: counterStartDate,
        counterOfferValue: `${counterOfferTime}`,
      },
    ]
    : [];

  const detailsDisplay = (allowanceType = notCovered, value = 0) => {
    const convertedValue = `$${convertToDecimals(value)}`;
    const parsedAllowanceType = !!allowanceType && allowanceType.toString();

    switch (parsedAllowanceType) {
      case covered:
        return { data: true, value: strings.covered };
      case notCovered:
        return { data: false, value: strings.notCovered };
      case custom:
        return { data: !!value, value: convertedValue };
      default:
        return { data: false, value: 0 };
    }
  };

  const bookingDetailsConfig = [
    {
      key: '2',
      name: isAdmin ? strings.pharmacistContractValue : strings.contractValue,
      value: `$${convertToDecimals(cost)}${suffix}`,
      data: cost,
      amount: cost,
      counterOfferAmount: counterCost,
      counterOfferData: counterCost,
      counterOfferValue: `$${convertToDecimals(counterCost)}`,
    },
    {
      key: '3',
      name: strings.accomodation,
      value: detailsDisplay(accommodationAllowanceType, accommodationAllowance)
        .value,
      data: detailsDisplay(accommodationAllowanceType, accommodationAllowance)
        .data,
      amount: accommodationAllowance,

      // Counter offer
      counterOfferData: detailsDisplay(
        counterAccommodationAllowanceType,
        counterAccommodationAllowance,
      ).data,
      counterOfferValue: detailsDisplay(
        counterAccommodationAllowanceType,
        counterAccommodationAllowance,
      ).value,
      counterOfferAmount: counterAccommodationAllowance,
    },
    {
      key: '4',
      name: strings.emergencyServiceFees,
      value: `$${convertToDecimals(emergencyFees)}`,
      counterOfferValue: `$${convertToDecimals(emergencyFees)}`,
      counterOfferData: true,
      data: emergencyFees,
      amount: emergencyFees,
      counterOfferAmount: emergencyFees,
    },
    {
      key: '5',
      name: strings.travel,
      value: detailsDisplay(travelAllowanceType, travelAllowance).value,
      data: detailsDisplay(travelAllowanceType, travelAllowance).data,
      amount: travelAllowance,

      // Counter offer
      counterOfferData: detailsDisplay(
        counterTravelAllowanceType,
        counterTravelAllowance,
      ).data,
      counterOfferValue: detailsDisplay(
        counterTravelAllowanceType,
        counterTravelAllowance,
      ).value,
      counterOfferAmount: counterTravelAllowance,
    },
    {
      key: '6',
      name: strings.meal,
      value: detailsDisplay(mealAllowanceType, mealAllowance).value,
      data: detailsDisplay(mealAllowanceType, mealAllowance).data,
      amount: mealAllowance,

      // Counter Offer
      counterOfferData: detailsDisplay(
        counterMealAllowanceType,
        counterMealAllowance,
      ).data,
      counterOfferValue: detailsDisplay(
        counterMealAllowanceType,
        counterMealAllowance,
      ).value,
      counterOfferAmount: counterMealAllowance,
    },
    {
      key: '7',
      name: strings.pharmaPlanFees,
      value: `$${convertToDecimals(pharmaPlanFees ?? 0)}`,
      counterOfferData: counterPharmaPlanFees,
      counterOfferValue: `$${convertToDecimals(counterPharmaPlanFees ?? 0)}`,
      data: pharmaPlanFees,
      amount: pharmaPlanFees,
      counterOfferAmount: counterPharmaPlanFees,
    },
  ];

  const counterOfferCriteria = isCounterOffer
    ? {
      checkCriteria: 'counterOfferData',
      checker: areValuesDifferent,
    }
    : {
      checkCriteria: 'data',
      checker: () =>
        true,
    };

  const billTotal = convertToDecimals(
    bookingDetailsConfig?.reduce((acc, curr) =>
      acc + (curr.amount ?? 0), 0),
  );

  const counterOfferBillTotal = convertToDecimals(
    bookingDetailsConfig?.reduce(
      (acc, curr) =>
        acc + (curr.counterOfferAmount ?? 0),
      0,
    ),
  );

  const cardTitle = () => {
    switch (billType) {
      case TypeOfBill.bill:
        return strings.billDetails;
      case TypeOfBill.finalBill:
        return strings.contractDetails;
      case TypeOfBill.counterOffer:
        return isPharmacist ? strings.counterOffer : strings.billDetails;
      default:
        return strings.billDetails;
    }
  };

  return (
    <ServiceCard
      title={cardTitle()}
      isCounterOffer={isCounterOffer}
      content={(
        <BillContent
          successAction={successAction}
          counterOfferCriteria={counterOfferCriteria}
          rateAndTime={rateAndTime}
          billDetails={bookingDetailsConfig}
          isCounterOffer={isCounterOffer}
        />
      )}
      footer={(
        <BillFooter
          successAction={successAction}
          suffix={suffix}
          total={billTotal}
          counterTotal={isCounterOffer && counterOfferBillTotal}
        />
      )}
    />
  );
};

export default BillDetails;
