import {
  IAdminPharmacyListBaseData,
  adminActions,
  adminActivePharmacyList,
  adminDeactivatedPharmacyList,
  adminDeletedPharmacyList,
  getActivePharmacyList,
  getDeactivatedPharmacyList,
  successSelector,
  appointedPharmacyList,
} from '@pharmaplan/common';
import {
  getAppointedPharmaciesList,
  getDeletedPharmacyList,
} from '@pharmaplan/common/actions/adminActions';

import {
  activePharmaciesHeader,
  activePharmaciesList,
  appointedPharmaciesList,
  deactivatedPharmaciesHeader,
  deactivatedPharmaciesList,
  deletedPharmaciesHeader,
  deletedPharmaciesList,
} from '../../../components/Admin/PharmacyList/helper';
import { PharmacyListTabTypes } from '../../../helpers/Constants';
import { useAppSelector } from '../../useAppSelector';
import useGetItems from '../../useGetItems';
import usePharmacyListActions from './usePharmacyListAction';
import useUserProfileLink from '../useUserProfileLink';

const {
  activePharmacies,
  deactivatedPharmacies,
  deletedPharmacies,
  appointedPharmacies,
} = PharmacyListTabTypes;

const usePharmacyListTabs = (selectedTab: PharmacyListTabTypes) => {
  const { actions, activateModal } = usePharmacyListActions();
  const { getImage } = useGetItems();
  const { goToUserProfile } = useUserProfileLink();

  const headersConfig = {
    [activePharmacies]: activePharmaciesHeader(),
    [deactivatedPharmacies]: deactivatedPharmaciesHeader(),
    [deletedPharmacies]: deletedPharmaciesHeader(),
    [appointedPharmacies]: activePharmaciesHeader(),
  };

  const active = useAppSelector(adminActivePharmacyList);
  const deactivated = useAppSelector(adminDeactivatedPharmacyList);
  const deleted = useAppSelector(adminDeletedPharmacyList);
  const appointed = useAppSelector(appointedPharmacyList);

  const activeListSuccess = useAppSelector((state) =>
    successSelector([adminActions.getActivePharmacyList], state));
  const deactivatedListSuccess = useAppSelector((state) =>
    successSelector([adminActions.getDeactivatedPharmacyList], state));
  const deletedSuccess = useAppSelector((state) =>
    successSelector([adminActions.getDeletedPharmacyList], state));
  const appointedSuccess = useAppSelector((state) =>
    successSelector([adminActions.appointedPharmaciesList], state));

  const dataConfig = {
    [activePharmacies]: active,
    [deactivatedPharmacies]: deactivated,
    [deletedPharmacies]: deleted,
    [appointedPharmacies]: appointed,
  };

  const loadSuccessConfig = {
    [activePharmacies]: activeListSuccess,
    [deactivatedPharmacies]: deactivatedListSuccess,
    [deletedPharmacies]: deletedSuccess,
    [appointedPharmacies]: appointedSuccess,
  };

  const { data } = dataConfig[selectedTab as keyof typeof dataConfig];

  const api = {
    [activePharmacies]: getActivePharmacyList,
    [deactivatedPharmacies]: getDeactivatedPharmacyList,
    [deletedPharmacies]: getDeletedPharmacyList,
    [appointedPharmacies]: getAppointedPharmaciesList,
  };

  const rowConfig = {
    [activePharmacies]: activePharmaciesList(
      data as Array<IAdminPharmacyListBaseData>,
      getImage,
      goToUserProfile,
      actions,
    ),
    [deactivatedPharmacies]: deactivatedPharmaciesList(
      data as Array<IAdminPharmacyListBaseData>,
      getImage,
      goToUserProfile,
      activateModal,
    ),
    [deletedPharmacies]: deletedPharmaciesList(
      data as Array<IAdminPharmacyListBaseData>,
      getImage,
      goToUserProfile,
    ),
    [appointedPharmacies]: appointedPharmaciesList(
      data as Array<IAdminPharmacyListBaseData>,
      getImage,
      goToUserProfile,
      actions,
    ),
  };

  return {
    rows: rowConfig[selectedTab],
    headers: headersConfig[selectedTab],
    api: api[selectedTab],
    data: dataConfig[selectedTab],
    loadSuccess: loadSuccessConfig[selectedTab],
  };
};

export default usePharmacyListTabs;
