import React, { FC } from 'react';

import { Box } from '@mui/material';

import {
  convertToDecimals,
  currency,
  IPharmacyAdminPharmaciesData,
  PharmacyLegendVariant,
} from '@pharmaplan/common';
import useGetItems from '../../../../hooks/useGetItems';
import { formatDate } from '../../../Reports/helpers';
import strings from '../../../../localization';

import DetailsCard from './DetailsCard';
import TitleCard from './TitleCard';
import styles from './styles';

const { posted, requested, booked } = PharmacyLegendVariant;

const conf = (postedVal: number, requestedVal: number, bookedVal: number) =>
  [
    {
      key: posted,
      value: postedVal,
      label: strings.posted,
    },
    {
      key: requested,
      value: requestedVal,
      label: strings.requested,
    },
    {
      key: booked,
      value: bookedVal,
      label: strings.booked,
    },
  ];

const contractConf = (totalHour: number, totalContract: number) =>
  [
    {
      key: 1,
      value: totalHour ?? 0,
      label: strings.totalHours,
    },
    {
      key: 2,
      value: `${currency}${convertToDecimals(totalContract) ?? 0}`,
      label: strings.totalContractValue,
    },
  ];

interface IPharmacyAdminCard {
  item: IPharmacyAdminPharmaciesData;
}

const PharmacyAdminCard: FC<IPharmacyAdminCard> = ({ item }) => {
  const {
    name,
    city,
    lastLoginDate,
    logoId,
    postedCount,
    requestedCount,
    bannerNumber,
    bookedCount,
    contractHours,
    contractValue,
    pharmacyId,
  } = item;

  const { getImage } = useGetItems();

  const lastLoginDesc = lastLoginDate
    ? (strings.formatString(
      strings.lastLoggedIn,
        formatDate(lastLoginDate) as string,
    ) as string)
    : strings.lastLoginLongTime;

  return (
    <Box sx={styles.container}>
      <Box
        component="img"
        src={getImage(logoId)}
        alt={strings.banner}
        sx={styles.logo}
      />

      <Box sx={styles.subContainer}>
        <TitleCard
          pharmacyId={pharmacyId}
          pharmacyName={name}
          location={city}
          date={lastLoginDesc}
          licenseNo={bannerNumber}
        />

        {/* DETAILS */}
        <Box sx={styles.detailsContainer}>
          <DetailsCard
            config={conf(postedCount, requestedCount, bookedCount)}
            title={strings.workshiftDetails}
          />
          <Box sx={styles.emptyDivider} />
          <DetailsCard
            config={contractConf(contractHours, contractValue)}
            title={strings.contractBreakdown}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default PharmacyAdminCard;
