import React, { FC, useEffect } from 'react';

import { Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { FormikValues, useFormik } from 'formik';

import {
  AdminLegendVariant,
  adminResetValue,
  IAdminSearchResponse,
  isIncompatbileMatchSelector,
  searchedPharmacies,
  searchedPharmacists,
  TypeOfUser,
  validateExpressBooking,
} from '@pharmaplan/common';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import strings from '../../../localization';
import CustomTextField from '../../common/CustomTextField';
import genericClasses from '../../../theme/GenericClasses';
import CustomDatePicker from '../../common/CustomDatePicker';
import { getZeroHourDate, rateValidation } from '../../../helpers/Functions';
import {
  Constants,
  OtherScreens,
  serverDateKey,
} from '../../../helpers/Constants';
import DateTimeRange from '../../common/DateTimeRange';
import useExpressBooking from '../../../hooks/Admin/ExpressBooking/useExpressBooking';
import CustomAutocomplete from '../../common/CustomAutocomplete';
import {
  IFromScreen,
  renderScreen,
  setBack,
  setFooter,
} from '../../../actions/drawerActions';
import CustomCheckbox from '../../common/CustomCheckbox';
import useAutoCompleteScroll, {
  AutoCompleteScrollTypes,
} from '../../../hooks/useAutoCompleteScroll';

import useExpressBookingHr from '../../../hooks/Admin/ExpressBooking/useExpressBookingHr';

import { checkboxConfig, config, notesConfig } from './helper';
import Validator from './validator';
import styles from './styles';
import useExpressBookingDisabled from '../../../hooks/Admin/ExpressBooking/useExpressBookingDisabled';

const resetVal = 'isIncompatibleMatch';
interface IExpressBooking {
  id?: string;
  workshiftId?: string;
  type: Exclude<AdminLegendVariant, AdminLegendVariant.total>;
  fromScreen?: IFromScreen;
}

const { priceSuffix, allowancePlaceholder, timePlaceholder } = Constants;
const { pharmacist, pharmacy } = TypeOfUser;

export const toOptions = (userSearch: IAdminSearchResponse[]) =>
  userSearch.map((item) => {
    const { userName, email, id } = item ?? {};
    return {
      label: userName,
      subtitle: email,
      id,
    };
  });

const noActionsScreens = [
  OtherScreens.AdminBookingReports,
  OtherScreens.AdminPostedWorkshiftReports,
];

const ExpressBooking: FC<IExpressBooking> = ({
  id,
  type,
  workshiftId,
  fromScreen,
}) => {
  const dispatch = useAppDispatch();

  const pharmacists = useAppSelector(searchedPharmacists);
  const pharmacies = useAppSelector(searchedPharmacies);

  const pharmacistOptions = toOptions(pharmacists);
  const pharmacyOptions = toOptions(pharmacies);

  const {
    debouncedInput,
    getFormikInitialValues,
    debouncedPharmacyInput,
    confirmAction,
    detailsApiMap,
    bookWorkshift,
  } = useExpressBooking(type, id);

  const { onEndReached, pharmacistListData, pharmacyListData } = useAutoCompleteScroll(AutoCompleteScrollTypes.all);

  const noActionsScreen = noActionsScreens.includes(fromScreen as OtherScreens);

  const formikInitialValues = getFormikInitialValues();
  const isIncompatibleMatch = useAppSelector(isIncompatbileMatchSelector);

  const isBooking = type === AdminLegendVariant.booked;
  const isEventFromCalendar = !!id;

  const handleSubmit = (values: FormikValues) => {
    const { pharmacistHourlyRate, pharmacyHourlyRate } = values ?? {};
    const pharmacyGreaterPharmacist = rateValidation({
      pharmacistRate: pharmacistHourlyRate,
      pharmacyRate: pharmacyHourlyRate,
      dispatch,
    });

    if (pharmacyGreaterPharmacist) {
      dispatch(
        validateExpressBooking({
          pharmacistId: values.pharmacistId,
          pharmacyId: values.pharmacyId,
        }),
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      notesForSalesOrder: '',
      sendEmailToPharmacist: false,
      sendEmailToPharmacy: false,
      ...formikInitialValues,
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: Validator(),
  });

  const { pharmacists: formikPharmacists, pharmacies: formikPharmacies } = formik.values;

  useExpressBookingHr(formik, id ?? '');
  const { disabled } = useExpressBookingDisabled(formik.values.pharmacistId, isBooking);

  useEffect(() => {
    const back = () => {
      dispatch(
        renderScreen({
          eventId: workshiftId,
          ...detailsApiMap[type].back,
          date: detailsApiMap[type].details.startDate,
        }),
      );
    };

    if (id && !noActionsScreen) {
      dispatch(setBack(back));
    }

    dispatch(
      setFooter({
        show: true,
        pagination: {
          show: false,
        },
        buttons: {
          show: true,
          primaryButton: {
            label: strings.saveBooking,
            onClick: formik.handleSubmit,
          },
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikInitialValues.pharmacyId, formik.values]);

  const handleIncompatibleMatch = () => {
    if (isIncompatibleMatch) {
      confirmAction(() =>
        bookWorkshift(formik.values));
    } else {
      bookWorkshift(formik.values);
    }
    dispatch(adminResetValue([resetVal]));
  };

  useEffect(() => {
    if (isIncompatibleMatch !== null) {
      handleIncompatibleMatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIncompatibleMatch]);

  useEffect(() => {
    debouncedInput(formik.values.pharmacists);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput, formik.values.pharmacists]);

  useEffect(() => {
    if (!id) {
      debouncedPharmacyInput(formik.values.pharmacies);
    }
  }, [debouncedPharmacyInput, formik.values.pharmacies, id]);

  const serverToday = getZeroHourDate(
    sessionStorage.getItem(serverDateKey) ?? '',
  );

  const handleNegativeDates = () => {
    formik.setFieldValue('endTime', null);
  };

  const pharmacyOption = formikPharmacies ? pharmacyOptions : pharmacyListData;
  const pharmacistOption = formikPharmacists
    ? pharmacistOptions
    : pharmacistListData;

  const isFormikError = formik.errors.selectedDate && formik.touched.selectedDate;
  const error = isFormikError ? formik.errors.selectedDate : ' ';

  return (
    <Stack sx={styles.container}>
      <CustomAutocomplete
        label={strings.pharmacy}
        onEndReached={onEndReached(pharmacy)}
        infiniteScroll={!formikPharmacies}
        idName="pharmacyId"
        textName="pharmacies"
        autoCompleteName="pharmacySelected"
        placeholder={
          strings.formatString(strings.selectUser, strings.pharmacy) as string
        }
        disabled={isEventFromCalendar}
        formik={formik}
        options={pharmacyOption}
      />
      <CustomDatePicker
        formik={formik}
        minDate={serverToday}
        disabled={isEventFromCalendar}
        label={strings.selectedDate}
        error={error as string}
        name="selectedDate"
        readOnly
      />

      {/* IF BOOKING OR NO ID THEN ENABLE */}
      <Box component="div" sx={styles.timeContainer}>
        <DateTimeRange
          formik={formik}
          disabled={!(isBooking || !id)}
          startName="startTime"
          endName="endTime"
          customStartHandler={handleNegativeDates}
          startPlaceholder={timePlaceholder}
          endPlaceholder={timePlaceholder}
          startNameLabel={strings.startTime}
          endNameLabel={strings.endTime}
          horizontal={false}
          removeMargin
        />
      </Box>

      <CustomAutocomplete
        label={strings.pharmacist}
        idName="pharmacistId"
        onEndReached={() =>
          onEndReached(pharmacist)}
        infiniteScroll={!formikPharmacists}
        textName="pharmacists"
        autoCompleteName="pharmacistSelected"
        placeholder={
          strings.formatString(strings.selectUser, strings.pharmacist) as string
        }
        formik={formik}
        options={pharmacistOption}
      />

      {notesConfig.map((item) => {
        const { name, label, key } = item ?? {};
        return (
          <CustomTextField
            key={key}
            variant="outlined"
            formik={formik}
            id={name}
            placeholder=" "
            label={label}
            name={name}
            multiline
            maxLength={200}
            customClass={genericClasses.borderNoneTextField}
          />
        );
      })}
      <Typography sx={styles.title}>{strings.rateAndAllowances}</Typography>
      {config.map((item) => {
        const { key, name: formikName, label } = item ?? {};
        return (
          <CustomTextField
            label={label}
            suffix={priceSuffix}
            customClass={styles.horizontalField}
            placeholder={allowancePlaceholder}
            horizontal
            id={formikName}
            formik={formik}
            name={formikName}
            key={key}
          />
        );
      })}
      {checkboxConfig.map((item) => {
        const { name, label, key } = item ?? {};
        return (
          <CustomCheckbox
            key={key}
            labelClass={styles.checkboxLabel}
            disabled={disabled}
            value={disabled || formik.values[name as keyof typeof formik.values] as boolean}
            name={name}
            label={label}
            id=""
            handleChange={(event) =>
              formik.setFieldValue(name, event.target.checked)}
          />
        );
      })}
    </Stack>
  );
};

export default ExpressBooking;
