import { createAsyncThunk } from '@reduxjs/toolkit';
import PharmacyAdminController from '../controllers/PharmacyAdminController';
import { ICreatePharmacyAdmin } from '../models';
import { statusDispatch } from './globalActions';

const pharmacyAdmin = 'pharmacyAdmin';

export const pharmacyAdminActions = {
  getPharmacyAdminPharmacies: `${pharmacyAdmin}/get/pharmacies`,
  impersonatePharmacy: `${pharmacyAdmin}/impersonate/pharmacy`,
  getPharmacyAdminProfile: `${pharmacyAdmin}/get/profile`,
  updatePharmacyAdminProfile: `${pharmacyAdmin}/update/profile`,
};

export const getPharmacyAdminPharmacies = createAsyncThunk(
  pharmacyAdminActions.getPharmacyAdminPharmacies,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await PharmacyAdminController.pharmacyAdminPharmacies();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const impersonatePharmacy = createAsyncThunk(
  pharmacyAdminActions.impersonatePharmacy,
  async (id: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacyAdminController.impersonatePharmacy(id);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacyAdminProfile = createAsyncThunk(
  pharmacyAdminActions.getPharmacyAdminProfile,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await PharmacyAdminController.getPharmacyAdminProfile();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updatePharmacyAdminProfile = createAsyncThunk(
  pharmacyAdminActions.updatePharmacyAdminProfile,
  async (params: ICreatePharmacyAdmin, { rejectWithValue }) => {
    try {
      const resp = await PharmacyAdminController.updatePharmacyAdminProfile(
        params
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
