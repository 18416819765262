import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import {
  ConfirmationTypes,
  enforceLogout,
  LoginType,
  resetStatus,
  setEmailChangeReset,
  SocialLoginType,
} from '@pharmaplan/common';

import styles from '../../components/common/ChangeEmailField/styles';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import OutcomeModal from '../../components/Modals/OutcomeModal';
import { resetDialog, setDialog } from '../../reducers/dialogReducer';
import { Constants, OutcomeModalTypes } from '../../helpers/Constants';
import { logoutHandler } from '../../helpers/Functions';
import strings from '../../localization';

import { useAppSelector } from '../useAppSelector';
import { useAppDispatch } from '../useAppDispatch';
import useAdmin from '../useAdmin';

const { changeEmail, editEmail } = ConfirmationTypes;
const { pharmaplan } = LoginType;

const useChangeEmail = (
  setDisabled?: React.Dispatch<React.SetStateAction<boolean>>,
  loginType?: SocialLoginType | '0',
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAdmin } = useAdmin();

  const loginTypes = {
    [pharmaplan]: '',
    [SocialLoginType.google]: strings.gmail,
    [SocialLoginType.facebook]: strings.facebook,
    [SocialLoginType.apple]: strings.apple,
  };

  const isPharmaPlanUser = loginType === pharmaplan;

  const shouldEnforceLogout = useAppSelector(enforceLogout);

  const confirmAction = () => {
    navigate(Constants.paths.setNewEmail);
    dispatch(resetDialog());
  };

  const confirmForAdmin = () => {
    if (isAdmin) {
      setDisabled?.(false);
      dispatch(resetDialog());
    }
  };

  const confirmChangeEmail = () => {
    if (isPharmaPlanUser || isAdmin) {
      dispatch(
        setDialog({
          Component: (
            <ConfirmationModal
              type={editEmail}
              confirmAction={isAdmin ? confirmForAdmin : confirmAction}
            />
          ),
          showCloseButton: false,
          heading: { title: '' },
        }),
      );
      return;
    }

    dispatch(
      setDialog({
        Component: (
          <ConfirmationModal
            type={changeEmail}
            confirmAction={confirmAction}
            customMessage={(
              <Typography sx={styles.dialogMessage}>
                {strings.formatString(
                  strings.changeEmailConfirmation,
                  loginTypes[loginType ?? pharmaplan],
                )}
              </Typography>
            )}
          />
        ),
        heading: {
          title: '',
        },
        showCloseButton: false,
      }),
    );
  };

  const closeFunc = () => {
    logoutHandler();
    dispatch(resetDialog());
    dispatch(setEmailChangeReset(false));
    dispatch(resetStatus());
  };

  const showSessionReset = () => {
    dispatch(
      setDialog({
        Component: (
          <OutcomeModal
            type={OutcomeModalTypes.sessionReset}
            customClose={closeFunc}
            message={strings.logoutConfirmation}
            showTopPadding
          />
        ),
        heading: {
          title: '',
        },
        showCloseButton: false,
      }),
    );
  };

  const prematureLogout = (email: string) => {
    if (shouldEnforceLogout) {
      showSessionReset();
    } else {
      navigate(Constants.paths.addPassword, { state: email, replace: true });
    }
  };

  return { confirmChangeEmail, prematureLogout, showSessionReset };
};

export default useChangeEmail;
