import React, { useCallback, useMemo } from 'react';

import { FormikValues } from 'formik';

import {
  adminDeleteAvailability,
  AvailabilityTypes,
  multipleAvails,
  PharmacistLegendVariant,
} from '@pharmaplan/common';
import { setMultipleAvailabilities } from '@pharmaplan/common/actions/calendarActions';

import DeactivateDialog from '../components/Admin/PharmacistList/DeactivateDialog';
import { getMultipleEventAdd } from '../selectors/calendarSelector';
import ICalendarEventType from '../types/ICalendarEventType';
import { DeactivateModalIcon } from '../helpers/Constants';
import { setDialog } from '../reducers/dialogReducer';
import ICalendar from '../types/ICalendar';
import strings from '../localization';

import { useAppSelector } from './useAppSelector';
import { useAppDispatch } from './useAppDispatch';
import useAdmin from './useAdmin';

const { availability, booked, requested } = PharmacistLegendVariant;
const { tempAvailability } = AvailabilityTypes;

interface IHandleMultipleClick {
  type: ICalendarEventType | AvailabilityTypes;
  availableTimeId?: string;
}

const useMultipleDelete = () => {
  const dispatch = useAppDispatch();

  const multipleAvailabilities = useAppSelector(multipleAvails);
  const isMultipleAdd = useAppSelector(getMultipleEventAdd);

  const { isAdmin } = useAdmin();

  const adminAvailabilities = useMemo(() => {
    const availabilties = multipleAvailabilities?.data?.map((item) => {
      const { isRequested, availabilityStatus } = item;
      let type = availability;

      if (isRequested) {
        type = requested;
      } else if (availabilityStatus) {
        type = booked;
      }

      return {
        ...item,
        type,
      } as unknown as ICalendar;
    });

    return availabilties;
  }, [multipleAvailabilities.data]);

  const handleDeleteClick = (id?: string) => {
    const arr = multipleAvailabilities.data.map((item) => {
      if (item.availableTimeId === id) {
        return { ...item, isDeleted: !item.isDeleted };
      }
      return { ...item };
    });

    dispatch(setMultipleAvailabilities(arr));
  };

  const handleReset = () => {
    const arr = multipleAvailabilities.data.map((item) =>
      ({
        ...item,
        type: PharmacistLegendVariant.availability,
        isDeleted: false,
      }));

    dispatch(setMultipleAvailabilities(arr));
  };

  const getDeletedAvailabilities = useCallback(
    () =>
      multipleAvailabilities.data
        .filter((item) =>
          item.isDeleted)
        .map((item) =>
          item.availableTimeId),
    [multipleAvailabilities.data],
  );

  const deleteSelectedAvailabilities = (values: FormikValues) => {
    const { notes } = values;
    const deletedAvails = getDeletedAvailabilities();

    dispatch(
      adminDeleteAvailability({ availabilityIds: deletedAvails, notes }),
    );
  };

  const showDeleteConfirmation = () => {
    dispatch(
      setDialog({
        Component: (
          <DeactivateDialog
            validator={null}
            onSubmit={deleteSelectedAvailabilities}
            confirmationMessage={strings.confirmDeleteAvailability}
            id=""
            initialValues={{
              notes: '',
            }}
            heading={strings.deleteAvailability}
            iconType={DeactivateModalIcon.warning}
            name="notes"
          />
        ),
        heading: {
          title: '',
        },
        showCloseButton: true,
      }),
    );
  };

  const stopMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isAdmin) e.stopPropagation();
  };

  const handleMultipleClicks = ({
    type,
    availableTimeId,
  }: IHandleMultipleClick) => {
    const isMultipleAddAvailability = isMultipleAdd && type === availability && isAdmin;
    const allowClick = isMultipleAddAvailability || type === tempAvailability;

    if (isMultipleAddAvailability) {
      handleDeleteClick(availableTimeId);
    }

    return allowClick;
  };

  return {
    adminAvailabilities,
    handleMultipleClicks,
    deleteSelectedAvailabilities: showDeleteConfirmation,
    getDeletedAvailabilities,
    handleDeleteReset: handleReset,
    stopMouseDown,
  };
};

export default useMultipleDelete;
