import React, { FC } from 'react';

import { Box } from '@mui/system';
import { Avatar, Button, Typography } from '@mui/material';

import {
  AvailabilityTypes,
  getTypeOfUser,
  PharmacistLegendVariant,
  profileImage,
  TypeOfUser,
} from '@pharmaplan/common';
import { ReactComponent as ClockIcon } from '../../../../../assets/svg/clockIcon.svg';
import ICalendarEventType from '../../../../../types/ICalendarEventType';
import useGetItems from '../../../../../hooks/useGetItems';
import useDetailsNavigation from '../../../../../hooks/Admin/useDetailsNavigation';
import useAdmin from '../../../../../hooks/useAdmin';

import styles from './style';
import { useAppSelector } from '../../../../../hooks/useAppSelector';

interface ICalendarTooltipContent {
  eventType: ICalendarEventType;
  onClick: (inId: string) => void;
  durations: Array<{
    label: string;
    key: string;
    logoId?: string;
    id: string;
    workshiftId: string;
  }>;
}

const CalendarTooltipContent: FC<ICalendarTooltipContent> = ({
  eventType,
  durations,
  onClick,
}) => {
  const { getImage } = useGetItems();
  const { handleNavigation } = useDetailsNavigation();
  const { isAdmin } = useAdmin();

  const userType = useAppSelector(getTypeOfUser);
  const avatar = useAppSelector(profileImage);

  const isPharmacy = userType === TypeOfUser.pharmacy;
  const isAvailability = eventType === PharmacistLegendVariant.availability;
  const isTempAvailability = (eventType as unknown) === AvailabilityTypes.tempAvailability;
  const isClickable = !isAvailability && !isTempAvailability;

  const tooltipClick = (id: string, workshiftId: string) =>
    () => {
      if (isAdmin) {
        handleNavigation(id, eventType, workshiftId)();
      } else {
        onClick(id);
      }
    };

  return (
    <Box sx={styles.container}>
      {durations.map((item, index) => {
        const { key, label, logoId, id, workshiftId } = item ?? {};
        const notLastElement = index !== durations.length - 1;
        const image = getImage(logoId ?? '');

        return (
          <Box
            key={key}
            component={isClickable ? Button : 'div'}
            onClick={
              isClickable
                ? tooltipClick(id, workshiftId) // eslint-disable-next-line @typescript-eslint/no-empty-function
                : () => {}
            }
            sx={[
              styles.infoContainer,
              notLastElement && styles.infoBorder(eventType),
            ]}
          >
            {!(isAvailability || isTempAvailability) ? (
              <Avatar
                src={isPharmacy ? avatar : image}
                sx={styles.imageContainer}
                imgProps={{ style: styles.image }}
              />
            ) : (
              <ClockIcon />
            )}
            <Typography sx={styles.infoFont}>{label}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};
export default CalendarTooltipContent;
