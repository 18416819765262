import React from 'react';

import { getNearbyProps, setNearbyProps } from '@pharmaplan/common';
import { setDialog } from '../../reducers/dialogReducer';
import OutcomeModal from '../../components/Modals/OutcomeModal';
import { OutcomeModalTypes } from '../../helpers/Constants';
import strings from '../../localization';

import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';

const { error } = OutcomeModalTypes;
const geolocation = 'geolocation';
const granted = 'granted';

const useNearbyPharmacies = () => {
  const dispatch = useAppDispatch();
  const nearbyProps = useAppSelector(getNearbyProps);

  const showErrorModal = () => {
    dispatch(
      setDialog({
        showCloseButton: true,
        Component: (
          <OutcomeModal type={error} message={strings.pleaseEnableLocation} />
        ),
        heading: {
          title: '',
        },
      }),
    );
  };

  const checkForLocation = () => {
    navigator.permissions
      .query({ name: geolocation })
      .then((permissionStatus) => {
        const permissionAccess = permissionStatus.state === granted;
        if (permissionAccess) {
          dispatch(
            setNearbyProps({
              ...nearbyProps,
              showNearBy: !nearbyProps.showNearBy,
            }),
          );
        } else {
          showErrorModal();
        }
      });
  };

  return { checkForLocation };
};

export default useNearbyPharmacies;
