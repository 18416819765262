import React, { useEffect, useMemo, useState } from 'react';

import { FormikValues, useFormik } from 'formik';
import { Grid } from '@mui/material';

import {
  getLanguages,
  getPharmacyAdminProfile,
  ICreatePharmacyAdmin,
  pharmacyAdminProfile,
  updatePharmacyAdminProfile,
} from '@pharmaplan/common';

import ProfileFormGenerator from '../../Profile/ProfileForm/ProfileFormGenerator';
import ResponsiveClasses from '../../../theme/ResponsiveClasses';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import ContentContainer from '../../common/ContentContainer';
import ProfileSettings from '../../Profile/ProfileSettings';
import AdsContainer from '../../common/AdsContainer';

import { pharmacyAdminGeneralProfile } from './helper';

const PharmacyAdminProfile = () => {
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState('general');

  const languages = useAppSelector(getLanguages);
  const profile = useAppSelector(pharmacyAdminProfile);

  const handleSelection = (screen: string) => {
    setSelected(screen);
  };

  const handleSubmit = (values: FormikValues) => {
    dispatch(updatePharmacyAdminProfile(values as ICreatePharmacyAdmin));
  };

  const { pharmacies, pharmacyCompany } = profile ?? {};
  const { user } = pharmacyCompany ?? {};

  const imageList = useMemo(
    () =>
      pharmacies?.map((item) => {
        const { id, logoId, userName, email } = item;
        return {
          id,
          logoId,
          name: userName,
          email,
        };
      }),
    [pharmacies],
  );

  const formik = useFormik({
    initialValues: {
      ...user,
      ...pharmacyCompany,
      pharmacyIds: pharmacies.map((item) =>
        item.id),
    },
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const profileMap = pharmacyAdminGeneralProfile(languages, imageList).general;

  useEffect(() => {
    dispatch(getPharmacyAdminProfile());
  }, []);

  return (
    <Grid spacing={2} container>
      {/* Settigns list */}
      <Grid xs={3} lg={2} item>
        <ProfileSettings selected={selected} onClick={handleSelection} />
      </Grid>
      {/* Content List */}
      <Grid xs={9} lg={8} item>
        <ContentContainer>
          {profileMap.map((item) => {
            const { form, title, hideSave } = item;
            return (
              <ProfileFormGenerator
                formik={formik}
                items={form()}
                title={title}
                hideSave={hideSave}
              />
            );
          })}
        </ContentContainer>
      </Grid>
      <Grid sx={ResponsiveClasses.hideOnLg} lg={2} item>
        <AdsContainer />
      </Grid>
    </Grid>
  );
};

export default PharmacyAdminProfile;
