import React from 'react';

import { IPharmacyDetailModel, convertToDecimals, currency, ServiceTypes } from '@pharmaplan/common';

import ServiceHandler from '@pharmaplan/common/helpers/ServiceHandler';
import strings from '../../../../../localization';
import PharmacyOperation from '../../../../SelfService/PharmacyOperation';

const representNullValue = '-';

interface IPharmacyProfileConfig {
  details: IPharmacyDetailModel;
  language: string;
  software: string;
  successAction: string;
  selectedPreferences: string;
}

const selfServiceConfigKey = 'pharmaGapConfig';

export const handleValue = (value: string | number | null) => {
  if (!value) {
    return representNullValue;
  }

  return value;
};

export const handleBooleanValue = (value: boolean) => {
  if (!value) {
    return strings.no;
  }

  return strings.yes;
};

export const convertDecimal = (rate: number | null): string | null => {
  if (rate) {
    return `${currency}${convertToDecimals(rate)}`;
  }
  return `${currency}0`;
};

export const pharmacyProfileConfig = ({
  details,
  language,
  software,
  successAction,
  selectedPreferences,
}: IPharmacyProfileConfig) => {
  const isSelfService = ServiceHandler.getService() === ServiceTypes.self;

  const { pharmacy } = details ?? {};
  const {
    pharmacyShortName,
    phone,
    email,
    emergencyPhone,
    administrator,
    code,
    useLSE,
    number,
    fax,
    province,
    address,
    postalCode,
    region,
    city,
    primaryContactEmail,
    primaryContactMobile,
    primaryContactName,
    primaryContactPhone,
    primaryContactPhoneExt,
    secondaryContactEmail,
    name,
    secondaryContactMobile,
    secondaryContactName,
    secondaryContactPhone,
    secondaryContactPhoneExt,
    accountingContactEmail,
    accountingContactMobile,
    accountingContactName,
    accountingContactPhone,
    accountingContactPhoneExt,
    hourlyRate,
    hourlyRateTech,
    banner,
    pharmacyHourlyRate,
    noCancellationPeriod,
    pharmaPlanPercentFees,
    cancellationPercentFees,
  } = pharmacy ?? {};

  const pharmacistRate = convertDecimal(hourlyRate);

  const technicianHourlyRate = convertDecimal(hourlyRateTech);
  const pharmacyRate = convertDecimal(pharmacyHourlyRate);

  const arr = [
    {
      key: 0,
      title: strings.general,
      keepExpanded: true,
      cardContent: [
        {
          key: 0,
          label: strings.email,
          value: handleValue(email),
        },
        {
          key: 1,
          label: strings.ownerCompanyName,
          value: handleValue(name),
        },
        {
          key: 2,
          label: strings.shortNamePharmacy,
          value: handleValue(pharmacyShortName),
        },
        {
          key: 3,
          label: strings.banner,
          value: handleValue(banner),
        },
        {
          key: 4,
          label: strings.bannerNumber,
          value: handleValue(number),
        },
        {
          key: 5,
          label: strings.software,
          value: handleValue(software),
        },
        {
          key: 6,
          label: strings.contactPreferences,
          value: handleValue(selectedPreferences),
        },
        {
          key: 7,
          label: strings.preferredLanguage,
          value: handleValue(language),
        },
        {
          key: 8,
          label: strings.pharmaplanID,
          value: handleValue(code),
        },
        {
          key: 9,
          label: strings.administrator,
          value: handleValue(administrator),
        },
      ],
    },
    {
      key: 1,
      title: strings.coordinates,
      cardContent: [
        {
          key: 0,
          label: strings.pharmacyPhone,
          value: handleValue(phone),
        },
        {
          key: 1,
          label: strings.pharmacyFax,
          value: handleValue(fax),
        },
        {
          key: 2,
          label: strings.emergencyPhone,
          value: handleValue(emergencyPhone),
        },
        {
          key: 3,
          label: strings.address,
          value: handleValue(address),
        },
        {
          key: 4,
          label: strings.city,
          value: handleValue(city),
        },
        {
          key: 5,
          label: strings.province,
          value: handleValue(province),
        },
        {
          key: 6,
          label: strings.postalCode,
          value: handleValue(postalCode),
        },
        {
          key: 7,
          label: strings.region,
          value: handleValue(region),
        },
      ],
    },
    {
      key: 2,
      title: strings.operationalInfo,
      customComponent: (
        <PharmacyOperation
          successAction={successAction}
          title=""
          customStyle={{ padding: 0 }}
          event={pharmacy}
        />
      ),
    },
    {
      key: 3,
      title: strings.contactDetails,
      cardContent: [
        {
          key: 0,
          label: strings.primaryContactName,
          value: handleValue(primaryContactName),
        },
        {
          key: 1,
          label: strings.primaryContactPhone,
          value: handleValue(primaryContactPhone),
        },
        {
          key: 2,
          label: strings.contactPhoneExtension,
          value: handleValue(primaryContactPhoneExt),
        },
        {
          key: 3,
          label: strings.primaryContactMobile,
          value: handleValue(primaryContactMobile),
        },
        {
          key: 4,
          label: strings.primaryContactEmail,
          value: handleValue(primaryContactEmail),
        },
        {
          key: 5,
          label: strings.secondaryContactName,
          value: handleValue(secondaryContactName),
        },
        {
          key: 6,
          label: strings.secondaryContactPhone,
          value: handleValue(secondaryContactPhone),
        },
        {
          key: 7,
          label: strings.contactPhoneExtension,
          value: handleValue(secondaryContactPhoneExt),
        },
        {
          key: 8,
          label: strings.secondaryContactMobile,
          value: handleValue(secondaryContactMobile),
        },
        {
          key: 9,
          label: strings.secondaryContactEmail,
          value: handleValue(secondaryContactEmail),
        },
        {
          key: 10,
          label: strings.accountingContactName,
          value: handleValue(accountingContactName),
        },
        {
          key: 11,
          label: strings.accountingContactPhone,
          value: handleValue(accountingContactPhone),
        },
        {
          key: 12,
          label: strings.contactPhoneExtension,
          value: handleValue(accountingContactPhoneExt),
        },
        {
          key: 13,
          label: strings.accountingContactMobile,
          value: handleValue(accountingContactMobile),
        },
        {
          key: 14,
          label: strings.accountingContactEmail,
          value: handleValue(accountingContactEmail),
        },
      ],
    },
    {
      key: 4,
      title: strings.preferences,
      cardContent: [
        {
          key: 0,
          label: strings.useLabSchedulingExt,
          value: handleBooleanValue(useLSE),
        },
      ],
    },
    {
      key: 5,
      title: strings.hourlyRates,
      cardContent: [
        {
          key: 0,
          label: strings.pharmacistHourlyRate,
          value: handleValue(pharmacistRate),
        },
        {
          key: 1,
          label: strings.pharmacyHourlyRate,
          value: handleValue(pharmacyRate),
        },
        {
          key: 2,
          label: strings.technicianHourlyRate,
          value: handleValue(technicianHourlyRate),
        },
      ],
    },
    {
      key: selfServiceConfigKey,
      title: strings.configurations,
      cardContent: [
        {
          key: 0,
          label: strings.noCancellationPeriod,
          value: handleValue(`${noCancellationPeriod} ${strings.days}`),
        },
        {
          key: 1,
          label: strings.pharmaPlanFees,
          value: handleValue(`${pharmaPlanPercentFees}%`),
        },
        {
          key: 2,
          label: strings.cancellationFees,
          value: handleValue(`${cancellationPercentFees}%`),
        },
      ],
    },
  ];

  if (!isSelfService) {
    return arr.filter((item) =>
      item.key !== selfServiceConfigKey);
  }

  return arr;
};
