const classes = {
  mainCalendarContainer: {
    backgroundColor: 'secondary.main',
    width: '100%',
    borderRadius: '10px',
  },
  calendar: (height:number) =>
    ({
      height: ((height * 3) / 4) - 40,
    }),
  selectableCalendar: (height:number) =>
    ({
      height: ((height * 3) / 4) - 40,
      cursor: 'pointer',
    }),
};

export default classes;
