import React, { FC, useCallback, useEffect, useState } from 'react';

import {
  adminActions,
  getPharmaciesForPharmacyAdmin,
  getPharmaciesForPharmacyAdminPagination,
  ITEMS_PER_PAGE,
} from '@pharmaplan/common';

import { ITransferListItem } from '../../../../common/TransferList/TransferList';
import useInfiniteScroll from '../../../../../hooks/useInfiniteScroll';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { IListState } from '../../../../../hooks/useTransferList';
import PharmacyTransferSearch from './PharmacyTransferSearch';
import { ListType } from '../../../../../helpers/Constants';
import TransferList from '../../../../common/TransferList';
import strings from '../../../../../localization';

interface IPharmacyAdminTransferList {
  list: IListState;
  setList: React.Dispatch<React.SetStateAction<IListState>>;
  left: Array<ITransferListItem>;
  right: Array<ITransferListItem>;
}

const PharmacyTransferList: FC<IPharmacyAdminTransferList> = ({
  list,
  left,
  right,
  setList,
}) => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState({ left: 1, right: 1 });

  const observerCallback = useCallback(
    (type: ListType) =>
      (entries: { isIntersecting: any }[]) => {
        const currentData = type === ListType.left ? left : right;
        const paginationCondition = currentData.length >= page[type] * ITEMS_PER_PAGE;
        if (paginationCondition && entries[0].isIntersecting) {
          setPage((prevPage) =>
            ({
              ...prevPage,
              left: prevPage[type] + 1,
            }));
        }
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, left, right],
  );

  const { listEndRef: leftRef } = useInfiniteScroll({
    observerCallback: observerCallback(ListType.left),
  });
  const { listEndRef: rightRef } = useInfiniteScroll({
    observerCallback: observerCallback(ListType.right),
  });

  useEffect(() => {
    if (page.left !== 1) {
      dispatch(getPharmaciesForPharmacyAdminPagination({ page: page.left }));
    }
  }, [page.left]);

  useEffect(() => {
    dispatch(getPharmaciesForPharmacyAdmin({ page: 1 }));
  }, []);

  return (
    <TransferList
      leftOrnament={<PharmacyTransferSearch setPage={setPage} />}
      leftTitle={strings.allPharmacies}
      rightTitle={strings.myPharmacies}
      rightDesc={strings.atleastTwoPharmacies}
      leftDesc={strings.searchAndSelectPharmacies}
      list={list}
      setList={setList}
      leftRef={leftRef}
      rightRef={rightRef}
      successAction={adminActions.createPharmacyChain}
      allowSingle={false}
    />
  );
};

export default PharmacyTransferList;
